import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BlogComponent from "../../components/BlogComponent/BlogComponent";
import style from "./NewsLetter.module.css";
import arrow from "../../Assets/arrow.png";
import cross from "../../Assets/plus.png";
import Footer from "../../components/Footer/Footer";
import { ListAllBlogs } from "../../services/blogsApi";

// Utility function to strip HTML tags
const stripHtmlTags = (str) => {
  return str ? str.replace(/<\/?[^>]+(>|$)/g, "") : "";
};

const NewsLetter = () => {
  const [ShowModal, setShowModal] = useState("main-modal-container_closed");
  const [subscribed, setSubscribed] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const token = localStorage.getItem("access_token");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (ShowModal === "main-modal-container") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [ShowModal]);

  const handleSubscribe = async () => {
    try {
      await fetch("https://backend.venturebuddy.io/subscribenl/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setShowModal("main-modal-container");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await fetch(`${BASE_URL}subscribenl/`, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const res = await response.json();
        setSubscribed(res === true);
      } catch (error) {
        console.log(error);
      }
    };
    checkSubscription();
  }, [BASE_URL, token]);

  const fetchAllBlogs = async () => {
    try {
      const response = await ListAllBlogs();
      setAllBlogs(response.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  return (
    <>
      <div className={style["main-newsletter-container"]}>
        <div className={style[ShowModal]}>
          <div className={style["modal-message-container"]}>
            <div className={style["button-container"]}>
              <img
                src={arrow}
                className={style["arrow-btn"]}
                alt=""
                onClick={() => {
                  setShowModal("main-modal-container_closed");
                }}
              />
              <img
                src={cross}
                className={style["cross-btn"]}
                onClick={() => {
                  setShowModal("main-modal-container_closed");
                }}
                alt=""
              />
            </div>
            <h1>Thanks! You have successfully Subscribed to the mailing List</h1>
            <p>Check your mail for confirmation.</p>
            <button
              onClick={() => {
                setShowModal("main-modal-container_closed");
              }}
            >
              Done
            </button>
          </div>
        </div>
        <div className={style["heading"]}>
          <h1>Blog</h1>
        </div>
        <div className={style["blog-container"]}>
          <div className={style["highlighted-blog"]}>
            <BlogComponent lastBlog={allBlogs[allBlogs.length - 1]} />
          </div>
          <div className={style["blog-list-container"]}>
            {allBlogs?.map((item, index) => (
              <Link to={`/blog/${item?.slug}`} key={index}>
                <div className={style["blog-card-container"]}>
                  <img src={item?.blog_image} className={style["card-image"]} alt="" />
                  <div className={style["card-content"]}>
                    <h4>{item?.blog_title}</h4>
                    <p>{stripHtmlTags(item?.blog).substring(0, 100)}...</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className={style["subscribe-container"]}>
          <h1>Want to subscribe to our Newsletter?</h1>
          <p>Click the button to subscribe to our latest Articles and blogs.</p>
          <button onClick={handleSubscribe}>
            {subscribed ? "Subscribed" : "Subscribe"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsLetter;
