import axios from 'axios'
import { BASE_URL } from '../utils/constant'

const getHeaders = () => {
  const token = localStorage.getItem('access_token')
  const headers = {
    'Content-Type': 'application/json',
  }
  if (token) headers['Authorization'] = `Bearer ${token}`
  return headers
}

export const getAllStartups = async (query, cb) => {
  const headers = getHeaders()
  delete headers['Authorization']
  await axios
    .get(`${BASE_URL}/liststartups/?${query}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const getStartupDetails = async (slug, cb) => {
  const headers = getHeaders()
  delete headers['Authorization']
  await Promise.allSettled([axios.get(`${BASE_URL}/getDetailStartup/?slug=${slug}`, { headers }), axios.get(`${BASE_URL}/founders/?slug=${slug}`, { headers }), axios.get(`${BASE_URL}/industryoverview/?slug=${slug}`, { headers }), axios.get(`${BASE_URL}/financialsynopsis/?slug=${slug}`, { headers }), axios.get(`${BASE_URL}/fundusage/?slug=${slug}`, { headers }), axios.get(`${BASE_URL}/swotanalysis/?slug=${slug}`, { headers })])
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const createInvestment = async (data, cb) => {
  const headers = getHeaders()
  await axios
    .post(`${BASE_URL}/invest_now/`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const postInvestmentUserDetail = async (data, cb) => {
  const headers = getHeaders()
  await axios
    .post(`${BASE_URL}/investmentuserdetails/`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const getPaymentUrl = async (cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/pay/`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const verifyPayment = async (orderId, cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/verifypay/?orderid=${orderId}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const getInvoiceDetails = async (orderId, cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/invoice/?orderid=${orderId}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const getConvenienceFee = async (id, cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/getconveniencefee/?startup_id=${id}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const getmyInvest = async (slug, cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/getstartupupdates/?slug=${slug}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const getmyInvest_updates = async (slug, cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/getDetailStartup/?slug=${slug}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const getmyInvest_details = async (id, cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/myinvesments/?id=${id}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const getinvest = async (cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/myinvesments/`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const addfundraise = async ({ company_name, location, about_ur_company, upload_files, email, phone }) => {
  try {
    const body_object = {
      company_name,
      location,
      about_ur_company,
      email,
      phone,
      upload_files,
    }
    const fd = new FormData()
    Object.keys(body_object).forEach((each) => {
      fd.append(each, body_object[each])
    })
    const url = `${BASE_URL}/postfundraising/`
    const data = await fetch(url, {
      method: 'POST',
      body: fd,
    })
    const res = await data.json()
    return res
  } catch (error) {
    if (error) {
      return { catchError: 'Internal Server Error' }
    }
  }
}
export const getQuestions = async () => {
  const url = `${BASE_URL}/questions/`
  const data = await fetch(url)
  const res = await data.json()
  return res
}

export const getprofile = async (cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/userData/`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const getprofile_pic = async (cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/profileSetup/`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const postPromoCode = async (data, cb) => {
  const headers = getHeaders()
  await axios
    .post(`${BASE_URL}/val_promo/`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const validateProfilesetup = async (data, cb) => {
  const headers = getHeaders()
  await axios
    .post(`${BASE_URL}/validate_profile/`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const validateReferralCode = async (data, cb) => {
  await axios
    .post(`${BASE_URL}/validate_referral/`, data)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const emailSubscribe = async (data, cb) => {
  const header = getHeaders()
  await axios
    .post(`${BASE_URL}/postemailsubscribe/`, data, { header })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const askQuery = async (query, cb) => {
  const headers = getHeaders()
  const body = { query }
  
  await axios
    .post(`${BASE_URL}/askquery/`, body, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const getStartupUpdates = async (startupId, cb) => {
  const headers = getHeaders();
  const body = { startup_id: startupId };

  await axios
    .post(`${BASE_URL}/getstartupupdates/`, body, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};