import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthCtx from "../../hooks/useAuthCtx";
import { getUserData, googleLogin } from "../../services/account";

import styles from "./GoogleLoginButton.module.css";

import gIcon from "./../../Assets/icons/google-icon.png";
import { isEmptyField } from "../../utils";
import { toast } from "react-toastify";

const GoogleLoginButton = ({ text = "Login with Google" }) => {
  // Note This file is in use
  const { setIsLoggedIn, setShowFinishSignupModal,currentRegisterProgress,setCurrentRegisterProgress } = useAuthCtx();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("access_token", tokenResponse.access_token);
      googleLogin(formData, (err, res) => {
        if (err) return console.log(err);
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem('user_id',res?.data?.user?.pk)
        getUserData((err, res) => {
          setIsLoading(false);
          if (err) return;
          // console.log(isEmptyField(res.data?.[0]?.deals_type) && res.data?.[0]?.start_up_type?.length === 0);
          if (isEmptyField(res.data?.[0]?.deals_type) && res.data?.[0]?.start_up_type?.length === 0) {
            setIsLoggedIn(true);
            setShowFinishSignupModal(true);
            console.log("this is data ",res.data)
            toast.info("Complete the Signup to continue!");
            return;
          } else {
            setIsLoggedIn(true);
            navigate("/dashboard");
            toast.success("Logged In Successfully!");
          }
        });
      });
    },
  });

  return (
    <button
      disabled={isLoading}
      className={`btn-outline-blue w-100 ${styles["google-btn"]} `}
      onClick={(e) => {
        e.preventDefault();
        handleGoogleLogin();
      }}
    >
      <img src={gIcon} className="img-fluid me-3" width={20} alt="" />
      {isLoading ? (
        <svg
          //   style={{ margin: "auto", background: "rgba(221, 221, 221, 0)", display: "block" }}
          width="22px"
          height="22px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx={50} cy={50} fill="none" stroke="#000" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
          </circle>
        </svg>
      ) : (
        text
      )}
    </button>
  );
};

export default GoogleLoginButton;
