import React from 'react'
import styles from './FundRaising.module.css'
import Footer from '../../components/Footer/Footer.js'
import HowItWorks1 from './../../Assets/images/how-it-works-1.png'
import HowItWorks2 from './../../Assets/images/how-it-works-5.png'
import HowItWorks3 from './../../Assets/images/how-it-works-6.png'
import Raise from '../../Assets/images/raise.png'
import Angel from '../../Assets/images/angel-investor.png'
import HeaderBg from '../../Assets/images/Illustration-1.png'
import TimeBg from '../../Assets/images/Illustration-2.png'
import OrgBg from '../../Assets/images/Illustration-3.png'
import ApplyBg from '../../Assets/images/Illustration-4.png'
import Time1 from '../../Assets/images/time-1.png'
import Time2 from '../../Assets/images/time-2.png'
import Time3 from '../../Assets/images/time-3.png'
import Time4 from '../../Assets/images/time-4.png'
import Time5 from '../../Assets/images/genuine-startups.png'
import LineUp from '../../Assets/images/line-up.png'
import LineDown from '../../Assets/images/line-down.png'
import { useNavigate } from 'react-router-dom'
import HowItWorksItem from '../../components/HowItWorks/HowItWorksItem'
import TimelineCard from '../../components/TimelineCard/TimelineCard'
import Testimonial from '../../components/Testimonial/Testimonial'
import FundRaiseOrganizations from '../../components/FundRaiseOrganizations/FundRaiseOrganizations'
import FAQ from '../../components/FAQ/FAQ'
import FAQ2 from '../../components/FAQ/investment_faq/FAQ2'

/* File is in use */

const FundRaising = () => {
  const navi = useNavigate()

  const timelines = [
    {
      id: 1,
      imgId: 'timeline-1',
      img: Time1,
      header: 'We receive the initial documents from your side',
      description: 'Innovation plays an important role in our business services and solutions',
      line: true,
      up: true,
      LineUp,
      LineDown,
    },
    {
      id: 2,
      imgId: 'timeline-2',
      img: Time2,
      header: 'Our team will complete the due diligence process',
      description: 'Venture Buddy is one of the most affordable consulting services out there, with the best value for your money.',
      line: true,
      up: false,
      LineUp,
      LineDown,
    },
    {
      id: 3,
      imgId: 'timeline-3',
      img: Time3,
      header: 'Experts Review the application',
      description: "Venturebuddy's team of industry experts & consultants is committed to providing high-quality service to take your business to the next level.",
      line: true,
      up: true,
      LineUp,
      LineDown,
    },
    {
      id: 4,
      imgId: 'timeline-4',
      img: Time4,
      header: 'Valuation and Deal terms is finalized',
      description: "We offer end-to-end business consulting services to help you in all stages of your company's growth.",
      line: true,
      up: false,
      LineUp,
      LineDown,
    },
    {
      id: 5,
      imgId: 'timeline-5',
      img: Time5,
      header: 'Campaign is live on 16th day',
      description: "We offer end-to-end business consulting services to help you in all stages of your company's growth.",
      line: false,
      up: true,
      LineUp,
      LineDown,
    },
  ]

  const servicess = [
    {
      id: 1,
      imgId: 'hasseless',
      img: HowItWorks1,
      header: 'One line on the Cap table',
      description: 'All investors represent a single line on your cap table acting as a single investor.',
      reverse: false,
    },
    {
      id: 2,
      imgId: 'investor',
      img: HowItWorks2,
      header: 'Fast processing of application',
      description: 'At Venturebuddy, we value your time. Hassle free quick process to get your funding campaign live within 16 days',
      reverse: true,
    },
    {
      id: 3,
      imgId: 'periodic',
      img: HowItWorks3,
      header: 'Small investors are your Super Fans',
      description: 'Each small investor helps spread the word with their network and our super engaged supporters.',
      reverse: false,
    },
  ]

  return (
    <>
      <div className={styles['main-fund-raising-container']}>
        <div className={styles['fundraise-upper-container']}>
          <div className={styles['section-bg-holder']}>
            <img src={HeaderBg} alt="header-background" />
          </div>
          <div className={styles['fundraise-text']}>
            <div className={styles['heading']}>
              <p>
                <span className={styles['green']}>Easy</span> <span className={styles['blue']}>Fund Raising</span>
                <br />
                process for your startup
              </p>
            </div>
            <div className={styles['fund-raise-btn']}>
              <button
                onClick={() => {
                  navi('/add-fundraise')
                }}
              >
                Apply to Raise
              </button>
            </div>
          </div>
          <div className={styles['fund-raise-image']}>
            <img src={Raise} alt="raise" />
            <img src={Angel} alt="angel-investor" />
          </div>
        </div>

        {/* Timelines Section */}
        <div className={styles.timelines}>
          <p className={styles['main-heading']}>We created a streamlined, startup-friendly process that makes running an investment campaign easy and affordable.</p>
          <div className={styles['timelines-card-container']}>
            {timelines.map((timeline) => (
              <TimelineCard key={timeline.id} heading={timeline.header} paragraph={timeline.description} img={timeline.img} imgId={timeline.imgId} line={timeline.line} up={timeline.up} lineUp={timeline.LineUp} lineDown={timeline.LineDown} />
            ))}
          </div>
          <div className={styles['section-bg-holder']}>
            <img src={TimeBg} alt="timeline-background" />
          </div>
        </div>

        {/* How it works Section */}
        <div className={styles['main-how-it-works']}>
          <div className={styles['main-heading-holder']}>
            <p className={`${styles['main-heading']} ${styles['why-raise']}`}>Why raise through Venturebuddy?</p>
          </div>
          <div className={styles['how-it-works-card-container']}>
            {servicess.map((item, idx) => {
              return <HowItWorksItem key={item.id} serial={idx + 1} heading={item.header} paragraph={item.description} image={item.img} reverse={item.reverse} />
            })}
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className={styles.testimonials}>
        <div className={styles['section-bg-holder']}>
          <img src={OrgBg} alt="timeline-background" />
        </div> 
        <Testimonial />
      </div>

      {/* Organizations */}
      <FundRaiseOrganizations />

      {/* FAQ Section*/}
      <div className={styles.faq}>
      
        <FAQ />
        {/* <FAQ2/> */}
        <div className={styles['section-bg-holder']}>
          <img src={ApplyBg} alt="timeline-background" />
        </div>
      </div>

      <Footer />
    </>
  )
}

export default FundRaising
