import React, { useContext } from "react";
import Alert from "../../components/Alert/Alert";
import Options from "../../components/Options/Options";
import styles from "./LiveDeals.module.css";
//import logo from "./../../Assets/images/uber-eats.png";
import { startupdetailContext } from "../../Contexts/Startupdetailcontext";
import mentor from "../../Assets/images/startups/mentor.png";
import DealCard from "../../components/DealCard/DealCard";
import { useState } from "react";
import { useEffect } from "react";

const LiveDeals = () => {
  const { allstartups, filteredstartups } = useContext(startupdetailContext);
  // console.log("🚀 ~ file: LiveDeals.js:14 ~ LiveDeals ~ allstartups",allstartups );
  //  const [count,setcount]=useState(0);
  const [liveStartups, setLiveStartups] = useState([]);
  useEffect(() => {
    // let data = filteredstartups.length===0 ? allstartups : filteredstartups
    if (allstartups?.length === 0) return setLiveStartups([]);
    const filterby_deadline = allstartups.filter((startup) => {
      const MS_TIME_DIFFERENCE =
        new Date(startup.deadline).getTime() - new Date().getTime();
      const HOURS_TIME_DIFFERENCE = Math.floor(
        MS_TIME_DIFFERENCE / 1000 / 60 / 60
      );
      const DAYS_TIME_DIFFERENCE = Math.floor(HOURS_TIME_DIFFERENCE / 24);
      if (DAYS_TIME_DIFFERENCE < 0 || !startup?.is_live) {
      } else {
        return startup;
      }
    });
    setLiveStartups(filterby_deadline);
  }, [allstartups, filteredstartups]);

  return (
    <div id={styles.liveDealsContainer} >
      <div >
        <h1>Invest Now</h1>
        <h6 className="col-4">
          Venture buddy offers a variety of investment opportunities.
        </h6>
      </div>

      <div className="my-4">
        <Options />
      </div>
      <div id={styles.dealsHeading}>
        <h1>Live Deals</h1>
        <p>
          {liveStartups?.length}{" "}
          {liveStartups?.length == 1 ? "opportunity" : "Opportunities"}
        </p>
      </div>

      <div className={styles["deals-container"]} id={styles.cardContainer}>
        {liveStartups.map((ele) => (
          <>
            <DealCard key={ele.id} deal={ele} isClosed={false} />
          </>
        ))}
      </div>
    </div>
  );
};

export default LiveDeals;
