import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './RegisterEmail.module.css'
import { isEmptyField, validateEmail, validatePhone } from '../../utils'
import { checkUserName, generateOtp } from '../../services/account'
import ErrorIcon from '../../Assets/vector/Error'
import { toast } from 'react-toastify'
import GoogleLoginButton from '../GoogleLoginButton/GoogleLoginButton'

const RegisterEmail = ({ registerInputData, setRegisterInputData, currentRegisterProgress, setCurrentRegisterProgress }) => {
  // Note This file is in use
  const [firstNameInputErrMsg, setFirstNameInputErrMsg] = useState(null)
  const [lastNameInputErrMsg, setLastNameInputErrMsg] = useState(null)
  const [emailInputErrMsg, setEmailInputErrMsg] = useState(null)
  const [mobileInputErrMsg, setMobileInputErrMsg] = useState(null)
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isEmptyField(registerInputData.first_name)) return setFirstNameInputErrMsg('This field is required!')
    if (isEmptyField(registerInputData.last_name)) return setLastNameInputErrMsg('This field is required!')
    if (registerInputData.method === 'email') {
      if (isEmptyField(registerInputData.email)) return setEmailInputErrMsg('This field is required!')
      if (!validateEmail(registerInputData.email)) return setEmailInputErrMsg('Please enter a valid email!')
    } else if (registerInputData.method === 'mobile') {
      if (isEmptyField(registerInputData.mobile)) return setMobileInputErrMsg('This field is required!')
      if (!validatePhone(registerInputData.mobile)) return setMobileInputErrMsg('Please enter a valid mobile number!')
    }
    setSubmitBtnLoading(true)
    checkUserName(registerInputData[registerInputData.method], (err, res) => {
      if (!err && res.data.message) {
        setSubmitBtnLoading(false)
        toast.error('This account is already registered!')
        return
      }

      if (err && !err.response?.data?.message) {
        generateOtp(registerInputData[registerInputData.method], (err, res) => {
          setSubmitBtnLoading(false)
          if (err) {
            return toast.error('Something went wrong!')
          }
          setCurrentRegisterProgress(currentRegisterProgress + 1)
        })
      }
    })
  }
  return (
    <>
      {/* >>>>>>>> For Desktop <<<<<<<< */}
      <form className="my-auto d-none d-lg-flex flex-column justify-content-between text-dark" onSubmit={(e) => e.preventDefault()} id={styles.registerEmail} >
        <h1 className="mt-0">Signup</h1>
        <div>
          <h6 className="">First Name</h6>
          <div className={`${firstNameInputErrMsg && 'form-input-err'}`}>
            <input
              id="f-name"
              type="text"
              placeholder="Your first name"
              className="form-control px-4 py-3"
              name="name"
              value={registerInputData.first_name}
              onChange={(e) => {
                setFirstNameInputErrMsg(null)
                setRegisterInputData((prev) => {
                  return { ...prev, first_name: e.target.value }
                })
              }}
              required
            />
            {firstNameInputErrMsg && (
              <p className={`input-err-msg`}>
                <ErrorIcon fill="red" width="15px" height="15px" />
                <span>{firstNameInputErrMsg}</span>
              </p>
            )}
          </div>
        </div>
        <div>
          <h6 className="">Last Name</h6>
          <div className={`${lastNameInputErrMsg && 'form-input-err'}`}>
            <input
              id="l-name"
              type="text"
              placeholder="Your last name"
              className="form-control px-4 py-3"
              name="name"
              value={registerInputData.last_name}
              onChange={(e) => {
                setLastNameInputErrMsg(null)
                setRegisterInputData((prev) => {
                  return { ...prev, last_name: e.target.value }
                })
              }}
              required
            />
            {lastNameInputErrMsg && (
              <p className={`input-err-msg`}>
                <ErrorIcon fill="red" width="15px" height="15px" />
                <span>{lastNameInputErrMsg}</span>
              </p>
            )}
          </div>
        </div>

        <div className="row row-cols-2">
          <div className="d-flex align-items-center py-3">
            <input
              id="email"
              name="method"
              checked={registerInputData.method === 'email'}
              className="border"
              type="radio"
              style={{ cursor: 'pointer' }}
              onChange={() => {
                setRegisterInputData((prev) => {
                  return { ...prev, method: 'email', mobile: '' }
                })
                setEmailInputErrMsg(null)
              }}
            />
            <label style={{ cursor: 'pointer' }} htmlFor="email" className="ms-2" value="Email Address">
              Email Address
            </label>
          </div>
          <div className="d-flex align-items-center py-3">
            <input
              id="mobile"
              name="method"
              style={{ cursor: 'pointer' }}
              checked={registerInputData.method === 'mobile'}
              type="radio"
              onChange={() => {
                setRegisterInputData((prev) => {
                  return { ...prev, method: 'mobile', email: '' }
                })
                setMobileInputErrMsg(null)
              }}
            />
            <label htmlFor="mobile" style={{ cursor: 'pointer' }} className="ms-2" value="Mobile Number">
              Mobile Number
            </label>
          </div>
        </div>

        <div className={styles.emailOrMobile}>
          {/* If User Chooses Email ID Method for register - */}
          {registerInputData.method === 'email' && (
            <div id="email-input" className={`${emailInputErrMsg && 'form-input-err'}`}>
              <input
                type="email"
                id="register-email"
                className="form-control px-4 py-3"
                placeholder="Your email address"
                value={registerInputData.email}
                onChange={(e) => {
                  setEmailInputErrMsg(null)
                  setRegisterInputData((prev) => {
                    return { ...prev, email: e.target.value }
                  })
                }}
              />
              {emailInputErrMsg && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{emailInputErrMsg}</span>
                </p>
              )}
            </div>
          )}

          {/* If User Chooses Mobile Number Method for register - */}
          {registerInputData.method === 'mobile' && (
            <div id="mobile-input" className={`${mobileInputErrMsg && 'form-input-err'}`}>
              <input
                type="phone"
                id="register-phone"
                className="form-control px-4 py-3"
                placeholder="Your mobile number"
                value={registerInputData.mobile}
                maxLength={10}
                onChange={(e) => {
                  setMobileInputErrMsg(null)
                  setRegisterInputData((prev) => {
                    return { ...prev, mobile: e.target.value }
                  })
                }}
              />
              {mobileInputErrMsg && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{mobileInputErrMsg}</span>
                </p>
              )}
            </div>
          )}
        </div>

        {/* <button className="carousel-control-next d-none" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next" id="next"></button> */}

        <input disabled={submitBtnLoading} type="submit" value={submitBtnLoading ? 'PROCESSING...' : 'NEXT'} onClick={handleSubmit} className={`w-100 btn-blue fw-bolder mt-0 ${styles.emailNext}`} id="continue" />

        <p className="text-center" id={styles.or}>
          OR
        </p>

        <GoogleLoginButton text="Signup with Google" currentRegisterProgress={currentRegisterProgress} setCurrentRegisterProgress={setCurrentRegisterProgress}/>

        <p className="text-center" id="signUp">
          Already have an account?{' '}
          <Link to="/login" className="text-green" style={{fontWeight:'600'}}>
            Login
          </Link>
        </p>
      </form>

      {/* TODO: Change the mobile functionality */}
      {/* >>>>>>>> For Mobile <<<<<<<< */}
      <form className="my-auto d-flex d-lg-none flex-column justify-content-between text-dark px-3 bg-white" onSubmit={(e) => e.preventDefault()} id={styles.registerEmail}>
        <div className={`signup-heading ${styles.head_one}`}>
          <h1 className="my-0">Signup</h1>
          <p className={`fw-normal my-0 ${styles.Desc}`}>Let's get you started.</p>
        </div>
        <h6>First Name</h6>
        <div className={`${firstNameInputErrMsg && 'form-input-err'}`}>
          <input
            id="name2"
            type="text"
            placeholder="Your first name"
            className="form-control px-4 py-3"
            name="name"
            value={registerInputData.first_name}
            onChange={(e) => {
              setFirstNameInputErrMsg(null)
              setRegisterInputData((prev) => {
                return { ...prev, first_name: e.target.value }
              })
            }}
            required
          />
          {firstNameInputErrMsg && (
            <p className={`input-err-msg`}>
              <ErrorIcon fill="red" width="15px" height="15px" />

              <span>{firstNameInputErrMsg}</span>
            </p>
          )}
        </div>
        <h6>Last Name</h6>
        <div className={`${lastNameInputErrMsg && 'form-input-err'}`}>
          <input
            id="name2"
            type="text"
            placeholder="Your last name"
            className="form-control px-4 py-3"
            name="name"
            value={registerInputData.last_name}
            onChange={(e) => {
              setLastNameInputErrMsg(null)
              setRegisterInputData((prev) => {
                return { ...prev, last_name: e.target.value }
              })
            }}
            required
          />
          {lastNameInputErrMsg && (
            <p className={`input-err-msg`}>
              <ErrorIcon fill="red" width="15px" height="15px" />
              <span>{lastNameInputErrMsg}</span>
            </p>
          )}
        </div>

        <div className="row row-cols-2">
          <div className="d-flex align-items-center py-3">
            <input
              id="email2"
              checked={registerInputData.method === 'email'}
              name="method2"
              className="border"
              type="radio"
              onChange={() => {
                setRegisterInputData((prev) => {
                  return { ...prev, method: 'email', mobile: '' }
                })
                setEmailInputErrMsg(null)
              }}
            />
            <label htmlFor="email2" className="ms-2" value="Email Address">
              Email Address
            </label>
          </div>
          <div className="d-flex align-items-center py-3">
            <input
              id="phone2"
              checked={registerInputData.method === 'mobile'}
              name="method2"
              type="radio"
              onChange={() => {
                setRegisterInputData((prev) => {
                  return { ...prev, method: 'mobile', email: '' }
                })
                setMobileInputErrMsg(null)
              }}
            />
            <label htmlFor="phone2" className="ms-2" value="Mobile Number">
              Mobile Number
            </label>
          </div>
        </div>

        <div>
          {registerInputData.method === 'email' && (
            <div id="email-input-2" className={`${emailInputErrMsg && 'form-input-err'}`}>
              <input
                type="email"
                id="register-email2"
                className="form-control px-4 py-3"
                placeholder="Your email address"
                value={registerInputData.email}
                onChange={(e) => {
                  setEmailInputErrMsg(null)
                  setRegisterInputData((prev) => {
                    return { ...prev, email: e.target.value }
                  })
                }}
                required
              />
              {emailInputErrMsg && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{emailInputErrMsg}</span>
                </p>
              )}
            </div>
          )}

          {registerInputData.method === 'mobile' && (
            <div id="mobile-input-2" className={`${mobileInputErrMsg && 'form-input-err'}`}>
              <input
                type="phone"
                id="register-phone"
                className="form-control px-4 py-3"
                placeholder="Your mobile number"
                value={registerInputData.mobile}
                maxLength={10}
                onChange={(e) => {
                  setMobileInputErrMsg(null)
                  setRegisterInputData((prev) => {
                    return { ...prev, mobile: e.target.value }
                  })
                }}
                required
              />
              {mobileInputErrMsg && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{mobileInputErrMsg}</span>
                </p>
              )}
            </div>
          )}
        </div>
        {/* <button className="carousel-control-next mt-3" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next" id="next2"></button> */}

        <input disabled={submitBtnLoading} type="submit" value={submitBtnLoading ? 'PROCESSING...' : 'NEXT'} onClick={handleSubmit} className="w-100 btn-blue" id="continue" />
        {/* <button onClick={() => console.log("object")}>NEXT</button> */}

        <p className="text-center" id={styles.or}>
          OR
        </p>

        <GoogleLoginButton text="Signup with Google" />

        <p className="text-center" id="signUp">
          Already have an account?{' '}
          <Link to="/login" style={{color:'#18A62B',fontWeight:'600'}}>
            <p style={{fontWeight:'600'}}>Login</p>
          </Link>
        </p>
      </form>
    </>
  )
}

export default RegisterEmail