import React from 'react'
import dhl from './../../Assets/images/organizations/client-1-removebg-preview.png'
import next from './../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from './../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from './../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from './../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from './../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from './../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from './../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from './../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from './../../Assets/images/organizations/client-13-removebg-preview.png'
import styles from './FundRaiseOrganizations.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'
import { useNavigate } from 'react-router-dom'

const FundRaiseOrganizations = () => {
  const navi = useNavigate()

  const orgs = [
    { id: 1, img: dhl, title: 'org1' },
    { id: 2, img: next, title: 'org2' },
    { id: 3, img: aviva, title: 'org3' },
    { id: 4, img: santander, title: 'org4' },
    { id: 5, img: serco, title: 'org5' },
    { id: 6, img: healthShield, title: 'org6' },
    { id: 7, img: client_10, title: 'org7' },
    { id: 8, img: client_11, title: 'org8' },
    { id: 9, img: client_12, title: 'org9' },
    { id: 10, img: client_13, title: 'org10' },
  ]

  return (
    <>
      <div id={styles.organizations}>
        <>
          <h4 id={styles.orgHead}>200+ Organisations using our services</h4>
          {/* Desktop Slider Start Here */}
          <div className={`${styles.slider_desktop}`}>
            <Swiper
              slidesPerView={5}
              spaceBetween={20}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={false}
              modules={[Autoplay]}
            >
              {orgs.map((org) => (
                <SwiperSlide key={org.id} className={styles['swiper-slide-width']}>
                  <div className={`${styles.Orgimg} ${styles.center}`}>
                    <img src={org.img} alt={org.title} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* Desktop Slider End Here */}

          {/* Phone Slider Start Here */}
          <div className={styles.organisation_conatiner_mobile}>
            {orgs.map((org) => (
              <div key={org.id} className={styles.Orgimg}>
                <img src={org.img} alt={org.title} />
              </div>
            ))}
          </div>
          {/* Phone Slider End Here */}
        </>
      </div>

      <div className={styles.about}>
        <div id={styles.joinGrowingBusinesses}>
          <h1 id={styles.growHead}>Join 200+ growing businesses that use Venturebuddy.</h1>
          <div className={styles['fund-raise-btn']}>
            <button
              onClick={() => {
                navi('/add-fundraise')
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FundRaiseOrganizations
