import React, { useState } from "react";
import styles from "./TypeOfDeals.module.css";

const TypeOfDeals = ({ currentProgress, setCurrentProgress, registerInputData, setRegisterInputData }) => {
  // Note This file is in use
  const [buttonActive, setButtonActive] = useState(false);
  // console.log("this is type of deals ",registerInputData)

  return (
    <div className="text-dark px-2" id={styles.typeOfDeals} style={{marginTop:'0px'}} >
      <h5 className="text-center" style={{paddingTop:'15px'}}>What type of deals are you looking for?</h5>

      <div id={styles.types} className="row row-cols-2 justify-content-center text-blue py-5 m-lg-5 text-center">
        <div
          className={`bg-white border-1 border border-secondary rounded-4 ${registerInputData.deals_type === 1 && styles.active} ${styles.deal}`}
          id={styles.retail}
          onClick={() => {
            setRegisterInputData((prev) => {
              return { ...prev, deals_type: 1 };
            });
            setButtonActive(true);
          }}
        >
          <h6 style={{ color: registerInputData.deals_type === 1 ? '#18A62B' : 'inherit' }}> Retail Investor</h6> <p className={`${registerInputData.deals_type === 1?'green_active_1':''}`}>upto ₹ 2 lakhs </p>
        </div>
        <div
          className={`bg-white border-1 border border-secondary rounded-4 ${registerInputData.deals_type === 2 && styles.active} ${styles.deal}`}
          id={styles.angel}
          onClick={() => {
            setRegisterInputData((prev) => {
              return { ...prev, deals_type: 2 };
            });
            setButtonActive(true);
          }}
        >
          <h6 style={{ color: registerInputData.deals_type === 2 ? '#18A62B' : 'inherit' }}>Angel Investor</h6> <p>above ₹ 2 lakhs </p>
        </div>
      </div>
      <div className="text-center" id={styles.buttonContainer}>
        <button className={`btn btn-blue px-4 mt-0 ${buttonActive ? "" : "disabled"}`} onClick={() => setCurrentProgress(currentProgress + 1)}>
          Next
        </button>

        <button className="carousel-control-next mt-3" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" id="dealNext"></button>
      </div>
    </div>
  );
};

export default TypeOfDeals;