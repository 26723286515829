import React from "react";
import { Link } from "react-router-dom";
import HeaderCarousel from "../HeaderCarousel/HeaderCarousel";
import styles from "./HomeHeader.module.css";

const HomeHeader = () => {
  // Note This file is in use
  return (
    <header className="container-fluid" id={styles.header}>
      <div className={`row align-items-center ${styles["header-container"]}`}>
        <div className={`col-12 col-sm col-lg-5 order-lg-0 py-5 ${styles["header-text-container"]}`}>
          <h6 className="text-blue" id={styles.h6}>
            THE INVESTMENT PLATFORM
          </h6>
          <h2 className={styles.h2}>Invest in India's growth story with Venturebuddy.</h2>
          <p className={`pt-2 ${styles.p}`}>We offer vetted investment opportunities in alternative investments.</p>

          <Link to="/explore" className="btn btn-secondary-blue mt-3" id={`${styles.btnText}`}>
            Invest Now
          </Link>
        </div>

        <div className="col-12 col-lg-7  mx-lg-auto mx-sm-auto" id={`${styles.carousel_container_mobile}`}>
          <div className="col-12 col-lg-8 ms-0 ms-lg-auto  me-lg-5 mt-3 pe-0 pe-lg-5 mx-sm-auto" id={`${styles.carousel_container_mobile}`}>
            <HeaderCarousel />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
