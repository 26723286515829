import React from "react";
import style from "./VideoPitch.module.css";
import useStartupCtx from "../../hooks/useStartupCtx";
import { useState } from "react";
import { useRef } from "react";

const VideoPitch = ({ styles }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail } = startupFullDetails;

  const videoPlayerRef = useRef(null);

  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  return (
    <div data-scroll-id="video-pitch" className={style["video-pitch-wrapper"]}>
      <h1 className={styles.sectionHeader}>
        Video Pitch
        <div className={styles.borderBottom}></div>
      </h1>

      <div className={style["video-container"]}>
        <video
          ref={videoPlayerRef}
          onPause={() => setIsVideoPaused(true)}
          onPlay={() => setIsVideoPaused(false)}
          onLoadStart={() => setIsVideoLoading(true)}
          onLoadedData={() => setIsVideoLoading(false)}
          className={style["startup-video-main"]}
          // src={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"}
          src={mainDetail.video_pitch.replace("http://", "https://")}
          width={"100%"}
          onClick={(e) => {
            if (videoPlayerRef.current.paused) videoPlayerRef.current.play();
            else videoPlayerRef.current.pause();
          }}
        ></video>
        {isVideoLoading ? (
          <div
            className={style["play-btn-bg"]}
            onClick={() => {
              if (videoPlayerRef.current.readyState !== 4) videoPlayerRef.current.load();
            }}
          >
            <svg className={styles["play"]} width={130} height={130} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx={50} cy={50} fill="none" stroke="#eee" strokeWidth={8} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
              </circle>
            </svg>
          </div>
        ) : (
          <div
            style={{ opacity: isVideoPaused ? 1 : 0 }}
            onClick={(e) => {
              if (videoPlayerRef.current.paused) videoPlayerRef.current.play();
              else videoPlayerRef.current.pause();
            }}
            className={style["play-btn-bg"]}
          >
            <svg width={170} height={170} viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85 17C66.9653 17 49.6692 24.1643 36.9167 36.9167C24.1643 49.6692 17 66.9653 17 85C17 103.035 24.1643 120.331 36.9167 133.083C49.6692 145.836 66.9653 153 85 153C103.035 153 120.331 145.836 133.083 133.083C145.836 120.331 153 103.035 153 85C153 66.9653 145.836 49.6692 133.083 36.9167C120.331 24.1643 103.035 17 85 17ZM45.932 45.932C56.2935 35.5705 70.3467 29.7495 85 29.7495C99.6533 29.7495 113.707 35.5705 124.068 45.932C134.429 56.2935 140.25 70.3467 140.25 85C140.25 99.6533 134.429 113.707 124.068 124.068C113.707 134.429 99.6533 140.25 85 140.25C70.3467 140.25 56.2935 134.429 45.932 124.068C35.5705 113.707 29.7495 99.6533 29.7495 85C29.7495 70.3467 35.5705 56.2935 45.932 45.932ZM74.7105 109.852L108.263 89.142C111.246 87.3011 111.246 82.6989 108.263 80.858L74.7105 60.1479C71.7281 58.307 68 60.6081 68 64.2899L68 105.71C68 109.392 71.7281 111.693 74.7105 109.852Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPitch;
