import React from "react";
import styles from "./ToolTip.module.css";
const ToolTip = ({ data, top, right, left, bottom }) => {
  // Note This file is in use
  return (
    <div className={`${styles["tooltip-container"]}`}>
      <p className={`${styles["tootlip-text-container"]}`}>
        <span
          className={`${styles["tooltip-text"]}`}
          style={{
            top: bottom && "-3rem",
            bottom: top && "12%",
            left: right && "-80px",
            right: left && "12px",
          }}
        >
          {data}
        </span>
      </p>
    </div>
  );
};

export default ToolTip;
