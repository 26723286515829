import React, { useEffect, useState } from 'react'
import styles from '../FAQ.module.css'
import { getFaq } from '../../../services/faqApi'

const FAQ2 = () => {
  const [faq, setFaq] = useState([])

  const fetchData = async () => {
    try {
      const data = await getFaq()
      setFaq(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <section style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
      <div className={styles.faq} style={{ borderRadius: '8px', width: '95%'}}>
        <div className={`d-flex ${styles.title} justify-content-center`}>Frequently Asked Questions</div>
        <br />
        <br />
        <div className={`!pt-[0px] accordion ${styles.accordion} accordion-flush`} id="accordionFlushExample" style={{ width: '100%' }}>
          {faq.length > 0 ? (
            faq.map((item, index) => (
              <div className={`accordion-item ${styles.item}`} style={{ width: '100%', paddingLeft: '30px' }} key={index}>
                <h2 className="accordion-header" id={`flush-heading-${index}`}>
                  <button className={`accordion-button accordion-button_mob collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index}`} aria-expanded="false" aria-controls={`flush-collapse-${index}`}>
                    {item?.question}
                  </button>
                </h2>
                <div id={`flush-collapse-${index}`} className={`accordion-collapse collapse ${styles.according_body_holder}`} aria-labelledby={`flush-heading-${index}`} data-bs-parent="#accordionFlushExample">
                  <div className={`accordion-body accordion-body_mob ${styles.accordion_body}`}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ width: '2px', backgroundColor: '#DBDCE1' }}></span>
                      <div style={{ fontSize: '20px', fontWeight: '400', color: '#49516A', paddingLeft: '19px' }}>{item?.answer}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>Loading....</div>
          )}
        </div>
      </div>
    </section>
  )
}

export default FAQ2
