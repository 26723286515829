import React, { useState, useEffect } from 'react'
import style from './Overseas.module.css'
import Faq3 from '../faqs/Faq3'
import styles from '../pitchdeck/pitchdeck.module.css'
import Star from '../pitchdeck/pich_assest/star.svg'
import client_image from '../pitchdeck/pich_assest/client_image.svg'
import Style2 from '../../../components/Organizations/Organization.module.css'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'
import dhl from '../../../Assets/images/organizations/client-1-removebg-preview.png'
import next from '../../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from '../../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from '../../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from '../../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from '../../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from '../../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from '../../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from '../../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from '../../../Assets/images/organizations/client-13-removebg-preview.png'
import BlogsSlider from '../blogs/BlogsSlider'
import GetTouchItAll from '../gettouchit/GetTouchItAll'
import Footer from '../../../components/Footer/Footer'
import style1 from '../pitchdeck/pitchdeck.module.css'
import back from '../pitchdeck/pich_assest/path_bg.svg'
import hero from './oversease_assests/over_hero.svg'
import CountryPlans from './CountryPlans'
import green_bg from '../pitchdeck/pich_assest/bg_green_all.svg'
import client1 from './oversease_assests/business_1.svg'
import client2 from './oversease_assests/business_2.svg'
import client3 from './oversease_assests/business_3.svg'
import client4 from './oversease_assests/business_4.svg'
import client5 from './oversease_assests/business_5.svg'
import client6 from './oversease_assests/business_6.svg'
import MobileBlogs from '../blogs/mobileblogs/MobileBlogs'
import MobileClientView from '../MobileClient/MobileClientView'
import Testimonial from '../../../components/Testimonial/Testimonial'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Overseas1 = () => {
  const [indexed, setIndexed] = useState(0)
  const navigate = useNavigate()
  const [showMain2, setShowMain2] = useState(false)
  const clientReview = [
    {
      id: 1,
      name: 'Rohit Ransore',
      status: 'INVESTER',
      profession: 'UX DESIGNER,ATG',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 2,
      name: 'Jeniffer White',
      status: 'INVESTER',
      profession: 'Vice President, Google',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 3,
      name: 'Niromi Taylor',
      status: 'BUSNINESS',
      profession: 'Senior Manager, IKEA',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
  ]

  function startprinting() {
    let start = clientReview[indexed].review[0].review_star
    let starts = []

    for (let i = 1; i <= start; i++) {
      starts.push(<img src={Star} />)
    }

    return starts
  }
  const data = [
    {
      question: 'How does your process work for creating custom pitch decks?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What sets your pitch deck services apart from others?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'Is there a sample report or a free trial available?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What is the authenticity of the tool?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'How does Venturebuddy protect my data?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What information do you need from clients to start working on their pitch deck?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
  ]
  const handlenavigate = () => {
    navigate('/contact')
  }

  useEffect(() => {
    // Delay the visibility of the main2 section
    const timer = setTimeout(() => {
      setShowMain2(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])
  return (
    <><section className={style['main1']}>
      
    <div className={style['header_wrapper']}>
    
      <h1>
        Expand Your Reach with Overseas
        <br /> Company Registration
      </h1>
      <h2>
        Experience top-tier CFO guidance without breaking the bank – <br />
        elevate your financial strategy affordably.
      </h2>   
           
      <img src={green_bg} className={style['green_bg']} />
      <div className={style['image_wapper']}>  
      <img src={hero} />
      {showMain2 && (
        <button onClick={() => handlenavigate()}>Contact us</button>
      )} 
      </div>
    </div>
  </section>
      
      {showMain2 && (
        <section className={style['main2']}>
          <CountryPlans />
        </section>
      )}
      
      {showMain2 && (
        <section className={style['main6']} style={{ marginTop: '0px', marginBottom: '10px' }}>
          <Testimonial />
        </section>
      )}
      {/* <div className={styles['client_about_detail']}>
        <h1>What Clients say About us</h1>
      </div> */}
      
      {showMain2 && (
        <div className={style['mobile_view_client']}>
          <MobileClientView />
        </div>
      )}
      <br />
      {showMain2 && (
        <section className={style['main7']} style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
          <div id="Style2.organizations" className="container-fluid" style={{ height: '705px', background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
            <>
              <div style={{ paddingTop: '250px', width: '100%' }}>
                <h4 className="text-blue text-center" id={Style2.orgHead}>
                  Our Top Clients
                </h4>
                <div className={style['company_wrapper']}>
                  <div className={style['company_main']}>
                    <img src={client1} />
                    <img src={client2} />
                    <img src={client3} />
                    <img src={client4} />
                    <img src={client5} />
                    <img src={client6} />
                  </div>
                </div>
              </div>
            </>
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main8']}>
          <BlogsSlider />
          
        </section>
      )}
      {showMain2 && (
        <section className={style['main08']}>
          
          <MobileBlogs />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main9']}>
          <GetTouchItAll />
        </section>
      )}
      <br />
      {showMain2 && <Footer />}
    </>
  )
}

export default Overseas1
