import React from 'react'
import styles from './Timeline.module.css'

const TimelineCard = ({ heading, paragraph, img, imgId, line, up, lineUp, lineDown }) => {
  return (
    <div className={styles['card-container']}>
      <div className={styles.card}>
        <div className={styles['img-holder']}>
          <img src={img} alt={imgId} />
        </div>
        <div className={styles.heading}>{heading}</div>
        <p className={styles.description}>{paragraph}</p>
      </div>
      {line && (
        <div className={styles['line-holder']}>
          <img src={up ? lineUp : lineDown} alt="line" />
        </div>
      )}
    </div>
  )
}

export default TimelineCard
