import React from 'react'
import style from "./TeamCard.module.css"
const TeamCard = ({ team_data }) => {
  // Note This file is in use
  return (
    <div className={style["main-team-card"]}>
      <img src={team_data?.pic} alt="" className={style["team-image"]} />
      <div className={style["team-info"]}>
        <p className={style["name"]}>{team_data?.name}</p>
        <p className={style["info-heading"]}>{team_data?.proffesion}</p>
        {team_data?.data && <p className={style["info-data"]}>{team_data?.data}</p>}
        {/* <img src={team_data?.icon} className={style["icon"]} alt="" onClick={()=>{
              window.open(team_data?.url, "_blank");
            }} /> */}
        <svg onClick={() => {
          window.open(team_data?.url);
        }} xmlns="http://www.w3.org/2000/svg" className={style["icon"]} data-name="Layer 1" viewBox="0 0 24 24" width="288" height="288"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z" fill="#6d747a" class="color000 svgShape"></path></svg>
      </div>
    </div>
  )
}

export default TeamCard