import React from 'react'
import styles from './CopyReferCode.module.css'
import referIllustration from './../../Assets/images/refer-illustration.png'
import copyIcon from './../../Assets/icons/copy-icon.png'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import coppiedIllustration from './../../Assets/icons/coppied-illustration.png'
import sharenode from './../../Assets/icons/share-nodes.png'
import facebook from './../../Assets/icons/facebook.png'
import twitter from './../../Assets/icons/twitter.png'
import message from './../../Assets/icons/message.png'
import whatsapp from './../../Assets/icons/whatsapp.png'
import { formatINR } from '../../utils'
import { toast } from 'react-toastify'
import { getprofile } from '../../services/startup'
import Loader from '../Loader/Loader'
import Reffer from '../../Assets/images/Full_Illustartion_centre.svg'
class CopyReferCode extends React.Component {
  // Note This file is in use
  state = {
    value: '',
    copied: false,
    loading: false,
  }
  componentDidMount() {
    this.setState({ loading: true })
    getprofile((err, res) => {
      this.setState({ loading: false })
      this.setState({ value: res?.data[0]?.referal_code_auto })
      if (err) {
        toast.error('Something went wrong')
      }
    })
  }
  render() {
    return (
      <div id={styles.copyReferCodeContainer}>
        <div className={`${styles['refer-header-container']}`}>
          <h1>Refer & Earn</h1>
          <p className="col-4">Refer Venturebuddy to your buddies and earn upto ₹{formatINR(1000)} with each registration.</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <div className={styles.cr_wrap}>
            <div id={styles.codeContainer} className="mx-auto" style={{ width: '388px', background: 'var(--Primary-10, #E9EBF4)', boxShadow: ' 2px 4px 31px 0 rgba(0, 0, 0, 0.13)' }}>
              <img src={Reffer} className={`img-fluid ${styles['mobile-image']}`} alt="" />
              <img src={referIllustration} alt="" className={styles['other-mobile-image']} />
              <p className={`${styles['your-referral']}`}>Your Referral Code</p>
              <div id={styles.code} style={{ marginBottom: '58px' }}>
                {this.state.loading ? <Loader /> : <p className={`${styles['referral-code']}`}>{this.state.value}</p>}

                <CopyToClipboard
                  text={this.state.value}
                  onCopy={() => {
                    this.setState({ copied: true })
                    toast.success('Referral code successfully copied')
                  }}
                >
                  <img src={copyIcon} className={`${styles['copy-icon']}`} alt="" />
                </CopyToClipboard>
              </div>
            </div>

            <div className="d-flex justify-content-center" id={styles.shareContainer}>
              <div id={styles.share}>
                <img src={sharenode} alt="" style={{ cursor: 'default' }} />
                <p style={{ cursor: 'default' }}>Share:</p>
              </div>

              <img
                src={whatsapp}
                onClick={() => {
                  window.open(`https://api.whatsapp.com/send?text=${this.state.value}`, '_blank')
                }}
                alt=""
              />
              <img
                src={twitter}
                onClick={() => {
                  window.open(`https://twitter.com/intent/tweet?text=${this.state.value}`, '_blank')
                }}
                alt=""
              />
              <img
                src={facebook}
                onClick={() => {
                  window.open('https://www.facebook.com/', '_blank')
                }}
                alt=""
              />
              <img
                src={message}
                onClick={() => {
                  window.open('mailto:', '_blank')
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CopyReferCode
