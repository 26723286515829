import React from 'react'
import styles from './HowItWorks.module.css'

const HowItWorksItem = ({ serial, heading, paragraph, image, reverse,imagewidth ,imagehieght }) => {
  // Note This file is in use
  return (
    <div className="row row-cols-1 row-cols-lg-2 mb-4 align-items-center justify-content-center">
      {reverse ? (
        <>
          <div className={`col order-sm-1 order-lg-2 ps-3 ${styles.why_text_container}`}>
            <div className="col-12">
              <h1 className={`text-blue text-start fw-bolder ${styles.serial}`}>{serial}</h1>
              <h2 className={styles.itemHeader}>{heading}</h2>
              <div className={styles.hr}>{/* <hr className="w-25" /> */}</div>
              <p className={styles.itemPara}>{paragraph}</p>
            </div>
          </div>
          <div className={`col px-0 order-sm-2 order-lg-1 ${styles.images_wrap_box}`}>
            <img src={image} alt="" className={`img-fluid col-9 ms-3 ${styles.images_wrap}`}  style={{width:`${imagewidth}px`,height:`${imagehieght}px`}}/>
          </div>
        </>
      ) : (
        <>
          <div className={`col order-sm-1 order-lg-0 ps-3 ${styles.why_text_container}`}>
            <div className="col-12">
              <h1 className={`text-blue text-start fw-bolder ${styles.serial}`}>{serial}</h1>
              <h2 className={styles.itemHeader}>{heading}</h2>
              <div className={styles.hr}>{/* <hr className="w-25" /> */}</div>
              <p className={styles.itemPara}>{paragraph}</p>
            </div>
          </div>
          <div className={`col order-sm-2  ${styles.images_wrap_box}`}>
            <img src={image} alt="" className={`img-fluid col-9 ms-3 ${styles.images_wrap}`} style={{width:`${imagewidth}px`,height:`${imagehieght}px`}}/>
          </div>
        </>
      )}
    </div>
  )
}

export default HowItWorksItem
