import React, { useContext, useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import GetTouchWithUs from '../../components/GetTouch/GetTouchWithUs'
import JoinAsInvestor from '../../components/JoinAsInvestor/JoinAsInvestor'
import Organizations from '../../components/Organizations/Organizations'
import WhyChooseUs from '../../components/WhyChooseUs/WhyChooseUs'
import HowItWorks from '../../components/HowItWorks/HowItWorks'
import Testimonial from '../../components/Testimonial/Testimonial'
import HomeHeader from '../../components/HomeHeader/HomeHeader'
import { authContext } from '../../Contexts/Authcontext'
import { useNavigate } from 'react-router-dom'
import ChatBot from '../../components/ChatBot/ChatBot'
import { Helmet } from 'react-helmet'
const Home = () => {
  const { isLoggedIn } = useContext(authContext)

  const navigate = useNavigate()
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate("/dashboard", { replace: true });
  //   }
  // }, [isLoggedIn]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Venture Buddy</title>
      </Helmet>
      <HomeHeader />
      <WhyChooseUs />
      <JoinAsInvestor />
      <HowItWorks />
      <div style={{height:"50px"}}>
      </div>
      <Testimonial />
      <Organizations />
      <GetTouchWithUs />
      <Footer />
      <ChatBot />
    </>
  )
}

export default Home
