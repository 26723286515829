import React, { useEffect } from 'react'
import styles from './ListingPage.module.css'
import StartupDetailHeader from '../StartupDetailHeader/StartupDetailHeader'
import StartupDetailBody from '../StartupDetailBody/StartupDetailBody'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import useStartupCtx from '../../hooks/useStartupCtx'
import useAuthCtx from '../../hooks/useAuthCtx'
import useWindowDimensions from '../../hooks/useWindowDimensions'

export const ListingPage = () => {
  // Note This file is in use
  const navigate = useNavigate()
  const location = useLocation()
  const { loadingStartupDetail } = useStartupCtx()
  const { isLoggedIn } = useAuthCtx()
  const { setCurrentStartupId, setLoadingStartupDetail } = useStartupCtx()
  const { slug } = useParams()
  const { width } = useWindowDimensions()

  useEffect(() => {
    setCurrentStartupId(slug)
    return () => {
      setCurrentStartupId(null)
      setLoadingStartupDetail(true)
    }
  }, [slug])

  useEffect(() => {
    if (isLoggedIn && location.pathname.includes('startup')) {
      navigate(`/dashboard/deal/${slug}`, { replace: true, state: location.state })
    }
  }, [isLoggedIn])

  return (
    <>
      <div style={{ padding: !isLoggedIn && width >= 1000 && '40px', paddingLeft: '10px' }} className={styles['listing-container']}>
        <Link to={location.state?.from ? location.state?.from : isLoggedIn ? '/dashboard/live-deals' : '/explore'}>
          <svg style={{ marginLeft: '20px' }} width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles['back-btn']}>
            <path d="M0.292891 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292891 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292891 7.29289ZM31 7L0.999998 7V9L31 9V7Z" fill="#6D747A" />
          </svg>
        </Link>
        {loadingStartupDetail ? (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', background: 'rgba(221, 221, 221, 0)', display: 'block' }} width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx={50} cy={50} fill="none" stroke="#000" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
            </circle>
          </svg>
        ) : (
          <>
            <StartupDetailHeader />

            <StartupDetailBody />
          </>
        )}
      </div>
    </>
  )
}
