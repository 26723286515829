import React, { useEffect, useState } from 'react'
import style from './Community.module.css'
import { useNavigate } from 'react-router-dom'
import { AllThoughtsUsers } from '../../services/Communitys'
import { BASE_URL } from '../../utils/constant'
import axios from 'axios'

const Thought = ({ each }) => {
  const [profiles, setProfiles] = useState()

  const navigate = useNavigate()
  const handleNaviagte = (id) => {
    navigate('/comments', { state: id })
  }
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await axios.get(`${BASE_URL}/getimg/${each?.id}/`)
        const res = await usersData.data
        setProfiles(res)
      } catch (error) {
        console.log(error)
      }
    }

    fetchUsers()
  }, [each.id])

  const formatViewCount = (count) => {
    if (count >= 1000) {
      return (count / 1000).toFixed(1) + 'k'
    } else {
      return count.toString()
    }
  }
  return (
    <>
      <tr onClick={() => handleNaviagte(each.id)} style={{ cursor: 'pointer' }}>
        <td className={style['text-start']}>
          {each?.title} <span className={style['sub-title']}>{each?.thought}</span>
        </td>
        <td className={style['data-2']}>
          <div>
          {profiles?.profile_pics && profiles?.profile_pics.map((user, idx) => (
  <div key={idx}>
    {idx < 5 ? (
      <img
        style={{ width: '24px', height: '24px', borderRadius: '50%' }}
        src={`${BASE_URL}${user}`}
        onError={(e) => {
          e.target.src = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
        }}
      />
    ) : null}
  </div>
))}
{profiles?.profile_pics && profiles?.profile_pics > 5 && (
  <div style={{ backgroundColor: '#F9F5FF', color: '#283891', borderRadius: '50%' }}>
    +{profiles?.profile_pics?.length - 5}
  </div>
)}

          </div>
        </td>
        <td className={style['data-3']}>{formatViewCount(each?.view_count)}</td>
        <td className={style['data-4']}>{each?.comment_count}</td>
      </tr>
    </>
  )
}

export default Thought
