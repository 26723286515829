import React from 'react'
import { useEffect } from 'react'
import TestimonialCard from '../Testimonial_Card/TestimonialCard'
import star from './../../Assets/images/star.png'
import styles from './Testimonial.module.css'
import { Testimonial as Sayaboutus } from '../../services/contact'
import { useState } from 'react'
const Testimonial = () => {
  // Note This file is in use
  const [data, setdata] = useState([])
  const [activeId, setactiveID] = useState(1)
  const [about, setabout] = useState({})
  const FetchTestimonial = async () => {
    const data = await Sayaboutus()
    setdata(data?.results?.slice(0, 10))
    setabout({
      title: data?.results[0]?.client_opinion_heading,
      des: data?.results[0]?.client_opinion,
      stars: data?.results[0]?.client_rating,
      id: data?.results[0]?.id,
    })
  }
  useEffect(() => {
    FetchTestimonial()
  }, [])
  const PrintRating = (number) => {
    const tempArr = []
    for (let i = 0; i < number; i++) {
      tempArr.push(i)
    }
    return tempArr
  }
  const scrollintoview = (number) => {
    const ele = document.querySelector(`.class${number}`)
    ele.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    if (data) {
      const Ele = document.querySelectorAll('[data-number]')
      const obs = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const Id = entry.target.getAttribute('data-number')
              setactiveID(parseInt(Id))
            }
          })
        },
        {
          threshold: 0.5,
        }
      )
      Ele.forEach((e) => {
        obs.observe(e)
      })
    }
  }, [data])

  return (
    <>
      <div className={`container-fluid ${styles.testimonial_container}`} id="testimonial" style={{marginTop:'-100px'}}>
        
        <h1 className="text-center" id={styles.sectionHeader}>
          What Clients say About us
        </h1>
        <div id={styles.desktop_testimonial_container}>
          <div className={`nav nav-pills col-12 col-lg-5 ${styles['testimonila-button-container']}`} id="v-pills-tab" role="tablist" aria-orientation="vertical">
            {data?.map((testimonial) => {
              return (
                <button
                  class={`nav-link ${testimonial.id === about.id ? 'active' : ''} d-flex w-75 mx-3 align-items-center my-3 py-4 px-7`}
                  id="testimonial-01"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                  onClick={() => {
                    setabout({
                      title: testimonial?.client_opinion_heading,
                      des: testimonial?.client_opinion,
                      stars: testimonial?.client_rating,
                    })
                  }}
                >
                  <div className={`col-2 ${styles['this']}`}>
                    <img src={testimonial?.client_pic.replace('http://', 'https://')} className={`my-2  img-fluid ${styles['testimonial-image']}`} alt="" />
                  </div>
                  <div className="col-10 text-start ps-1" style={{ width: '100%' }}>
                    <div className={`${styles.commentName} ms-2 me-0`}>
                      <p>{testimonial?.client_name} </p> <span className="text-blue">| {testimonial?.client_type}</span>
                    </div>
                    <div className={`${styles.commentdest} pt-0 text-secondary ps-2`}>{testimonial?.client_designation}</div>
                  </div>
                </button>
              )
            })}

            {/* <button
            class="nav-link d-flex w-75 align-items-center mx-3 my-3 py-4 px-7"
            id="testimonial-02"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-profile"
            type="button"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            <div className="col-2">
              <img
                src={
                  "https://valuation.venturebuddy.io/static/sitemanager/images/landing-page/testimonial-2.jpeg"
                }
                className={`my-2 img-fluid ${styles["testimonial-image"]}`}
                alt=""
              />
            </div>
            <div className="col-10 text-start ps-1">
              <div className={`${styles.commentName} ms-2 me-0`}>
                Anjali Bawa <span className="text-blue">| INVESTOR</span>
              </div>
              <div className="pt-0 text-secondary ps-2"> Founder - Voila</div>
            </div>
          </button> */}
          </div>
          {/* Testimonial button End Here */}
          <div class={`tab-content col-12 col-lg-7 pt-3 w-50 ${styles.flex_grow_100}`} id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="testimonial-01" tabindex="0">
              <>
                <h4 className={styles.commentHeader}> {about?.title}</h4>
                <div className="d-flex gap-2">
                  {PrintRating(about?.stars).map((index) => {
                    return <img className="star" key={index} src={star}></img>
                  })}
                </div>
                <p className={`pt-2 pt-lg-5 ${styles.comment}`}>{about?.des} </p>
              </>
            </div>
            {/* <div
            class="tab-pane fade"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="testimonial-02"
            tabindex="0"
          >
            <>
              <h4 className={styles.commentHeader}>It was a great experience</h4>
              <div className="d-flex gap-2">
                <img className="star" src={star} alt="" />
                <img className="star" src={star} alt="" />
                <img className="star" src={star} alt="" />
                <img className="star" src={star} alt="" />
                <img className="star" src={star} alt="" />
              </div>
              <p className={`pt-2 pt-lg-5 ${styles.comment}`}>
                Zeal is approachable, diligent, and a great advisor. I got my
                financial modeling done for our business from Zeal and have been
                thoroughly impressed with the detailed analysis and assumptions
                that were undertaken. The best part about working with her is
                that she helps you understand every step and scope of work very
                nicely, and also makes you understand things you should keep in
                factored, something which is rarely communicated by financial
                experts.{" "}
              </p>
            </>
          </div> */}
          </div>
        </div>
        
        <div className={`${styles.mobile_testimonial_container}`}>
          
          <div className={styles['main-mobile-testimonial']} id="main-mobile-testimonial">
            {data?.map((t, i) => {
              return (
                <div className={`class${t.id}`} data-number={t.id}>
                  <TestimonialCard key={i} name={t?.client_name} proffesion={t?.client_type} proffesion2={t?.client_designation} title={t?.client_opinion_heading} testimonial={t?.client_opinion} image={t?.client_pic.replace('http://', 'https://')} rating={PrintRating(t?.client_rating)} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className={styles.check}>
        <div className={styles['testimonial-navigator']}>
          {data?.map((t) => {
            return (
              <div
                className={t.id === activeId ? styles['active-circle'] : styles['circle']}
                onClick={() => {
                  scrollintoview(t.id)
                }}
              ></div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Testimonial
