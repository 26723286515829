import React from 'react'
import styles from './Testimonial_Card.module.css'
import star from './../../Assets/images/star.png'
const TestimonialCard = ({ name, proffesion, proffesion2, title, testimonial, image, rating }) => {
  // Note This file is in use
  return (
    <div className={`${styles.main_testimonial_container}`}>
      <div className={`${styles.user_data_conatiner}`}>
        <div className={`${styles.client_image}`}>
          <img src={image} alt="" />
        </div>
        <div className={`${styles.client_name}`}>
          <p>
            {name} <span>| {proffesion}</span>
          </p>
          <p>{proffesion2}</p>
        </div>
      </div>
      <div className={`${styles.review}`}>
        <div className={`${styles.testimonial_heading}`}>
          <h3>{title}</h3>
        </div>
        <div className={`${styles.stars}`}>
          {rating?.map((rate, idx) => {
            return <img className="star" key={idx} src={star} alt="" />
          })}
        </div>
        <div className={`${styles.description}`}>
          <p>{testimonial}</p>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
