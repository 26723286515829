import React, { useEffect, useState } from 'react'
import style from './Comments.module.css'
import BackBtn from '../../Assets/icons/Vectorbackbtn.png'
import UserImg from '../../Assets/images/Niromi.png'
import saveBtn from '../../Assets/icons/Vectorsave.png'
import UpIcon from '../../Assets/icons/Vectorup.png'
import CommentCard from '../../components/CommentCard/CommentCard'
import dropDownBtn from '../../Assets/icons/Vectorbackbtn.png'
import Footer from '../../components/Footer/Footer.js'
import img from '../../Assets/images/Niromi.png'
import { CommentReply, CommentsView, DoComment, ThoughtComment, ViewReplyComment } from '../../services/Communitys.js'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../utils/constant.js'
import axios from 'axios'

const Comments = () => {
  const [text, setText] = useState('')
  const [text2, setText2] = useState('')

  const [data, setData] = useState()
  const [comment, setComment] = useState()
  const [reply, setReply] = useState()
  const [liked, setLiked] = useState()
  const [com, setCom] = useState()
  const [toggle, seToggle] = useState(false)
  const [indexd,setIndexd]=useState(null)
  const [indexo,setIndexo]=useState(null)
  const [parent,setParent]=useState()
  const [thoughtid,setthoughtid]=useState()
  const [replies1,setReplies]=useState(null)
  const [disLiked,setDisliked]=useState()
  const [userProfile,setuserProfile]=useState()
  const location = useLocation()
  const navigate = useNavigate()
  const id = location.state
  const user_id = localStorage.getItem('user_id')
  const token = localStorage.getItem('access_token')
  let baseUrl = process.env.REACT_APP_BASE_URL;
  if (baseUrl.endsWith("/")) {
    baseUrl = baseUrl.slice(0, -1);
  }
  const BASE_URL1 = baseUrl;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommentsView(location.state)
        setData(response)
      } catch (error) {}
    }

    fetchData()
  }, [location.state])

  useEffect(() => {
    const fetchComment = async () => {
      try {
        const response = await ThoughtComment(location.state)
        setComment(response)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchComment()
  }, [liked, com,disLiked,location.state])
  const handleCommit = async () => {
    if (!token) {
      navigate('/login')
    }
    if (text === '') {
      alert('write somethings please')
    }
    const commentData = {
      comment: text,
      user: user_id,
      thought: id,
    }
    try {
      const response = await DoComment(commentData)
      setCom(response)
      setText('')
    } catch (error) {
      console.error(error)
    }
  }
 
  const handleCommentReply = async()=>{
    const data = {
      comment: text2,
    user: user_id,
    thought: thoughtid,
    parentcomment: parent
    }
      try{
        const response = await CommentReply(data)
         setText2("")
         setReplies(response)
         setIndexo(null)
      }catch(error){
        console.log(error)
      }
  }

  const formateDate = (data) => {
    const formattedDate = new Date(data?.date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    return formattedDate
  }

  const formatTime = (timeStr) => {
    const parsedTime = new Date(`2000-01-01T${timeStr}Z`)
    const formattedTime = parsedTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
    })
    return formattedTime
  }

  useEffect(()=>{
    const  fetchProfile = async()=>{
      try{
        const response = await axios.get(`${BASE_URL}/getuser/${user_id}/`)
        const res = await response.data
        setuserProfile(res['Users'][0])     
      }catch(err){}
    }
    fetchProfile()
  
  },[user_id])


  return (
    <>
      <div className={style['main-comments-container']}>
        <div className={style['comment-contianer']}>
          <div className={style['comment-container-heading']}>
            <div className={style['heading-wrapper']}>
              <h1>Vibrant Community</h1>
              <p>A List of questions and thoughts by the users about the event.</p>
            </div>

          </div>
          <div className={style['main-comment']}>
            <div className={style['back-btn']}>
              <button>
                <img src={BackBtn} alt="" onClick={() => navigate('/community')} />
              </button>
            </div>
            <div className={style['comment']}>
              <p className={style['comment-text']}>{data?.thought}</p>
              <div className={style['date-time-user-container']}>
                <div className={style['date-time']}>
                  <span>{formateDate(data)}</span>
                  <span>{formatTime(data?.time)}</span>
                </div>
                <div className={style['user']}>
                  <img
                    src={`${BASE_URL}${data?.user?.profile_pic}`}
                    alt=""
                    onError={(e) => {
                      e.target.src = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                    }}
                  />
                  {data?.user?.username}
                </div>
              </div>
              <div className={style['option-container']}>
                <img src={saveBtn} alt="" />
                <img src={UpIcon} alt="" />
                <span className={style['number']}>354</span>
              </div>
            </div>
            <div className={style['border']}></div>
          </div>
          <div className={style['all-comments-container']}>
            <div className={style['all-comments-heading']}>
              <button onClick={() => seToggle(!toggle)}>
                {' '}
                <img src={BackBtn} alt="" style={{ transform: `rotate(${toggle === true ? '90deg' : '-90deg'})` }} />{' '}
              </button>
              <p>Comments</p>
            </div>
            {toggle && (
              <div className={style['all-comments-list']}>
                <div className={style['top-comment']}>
                  {comment?.map((item, index) => (
                    <>
                      <div key={index}>
                        <CommentCard comment={item?.comment} likes={item?.like_count} disLiked={disLiked} key={index} comId={item?.id} reply={item?.replycomment_count} time={item?.time} profile={item?.user?.profile_pic} state={setReply} indexd={setIndexd} Tfalse={true}  customKey={index} setIndexo={setIndexo} setDisliked={setDisliked} replies1={replies1} setParent={setParent} setthoughtid={setthoughtid} liked={setLiked} thoughtID={location.state} />
                        {index===indexo? <div style={{ display: 'flex', width: '90%', alignItems: 'center', marginLeft:'60px'}} className={style['main_comment2']}>
                            <img src={`${BASE_URL1}/media/${userProfile?.profile_pic}`} style={{ width: '32px', height: '32px' }} onError={(e)=>e.target.src="https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"}/>
                            <div
                              style={{
                                borderRadius: '10px',
                                background: 'var(--Neutral-50, #F7F8F9)',
                                display: 'flex',
                                width: '100%',
                                marginLeft: '12px',
                                alignItems: 'center',
                              }}
                            >
                              <input type="text" placeholder="Write a Comment...." style={{ outline: 'none', border: 'none', color: 'var(--Blacks-Darker-grey, #6D747A)', width: '100%', padding: '6px' }} value={text2} onChange={(e) => setText2(e.target.value)} />
                              <div style={{ paddingRight: '13px' }}>
                                {text2 === '' ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M22 12L3 19L7 12L3 5L22 12Z" stroke="#CED4DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleCommentReply()} style={{ cursor: 'pointer' }}>
                                    <path d="M22 12L3 19L7 12L3 5L22 12Z" stroke="#283891" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                )}
                              </div>
                            </div>
                            <div></div>
                          </div>:''}
                        {index===indexd?<div className={style['other-comment']}>
                          <div>{reply?.length === 0 ? null : reply?.map((item, index) => <CommentCard id={item?.id} disLiked={disLiked} com={item?.comment} Tfalse={false} like1={item?.like_count} replies1={replies1} profile_pic={item?.user?.profile_pic} time1={item?.time} key={index} state2={reply} setIndexo={setIndexo} setParent={setParent} setthoughtid={setthoughtid} setDisliked={setDisliked}  thoughtID={location.state}/>)}</div>                      
                        </div>:null

                        }
                       
                      </div>
                    </>
                  ))}
                     
                </div>
                
              </div>
            )}
          </div>
          <div style={{ display: 'flex', width: '90%', alignItems: 'center', marginLeft:'60px'}} className={style['main_comment']}>
                            <img src={`${BASE_URL1}/media/${userProfile?.profile_pic}`}  style={{ width: '32px', height: '32px' }} onError={(e)=>e.target.src="https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"}/>
                            <div
                              style={{
                                borderRadius: '10px',
                                background: 'var(--Neutral-50, #F7F8F9)',
                                display: 'flex',
                                width: '100%',
                                marginLeft: '12px',
                                alignItems: 'center',
                     
                              }}
                            >
                              <input type="text" placeholder="Write a Comment...." style={{ outline: 'none', border: 'none', color: 'var(--Blacks-Darker-grey, #6D747A)', width: '100%', padding: '6px' }} value={text} onChange={(e) => setText(e.target.value)} />
                              <div style={{ paddingRight: '13px' }}>
                                {text === '' ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M22 12L3 19L7 12L3 5L22 12Z" stroke="#CED4DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleCommit()} style={{ cursor: 'pointer' }}>
                                    <path d="M22 12L3 19L7 12L3 5L22 12Z" stroke="#283891" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                )}
                              </div>
                            </div>
                            <div></div>
                          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Comments
