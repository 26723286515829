import React from 'react'
import package1 from '../bookingkeeping_assests/package_1.svg'
import package2 from '../bookingkeeping_assests/package_2.svg'
import package3 from '../bookingkeeping_assests/package_3.svg'
import package4 from '../bookingkeeping_assests/package_4.svg'
import style from '../BookingKeeping.module.css'

const Packages = () => {
    const packageAccout=[{
        id:1,
        title1:'Start up Phase Accounting',
        title2:'Turnover up to Rs 5 Crore',
        price:'$ 50',
        image:package1,
        color:'#2FB6FA'
      },{
        id:2,
        title1:'Growth Phase Accounting',
        title2:'Turnover from Rs 5 Crore to 25 Crores',
        price:'$ 150',
        image:package2,
        color:'#F68C09'
      },
      {
        id:3,
        title1:'Expansion Phase Accounting',
        title2:'Turnover from Rs 25 Crore to 100 Crores',
        price:'$ 300',
        image:package3,
        color:'#08DA4E'
      },
      {
        id:4,
        title1:'Unicorn Phase Accounting',
        title2:'Turnover above 100 Crores',
        price:'On call',
        image:package4,
        color:'#F760A6'
      }]
  return (
   <>
     <div className={style['Package_headeing']}>
            <h1>Packages for Accounting and Bookkeeping Services</h1>
            <h2>Take advantage of our bundle services and save time and money</h2>
            <br/>

           </div>
           <br/>
           <div className={style['main_package_bg']}>
            <div className={style['package_wrapper_list2']}>
              <div className={style['package_wrapper_list_3']} style={{paddingLeft:'30px',paddingRight:'30px'}}>
              {packageAccout.map((item,index)=>(
              <>
              <div className={style['package_card_02']} style={{width:'291px', justifyContent:'center', alignItems:'center', flexDirection:'column', paddingBottom:'15px',backgroundColor:'white',borderRadius:'8px',borderBottom:`4px solid ${item.color}`,height:'484px'}} key={index}>

<div className='package_image' style={{width:'100%',display:'flex',justifyContent:'center'}}>
<img src={item.image} style={{ marginTop: index === 0 || index === 3 ? '-13px' : '-16px' }} />
</div>
<div className='package_detail'>
  <h2 style={{fontSize:'28px',color:'#08090A',fontWeight:'600',textAlign:'center',paddingTop:'25px'}}>{item.title1}</h2>
  <h3 style={{fontSize:'16px',color:'#000000',textAlign:'center',fontWeight:'500',paddingTop:'10px'}}>{item.title2}</h3>
  <br/>
  <div style={{display:'flex',justifyContent:'center',marginTop:index === 0 || index === 3 ? '20px' : '0' }}>
  <h4 style={{color:'#0F1F78',fontSize:'32px',fontWeight:'700',textAlign:'center',display:'flex'}}>{item.price} <span style={{color:'#0F1F78',fontSize:'16px',fontWeight:'500',display:`${item.price==="On call"?'none':'block'}`,paddingTop:'14px',paddingLeft:'6px'}}>per Month</span></h4>
  </div>
  <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'22px'}}>
  <button style={{width:'110px',height:'48px',backgroundColor:'white',border:'1px solid #0F1F78',borderRadius:'8px',color:'#0F1F78',fontWeight:'600'}} onClick={()=>alert("button clicked backend not integrated")}>Buy Now</button>
  </div>
</div>
</div>
              </>
             ))

             }
              </div>
            </div>
           </div>
   </>
  )
}

export default Packages