import { jsPDF } from 'jspdf'

/*
* `data` argument value -
    {
      issueDate: string, // Issue Date 
      company: string,  // Company Full Name
      user: string, // User Full Name
      transactionId: string, // Transaction ID
      orderId: string, // Order ID
      lotPrice: string, // Lot Price of the Invested Company
      qty: string, // Quantity of Lots Purcahased by the User
      totalAmount: string, // Total Amount Invested by User
      receiptTotal: string, // Total Sum Amount of All Investment 
      convenienceFee: string, // Convenience Fee 
      finalTotalAmount: string, // Grand Total Amount
    }

*/
export const downloadReceipt = (data) => {
  const { issueDate = '', company = '', user = '', transactionId = '', orderId = '', lotPrice = '', qty = '', totalAmount = '', receiptTotal = '', convenienceFee = '', finalTotalAmount = '' } = data

  const doc = new jsPDF({ orientation: 'p', unit: 'px', format: [595, 820] })

  const width = doc.internal.pageSize.getWidth()

  doc.setDrawColor(0)
  doc.setFillColor(235, 237, 240)
  doc.rect(0, 0, width, 332, 'F')

  doc.addImage('vb-logo.png', 'PNG', 33, 34, 90, 32)

  doc.setTextColor(15, 31, 120)

  // Heading
  doc.setFontSize(24).setFont('helvetica', 'bold')
  doc.text('Payment Receipt', 313, 70)

  // Issue Date
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('Issue date', 313, 108)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.text(issueDate, 313, 120)

  // From
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('From', 313, 148)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.text(company, 313, 160, { maxWidth: 90 })

  // To
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('To', 454, 148)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.text(user, 454, 160, { maxWidth: 90 })

  // Contact
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('Contact Support', 313, 225)

  // doc.setFontSize(12).setFont('helvetica', 'normal')
  // doc.text('13620519', 388, 220)

  // Email
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('Email:', 313, 252)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.textWithLink('preetesh@venturebuddy.io', 342, 252, {
    url: 'mailto:preetesh@venturebuddy.io',
  })

  // Mobile
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('Mobile:', 313, 280)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.textWithLink('9594228662', 346, 280, { url: 'tel:9594228662' })

  // ** Body **
  doc.setTextColor(0, 0, 0)

  // Table Body Headings
  doc.setFontSize(14).setFont('helvetica', 'bold')
  doc.text('Startup Company', 40, 362)

  doc.setFontSize(14).setFont('helvetica', 'bold')
  doc.text('Lot price', 313, 362)

  doc.setFontSize(14).setFont('helvetica', 'bold')
  doc.text('Qty', 417, 362)

  doc.setFontSize(14).setFont('helvetica', 'bold')
  doc.text('Total', 555, 362, { maxWidth: 90, align: 'right' })

  // Divider Line
  doc.setLineWidth(1)
  doc.line(40, 375, 555, 375)

  // Company Name row
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text(company, 40, 393)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.text(`${lotPrice} INR`, 313, 393)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.text(qty, 417, 393)

  doc.setFontSize(12).setFont('helvetica', 'normal')
  doc.text(`${totalAmount} INR`, 555, 393, { maxWidth: 90, align: 'right' })

  // Divider Line
  doc.setLineWidth(1)
  doc.line(313, 431, 555, 431)

  // Receipt Total
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('Receipt total', 313, 451)

  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text(`${receiptTotal} INR`, 555, 451, { maxWidth: 90, align: 'right' })

  // Convenience fee
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('Convenience fee', 313, 471)

  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text(`${convenienceFee} INR`, 555, 471, { maxWidth: 90, align: 'right' })

  // Divider Line
  doc.setLineWidth(1)
  doc.line(313, 484, 555, 484)

  // Total Amount
  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text('Total Amount', 313, 500)

  doc.setFontSize(12).setFont('helvetica', 'bold')
  doc.text(`${finalTotalAmount} INR`, 555, 500, { maxWidth: 90, align: 'right' })

  // Footer
  doc.setFontSize(13).setFont('helvetica', 'normal')
  doc.text(`Note: In case the investment deal isn't subscribed fully or accepted by the investee company, your money will be refunded in 15 days after closure of campaign.`, 40, 728, { maxWidth: width - 80 })

  doc.setFontSize(13).setFont('helvetica', 'normal')
  doc.text(`Transaction ID: ${transactionId}`, 40, 760, { maxWidth: width - 80 })

  doc.setFontSize(13).setFont('helvetica', 'normal')
  doc.text(`Order ID: ${orderId}`, 40, 777, { maxWidth: width - 80 })

  doc.save(`Venture Buddy Receipt- ${orderId}`)

  // const pdfBlob = doc.output('blob')
  // const url = URL.createObjectURL(pdfBlob)

  // setPdfUrl(url)
}
