import React, { useEffect, useState } from 'react'
import style from './blogslider.module.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper';
import BlogsCards from './BlogsCards';
import { ListAllBlogs } from '../../../services/blogsApi';
// import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper/modules";

const BlogsSlider = () => {
  const [allblogs1,setAllblogs]=useState()
  const allblogs = async ()=>{
    try{
      const response = await ListAllBlogs()
      const res = await response
      // console.log("this is allblogs",res.results)
      setAllblogs(res)      
    }catch(err){

    }
}

useEffect(()=>{
  allblogs()
},[])
  return (
    <>
    <div className={style['blog_hero']} style={{backgroundColor:'#FBFBFB'}}>
        <div className={style['blog_heading']}>
            <h1>Blogs</h1>
            <p>Stay informed with our medical blog. From health tips to the latest advancements, we're your source for concise, insightful updates on wellness and medical breakthroughs.</p>
        </div>
        {/* <div className={style['blogs_slideing']} style={{backgroundColor:'red',height:'200%'}}>
        <Swiper
              slidesPerView={window.innerWidth<=500?1.25:3.2}
              spaceBetween={24}
              pagination={false}
              keyboard={true}
              mousewheel={{forceToAxis:true,thresholdDelta:30}}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              breakpoints={{
                500: {
                  slidesPerView: 1.15,
                },
                768: {
                  slidesPerView: 1.15, 
                },
                1024: {
                  slidesPerView: 3.2,
                },
                1539:{
                  slidesPerView:3.5
                }
              }}
              className="mySwiper"
            >
            {allblogs1?.results.length>0?(
              allblogs1?.results?.map((item,index)=>(
                <>
                <SwiperSlide></SwiperSlide>
                </>
              ))
            ):('Loading...')

            }
               
            </Swiper>

        </div> */}
        <div style={{width:'100%',display:'flex',gap:'40px',overflowX:'scroll',padding:'0px 60px'}} className={style['new_sliding']}>
        {allblogs1?.results.length>0?(
              allblogs1?.results?.map((item,index)=>(
             <BlogsCards item={item} />
              ))
            ):('Loading...')

            }


        </div>
    </div>
        
    </>
  )
}

export default BlogsSlider