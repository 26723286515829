import React, { useEffect, useState } from 'react'
import searchImg from './../../Assets/icons/search.png'
import styles from './Options.module.css'
import toggleBlack from './../../Assets/icons/toggle.png'
import toggleWhite from './../../Assets/icons/toggle-white.png'
import { getAllStartups } from '../../services/startup'
import useStartupCtx from '../../hooks/useStartupCtx'
import { useRef } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'

// WORKING: File is in use.
const Options = () => {
  const { setAllstartups } = useStartupCtx()

  const [all, setall] = useState(false)

  const { width } = useWindowDimensions()
  const sectorDropdownRef = useRef(null)
  const techDropdownRef = useRef(null)
  const revenueDropdownRef = useRef(null)
  const [isSectorDropdownOpen, setIsSectorDropdownOpen] = useState(false)
  const [isTechDropdownOpen, setIsTechDropdownOpen] = useState(false)
  const [isRevenueDropdownOpen, setIsRevenueDropdownOpen] = useState(false)
  const [searchedValue, setSearchedValue] = useState('')
  const [searchedQuery, setSearchedQuery] = useState([])
  const [selectedSector, setSelectedSector] = useState({
    label: 'Sector',
    value: null,
  })
  const [selectedTech, setSelectedTech] = useState({
    label: 'Tech',
    value: null,
  })
  const [selectedRevenue, setSelectedRevenue] = useState({
    label: 'Revenue ',
    value: null,
  })

  const sectorList = [
    { label: 'Sector', value: null },
    { label: 'Arts & Entertainment', value: 'Arts & Entertainment' },
    { label: 'Consumer goods & Retails', value: 'Consumer goods & Retails' },
    { label: 'Edtech', value: 'Edtech' },
    { label: 'Fintech', value: 'Fintech' },
    { label: 'Food & Drinks', value: 'Food & Drinks' },
    { label: 'Health & wellness', value: 'Health & wellness' },
    { label: 'Healthcare', value: 'Healthcare' },
    { label: 'Real estate', value: 'Real estate' },
    { label: 'Transpotation', value: 'Transpotation' },
    { label: 'Travel & Hospitality', value: 'Travel & Hospitality' },
  ]
  const techList = [
    { label: 'Tech', value: null },
    { label: '3D Printing', value: '3D Printing' },
    { label: 'Apps', value: 'Apps' },
    { label: 'Blockchain', value: 'Blockchain' },
    { label: 'Hardware', value: 'Hardware' },
    { label: 'Robotics', value: 'Robotics' },
    { label: 'AI and Machine Learning', value: 'AI and Machine Learning' },
    { label: 'Biotechnology', value: 'Biotechnology' },
    { label: 'IOT', value: 'IOT' },
    { label: 'Wearables', value: 'Wearables' },
  ]
  const revenueList = [
    { label: 'Revenue', value: null },
    { label: '₹5L+', value: 500000 },
    { label: '₹10L+', value: 1000000 },
    { label: '₹2Cr+', value: 20000000 },
    { label: '₹5Cr+', value: 50000000 },
    { label: '₹10Cr+', value: 100000000 },
    { label: '₹50Cr+', value: 500000000 },
    { label: '₹100Cr+', value: 1000000000 },
  ]

  const searchParams = new URLSearchParams()
  useEffect(() => {
    if (!searchedValue.match(/((\r\n|\n|\r)$)|(^(\r\n|\n|\r))|^\s*$/gm)) searchParams.append('search', searchedValue)
    if (selectedSector.value !== null) searchParams.append('sector', selectedSector.value)
    if (selectedTech.value !== null) searchParams.append('tech', selectedTech.value)
    if (selectedRevenue.value !== null) searchParams.append('revenue', selectedRevenue.value)

    // console.log(searchParams.toString())
    getAllStartups(searchParams.toString(), (err, res) => {
      if (err) console.log(err)
      // console.log(res.data)
      setAllstartups(res.data)
    })
  }, [searchedValue, selectedSector, selectedTech, selectedRevenue])
  return (
    <>
      <div id={styles.options} className="d-inline-block">
        {width >= 1000 ? (
          <div className={styles.dropdown_dex}>
            <div id={styles.search} className="px-0">
              <img src={searchImg} alt="" />
              <input type="text" className={`${styles['search-bar']}`} autoComplete="off" placeholder="Search for interested startups" onChange={(e) => setSearchedValue(e.target.value)} id="searchInput" spellCheck="false" />
            </div>

            <div className="d-flex px-0 dropdown_first" id={styles.drop}>
              <div className="dropdown">
                <button className={`btn rounded-pill dropdown-toggle ${selectedSector.value !== null && styles.active}`} type="button" data-bs-toggle="dropdown" id={styles.sector} onClick={() => setIsSectorDropdownOpen(!isSectorDropdownOpen)}>
                  {selectedSector.label}
                  <img src={selectedSector.value !== null ? toggleWhite : toggleBlack} className={styles.toggle} alt="" style={{ transform: isSectorDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </button>
                <ul ref={sectorDropdownRef} className={`dropdown-menu ${styles.dropdown}`} id="sectorDropdown">
                  {sectorList?.map((sector, idx) => {
                    return (
                      <li
                        key={idx}
                        onClick={(e) => {
                          setSelectedSector(sectorList[idx])
                          sectorDropdownRef?.current.classList.remove('show')
                          setIsSectorDropdownOpen(!isSectorDropdownOpen)
                        }}
                        className="dropdown-item"
                      >
                        {sector.label}
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className="dropdown">
                <button className={`btn rounded-pill dropdown-toggle ${selectedTech && selectedTech.value !== null && styles.active}`} type="button" data-bs-toggle="dropdown" id={styles.tech} onClick={() => setIsTechDropdownOpen(!isTechDropdownOpen)}>
                  {selectedTech.label}
                  <img src={selectedTech.value !== null ? toggleWhite : toggleBlack} className={styles.toggle} alt="" style={{ transform: isTechDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </button>

                <ul ref={techDropdownRef} className={`dropdown-menu ${styles.dropdown}`} id="techDropdown">
                  {techList?.map((tech, idx) => {
                    return (
                      <li
                        key={idx}
                        onClick={(e) => {
                          setSelectedTech(techList[idx])
                          techDropdownRef?.current.classList.remove('show')
                          setIsTechDropdownOpen(!isTechDropdownOpen)
                        }}
                        className="dropdown-item"
                      >
                        {tech.label}
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className="dropdown">
                <button className={`btn rounded-pill dropdown-toggle ${selectedRevenue && selectedRevenue.value !== null && styles.active}`} type="button" data-bs-toggle="dropdown" id={styles.revenue} onClick={() => setIsRevenueDropdownOpen(!isRevenueDropdownOpen)}>
                  {selectedRevenue.label}
                  <img src={selectedRevenue.value !== null ? toggleWhite : toggleBlack} className={styles.toggle} alt="" style={{ transform: isRevenueDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </button>
                <ul ref={revenueDropdownRef} className={`dropdown-menu ${styles.dropdown}`} id="revenueDropdown">
                  {revenueList?.map((revenue, idx) => {
                    return (
                      <li
                        key={idx}
                        onClick={(e) => {
                          setSelectedRevenue(revenueList[idx])
                          revenueDropdownRef?.current.classList.remove('show')
                          setIsRevenueDropdownOpen(!isRevenueDropdownOpen)
                        }}
                        className="dropdown-item"
                      >
                        {revenue.label}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.mob_view}>
            <div className={styles.dropdown_mob_add}>
              <div className={styles.search_box}>
                <img src={searchImg} alt="" />
                <input type="text" autoComplete="on" placeholder="Search" onChange={(e) => setSearchedValue(e.target.value)} id="searchInput" />
              </div>
              <div className={styles.dropdown_mob} onClick={() => setall(!all)}>
                <button style={{ background: all ? '#18A62B' : 'initial', transition: '250ms', color: all ? '#fff' : '#6d747a' }}>
                  All
                  <img src={all ? toggleWhite : toggleBlack} alt="" style={{ transform: all ? 'rotate(180deg)' : 'rotate(0deg)', transition: '250ms' }} />
                </button>
              </div>
            </div>
            <div
              className={styles.mob_opt}
              style={
                all
                  ? {
                      display: 'initial',
                      visibility: 'visible',
                      opacity: '1',
                      transition: '250ms',
                    }
                  : { display: 'none', visibility: 'hidden', opacity: '0' }
              }
            >
              <div className="d-flex px-0 dropdown_first" id={styles.drop}>
                <div className="dropdown">
                  <button className={`btn rounded-pill dropdown-toggle ${selectedSector.value !== null && styles.active}`} type="button" data-bs-toggle="dropdown" id={styles.sector} onClick={() => setIsSectorDropdownOpen(!isSectorDropdownOpen)} style={{ background: isSectorDropdownOpen ? '#18A62B' : 'initial', transition: '250ms', color: isSectorDropdownOpen ? '#fff' : '#6d747a' }}>
                    {selectedSector.label}
                    <img src={isSectorDropdownOpen ? toggleWhite : selectedSector.value !== null ? toggleWhite : toggleBlack} className={styles.toggle} alt="" style={{ transform: isSectorDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: '250ms' }} />
                  </button>

                  <ul ref={sectorDropdownRef} className={`dropdown-menu ${styles.dropdown}`} id="sectorDropdown">
                    {sectorList?.map((sector, idx) => {
                      return (
                        <li
                          key={idx}
                          onClick={(e) => {
                            setSelectedSector(sectorList[idx])
                            sectorDropdownRef?.current.classList.remove('show')
                          }}
                          className="dropdown-item"
                        >
                          {sector.label}
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="dropdown">
                  <button className={`btn rounded-pill dropdown-toggle ${selectedTech && selectedTech.value !== null && styles.active}`} type="button" data-bs-toggle="dropdown" id={styles.tech} onClick={() => setIsTechDropdownOpen(!isTechDropdownOpen)} style={{ background: isTechDropdownOpen ? '#18A62B' : 'initial', transition: '250ms', color: isTechDropdownOpen ? '#fff' : '#6d747a' }}>
                    {selectedTech.label}
                    <img src={isTechDropdownOpen ? toggleWhite : selectedTech.value !== null ? toggleWhite : toggleBlack} className={styles.toggle} alt="" style={{ transform: isTechDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: '250ms' }} />
                  </button>

                  <ul ref={techDropdownRef} className={`dropdown-menu ${styles.dropdown}`} id="techDropdown">
                    {techList?.map((tech, idx) => {
                      return (
                        <li
                          key={idx}
                          onClick={(e) => {
                            setSelectedTech(techList[idx])
                            techDropdownRef?.current.classList.remove('show')
                          }}
                          className="dropdown-item"
                        >
                          {tech.label}
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="dropdown">
                  <button className={`btn rounded-pill dropdown-toggle ${selectedRevenue && selectedRevenue.value !== null && styles.active}`} type="button" data-bs-toggle="dropdown" id={styles.revenue} onClick={() => setIsRevenueDropdownOpen(!isRevenueDropdownOpen)} style={{ background: isRevenueDropdownOpen ? '#18A62B' : 'initial', transition: '250ms', color: isRevenueDropdownOpen ? '#fff' : '#6d747a' }}>
                    {selectedRevenue.label}
                    <img src={isRevenueDropdownOpen ? toggleWhite : selectedRevenue.value !== null ? toggleWhite : toggleBlack} className={styles.toggle} alt="" style={{ transform: isRevenueDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: '250ms' }} />
                  </button>

                  <ul ref={revenueDropdownRef} className={`dropdown-menu ${styles.dropdown}`} id="revenueDropdown">
                    {revenueList?.map((revenue, idx) => {
                      return (
                        <li
                          key={idx}
                          onClick={(e) => {
                            setSelectedRevenue(revenueList[idx])
                            revenueDropdownRef?.current.classList.remove('show')
                          }}
                          className="dropdown-item"
                        >
                          {revenue.label}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <div style={{ display: 'flex', gap: '40px' }}>
        {/* <div >
        {searchedQuery.map((query, index) => (
          <div key={index} >
            <span>{query}</span>
            <button>X</button>
          </div>
        ))}
      </div> */}
        {/* <div>
     {searchedQuery.length > 0 && (
      <button 
  onClick={() => {
    setSearchedValue("");
    setSearchedQuery([]);
  }} 
  style={{
    width: '78px',
    height: '32px',
    borderRadius: '32px',
    border: '1px solid #CED4DA',
    backgroundColor: 'white',
    fontSize: '14px'
  }}
>
  Clear All
</button>
      )}
     </div> */}
      </div>
    </>
  )
}

export default Options
