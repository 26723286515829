import React from "react";
import HasselessProject from "./../../Assets/images/Hasseless-Process.png";
import investorDashboard from "./../../Assets/images/investor-dashboard.png";
import periodic from "./../../Assets/images/periodic-reports.png";
import buddies from "./../../Assets/images/genuine-startups.png";
import verfiedStartups from "./../../Assets/images/verified-startups.png";
import WhyChooseCard from "./WhyChooseCard";
import styles from "./WhyChooseUs.module.css";

const WhyChooseUs = () => {
  // Note This file is in use
  const servicess = [
    {
      id: 1,
      imgId: "hasseless",
      img: HasselessProject,
      header: "1. Easy Access",
      description:
        "Innovation plays an important role in our business services and solutions",
    },
    {
      id: 2,
      imgId: "investor",
      img: investorDashboard,
      header: "2. Investor Dashboards",
      description:
        "Get all your investments under on roof.",
    },
    {
      id: 3,
      imgId: "periodic",
      img: periodic,
      header: "3. Periodic Reports",
      description:
        "Know your startups closely with periodic reports of their growth.",
    },
    {
      id: "rocket",
      imgId: "verified",
      img: verfiedStartups,
      header: "4. Verified and Genuine Investment Opportunities",
      description:
        "Venturebuddy's verified startups with high growth founders enable you to maximize returns.",
    },
    {
      id: 5,
      imgId: "buddies",
      img: buddies,
      header: "5. Free Tool For Lifetime",
      description:
        "Turn from an investor to founder by validating your ideas with lifetime access of valuation tool.",
    },
  ];

  return (
    <section className="text-center" id={styles.whyChooseUs}>
      <div className={`col-10 col-lg-6 mx-auto ${styles.sectionHeader_warp}`}>
        <h1 className={styles.sectionHeader}>Why Investors choose us?</h1>
        <p className={`col-9 mx-auto ${styles.sectionText}`}>
          At VentureBuddy, we believe that the success of your business is our
          success. That's why we work closely with you, to help you succeed.
        </p>
      </div>
      <div className={`${styles.choose_us_mobile_msg}`}>
        <p>
          At VentureBuddy, we believe that the success of your business is our
          success. That's why we work closely with you, to help you succeed.
        </p>
      </div>

      <div className="container">
        <div
          className={`row row-cols-1 row-cols-lg-3 justify-content-center align-items-baseline mt-5 ${styles.card_wrap}`}
        >
          {servicess.map((item) => (
            <WhyChooseCard
              id={item.id}
              img={item.img}
              header={item.header}
              desc={item.description}
              imgId={item.imgId}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
