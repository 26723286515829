import React, { useState } from 'react'
// import Style from '../../TermsAndConditionPage/TermsAndConditionPage.module.css'
import Style from './TermConditions.module.css'
import useAuthCtx from '../../../hooks/useAuthCtx';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { registerNewUser, updateGoogleUser, verifyBankDetails } from "../../../services/account";

const TermCondition2 = ({userKycDetails, setUserKycDetails, submitBtnRef, submitKycDetails}) => {
  
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const handleBankDetailsSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("bank_number", userKycDetails.bank_account_number);
        formData.append("ifsc_code", userKycDetails.ifsc_code);
        setSubmitBtnLoading(true);
        verifyBankDetails(formData, (err, res) => {
            setSubmitBtnLoading(false);
          if (err || res.status !== 200) return toast.error("Something went wrong!");
          if (res.data.status !== 200) return toast.error("Data in invalid!");
          if (res.data.status === 200) {
            setUserKycDetails((prev) => {
              return { ...prev, isBankVarified: true };
            });
            submitKycDetails();
            // document.getElementById("next").click();
            // document.getElementById("stepThree")?.classList.remove("step-active");
            // document.getElementById("stepThree")?.classList.add("step-done");
            // document.getElementById("stepFour")?.classList.add("step-active");
          }
        });
      };
  return (
   <>
    <div className={Style['main1']}>
     <div className={Style['wrapper_1']}>
       <div className={Style['content_div']}>
         <h1>Terms & Conditions</h1>
         <p>When it comes to what you should include in your terms and conditions or how you should be wording them, there are what could be considered standard clauses but, ultimately, you should always consider the particularities of your business and avoid using legalese.
This template could help you get a head start, simply adapt it to your business, taking into consideration the services or products that you offer and the risks and liabilities that come with those.</p>
<h2>Condition of use</h2>
<p>When it comes to what you should include in your terms and conditions or how you should be wording them, there are what could be considered standard clauses but, ultimately, you should always consider the particularities of your business and avoid using legalese. This template could help you get a head start, simply adapt it to your business, taking into consideration the services or products that you offer and the risks and liabilities that come with those.</p>
<h3>Privacy policy</h3>
<p>When it comes to what you should include in your terms and conditions or how you should be wording them, there are what could be considered standard clauses but, ultimately, you should always consider the particularities of your business and avoid using legalese. This template could help you get a head start, simply adapt it to your business, taking into consideration the services or products that you offer and the risks and liabilities that come with those.</p>
       </div>
     </div>

     <div className={Style['btn_cls']}>
        <div>
            <button onClick={handleBankDetailsSubmit}>{submitBtnLoading ? <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ margin: "auto", background: "rgba(221, 221, 221, 0)", display: "block" }}
                width="42px"
                height="42px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle cx={50} cy={50} fill="none" stroke="#f2f2f2" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                </circle>
              </svg>:'Accept'}</button>
        </div>
     </div>

    </div>
   </>
  )
}

export default TermCondition2