import React, { useEffect, useState } from 'react'
import style3 from '../../../components/Testimonial/Testimonial.module.css'
import { Testimonial } from '../../../services/contact'
import TestimonialCard from '../../../components/Testimonial_Card/TestimonialCard'

const MobileClientView = () => {
    const [data1, setdata] = useState([])
    const [activeId, setactiveID] = useState(1)
    const [about, setabout] = useState({})
    const FetchTestimonial = async () => {
        const data = await Testimonial()
        setdata(data?.results?.slice(0, 10))
        setabout({
          title: data?.results[0]?.client_opinion_heading,
          des: data?.results[0]?.client_opinion,
          stars: data?.results[0]?.client_rating,
          id: data?.results[0]?.id,
        })
      }
      useEffect(() => {
        FetchTestimonial()
      }, [])
      const PrintRating = (number) => {
        const tempArr = []
        for (let i = 0; i < number; i++) {
          tempArr.push(i)
        }
        return tempArr
      }
      useEffect(() => {
        if (data1) {
          const Ele = document.querySelectorAll('[data-number]')
          const obs = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  const Id = entry.target.getAttribute('data-number')
                  setactiveID(parseInt(Id))
                }
              })
            },
            {
              threshold: 0.5,
            }
          )
          Ele.forEach((e) => {
            obs.observe(e)
          })
        }
      }, [data1])
      const scrollintoview = (number) => {
        const ele = document.querySelector(`.class${number}`)
        ele.scrollIntoView({ behavior: 'smooth' })
      }
  return (
  <>
       <div className={`${style3.mobile_testimonial_container}`}>
          <div className={style3['main-mobile-testimonial']} id="main-mobile-testimonial">
            {data1?.map((t, i) => {
              return (
                <div className={`class${t.id}`} data-number={t.id}>
                  <TestimonialCard key={i} name={t?.client_name} proffesion={t?.client_type} proffesion2={t?.client_designation} title={t?.client_opinion_heading} testimonial={t?.client_opinion} image={t?.client_pic.replace('http://', 'https://')} rating={PrintRating(t?.client_rating)} />
                </div>
              )
            })}
          </div>
        </div>
        <div className={style3.check}>
        <div className={style3['testimonial-navigator']}>
          {data1?.map((t) => {
            return (
              <div
                className={t.id === activeId ? style3['active-circle'] : style3['circle']}
                onClick={() => {
                  scrollintoview(t.id)
                }}
              ></div>
            )
          })}
        </div>
      </div>
  </>
  )
}

export default MobileClientView