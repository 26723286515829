import styles from './profilesetup.module.css'
import React, { useEffect, useState } from 'react'
import { postprofil } from '../../services/account'
import { getprofile } from '../../services/startup'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { getprofile_pic } from '../../services/startup'
import ErrorIcon from '../../Assets/vector/Error'
import useAuthCtx from '../../hooks/useAuthCtx'
import { useNavigate } from 'react-router-dom'
import { validateProfilesetup } from '../../services/startup'

// import { BASE_URL } from '../../utils/constant'
const BASE_URL = 'https://venturebuddy.s3.amazonaws.com'
const Profilesetup = () => {
  const navigate = useNavigate()
  const [files, setFiles] = useState([])
  const [p_data, setdata] = useState([])
  const [postp_image, setpostp_image] = useState()
  const [p_image, setp_image] = useState([])
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [email_d, setemail] = useState('')
  const [phone_d, setphone] = useState('')
  const [first_d, setfirst] = useState('')
  const [second_d, setsecond] = useState('')
  const [address_d, setaddress] = useState('')
  const [errmsg, seterr] = useState([])
  const [loadingLoader, setLoadingLoader] = useState(true)

  const { setDp } = useAuthCtx()

  function isValidUrl(url) {
    // Regular expression for a valid URL
    var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/

    // Test the input against the regular expression
    return urlRegex.test(url)
  }

  useEffect(() => {
    getprofile((err, res) => {
      if (err) return
      setdata(res.data[0])
      setWebsiteUrl(res.data[0].website_link)
      setemail(res.data[0].email)
      setphone(res.data[0].mobile)
      setfirst(res.data[0].first_name)
      setsecond(res.data[0].last_name)
      setaddress(res.data[0].address)
      setLoadingLoader(false)
    })
    getprofile_pic((err, res) => {
      if (err) return
      const urlportion = res?.data?.profile_info[0]?.profile_pic?.split('https://testingfrontendbanao.io/media')
      const desiredPortion = urlportion[1]
      setp_image(`${BASE_URL}${desiredPortion}`)
      setLoadingLoader(false)
    })
  }, [])

  const handleWebsiteChange = (event) => {
    if (event.target.value !== websiteUrl) {
      setIsActive(true)
    }
    setWebsiteUrl(event.target.value)
  }

  const handlesave = () => {
    const formData = new FormData()
    const formDataValidation = new FormData()
    // console.log('from post data---> ' + JSON.stringify(postp_image))

    if (postp_image) {
      formData.append('profile_pic', postp_image)
      formDataValidation.append('profile_pic', postp_image?.path)
    }
    formData.append('website_link', websiteUrl)
    if (email_d) {
      formData.append('email', email_d)
      formDataValidation.append('email', email_d)
    }
    if (phone_d) {
      formData.append('mobile', phone_d)
      formDataValidation.append('mobile', phone_d)
    }
    if (second_d) {
      formData.append('last_name', second_d)
    }
    if (first_d) {
      formData.append('first_name', first_d)
    }
    formData.append('address', address_d)

    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
    const firstNameRegex = /^[A-Za-z\s]+$/
    if (websiteUrl.trim() !== '' && !isValidUrl(websiteUrl)) {
      return toast.error('Please enter a valid URL!')
    }
    if (!firstNameRegex.test(first_d)) {
      return toast.error('Please enter a valid first name!')
    }
    const lastNameRegex = /^[A-Za-z\s]+$/
    if (!lastNameRegex.test(second_d)) {
      return toast.error('Please enter a valid last name!')
    }
    if (!emailRegex.test(email_d)) {
      toast.error('Please enter a valid email address')
      return
    }
    validateProfilesetup(formDataValidation, (err, res) => {
      if (err) {
        toast.error('Something went wrong! Please try again.')
        return
      } else {
        if (!res.data?.success) {
          seterr(res.data)
          return
        } else {
          postprofil(formData, (err, res) => {
            if (err) {
              seterr(true)
              toast.error(err?.response?.data?.mobile[0])
              setIsActive(false)
              return
            } else if (res) {
              // console.log('Something went wrong! ' + res)
              setDp(postp_image)
              setIsActive(false)
              toast.success('Profile updated Successfully', {
                position: toast.POSITION.TOP_RIGHT,
              })
              navigate('/dashboard/profile/')
              return
            }
          })
        }
      }
    })
  }

  const for_img = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        console.error('Rejected files:', rejectedFiles)
        toast.error('File type not supported')
        return
      }
      // console.log('Accepted files:', acceptedFiles)
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
      setpostp_image(acceptedFiles[0])
      setIsActive(true)
    },
  })

  const [docfiles, set_docFiles] = useState([])
  const for_doc = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      // console.log(acceptedFiles)
      set_docFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const [isActive, setIsActive] = useState(false)

  return (
    <>
      {loadingLoader ? (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', background: 'rgba(221, 221, 221, 0)', display: 'block' }} width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx={50} cy={50} fill="none" stroke="#000" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
          </circle>
        </svg>
      ) : (
        <div className={`${styles.profile_container}`} >
          <h1 className={styles.header}>Profile Setup</h1>
          <div className={styles.profile_wrapper}>
            <div className={styles.first_prop}>
              <div className={styles.u_name}>
                <div className={styles.first_name}>
                  <h1>First Name</h1>
                  <input
                    type="text"
                    value={first_d}
                    onChange={(event) => {
                      setfirst(event.target.value)
                      setIsActive(event.target.value)
                    }}
                  />
                </div>
                <div className={styles.second_name}>
                  <h1>Last Name</h1>
                  <input
                    type="text"
                    value={second_d}
                    onChange={(event) => {
                      setsecond(event.target.value)
                      setIsActive(event.target.value)
                    }}
                  />
                </div>
              </div>
              <div className={styles.u_pic}>
                <h1>Upload profile picture</h1>
                <div className={styles.profile_set}>
                  <div {...for_img.getRootProps()} className={styles.profile_add}>
                    <input
                      {...for_img.getInputProps()}
                      onChange={() => {
                        seterr(false)
                      }}
                    />
                    <p>Choose your image</p>
                    <button>
                      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.144 10.688V0.511999H6.656V10.688H5.144ZM0.68 6.32V4.904H11.12V6.32H0.68Z" fill="white" />
                      </svg>
                    </button>
                  </div>
                  {files.length === 0 ? (
                    p_data?.profile_pic === '' || p_data?.profile_pic === null ? (
                      <svg className={styles.profileimg} width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9405 12.9341C33.9405 7.03342 29.1571 2.25 23.2564 2.25C17.3558 2.25 12.5723 7.03342 12.5723 12.9341C12.5723 18.8347 17.3558 23.6181 23.2564 23.6181C29.1571 23.6181 33.9405 18.8347 33.9405 12.9341ZM36.1905 12.9341C36.1905 5.79078 30.3997 0 23.2564 0C16.1131 0 10.3223 5.79078 10.3223 12.9341C10.3223 18.9748 14.4635 24.0483 20.0617 25.4706C15.0108 26.0578 10.2748 28.3307 6.64334 31.9621C2.38968 36.2158 0 41.985 0 48.0006H2.25C2.25 42.5817 4.40263 37.3848 8.23433 33.5531C12.066 29.7214 17.2629 27.5688 22.6818 27.5688C28.1006 27.5688 33.2975 29.7214 37.1292 33.5531C40.9609 37.3848 43.1135 42.5817 43.1135 48.0006H45.3635C45.3635 41.985 42.9739 36.2158 38.7202 31.9622C35.2657 28.5076 30.8116 26.2825 26.0377 25.5684C31.8443 24.2959 36.1905 19.1226 36.1905 12.9341Z" fill="#595757" />
                      </svg>
                    ) : (
                      <img src={p_image} alt="Profile" />
                    )
                  ) : (
                    <img src={files[0]?.preview} alt="Profile Preview" />
                  )}
                </div>
                {errmsg?.success === false && errmsg?.profile_pic !== null && p_data?.is_email_registration ? (
                  <p className={`input-err-msg`}>
                    <span>{errmsg?.profile_pic}</span>
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={styles.address}>
              <h1>Address</h1>
              <input
                type="text"
                value={address_d}
                onChange={(event) => {
                  if (event.target.value !== address_d) {
                    setIsActive(true)
                  }
                  setaddress(event.target.value)
                }}
              />
            </div>
            <div className={styles.mid_prop}>
              <div className={styles.mid1}>
                <h1>Mobile Number</h1>
                {p_data?.is_email_registration ? (
                  <input
                    type="number"
                    value={phone_d}
                    className={styles.phone_input}
                    onChange={(event) => {
                      setphone(event.target.value)
                      setIsActive(event.target.value)
                      seterr(false)
                    }}
                  />
                ) : (
                  <p>{`+91 - ${p_data?.mobile}`}</p>
                )}
                {errmsg?.success === false && errmsg?.mobile !== null && p_data?.is_email_registration ? (
                  <p className={`input-err-msg`}>
                    <span>{errmsg?.mobile}</span>
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className={styles.mid2}>
                <div className={styles.email}>
                  <h1>Email</h1>
                </div>
                <div className={styles.email_box_data}>
                  {p_data?.is_email_registration ? (
                    <p>{p_data?.email}</p>
                  ) : (
                    <input
                      type="email"
                      className={styles.input_email}
                      value={email_d}
                      onChange={(event) => {
                        setemail(event.target.value)
                        setIsActive(event.target.value)
                        seterr(false)
                      }}
                    />
                  )}
                  {errmsg?.success === false && errmsg?.email !== null && !p_data?.is_email_registration ? (
                    <p className={`input-err-msg`}>
                      <span>{errmsg?.email}</span>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className={styles.userid}>
              <h1>User ID</h1>
              <p>{p_data?.user_id}</p>
            </div>
            <div className={styles.u_link}>
              <h1>Link your own website</h1>
              <div className={styles.add_url}>
                <input type="text" placeholder="URL for your website" onChange={handleWebsiteChange} value={websiteUrl} />
              </div>
              <div className={styles.link_list}>
                {docfiles.map((val, ind) => (
                  <a target={'_blank'} href={val.preview} key={ind}>
                    <li>{val.name}</li>
                  </a>
                ))}
              </div>
            </div>
            <div className={styles.save}>
              <button className={isActive ? styles.active_save_btn : styles.save_btn} disabled={!isActive} onClick={handlesave}>
                Save
              </button>
              <button className={styles.cancel_btn} onClick={() => navigate(-1)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Profilesetup
