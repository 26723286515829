import React, { createContext, useEffect, useState } from "react";
import { getAllStartups, getStartupDetails } from "../services/startup";

const startupdetailContext = createContext({});

const Startupdetailcontext = ({ children }) => {
  const [allstartups, setAllstartups] = useState([]);
  const [filteredstartups, setFilteredstartups] = useState([]);

  const [loadingStartupDetail, setLoadingStartupDetail] = useState(true);
  const [currentStartupId, setCurrentStartupId] = useState(null);
  const [startupFullDetails, setStartupFullDetails] = useState({
    mainDetail: {},
    founders: [],
    industryOverview: {},
    competitors: {},
    financialSynopsis: {},
    fundsUsage: {},
    swotAnalysis: {},
  });

  useEffect(() => {
    getAllStartups(null, (err, res) => {
      if (err) return;
      setAllstartups(res.data);
    });
  }, []);

  useEffect(() => {
    if (currentStartupId === null) return;
    setLoadingStartupDetail(true);
    getStartupDetails(currentStartupId, (err, res) => {
      if (err) return console.log(err);
      setLoadingStartupDetail(false);
      // console.log(res);
      setStartupFullDetails({
        mainDetail: res[0].value?.data[0],
        founders: res[1].value?.data,
        industryOverview: res[2].value?.data[0],
        financialSynopsis: res[3].value?.data[0],
        fundsUsage: res[4].value?.data[0],
        swotAnalysis: res[5].value?.data[0],
      });
    });
  }, [currentStartupId]);

  return (
    <startupdetailContext.Provider
      value={{
        filteredstartups,
        setFilteredstartups,

        allstartups,
        setAllstartups,
        startupFullDetails,
        setStartupFullDetails,
        currentStartupId,
        setCurrentStartupId,
        loadingStartupDetail,
        setLoadingStartupDetail,
      }}
    >
      {children}
    </startupdetailContext.Provider>
  );
};

export { Startupdetailcontext, startupdetailContext };
