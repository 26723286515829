import React from "react";
import { Link } from "react-router-dom";
import styles from "./StartExplore.module.css";

const StartExplore = ({ heading = "Currently, there are no Investment done.", para = "Invest now, what are you waiting for?" }) => {
  return (
    <div id={styles.content}>
      <h1>{heading}</h1>
      <p>{para}</p>

      <Link to="../live-deals">Stay Tuned</Link>
    </div>
  );
};

export default StartExplore;
