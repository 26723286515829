import React, { useEffect, useState } from 'react'
import style from './vertualcfo.module.css'
import styles from '../pitchdeck/pitchdeck.module.css'
import virtual1 from './virtualcfo_asset/virtual_cfo_1.svg'
import virtual2 from './virtualcfo_asset/virtual_cfo_2.svg'
import virtual3 from './virtualcfo_asset/virtual_cfo_3.svg'
import Star from '../pitchdeck/pich_assest/star.svg'
import client_image from '../pitchdeck/pich_assest/client_image.svg'
import Style2 from '../../../components/Organizations/Organization.module.css'
import dhl from '../../../Assets/images/organizations/client-1-removebg-preview.png'
import next from '../../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from '../../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from '../../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from '../../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from '../../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from '../../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from '../../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from '../../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from '../../../Assets/images/organizations/client-13-removebg-preview.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'
import BlogsSlider from '../blogs/BlogsSlider'
import Faq3 from '../faqs/Faq3'
import GetTouchItAll from '../gettouchit/GetTouchItAll'
import Footer from '../../../components/Footer/Footer'
import back from '../pitchdeck/pich_assest/path_bg.svg'
import hero from './virtualcfo_asset/virtual_hero.svg'
import hero_scrope_left from './virtualcfo_asset/here_scope_left.svg'
import manu from './virtualcfo_asset/manufacturing.svg'
import consult from './virtualcfo_asset/consulting.svg'
import ecommerce from './virtualcfo_asset/ecommerce.svg'
import entertainment from './virtualcfo_asset/entertainment.svg'
import export1 from './virtualcfo_asset/exports.svg'
import retail from './virtualcfo_asset/retail.svg'
import Saas from './virtualcfo_asset/saas.png'
import RealEsate from './virtualcfo_asset/realestate.svg'
import green_bg from '../pitchdeck/pich_assest/bg_green_all.svg'
import Clientt1 from './virtualcfo_asset/business_1.svg'
import Clientt2 from './virtualcfo_asset/business_2.svg'
import Clientt3 from './virtualcfo_asset/business_3.svg'
import Clientt4 from './virtualcfo_asset/business_4.svg'
import Clientt5 from './virtualcfo_asset/business_5.svg'
import Clientt6 from './virtualcfo_asset/business_6.svg'
import MobileBlogs from '../blogs/mobileblogs/MobileBlogs'
import MobileClientView from '../MobileClient/MobileClientView'
import MobileViewClients from '../MobileViewClients/MobileViewClients'
import Testimonial from '../../../components/Testimonial/Testimonial'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const VirtualCfo1 = () => {
  const [indexed, setIndexed] = useState(0)
  const navigate = useNavigate()
  const [showMain2, setShowMain2] = useState(false)
  const virtualdata = [
    {
      title: '1. Save Time and Money',
      detail: 'Our committed Virtual CFO Team streamlines operations, conducts thorough research, leverages a vast network, and delivers cost-effective solutions, preserving valuable resources for your business.',
      Image: virtual1,
    },
    {
      title: '2. Drive Profitable Growth',
      detail: 'Whether your business is scaling up, optimizing operations, or seeking efficiency gains, our Virtual CFO services provide strategic guidance to boost profitability and achieve your growth targets.',
      Image: virtual2,
    },
    {
      title: '3.Implement Strategy and Plans',
      detail: 'Recognizing the pivotal role of strategic planning in business success, our Virtual CFOs help develop and execute robust strategies, enabling proactive anticipation of challenges and seizing opportunities for sustainable growth.',
      Image: virtual3,
    },
  ]
  const clientReview = [
    {
      id: 1,
      name: 'Rohit Ransore',
      status: 'INVESTER',
      profession: 'UX DESIGNER,ATG',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 2,
      name: 'Jeniffer White',
      status: 'INVESTER',
      profession: 'Vice President, Google',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 3,
      name: 'Niromi Taylor',
      status: 'BUSNINESS',
      profession: 'Senior Manager, IKEA',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
  ]
  const data = [
    {
      question: 'How does your process work for creating custom pitch decks?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What sets your pitch deck services apart from others?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'Is there a sample report or a free trial available?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What is the authenticity of the tool?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'How does Venturebuddy protect my data?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What information do you need from clients to start working on their pitch deck?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
  ]

  function startprinting() {
    let start = clientReview[indexed].review[0].review_star
    let starts = []

    for (let i = 1; i <= start; i++) {
      starts.push(<img src={Star} />)
    }

    return starts
  }
  const industry = [
    {
      title: 'Manufacturing',
      image: manu,
    },
    {
      title: 'Consultancy',
      image: consult,
    },
    {
      title: 'E-Commerce',
      image: ecommerce,
    },
    {
      title: 'Entertainment',
      image: entertainment,
    },
    {
      title: 'Export',
      image: export1,
    },
    {
      title: 'Retail',
      image: retail,
    },
    {
      title: 'Saas',
      image: Saas,
    },
    {
      title: 'Real Estate',
      image: RealEsate,
    },
  ]
  const handlenavigate = () => {
    navigate('/contact')
  }
  const clientlogos = [Clientt1, Clientt2, Clientt3, Clientt4, Clientt5, Clientt6]

  useEffect(() => {
    // Delay the visibility of the main2 section
    const timer = setTimeout(() => {
      setShowMain2(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])
  return (
    <>
      <section className={styles['main1']}>
        <div className={styles['pitch_hero']}>
          <img src={back} className={style['back_image']} />
          <div className={styles['detail_hero']}>
            <div>
              <div className={styles['detail']} style={{ paddingTop: '38px' }}>
                <h1 className={styles['hero_text']}>Hire Virtual CFO at Half the Cost!</h1>
                <h1 className={style['hero_text2']} style={{ paddingLeft: '15px', paddingRight: '15px', textAlign: 'center' }}>
                  Unlock Financial Expertise at Half the Cost
                </h1>
                <p style={{ lineHeight: '30px' }}>
                  Experience top-tier CFO guidance <br /> without breaking the bank –
                  elevate your financial strategy affordably.
                </p>
              </div>
              <div className={styles['pitch_image_1']} style={{ marginTop: '30px' }}>
                <img src={hero} className={styles['pitch_image']} />
              </div>
              <div className={style['download_btn']}>
                <button style={{ width: '140px' }} onClick={() => handlenavigate()}>
                  Contact Us
                </button>
              </div>
            </div>
          </div>
          <div className={styles['white_bg']} />
        </div>
      </section>
      {showMain2 && (
        <section className={style['main2']} style={{ position: 'relative',paddingTop:"0px" }}>
          <div className={style['here_scrope_heading']}>
            <h1>Our Virtual CFO Solutions</h1>
            <h2>Empower your business with expert financial guidance and strategic insights from our Virtual CFO team</h2>
          </div>

          <img src={green_bg} className={style['mian_package_image_green']} />
          <div className={style['here_scrope_wrapper']}>
            <div className={style['hero_scope_wrapper_main']}>
              <div className={style['main_here_1']} style={{ backgroundColor: 'white' }}>
                <div className={style['main_here_left']}>
                  <img src={hero_scrope_left} />
                </div>
                <div className={style['main_here_right']}>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>Streamlining Bookkeeping and Accounting Functions.</p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>
                      <div className={style['brt']}>
                        <br/>
                        </div>
                         Transaction Advisory which includes Mergers and
                      Acquisitions, Due Diligence, Debt Restructuring.
                    </p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>Strengthening Internal Controls.</p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>Budgeting and Planning</p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>Managing Treasury Function</p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>Taxation Strategy</p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>Business Valuations</p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>Raising funds for company</p>
                  </div>
                  <div className={style['green_with_text1']}>
                    <div className={style['green_dot']}></div>
                    <p>MIS Reporting.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showMain2 && (
            <section className={style['main3']}>
              <div className={style['industry_heaing']}>
                <h1>Industry We Cater To!</h1>
              </div>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={style['inudstry_wrapper_main']}>
                  <div className={style['all_industry']}>
                    {industry.map((item, index) => (
                      <>
                        <div className={style['industry_card']} key={index}>
                          <div className={style['card_title']}>
                            <p>{item.title}</p>
                            <div className={style['card_underline_wrapper']}>
                              <div className={style['card_underline']}></div>
                            </div>
                          </div>
                          <img src={item.image} />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}
        </section>
      )}
      {showMain2 && (
        <section
          className={styles['main4']}
          style={{
            backgroundImage: 'linear-gradient(to right, #FFFFFF 0%, #EBF8ED 33.33%, #EBF8ED 66.66%, #FFFFFF 100%)',
            width: '100%',
            marginTop: '-40px',
          }}
        >
          <div className={styles['wrapper_pitch']}>
            <div className={styles['pitch_deck_detail1']}>
              <div className={styles['deatil_2']} style={{ width: '100%', paddingTop: '100px' }}>
                <h1>Unlocking Value with Virtual CFO Services</h1>
                <div className={styles['small_detail']}>
                  <p>Optimize Operations, Drive Growth, and Strategize for Success</p>
                </div>
              </div>
              <br />
              <div className={styles['detail_list']}>
                {virtualdata.map((item, index) => (
                  <>
                    <div className={styles['pitch_deatil_card']} key={index}>
                      <img src={item.Image} />
                      <h2>{item.title}</h2>
                      <div className={styles['detail_03']}>
                        <h6>{item.detail}</h6>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={styles['main5']}>
          <Testimonial />
        </section>
      )}
      {showMain2 && (
        <div className={styles['client_about_detail']}>
          <h1>What Clients say About us</h1>
        </div>
      )}
      {showMain2 && (
        <div className={style['mobile_view_client']}>
          <MobileClientView />
        </div>
      )}
      <br />
      {showMain2 && (
        <section className={style['main6']} style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
          <div id="Style2.organizations" className="container-fluid" style={{ height: '705px', background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
            <>
              <div style={{ paddingTop: '250px', width: '100%' }}>
                <h4 className="text-blue text-center" id={Style2.orgHead}>
                  Our Top Clients
                </h4>
                {/* Desktop Slider Start Here */}
                <div className={style['company_wrapper']}>
                  <div className={style['company_main']}>
                    <img src={Clientt1} />
                    <img src={Clientt2} />
                    <img src={Clientt3} />
                    <img src={Clientt4} />
                    <img src={Clientt5} />
                    <img src={Clientt6} />
                  </div>
                </div>
                {/* Desktop Slider End Here */}
              </div>
            </>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main06']}>
          <MobileViewClients clientlogos={clientlogos} />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main7']}>
          <BlogsSlider />
        </section>
      )}
      {showMain2 && (
        <section className={style['main07']}>
          <MobileBlogs />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main8']} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '750px' }}>
            <Faq3 data={data} page="Virtual CFO" />
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main9']}>
          <GetTouchItAll />
        </section>
      )}
      <br />
      {showMain2 && <Footer />}
    </>
  )
}

export default VirtualCfo1
