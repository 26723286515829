import React, { createContext, useEffect, useState } from 'react'

const authContext = createContext()

const Authcontext = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [convenienceFee, setConvenienceFee] = useState(null)
  const [user, setUser] = useState({})
  const [userKycDetail, setUserKycDetail] = useState({})
  const [savedDeals, setSavedDeals] = useState([])
  const [dp, setDp] = useState()

  const [showFinishSignupModal, setShowFinishSignupModal] = useState(false)
  const [registerInputData, setRegisterInputData] = useState({
    first_name: '',
    last_name: '',
    method: 'email', // VALUE -> email/mobile
    email: '',
    mobile: '',
    password: '',
    referral_code: '',
    deals_type: null,
    start_up_type: [],
  })
  return (
    <authContext.Provider
      value={{
        user,
        setUser,
        isLoggedIn,
        setIsLoggedIn,
        userKycDetail,
        setUserKycDetail,
        convenienceFee,
        setConvenienceFee,
        savedDeals,
        setSavedDeals,
        showFinishSignupModal,
        setShowFinishSignupModal,
        registerInputData,
        setRegisterInputData,
        setDp,
        dp,
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export { Authcontext, authContext }
