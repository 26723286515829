import React from "react";
import HowItWorks1 from "./../../Assets/images/how-it-works-1.png";
import HowItWorks2 from "./../../Assets/images/how-it-works-2.png";
import HowItWorks3 from "./../../Assets/images/how-it-works-3.png";
import HowItWorks4 from "./../../Assets/images/how-it-works-4.png";
import styles from "./HowItWorks.module.css";
import HowItWorksItem from "./HowItWorksItem";
import icon1 from "../../Assets/icons/invesment 1.svg";
import icon2 from "../../Assets/icons/growth 1.svg";

const HowItWorks = () => {
  // Note This file is in use
  const items = [
    {
      id: 1,
      heading: "Become an Investor",
      paragraph:
        "Easy and safe payment methods to begin your investment journey",
      image: HowItWorks1,
      reverse: false,
    },
    {
      id: 2,
      heading: "Explore startups",
      paragraph:
        "Search and select from handpicked  list of promising startups.",
      image: HowItWorks2,
      reverse: true,
    },
    {
      id: 3,
      heading: "Get your agreements",
      paragraph: "Hassle free paper work",
      image: HowItWorks3,
      reverse: false,
    },
    {
      id: 4,
      heading: "Relax and earn 10x return",
      paragraph: "Easy and safe payment methods investment journey",
      image: HowItWorks4,
      reverse: true,
    },
  ];

  return (
    <div id={styles.howItWorks}>
      <div
        className={`container-fluid col-lg-7 col-11 mt-5 ${styles.howItWorks_wrap}`}
      >
        <div className="text-center">
          <div id={styles.HowItWorksHead}>
            <h1
              className="text-center"
              id={styles.HowItWorks}
              style={{ color: "#283891" }}
            >
              How it works?
            </h1>
            <p id={styles.getStart} style={{color:'#6D747A'}}>Get started in just 4 easy steps!</p>
          </div>

          <div className="text-start">
            {items.map((item) => (
              <HowItWorksItem
                key={item.id}
                serial={item.id}
                heading={item.heading}
                paragraph={item.paragraph}
                image={item.image}
                reverse={item.reverse}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="my-5 bg-green text-white" id={styles.counts}>
        <div className="container-fluid">
          <div className={`${styles.rowes}`}>
            {/* <div className="d-flex align-items-center flex-column justify-content-center flex-lg-row my-3">
              <h1>1.8B</h1>
              <h6 className="ms-3">Total Valuation</h6>
            </div> */}
            <div
              className="d-flex align-items-center justify-content-center flex-lg-row my-3"
              id={`${styles.mobile_style_row_item}`}
            >
             <h1 className="text-center " style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img
                  className={`${styles["how-it-works-icon"]} text-center`}
                  src={icon1}
                  alt=""
                  style={{width:'40px',height:'40px',marginRight:'15px'}}
                />{" "}
                <span style={{fontSize:'48px',marginTop:'8px'}}>500+</span>
                <h6 className="ms-3" style={{fontSize:'18px',marginTop:'15px'}}>Investors PAN India</h6>
              </h1>
              
            </div>
            <div
              className="d-flex align-items-center flex-column justify-content-center flex-lg-row my-3"
              id={`${styles.mobile_style_row_item}`}
            >
              <h1 className="text-center " style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                {" "}
                <img
                  className={`${styles["how-it-works-icon"]}`}
                  src={icon2}
                  alt=""
                  style={{width:'40px',height:'40px',marginRight:'15px'}}
                />{" "}
                <span style={{fontSize:'48px',marginTop:'8px'}}>5+</span>
                <h6 className="ms-3" style={{fontSize:'18px',marginTop:'15px'}}>Funded Startups</h6>
              </h1>
             
            </div>
            {/* <div className="d-flex align-items-center flex-column justify-content-center flex-lg-row my-3" id={`${styles.mobile_style_row_item}`}>
              <h1>60+</h1>
              <h6 className="ms-3">Valued Startups</h6>
            </div>
            <div className="d-flex align-items-center flex-column justify-content-center flex-lg-row my-3" id={`${styles.mobile_style_row_item}`}>
              <h1>15</h1>
              <h6 className="ms-3">Industries</h6>
            </div> */}
            {/* <div className="d-flex align-items-center flex-column justify-content-center flex-lg-row my-3">
              <h1>15</h1>
              <h6 className="ms-3">Industries</h6>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
