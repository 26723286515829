import React, { useEffect } from "react";
import styles from "./StartupDetailHeader.module.css";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useStartupCtx from "../../hooks/useStartupCtx";
import useAuthCtx from "../../hooks/useAuthCtx";
import { useState } from "react";
import { useRef } from "react";
import ToolTip from "../ToolTip/ToolTip";
import { formatINR } from "../../utils";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FullImage from "../FullImage/FullImage.jsx";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import BlurInfo from "../BlurInfo/BlurInfo";

const StartupDetailHeader = () => {
  // Note This file is in use
  const navigate = useNavigate();
  const location = useLocation();

  const videoPlayerRef = useRef(null);
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail } = startupFullDetails;
  const { isLoggedIn, user } = useAuthCtx();



  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const { slug } = useParams();
  const [fundingPercentage, setFundingPercentage] = useState(0);
  const [deadline, setDeadline] = useState("0 hours");
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!mainDetail) return;
    setFundingPercentage(parseInt(mainDetail?.status_percentage));
  }, [mainDetail]);

  useEffect(() => {
    const MS_TIME_DIFFERENCE = new Date(mainDetail?.deadline).getTime() - new Date().getTime();
    const HOURS_TIME_DIFFERENCE = Math.floor(MS_TIME_DIFFERENCE / 1000 / 60 / 60);
    const DAYS_TIME_DIFFERENCE = Math.floor(HOURS_TIME_DIFFERENCE / 24);

    if (HOURS_TIME_DIFFERENCE < 0 || !mainDetail?.is_live) return setDeadline("0 hours");
    if (HOURS_TIME_DIFFERENCE <= 48) return setDeadline(`${HOURS_TIME_DIFFERENCE} hours`);
    if (HOURS_TIME_DIFFERENCE > 48) return setDeadline(`${DAYS_TIME_DIFFERENCE} days`);
  }, [mainDetail]);

  const handleInvest = () => {
    if (deadline === "0 hours") return;
    if (!isLoggedIn) return navigate("/login", { state: { to: `/dashboard/deal/${slug}` } });
    if (!user.user_kyc_completed) {
      navigate("/become-investor", { state: { from: location.pathname } });
      toast("Complete KYC before investing!");
      return;
    }
    navigate("invest");
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${mainDetail?.start_up_name} | Venture Buddy`}</title>
        <meta name="description" content={mainDetail?.about_startup_short} />
      </Helmet>
      <header className={styles["header-wrapper"]}>
        <section>
          <img className={styles["startup-logo"]} src={`${mainDetail?.logo.replace("http://", "https://")}`} alt="" />
          <h1 className={styles["startup-name"]}>{mainDetail?.start_up_name}</h1>
        </section>

        <main>
          <div className={styles["content-box"]}>
            {mainDetail?.start_up_pic ? (
              <div className={styles["media"]}>
                <FullImage image={mainDetail?.start_up_pic.replace("http://", "https://")} height={"100%"} width={"100%"} />
              </div>
            ) : (
              <div className={styles["media"]}>
                <video
                  ref={videoPlayerRef}
                  onPause={() => setIsVideoPaused(true)}
                  onPlay={() => setIsVideoPaused(false)}
                  onLoadStart={() => setIsVideoLoading(true)}
                  onLoadedData={() => setIsVideoLoading(false)}
                  className={styles["startup-video-main"]}
                  // src={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"}
                  src={mainDetail?.video_pitch.replace("http://", "https://")}
                  width={"100%"}
                  onClick={(e) => {
                    if (videoPlayerRef.current.paused) videoPlayerRef.current.play();
                    else videoPlayerRef.current.pause();
                  }}
                ></video>
                {isVideoLoading ? (
                  <div
                    className={styles["play-btn-bg"]}
                    onClick={() => {
                      if (videoPlayerRef.current.readyState !== 4) videoPlayerRef.current.load();
                    }}
                  >
                    <svg
                      className={styles["play"]}
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      // style={{ margin: "auto", background: "rgba(221, 221, 221, 0)", display: "block" }}
                      width={130}
                      height={130}
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle cx={50} cy={50} fill="none" stroke="#eee" strokeWidth={8} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                      </circle>
                    </svg>
                  </div>
                ) : (
                  <div
                    style={{ opacity: isVideoPaused ? 1 : 0 }}
                    onClick={(e) => {
                      if (videoPlayerRef.current.paused) videoPlayerRef.current.play();
                      else videoPlayerRef.current.pause();
                    }}
                    className={styles["play-btn-bg"]}
                  >
                    <svg className={styles["play"]} width={170} height={170} viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M85 17C66.9653 17 49.6692 24.1643 36.9167 36.9167C24.1643 49.6692 17 66.9653 17 85C17 103.035 24.1643 120.331 36.9167 133.083C49.6692 145.836 66.9653 153 85 153C103.035 153 120.331 145.836 133.083 133.083C145.836 120.331 153 103.035 153 85C153 66.9653 145.836 49.6692 133.083 36.9167C120.331 24.1643 103.035 17 85 17ZM45.932 45.932C56.2935 35.5705 70.3467 29.7495 85 29.7495C99.6533 29.7495 113.707 35.5705 124.068 45.932C134.429 56.2935 140.25 70.3467 140.25 85C140.25 99.6533 134.429 113.707 124.068 124.068C113.707 134.429 99.6533 140.25 85 140.25C70.3467 140.25 56.2935 134.429 45.932 124.068C35.5705 113.707 29.7495 99.6533 29.7495 85C29.7495 70.3467 35.5705 56.2935 45.932 45.932ZM74.7105 109.852L108.263 89.142C111.246 87.3011 111.246 82.6989 108.263 80.858L74.7105 60.1479C71.7281 58.307 68 60.6081 68 64.2899L68 105.71C68 109.392 71.7281 111.693 74.7105 109.852Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}

            <div>
              <p className={styles["short-desc"]}>{mainDetail?.about_startup_short}</p>
              <div className={styles["tag-container"]}>
                {mainDetail?.sector?.map((sec) => (
                  <span className={styles["tag"]}>{sec}</span>
                ))}
              </div>
            </div>
          </div>
          {!isLoggedIn ? <BlurInfo>
            <div className={styles["details-box"]} id={styles["fake-data-container"]}>
              <div className={styles["details-box--top"]}>
                <section className={styles["commited-amount-graphic"]}>
                  <svg className={styles["svg-line"]} fill="none">
                    <rect style={{ width: "100%" }} height={"100%"} rx="8.5" fill="#D9D9D9" />
                    <rect style={{ width: "100%" }} height={"100%"} rx="8.5" fill="#4DBC5C" />
                    <rect style={{ width: "100%" }} height={"100%"} rx="8.5" fill="#3E4C9C" />
                  </svg>
                  <svg
                    className={styles["rocket"]}
                    style={{
                      left: `${fundingPercentage > 100 ? 100 : 100}%`,
                    }}
                    width={44}
                    height={41}
                    viewBox="0 0 44 41"
                    fill="none"
                  >
                    <path
                      d="M39.9373 17.5056L40.8906 18.9355C41.5624 19.9432 41.5624 21.256 40.8906 22.2637L39.9373 23.6936C36.228 29.2576 29.9834 32.5996 23.2963 32.5996L13 32.5996L13 8.59961L23.2963 8.59961C29.9834 8.59961 36.228 11.9416 39.9373 17.5056Z"
                      fill="white"
                      stroke="black"
                      strokeWidth={4}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20 20.5996C20 23.9133 22.6863 26.5996 26 26.5996C29.3137 26.5996 32 23.9133 32 20.5996C32 17.2859 29.3137 14.5996 26 14.5996C22.6863 14.5996 20 17.2859 20 20.5996Z"
                      fill={fundingPercentage === 0 ? "" : fundingPercentage <= 50 ? "#3E4C9C" : "#4DBC5C"}
                      stroke="black"
                      strokeWidth={4}
                    />
                    <path d="M24 8.59961L17.7826 2.59961L13 2.59961L13 38.5996L17.7826 38.5996L24 32.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 20.5996L2 20.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 12.5996L4 12.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 28.5996L4 28.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </section>

                <section className={styles["commited-amount"]}>
                  <p className={styles["value"]}>
                    <span className="rupee">₹</span>
                    10,000,000
                  </p>
                  <p className={styles["text"]}>
                    <span>Commited</span>
                    <ToolTip data={"Amount Invested by the Investors."} bottom right={width < 1000} left={width >= 1000} />
                  </p>
                </section>
                <section className={styles["total-investors"]}>
                  <p className={styles["value"]}>10</p>
                  <p className={styles["text"]}>
                    <span>Investors</span>
                    <ToolTip data={"Total investors in this deal."} bottom left />
                  </p>
                </section>
              </div>

              <div className={styles["details-box--bottom"]}>
                <div className={styles["min-amount"]}>
                  <p className={styles["value"]}>10,000,000</p>
                  <p className={styles["text"]}>
                    <span>Minimum Invest Amount</span>
                    <ToolTip data={"Minimum investment required from each individual or single investor"} bottom left />
                  </p>
                </div>
                <div className={styles["deadline"]}>
                  <p className={styles["value"]}>99</p>
                  <p className={styles["text"]}>
                    {/* <img src={alertImg} className={styles["deadline-alert"]} alt="alert" /> */}
                    <span>Left to invest</span>
                    <ToolTip data={"Total time remaining to invest in the startup."} bottom right left={"-80px"} />
                  </p>
                </div>

                {/* NOTE - If deadline is over or raised amount is greater than or equal to the funding goal or if the deal is not live then -> button will be disabled i.e. Deal is closed */}

                <button className={`${styles["invest-now-btn"]}`} disabled={deadline === "0 hours"} onClick={handleInvest}>
                  {deadline === "0 hours" || !mainDetail?.is_live ? " Deal is closed" : "INVEST NOW"}
                </button>

                <p className={styles["addtional-info"]}>
                  {/* <small>10% Discount for your First Investment</small> */}
                  <span className="rupee">₹</span>
                  0 minimum investment
                </p>
              </div>
            </div>
          </BlurInfo> : <div className={styles["details-box"]}>
            <div className={styles["details-box--top"]}>
              <section className={styles["commited-amount-graphic"]}>
                <svg className={styles["svg-line"]} fill="none">
                  <rect style={{ width: "100%" }} height={"100%"} rx="8.5" fill="#D9D9D9" />
                  <rect style={{ width: `${fundingPercentage > 100 ? 100 : fundingPercentage}%` }} height={"100%"} rx="8.5" fill="#4DBC5C" />
                  <rect style={{ width: `${fundingPercentage > 50 ? 50 : fundingPercentage}%` }} height={"100%"} rx="8.5" fill="#3E4C9C" />
                </svg>
                <svg
                  className={styles["rocket"]}
                  style={{
                    left: `${fundingPercentage > 100 ? 100 : fundingPercentage}%`,
                  }}
                  width={44}
                  height={41}
                  viewBox="0 0 44 41"
                  fill="none"
                >
                  <path
                    d="M39.9373 17.5056L40.8906 18.9355C41.5624 19.9432 41.5624 21.256 40.8906 22.2637L39.9373 23.6936C36.228 29.2576 29.9834 32.5996 23.2963 32.5996L13 32.5996L13 8.59961L23.2963 8.59961C29.9834 8.59961 36.228 11.9416 39.9373 17.5056Z"
                    fill="white"
                    stroke="black"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20 20.5996C20 23.9133 22.6863 26.5996 26 26.5996C29.3137 26.5996 32 23.9133 32 20.5996C32 17.2859 29.3137 14.5996 26 14.5996C22.6863 14.5996 20 17.2859 20 20.5996Z"
                    fill={fundingPercentage === 0 ? "" : fundingPercentage <= 50 ? "#3E4C9C" : "#4DBC5C"}
                    stroke="black"
                    strokeWidth={4}
                  />
                  <path d="M24 8.59961L17.7826 2.59961L13 2.59961L13 38.5996L17.7826 38.5996L24 32.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 20.5996L2 20.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 12.5996L4 12.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 28.5996L4 28.5996" stroke="black" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </section>

              <section className={styles["commited-amount"]}>
                <p className={styles["value"]}>
                  <span className="rupee">₹</span>
                  {formatINR(mainDetail?.amount_raised) || 0}
                </p>
                <p className={styles["text"]}>
                  <span>Commited</span>
                  <ToolTip data={"Amount Invested by the Investors."} bottom right={width < 1000} left={width >= 1000} />
                </p>
              </section>
              <section className={styles["total-investors"]}>
                <p className={styles["value"]}>{mainDetail?.total_investor || 0}</p>
                <p className={styles["text"]}>
                  <span>Investors</span>
                  <ToolTip data={"Total investors in this deal."} bottom left />
                </p>
              </section>
            </div>

            <div className={styles["details-box--bottom"]}>
              <div className={styles["min-amount"]}>
                <p className={styles["value"]}>{mainDetail?.min_investment}</p>
                <p className={styles["text"]}>
                  <span>Minimum Invest Amount</span>
                  <ToolTip data={"Minimum investment required from each individual or single investor"} bottom left />
                </p>
              </div>
              <div className={styles["deadline"]}>
                <p className={styles["value"]}>{deadline}</p>
                <p className={styles["text"]}>
                  {/* <img src={alertImg} className={styles["deadline-alert"]} alt="alert" /> */}
                  <span>Left to invest</span>
                  <ToolTip data={"Total time remaining to invest in the startup."} bottom right left={"-80px"} />
                </p>
              </div>

              {/* NOTE - If deadline is over or raised amount is greater than or equal to the funding goal or if the deal is not live then -> button will be disabled i.e. Deal is closed */}

              <button className={`${styles["invest-now-btn"]}`} disabled={deadline === "0 hours"} onClick={handleInvest}>
                {deadline === "0 hours" || !mainDetail?.is_live ? " Deal is closed" : "INVEST NOW"}
              </button>

              <p className={styles["addtional-info"]}>
                {/* <small>10% Discount for your First Investment</small> */}
                <span className="rupee">₹</span>
                {formatINR(mainDetail?.min_investment)} minimum investment
              </p>
            </div>
          </div>}

        </main>
      </header>
    </>
  );
};

export default StartupDetailHeader;
