import React, { useEffect, useState } from 'react';
import styles from '.././../../components/FAQ/FAQ.module.css';
import axios from 'axios';

const Faq4 = ({ data, header_left, full_wdith, below_border, page }) => {
    const [allFaqs, setAllFaqs] = useState([]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const fetchFaqs = async () => {
        try {
            const res = await axios.get(`${BASE_URL}getservicesfaq/`);
            setAllFaqs(res.data);
        } catch (error) {
            console.error('Error fetching FAQs:', error);
        }
    };

    useEffect(() => {
        if (!data) {
            fetchFaqs();
        }
    }, [data]);

    const faqsData = data ? data : allFaqs;

    return (
        <section style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
            <div className={styles.faq} style={{ borderRadius: '8px', width: `${full_wdith === true ? '150%' : ''}` }}>
                {header_left ? (
                    <div className={`d-flex ${styles.title}`} style={{ fontSize: '24px', paddingLeft: '35px', paddingBottom: '0px' }}>Frequently Asked Questions</div>
                ) : (
                    <div className={`d-flex ${styles.title} justify-content-center`}>Frequently Asked Questions</div>
                )}
                <br />
                <br />
                <div className={`!pt-[0px] accordion ${styles.accordion} accordion-flush`} id="accordionFlushExample" style={{ width: '100%', marginTop: '-30px' }}>
                    {faqsData.length > 0 ? (
                        faqsData
                            .filter(faq => faq.page === page) // Filter FAQs based on page
                            .map((item, index) => (
                                <div className={`accordion-item ${styles.item}`} style={{ width: '100%', paddingLeft: '30px', borderBottom: `${index + 1 === faqsData.length ? '0.3px solid #B6B9C3' : ''}` }} key={index}>
                                    <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                        <button className={`accordion-button accordion-button_mob collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index}`} aria-expanded="false" aria-controls={`flush-collapse-${index}`}>
                                            {item?.question}
                                        </button>
                                    </h2>
                                    <div id={`flush-collapse-${index}`} className={`accordion-collapse collapse ${styles.according_body_holder}`} aria-labelledby={`flush-heading-${index}`} data-bs-parent="#accordionFlushExample">
                                        <div className={`accordion-body accordion-body_mob ${styles.accordion_body}`}>
                                            <div style={{ display: 'flex' }}>
                                                <span style={{ width: '3px', backgroundColor: '#DBDCE1', marginTop: '10px', marginBottom: '10px', borderRadius: '2px' }}></span>
                                                <div style={{ fontSize: '20px', fontWeight: '400', color: '#49516A', paddingLeft: '19px' }}>{item?.answer}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                    ) : (
                        <div>Loading....</div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq4;
