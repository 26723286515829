import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Error from "../../Assets/vector/Error";
import { verifyBankDetails } from "../../services/account";
import { isEmptyField } from "../../utils";
import styles from "./BankDetails.module.css";

const BankDetails = ({ userKycDetails, setUserKycDetails, submitBtnRef, submitKycDetails }) => {


  const [inputErrMsgs, setInputErrMsgs] = useState({
    bank_account_number: null,
    ifsc_code: null,
  });
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);

  const handleBankDetailsSubmit = (e) => {
    e.preventDefault();
    if (isEmptyField(userKycDetails.bank_account_number))
      return setInputErrMsgs((prev) => {
        return { ...prev, bank_account_number: "This is required field!" };
      });
    if (isEmptyField(userKycDetails.ifsc_code))
      return setInputErrMsgs((prev) => {
        return { ...prev, ifsc_code: "This is required field!" };
      });
    const formData = new FormData();
    formData.append("bank_number", userKycDetails.bank_account_number);
    formData.append("ifsc_code", userKycDetails.ifsc_code);
    setVerifyBtnLoading(true);
    verifyBankDetails(formData, (err, res) => {
      setVerifyBtnLoading(false);
      // console.log("this is account response",res)
      if (err || res.status !== 200) return toast.error("Something went wrong!");
      if (res.data.status !== 200) return toast.error("Data is invalid!");
      if (res.data.status === 200) {
        setUserKycDetails((prev) => {
          return { ...prev, isBankVarified: true };
        });
        document.getElementById("next").click();
        document.getElementById("stepThree")?.classList.remove("step-active");
        document.getElementById("stepThree")?.classList.add("step-done");
        document.getElementById("stepFour")?.classList.add("step-active");
      }
    });
  };

  return (
    <div id={styles.BankDetailsContainer}>
      <form>
        <h1>Bank Details</h1>
        <h3 style={{fontWeight:'400'}}>Become an investor in few simple steps</h3>

        <div id={styles.BankDetails}>
          <div id={styles.accountNumber} className={`col-7 ${styles.Detail_box}`}>
            <h2 style={{fontWeight:'500',color:'#08090A'}}>Account number</h2>
            <div className={`${inputErrMsgs.bank_account_number && "form-input-err"}`}>
              <input
                type="text"
                className="form-control"
                name="account_number"
                placeholder="Account number"
                value={userKycDetails.bank_account_number}
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, bank_account_number: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      bank_account_number: e.target.value,
                    };
                  });
                }}
              />
              {inputErrMsgs.bank_account_number && (
                <p className={`input-err-msg`}>
                  <Error fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.bank_account_number}</span>
                </p>
              )}
            </div>
          </div>
          <div id={styles.ifscCode} className={`col-7 ${styles.Detail_box}`}>
            <h2 style={{fontWeight:'500',color:'#08090A'}}>IFSC code</h2>
            <div className={`${inputErrMsgs.ifsc_code && "form-input-err"}`}>
              <input
                type="text"
                className="form-control"
                placeholder="IFSC code"
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, ifsc_code: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      ifsc_code: e.target.value,
                    };
                  });
                }}
                value={userKycDetails.ifsc_code}
              />
              {inputErrMsgs.ifsc_code && (
                <p className={`input-err-msg`}>
                  <Error fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.ifsc_code}</span>
                </p>
              )}
            </div>
          </div>
        </div>

        <button
          type="submit"
          onClick={handleBankDetailsSubmit}
          ref={submitBtnRef}
          disabled={!userKycDetails.bank_account_number || !userKycDetails.ifsc_code || verifyBtnLoading}
          className={`btn-blue px-5 ${styles["verify-btn"]}`}
          style={{ height: "55px", width: "150px", padding: "0 auto" }}
        >
          {verifyBtnLoading ? "Processing..." : "SUBMIT"}
        </button>
      </form>
    </div>
  );
};

export default BankDetails;
