import { React, useContext, useEffect, useState } from 'react'
import styles from './Alert.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import { authContext } from '../../Contexts/Authcontext'
import useAuthCtx from '../../hooks/useAuthCtx'
import Red_Alert from '../../Assets/icons/red_alert.svg'
import bell from '../../Assets/bell_2.svg'
import message from '../../Assets/message_2.svg'

// WORKING: File is in use.
const Alert = () => {
  const { user } = useAuthCtx()
  const navigate = useNavigate()
  const [hidden, setHidden] = useState(false)

  const { slug } = useParams()

  //   useEffect(() => {
  //     if (!user) return;
  //     if (user.user_kyc_completed) setHidden(true);
  //     else setHidden(false);
  //   }, [user]);

  return (
    <div className={`d-flex justify-content-between align-items-center ${styles.alertCont} `} style={{marginLeft:'30px'}}>
      {!slug && !user?.user_kyc_completed && !hidden && (
        <div id="alert" class={`d-flex align-items-center justify-content-between  flex-fill ${styles.alert}`} role="alert" aria-live="assertive" aria-atomic="true" style={{borderRadius:'8px'}}>
          <div class={`body px-3 gold ${styles['kyc-msg-text']}`} style={{display:'flex',alignItems:'center'}}>
            <img src={Red_Alert} style={{width:'20px',height:'20px'}}/>
            <p style={{paddingLeft:'10px',paddingTop:'17px'}}>Complete your KYC process</p>
          </div>
          <div className={`complete-kyc-right ${styles['kyc-right-container']}`}>
            <button className={styles.btnGold} onClick={() => navigate('/become-investor')}>
              Complete KYC
            </button>
            <button type="button" onClick={() => setHidden(true)} className="btn fs-3 me-2 ms-2 gold my-0" id={styles.close}>
              &times;
            </button>
          </div>
        </div>
      )}

      <div className="me-0 ms-auto d-flex align-items-center justify-content-between gap-3" id={styles.iconContainer}>
        <button
          // id="btn"
          className="btn-blue mb-2 rounded-pill"
          onClick={() => {
            navigate('/add-fundraise')
          }}
          
          style={{width:'143px',fontSize:'14px',padding:'0px',height:'48px'}}
        >
          Raise Capital
        </button>

        <div className='d-flex gap-4'>
        <img src={bell} className="ml-4" alt="" style={{cursor:'pointer'}}/>
        {/* <img src={message} className="" alt="" style={{cursor:'pointer'}}/> */}
        </div>
      </div>
    </div>
  )
}

export default Alert
