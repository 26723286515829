import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorIcon from "../../Assets/vector/Error";
import { validatePanDetail } from "../../services/account";
import { validateEmail, validatePanNumber, validatePhone, isEmptyField } from "../../utils";
import styles from "./KYC.module.css";

// WORKING: File is in use.mam
const KYC = ({ userKycDetails, setUserKycDetails }) => {
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [inputErrMsgs, setInputErrMsgs] = useState({
    first_name: null,
    last_name: null,
    father_name: null,
    email: null,
    phone: null,
    dob: null,
    pan_card_number: null,
  });

  const submitNames = (e) => {
    e.preventDefault();

    const DateOfBirth = new Date(userKycDetails.dob);
    const currentDate = new Date();
    if (isEmptyField(userKycDetails.first_name))
      return setInputErrMsgs((prev) => {
        return { ...prev, first_name: "This is required field!" };
      });
    if (isEmptyField(userKycDetails.last_name))
      return setInputErrMsgs((prev) => {
        return { ...prev, last_name: "This is required field!" };
      });
    if (!validatePanNumber(userKycDetails.pan_card_number))
      return setInputErrMsgs((prev) => {
        return { ...prev, pan_card_number: "Please enter a valid PAN Number!" };
      });
    if (currentDate.getFullYear() - DateOfBirth.getFullYear() < 18) {
      return setInputErrMsgs((prev) => {
        return { ...prev, dob: "User has to be atleast 18 years old!" };
      });
    }
    if (isEmptyField(userKycDetails.father_name))
      return setInputErrMsgs((prev) => {
        return { ...prev, father_name: "This is required field!" };
      });
    if (isEmptyField(userKycDetails.phone))
      return setInputErrMsgs((prev) => {
        return { ...prev, phone: "This is required field!" };
      });
    if (!validatePhone(userKycDetails.phone))
      return setInputErrMsgs((prev) => {
        return { ...prev, phone: "Please enter a valid phone number!" };
      });
    if (!validateEmail(userKycDetails.email))
      return setInputErrMsgs((prev) => {
        return { ...prev, email: "Please enter a valid email!" };
      });

    if (isEmptyField(userKycDetails.middle_name)) {
      setUserKycDetails((prev) => {
        return {
          ...prev,
          middle_name: "",
        };
      });
    }
    const formData = new FormData();
    formData.append("pan_number", userKycDetails.pan_card_number);
    setSubmitBtnLoading(true);
    validatePanDetail(formData, (err, res) => {
      setSubmitBtnLoading(false);
      setUserKycDetails((prev) => {
        return { ...prev, isPanVarified: false };
      });
      if (err || res.status !== 200) return toast.error("Something went wrong!");
      if (res?.data?.status === 400 && res?.data?.message === "user with this PAN number Already Exist") {
        toast.error("This PAN Number is already registered!");
        return setInputErrMsgs((prev) => {
          return { ...prev, pan_card_number: "This PAN Number is already registered!" };
        });
      }
      // console.log("this is response pan card",res)
      if (res?.data?.status === 400) {
        toast.error("PAN Number is invalid!");
        return setInputErrMsgs((prev) => {
          return { ...prev, pan_card_number: "PAN Number is invalid!" };
        });
      }
      if (res?.data?.status === 200) {
        document.getElementById("next").click();
        setSubmitBtnLoading(false);
        setUserKycDetails((prev) => {
          return { ...prev, isPanVarified: true };
        });
      }
    });
  };

  return (
    <div id={styles.becomeAnInvestor}>
      <h1>Become an Investor in few simple steps</h1>
      <h3 style={{fontWeight:'400',color:'#000000'}}>Become an Investor in few simple steps</h3>

      <form action="" className="mt-4" onSubmit={submitNames}>
        <div className={`mb-4 row ${styles.margin_wid}`}>
          <div className={`col-4 ${styles.input_box}`}>
            <div className="d-flex">
              <h2>First Name</h2>
              <h4>(As Per PAN)</h4>
            </div>

            <div className={`${inputErrMsgs.first_name && "form-input-err"}`}>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, first_name: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      first_name: e.target.value,
                    };
                  });
                }}
                id="first-name"
                value={userKycDetails.first_name}
              />
              {inputErrMsgs.first_name && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.first_name}</span>
                </p>
              )}
            </div>
          </div>
          <div className={`col-4 ${styles.input_box}`}>
            <div className="d-flex">
              <h2>Middle Name</h2>
              <h4>(As Per PAN)</h4>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Middle Name"
              value={userKycDetails.middle_name}
              onChange={(e) =>
                setUserKycDetails((prev) => {
                  return {
                    ...prev,
                    middle_name: e.target.value,
                  };
                })
              }
            />
          </div>
          <div className={` col-4 ${styles.input_box}`}>
            <div className="d-flex">
              <h2>Last Name</h2>
              <h4>(As Per PAN)</h4>
            </div>
            <div className={`${inputErrMsgs.last_name && "form-input-err"}`}>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, last_name: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      last_name: e.target.value,
                    };
                  });
                }}
                id="last-name"
                value={userKycDetails.last_name}
              />
              {inputErrMsgs.last_name && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.last_name}</span>
                </p>
              )}
            </div>
          </div>
        </div>

        <div className={`mb-4 row ${styles.margin_wid}`}>
          <div className={`col-8 ${styles.input_box}`}>
            <h2>PAN Card Number</h2>
            <div className={`${inputErrMsgs.pan_card_number && "form-input-err"}`}>
              <input
                type="text"
                className="form-control"
                placeholder="PAN Card Number"
                name="panNumber"
                value={userKycDetails.pan_card_number}
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, pan_card_number: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      pan_card_number: e.target.value?.toUpperCase(),
                    };
                  });
                }}
              />
              {inputErrMsgs.pan_card_number && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.pan_card_number}</span>
                </p>
              )}
            </div>
          </div>
          <div className={`col-4 ${styles.input_box}`}>
            <h2>Date of birth</h2>
            <div className={`${inputErrMsgs.dob && "form-input-err"}`}>
              <input
                type="date"
                className="form-control"
                name="date"
                value={userKycDetails.dob}
                placeholder="Date of birth"
                // value={userKycDetails.dob}
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, dob: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      dob: e.target.value,
                    };
                  });
                }}
              />
              {inputErrMsgs.dob && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.dob}</span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={`mb-4 row ${styles.margin_wid}`}>
          <div className={`col-4 ${styles.input_box}`}>
            <div className="d-flex align-items-baseline">
              <h2>Father Name</h2>
              <h4 className="ps-1">(As Per PAN)</h4>
            </div>
            <div className={`${inputErrMsgs.father_name && "form-input-err"}`}>
              <input
                type="text"
                className="form-control"
                placeholder="Father Name"
                value={userKycDetails.father_name}
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, father_name: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      father_name: e.target.value,
                    };
                  });
                }}
              />
              {inputErrMsgs.father_name && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.father_name}</span>
                </p>
              )}
            </div>
          </div>
          <div className={`col-4 ${styles.input_box}`}>
            <div className="d-flex align-items-baseline">
              <h2>Phone number</h2>
            </div>
            <div className={`${inputErrMsgs.phone && "form-input-err"}`}>
              <input
                type="phone"
                maxLength={10}
                className="form-control"
                placeholder="Phone number"
                value={userKycDetails.phone}
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, phone: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      phone: e.target.value,
                    };
                  });
                }}
              />
              {inputErrMsgs.phone && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.phone}</span>
                </p>
              )}
            </div>
          </div>
          <div className={`col-4 ${styles.input_box}`}>
            <h2>Email</h2>
            <div className={`${inputErrMsgs.email && "form-input-err"}`}>
              <input
                type="email"
                className="form-control"
                placeholder="Your email address"
                name="email"
                value={userKycDetails.email}
                onChange={(e) => {
                  setInputErrMsgs((prev) => {
                    return { ...prev, email: null };
                  });
                  setUserKycDetails((prev) => {
                    return {
                      ...prev,
                      email: e.target.value,
                    };
                  });
                }}
              />
              {inputErrMsgs.email && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{inputErrMsgs.email}</span>
                </p>
              )}
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={
            submitBtnLoading ||
            !userKycDetails.first_name ||
            !userKycDetails.last_name ||
            !userKycDetails.pan_card_number ||
            !userKycDetails.dob ||
            !userKycDetails.phone ||
            !userKycDetails.father_name ||
            !userKycDetails.email
          }
          className="btn-blue px-5"
          style={{ height: "55px", width: "150px", padding: "0 auto",marginTop:'50px' }}
          id={styles.nameSubmit}
        >
          {submitBtnLoading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                margin: "auto",
                background: "rgba(221, 221, 221, 0)",
                display: "block",
            
              
              }}
              width="40px"
              height="40px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle cx={50} cy={50} fill="none" stroke="#fff" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
              </circle>
            </svg>
          ) : (
            "Next"
          )}
        </button>
      </form>
    </div>
  );
};

export default KYC;
