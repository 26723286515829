import React, { useEffect, useState } from "react";
import styles from "./OTP.module.css";
import useAuthCtx from "../../hooks/useAuthCtx";
import ErrorIcon from "../../Assets/vector/Error";
import { generateOtpRegister, verifyOtpRegister } from "../../services/account";
import { toast } from "react-toastify";

const OTP = ({ handleLogin, loginData, setLoginData, currentLoginProgress, setCurrentLoginProgress }) => {
  // Note This file is in use
  const { setToken } = useAuthCtx();
  const { REACT_APP_BASE_URL } = process.env;
  const url = REACT_APP_BASE_URL;
  const [digitOne, setDigitOne] = useState("");
  const [digitTwo, setDigitTwo] = useState("");
  const [digitThree, setDigitThree] = useState("");
  const [digitFour, setDigitFour] = useState("");

  const [error, setError] = useState(false);

  const [otpErrorMsg, setOtpErrorMsg] = useState(null);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [resendBtnLoading, setResendBtnLoading] = useState(false);

  const [timer, setTimer] = useState(30);

  const resendOtp = () => {
    setResendBtnLoading(true);
    generateOtpRegister(loginData.username, (err, res) => {
      setResendBtnLoading(false);
      if (err) return toast.error("Something went wrong!");
      setTimer(60);
      toast.success("OTP Resent!");
    });
  };

  const checkOTP = (e) => {
    e.preventDefault();
    const otp = String(digitOne) + String(digitTwo) + String(digitThree) + String(digitFour);
    if (otp.length < 4) return setOtpErrorMsg("Please enter a valid PIN!");

    setSubmitBtnLoading(true);
    const formData = new FormData();
    formData.append("otp", otp);
    verifyOtpRegister(loginData.username, formData, (err, res) => {
      setSubmitBtnLoading(false);
      if (err) return setOtpErrorMsg("Please enter a valid PIN!");
      if (res.status === 200) {
        handleLogin();
      }
    });
  };

  const changeEmail = () => {
    setCurrentLoginProgress(0);
    setLoginData({
      username: "",
      type: "password", // VALUE -> password/otp
      password: "",
      otp: "",
    });
    setTimer(30);
  };

  useEffect(() => {
    if (loginData.type !== "otp") return;
    document.getElementById("digit1").focus();
  }, [loginData]);

  const handleDigitOne = (e) => {
    if (e !== null) {
      document.getElementById("digit1").style.border = "1px solid #283891";
      document.getElementById("digit1").style.color = "#283891";
      document.getElementById("digit2").focus();
    } else {
      document.getElementById("digit1").style.border = "1px solid #d9d9d9";
    }
  };
  const handleDigitTwo = (e) => {
    if (e !== null) {
      document.getElementById("digit2").style.border = "1px solid #283891";
      document.getElementById("digit2").style.color = "#283891";

      document.getElementById("digit3").focus();
    } else {
      document.getElementById("digit1").focus();
      document.getElementById("digit2").style.border = "1px solid #d9d9d9";
    }
  };
  const handleDigitThree = (e) => {
    if (e !== null) {
      document.getElementById("digit3").style.border = "1px solid #283891";
      document.getElementById("digit3").style.color = "#283891";

      document.getElementById("digit4").focus();
    } else {
      document.getElementById("digit2").focus();
      document.getElementById("digit3").style.border = "1px solid #d9d9d9";
    }
  };
  const handleDigitFour = (e) => {
    if (e === null) {
      document.getElementById("digit3").focus();
      document.getElementById("digit4").style.border = "1px solid #d9d9d9";
    } else {
      document.getElementById("digit4").style.border = "1px solid #283891";
      document.getElementById("digit4").style.color = "#283891";
    }
  };

  useEffect(() => {
    if (currentLoginProgress !== 1 || loginData.type !== "otp") return;
    if (timer <= 0) return setTimer(0);
    setTimeout(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  }, [timer, currentLoginProgress, loginData]);

  return (
    <div id={styles.loginRight} className="rounded-4 rounded-md-0 text-dark">
      <div className="mx-0 px-0 h-100">
        <p
          className={`text-center mt-5 pt-3 ${styles.tittle}`}
          style={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Please enter the OTP sent to <br />{" "}
          <p className={`${styles.usename_p}`}>
            {" "}
            {loginData.username}.
            <span className="text-blue px-1 cursor-pointer" id={styles.changeText} onClick={changeEmail}>
              Change
            </span>
          </p>
        </p>
        <h6 className={`mt-4 ps-1 ${styles.usename_otp}`} style={{ marginTop: "32px" }}>
          Enter OTP
        </h6>
        <form style={{ marginTop: 10 }} onSubmit={checkOTP}>
          <div className={`row row-cols-4 justify-content-between px-3 ${styles.otp_box}  ${otpErrorMsg && "form-input-err"}`}>
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitOne(e.target.value);
                handleDigitOne(e.nativeEvent.data);
              }}
              id="digit1"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitTwo(e.target.value);
                handleDigitTwo(e.nativeEvent.data);
              }}
              id="digit2"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitThree(e.target.value);
                handleDigitThree(e.nativeEvent.data);
              }}
              id="digit3"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitFour(e.target.value);
                handleDigitFour(e.nativeEvent.data);
              }}
              id="digit4"
              className={styles.otp}
            />
          </div>
          {otpErrorMsg && (
            <p className={`input-err-msg`}>
              <ErrorIcon fill="red" width="15px" height="15px" />
              <span>{otpErrorMsg}</span>
            </p>
          )}
          <input type="submit" className={`btn-blue w-100 ${error ? "mt-0" : ""}`} onClick={checkOTP} disabled={submitBtnLoading} value={submitBtnLoading ? "VERIFYING..." : "VERIFY"} />
        </form>
        <p
          className={`text-center w-100 ms-1 ${styles.not_r}`}
          style={{
            fontWeight: "500",
          }}
        >
          Not received your code?{" "}
          {timer === 0 ? (
            <button
              disabled={resendBtnLoading}
              style={{
                border: "none",
                fontWeight: "500",
                background: "none",
                fontSize: 15,
                opacity: resendBtnLoading ? 0.4 : 1,
                pointerEvents: resendBtnLoading ? "none" : "all",
              }}
              className="text-green py-1 px-0 mt-0 cursor-pointer"
              onClick={resendOtp}
            >
              {resendBtnLoading ? "Sending.." : "Resend code"}
            </button>
          ) : (
            <span id={styles.resend}>Resend code in {timer} sec</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default OTP;
