import React from 'react'
import style from "./AboutTestimonial.module.css"
const AboutTestimonial = ({ des, name, position, img }) => {
    // This File is in use
    return (
        <div className={style["main-testimonial-card"]}>
            <div className={style["testimonial-para"]}>
                <p>{des}</p>
            </div>
            <div className={style["name-and-info"]}>
                <div className={style["user-image"]}>
                    <img src={img} alt="" />
                </div>
                <p className={style["name"]}>{name}</p>
                <p className={style["position"]}>{position}</p>
            </div>
        </div>
    )
}

export default AboutTestimonial