import React, { useEffect, useState } from 'react'
import styles from './PaymentSuccessFul.module.css'
import logo from './../../Assets/images/logo.png'
import rupee from './../../Assets/icons/rupee-big.png'
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { jsPDF } from 'jspdf'
import { renderToString } from 'react-dom/server'
import vblogo1 from '../../Assets/venturebuddyLogo1.png'
import { getInvoiceDetails, verifyPayment } from '../../services/startup'
import { toast } from 'react-toastify'
import { downloadReceipt } from '../../utils/receiptDownload'
import { formatINR } from '../../utils'

const PaymentSuccessFul = () => {
  const navigate = useNavigate()

  const [isVarifying, setIsVarifying] = useState(true)
  const [isPaymentVerified, setIsPaymentVerified] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [invoiceDetail, setInvoiceDetail] = useState(null)

  const [searchParams, setSearchParams] = useSearchParams()
  const order_id = searchParams.get('order_id')

  useEffect(() => {
    if (!order_id) return navigate('/dashborard', { replace: true })
    verifyPayment(order_id, (err, res) => {
      setIsVarifying(false)
      if (err) return
      if (res.data.message === 'Temp_InvestNow matching query does not exist.') return navigate('/dashboard', { replace: true }), toast.error('Something went wrong!')
      if (res.data.message === 'ACTIVE') return setIsPaymentVerified(false), setPaymentDetails(res.data.transaction_details)
      if (res.data.message === 'Verified') return setIsPaymentVerified(true), setPaymentDetails(res.data.transaction_details)
      if (res.data.message === 'Already Verified') return setIsPaymentVerified(true), setPaymentDetails(null)
    })
  }, [])

  useEffect(() => {
    if (isVarifying || !isPaymentVerified) return
    getInvoiceDetails(order_id, (err, res) => {
      if (err) return
      setInvoiceDetail(res.data)
    })
  }, [isPaymentVerified])

  const handleDownload = async () => {
    if (!invoiceDetail) return toast.error('Something went wrong!')
    const receiptData = {
      issueDate: invoiceDetail?.static?.date || '',
      company: invoiceDetail?.services?.startup_name || '',
      user: invoiceDetail?.static?.username || '',
      transactionId: invoiceDetail?.static?.Receipt_id || '',
      orderId: order_id || '',
      lotPrice: formatINR(invoiceDetail?.services?.cost_for_each) || '',
      qty: invoiceDetail?.services?.quantity || '',
      totalAmount: formatINR(invoiceDetail?.services?.total) || '',
      receiptTotal: formatINR(invoiceDetail?.static?.invoice_total) || '',
      convenienceFee: formatINR(invoiceDetail?.static?.C_fee) || '',
      finalTotalAmount: formatINR(invoiceDetail?.static?.total_amount) || '',
    }
    downloadReceipt(receiptData)
  }

  const onPrint = () => {
    let pdfConverter = jsPDF,
      doc = new pdfConverter()
    const stringHtml = `<div>hello</div>`
    doc.setFontSize(22)
    doc.text('Title', 15, 15)
    doc.fromHTML(stringHtml, 15, 20, { width: 180 })
    doc.save('a4.pdf')
    window.open(doc.output('bloburl'), '_blank')
  }

  // let successPay = {
  //   status: 200,
  //   message: "the payment status is PAID",
  //   details: {
  //     user_id: 3,
  //     invest_on_id: 3,
  //     lot_quantity: 10000,
  //     investment_amount: 1000000,
  //     date_buyed: "2022-12-13T14:17:36.253890Z",
  //   },
  // };
  // let successPayVerified = {
  //   message: "Already Verified!!",
  // };
  // let noOrder = {
  //   status: 400,
  //   message: "Transaction matching query does not exist.",
  // };
  // let failedPay = {
  //   status: 400,
  //   message: "ACTIVE",
  //   details: {
  //     user_id: 3,
  //     invest_on_id: 1,
  //     lot_quantity: 0,
  //     investment_amount: 25,
  //   },
  // };

  return (
    <>
      <div className="" id={styles.iconContainer}>
        <NavLink to="/">
          <img src={logo} className={`img-fluid col-10 col-lg-8 ${styles['logo']} `} alt="" />
        </NavLink>
        <div className="d-none me-0 ms-auto d-sm-flex align-items-center gap-3">
          {/* <button id="btn" className="btn btn-blue rounded-pill align-items-center px-4 mt-0 mb-0">
            Raise Capital
          </button>
          <img src={bell} className="" alt="" />
          <img src={mail} className="" alt="" /> */}
        </div>
      </div>
      <div id={styles.isPaymentVerifiedContainer} className="col-5 mx-auto">
        <div className={`col-11 text-center ${styles.payment_verify}`}>
          {isVarifying ? (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="90px" height="90px" strokeLinecap="round" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx={50} cy={50} fill="none" stroke="#444" strokeWidth={8} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
              </circle>
            </svg>
          ) : (
            <section className={styles['wrapper']}>
              {isPaymentVerified ? (
                <>
                  <svg version="1.1" fill="#283891" width="120px" height="120px" id="Capa_1" x="0px" y="0px" viewBox="0 0 330 330" style={{ enableBackground: 'new 0 0 330 330' }} xmlSpace="preserve">
                    <g>
                      <path
                        d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300
                c-74.44,0-135-60.561-135-135S90.56,30,165,30s135,60.561,135,135S239.439,300,165,300z"
                      />
                      <path
                        d="M226.872,106.664l-84.854,84.853l-38.89-38.891c-5.857-5.857-15.355-5.858-21.213-0.001
                c-5.858,5.858-5.858,15.355,0,21.213l49.496,49.498c2.813,2.813,6.628,4.394,10.606,4.394c0.001,0,0,0,0.001,0
                c3.978,0,7.793-1.581,10.606-4.393l95.461-95.459c5.858-5.858,5.858-15.355,0-21.213
                C242.227,100.807,232.73,100.806,226.872,106.664z"
                      />
                    </g>
                  </svg>
                  <h1>Payment Successful</h1>
                  <h5>Transaction ID : {order_id}</h5>
                  <button className={`${styles['download-btn']}`} onClick={handleDownload}>
                    Download Receipt
                  </button>

                  <Link to="/dashboard" replace id={styles.backHome}>
                    Back to home
                  </Link>
                </>
              ) : (
                <>
                  <svg width="120px" height="120px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <g fillRule="evenodd">
                      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" fill="#ff3828" />
                      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="white" />
                      <path fill="#ff3828" d="M7 5.969L5.599 4.568a.29.29 0 0 0-.413.004l-.614.614a.294.294 0 0 0-.004.413L5.968 7l-1.4 1.401a.29.29 0 0 0 .004.413l.614.614c.113.114.3.117.413.004L7 8.032l1.401 1.4a.29.29 0 0 0 .413-.004l.614-.614a.294.294 0 0 0 .004-.413L8.032 7l1.4-1.401a.29.29 0 0 0-.004-.413l-.614-.614a.294.294 0 0 0-.413-.004L7 5.968z" />
                    </g>
                  </svg>
                  <h1>Payment Failed</h1>
                  <h5>Transaction ID : {order_id}</h5>
                  {paymentDetails ? (
                    <button onClick={() => navigate(`/dashboard/deal/${paymentDetails?.slug}`)} className={`${styles['try-again-btn']}`}>
                      Try Again
                    </button>
                  ) : (
                    <Link to="/dashboard" replace id={styles.backHome}>
                      Back to home
                    </Link>
                  )}
                </>
              )}
            </section>
          )}
        </div>
      </div>
      {/*</> : <h1>not done</h1>}*/}
    </>
  )
}

export default PaymentSuccessFul
