import React, { useState } from 'react'
import style from './NewPost.module.css'
import style1 from '../Comments.module.css'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import { NewPost1 } from '../../../services/Communitys'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const NewPost = () => {
    const [text,setText]=useState("")
    const [text1,setText1]=useState("")

    const token = localStorage.getItem("access_token")
    const navigate = useNavigate()

    const handlePost = async()=>{
    

      if(text===""||text1===""){
        alert("please fill all detail")
      }
      
      if(!token){
            navigate("/login")
      } 

      const data = {
        title:text1,
        thought:text
      }
      try{
         const response = await NewPost1(data) 
         toast.success("post successfully")
         navigate("/community")
         setText("")
         setText1("")
      }catch(error){
        console.log(error)
      }
    }

  return (
    <>
    <section className={`${style.section1}`}>
      <div>
        <div className={`${style.header_part}`}>
        <h1>Vibrant Community</h1>
        <p>Organize Your Thoughts for the Community!</p>
        </div>

        <div className={`${style.title}`}>
        <input type='text' placeholder='title' value={text1} onChange={(e)=>setText1(e.target.value)}/>

        </div>

        <div className={`${style.main_comment}`}>
            <div className={`${style.comment}`}>
                <textarea placeholder='Thought' value={text} style={{width:'100%',height:'150px'}} onChange={(e)=>setText(e.target.value)}></textarea>
            </div>
            <div className={`${style.btn_pots}`}>
            <button className={`${style.post_btn} ${(text==="" || text1==="")?`${style.post_btn}`:`${style.post_btn_active}`}`} onClick={handlePost}>Post</button>
            </div>
        </div>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default NewPost