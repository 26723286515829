import React, { useEffect, useState } from 'react'
import styles from './FAQ.module.css'
import { getFaq } from '../../services/faqApi'

const FAQ = () => {
  const [invester, setInvester] = useState([])
  const [founder, setFounder] = useState([])
  const [active, setActive] = useState('invester')
  const [contextValues, setContextValues] = useState([])

  const fetchFaqData = async () => {
    const data = await getFaq()
    setInvester(data.filter((elem) => elem.role === 'Investor'))
    setFounder(data.filter((elem) => elem.role === 'Founder'))
    const uniqueContextValues = new Set(data.map((item) => item.context_value))
    setContextValues(Array.from(uniqueContextValues))
  }

  useEffect(() => {
    fetchFaqData()
  }, [])

  return (
    <div className={styles.faq} style={{ border: '1px solid #E5E7EB', borderRadius: '8px', }}>
      <div className={`d-flex ${styles.title} justify-content-center`} style={{marginBottom:"20px"}}>Frequently Asked Questions</div>
      <div className="d-flex justify-content-start" style={{ marginBottom: '24px', borderBottom: '1px solid #E5E7EB', marginLeft: '35px', marginRight: '35px', justifyContent: 'start' }}>
        <div className={styles.investerText}>
          <p
            onClick={() => {
              setActive('invester')
            }}
            style={{ color: active === 'invester' ? '#111827' : ' #6B7280' }}
          >
            Investor
          </p>
        </div>
        <div className={styles.founderText}>
          <p
            onClick={() => {
              setActive('founder')
            }}
            style={{ color: active === 'founder' ? '#111827' : ' #6B7280' }}
          >
            Founder
          </p>
        </div>
      </div>

      {active === 'invester' ? (
        <div className={`!pt-[0px] accordion ${styles.accordion} accordion-flush`} id="accordionFlushExample" style={{ width: '100%' }}>
          {contextValues?.reverse()?.map((item, index) => (
            <div className={`accordion-item ${styles.item}`} style={{ width: '100%', paddingLeft: '30px' }} key={index}>
              <h2 className="accordion-header" id={`flush-heading${index}`} style={{ width: '100%', textAlign: 'left' }}>
                <button className={`accordion-button accordion-button_mob collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`} style={{ color: '#000000', fontWeight: '600' }}>
                  {item}
                </button>
              </h2>
              <div id={`flush-collapse${index}`} className={`accordion-collapse collapse ${styles.according_body_holder}`} aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
                <div className={`accordion-body accordion-body_mob ${styles.accordion_body}`} style={{ color: '#343434', fontWeight: '400', backgroundColor: '#F9FAFB' }}>
                  {invester
                    ?.filter((elem) => elem?.context_value === item)
                    .map((faqItem, idx) => (
                      <div key={idx}>
                        <div style={{ fontSize: '20px', fontWeight: '400', color: '#343434', marginTop: '15px' }}>{faqItem.question}</div>
                        <div style={{ fontSize: '16px', fontWeight: '400', color: '#6B7280', paddingTop: '8px' }}>{faqItem.answer}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={`!pt-[0px] accordion ${styles.accordion} accordion-flush`} id="accordionFlushExample">
          {contextValues?.map((item, index) => (
            <div className={`accordion-item ${styles.item}`} style={{ paddingLeft: '30px' }} key={index}>
              <h2 className="accordion-header" id={`flush-heading${index}`}>
                <button className={`accordion-button accordion-button_mob collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`} style={{ color: '#000000', fontWeight: '600' }}>
                  {item}
                </button>
              </h2>
              <div id={`flush-collapse${index}`} className={`accordion-collapse collapse ${styles.according_body_holder}`} aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
                <div className={`accordion-body accordion-body_mob ${styles.accordion_body}`}>
                  {founder
                    ?.filter((elem) => elem?.context_value === item)
                    .map((faqItem, idx) => (
                      <div key={idx}>
                        <div style={{ fontSize: '20px', fontWeight: '400', color: '#343434', marginTop: '15px' }}>{faqItem.question}</div>
                        <div style={{ fontSize: '16px', fontWeight: '400', color: '#6B7280', paddingTop: '8px' }}>{faqItem.answer}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FAQ
