import React, { useEffect } from "react";
import styles from "./Deals.module.css";
import { Link, useLocation } from "react-router-dom";
import useAuthCtx from "../../hooks/useAuthCtx";
import { useState } from "react";
import { getUserData, updateDealBookmark } from "../../services/account";
import { formatINR } from "../../utils";

// WORKING: File is in use.
const DealCard = ({ deal, isClosed }) => {
  const location = useLocation();
  const { isLoggedIn, user, setUser } = useAuthCtx();

  const [isBookmarked, setIsBookmarked] = useState(false);
// console.log(deal)
  useEffect(() => {
    const dealId = user.saved_deals?.find((item) => item.live_deals_bookmark__id === deal.id);
    if (dealId) setIsBookmarked(true);
    else setIsBookmarked(false);
  }, [user]);

  const handleBookmark = (e) => {
    e.preventDefault();
    const data = {
      id: deal.id,
      task: isBookmarked ? "remove" : "add",
    };
    updateDealBookmark(data, (err, res) => {
      if (err) return console.log(err);
      if (res.data.status === 200) {
        getUserData((err, res) => {
          if (err) return;
          setUser(res.data?.[0]);
        });
      }
    });
  };

  return (
    <Link to={isLoggedIn ? `/dashboard/deal/${deal?.slug}` : `/startup/${deal?.slug}`} state={{ from: location.pathname }}>
      <div className={styles["card-container"]}>
        <header className={styles["card-header"]}>
          {isClosed && <div className={styles["closed-deal-tag"]}>closed</div>}
          <img className={styles["startup-thumbnail"]} src={deal?.start_up_pic.replace("http://", "https://")} alt="" />
          <img className={styles["startup-logo"]} src={deal?.logo.replace("http://", "https://")} alt="logo" />
          
        </header>
         {/* <div style={{display:"flex",justifyContent:'end'}}>
          <div style={{paddingTop:'15px',paddingRight:'20px'}}>
          {isBookmarked ? (
                <svg width="18" height="24" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.33869e-07 2V15.5C-6.33938e-05 15.5868 0.0224845 15.6722 0.0654246 15.7476C0.108365 15.8231 0.170217 15.8861 0.244892 15.9304C0.319567 15.9747 0.404491 15.9988 0.491305 16.0003C0.578119 16.0018 0.66383 15.9807 0.74 15.939L6 13.069L11.26 15.939C11.3362 15.9807 11.4219 16.0018 11.5087 16.0003C11.5955 15.9988 11.6804 15.9747 11.7551 15.9304C11.8298 15.8861 11.8916 15.8231 11.9346 15.7476C11.9775 15.6722 12.0001 15.5868 12 15.5V2C12 1.46957 11.7893 0.960859 11.4142 0.585786C11.0391 0.210714 10.5304 0 10 0L2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 1.33869e-07 1.46957 1.33869e-07 2Z"
                    fill="#0F1F78"
                  />
                </svg>
              ) : (
                <svg width="18" height="24" viewBox="0 0 18 24" fill="#0F1F78">
                  <path
                    d="M0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L15 0C15.7956 0 16.5587 0.316071 17.1213 0.87868C17.6839 1.44129 18 2.20435 18 3V23.25C17.9999 23.3857 17.9631 23.5188 17.8933 23.6351C17.8236 23.7515 17.7236 23.8468 17.604 23.9108C17.4844 23.9748 17.3497 24.0052 17.2142 23.9988C17.0787 23.9923 16.9474 23.9492 16.8345 23.874L9 19.6515L1.1655 23.874C1.05256 23.9492 0.921345 23.9923 0.785837 23.9988C0.650329 24.0052 0.5156 23.9748 0.395999 23.9108C0.276399 23.8468 0.176406 23.7515 0.106671 23.6351C0.036936 23.5188 7.00806e-05 23.3857 0 23.25V3ZM3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V21.849L8.5845 18.126C8.70759 18.0441 8.85215 18.0004 9 18.0004C9.14785 18.0004 9.29241 18.0441 9.4155 18.126L16.5 21.849V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H3Z"
                    fill="#0F1F78"
                  />
                </svg>
              )}
          </div>
         </div> */}

        <div className={styles["card-body"]} style={{marginTop:'15px'}}>
          <div style={{display:'flex',justifyContent:'space-between',alignContent:'center'}}>
          <h3>{deal?.start_up_name}</h3>
          {isLoggedIn && (
            <div onClick={handleBookmark} >
            <div style={{display:"flex",justifyContent:'end'}}>
          <div style={{paddingTop:'15px',paddingRight:'20px'}}>
          {isBookmarked ? (
                <svg width="18" height="24" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.33869e-07 2V15.5C-6.33938e-05 15.5868 0.0224845 15.6722 0.0654246 15.7476C0.108365 15.8231 0.170217 15.8861 0.244892 15.9304C0.319567 15.9747 0.404491 15.9988 0.491305 16.0003C0.578119 16.0018 0.66383 15.9807 0.74 15.939L6 13.069L11.26 15.939C11.3362 15.9807 11.4219 16.0018 11.5087 16.0003C11.5955 15.9988 11.6804 15.9747 11.7551 15.9304C11.8298 15.8861 11.8916 15.8231 11.9346 15.7476C11.9775 15.6722 12.0001 15.5868 12 15.5V2C12 1.46957 11.7893 0.960859 11.4142 0.585786C11.0391 0.210714 10.5304 0 10 0L2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 1.33869e-07 1.46957 1.33869e-07 2Z"
                    fill="#0F1F78"
                  />
                </svg>
              ) : (
                <svg width="18" height="24" viewBox="0 0 18 24" fill="#0F1F78">
                  <path
                    d="M0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L15 0C15.7956 0 16.5587 0.316071 17.1213 0.87868C17.6839 1.44129 18 2.20435 18 3V23.25C17.9999 23.3857 17.9631 23.5188 17.8933 23.6351C17.8236 23.7515 17.7236 23.8468 17.604 23.9108C17.4844 23.9748 17.3497 24.0052 17.2142 23.9988C17.0787 23.9923 16.9474 23.9492 16.8345 23.874L9 19.6515L1.1655 23.874C1.05256 23.9492 0.921345 23.9923 0.785837 23.9988C0.650329 24.0052 0.5156 23.9748 0.395999 23.9108C0.276399 23.8468 0.176406 23.7515 0.106671 23.6351C0.036936 23.5188 7.00806e-05 23.3857 0 23.25V3ZM3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V21.849L8.5845 18.126C8.70759 18.0441 8.85215 18.0004 9 18.0004C9.14785 18.0004 9.29241 18.0441 9.4155 18.126L16.5 21.849V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H3Z"
                    fill="#0F1F78"
                  />
                </svg>
              )}
          </div>
         </div>
          
            </div>
          )}
          </div>
          <p>{deal?.about_startup_short.length >= 200 ? `${deal?.about_startup_short.slice(0, 200)}...` : deal?.about_startup_short}</p>
          <div className={styles["sectors"]}>
            {deal?.sector.map((ele, idx) => (
              <span key={idx}>{ele}  </span>
            ))}
          </div>
        </div>

        <footer className={styles["card-footer"]}>
          <p>
            <span>₹{formatINR(deal?.amount_raised) || 0}</span> raised
          </p>
          <div>
            <hr />
            <p>
              <span>{deal?.total_investor}</span> investors
            </p>
          </div>
          <div>
            <hr />
            <p>
              <span>₹{formatINR(deal?.min_investment)}</span> minimum investment
            </p>
          </div>
          {!deal?.market_value || deal?.market_value == 0 ? null : (
            <div>
              <hr />
              <p>
                <span>₹{formatINR(deal?.market_value)}</span> market value
              </p>
            </div>
          )}
        </footer>
      </div>
    </Link>
  );
};

export default DealCard;
