import React from "react";
import style from "./FinancialSynopsis.module.css";
import useStartupCtx from "../../hooks/useStartupCtx";
import { markupText } from "../../utils";
import FullImage from "../FullImage/FullImage";
import useAuthCtx from "../../hooks/useAuthCtx";
import BlurInfo from "../BlurInfo/BlurInfo";

const FinancialSynopsis = ({ styles: parentStyles }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();
  const { financialSynopsis } = startupFullDetails;
  const { isLoggedIn } = useAuthCtx();
  return (
    <div data-scroll-id="financial-synopsis" className={style["fs-wrapper"]}>
      <h1 className={parentStyles.sectionHeader}>
        Financial Synopsis
        <div className={parentStyles.borderBottom}></div>
      </h1>

      {/* <div className={style["sub-heading"]}>
        <img src={document} id={style["icon"]} alt="Document" />
        <h3 className="">Highlights</h3>
      </div> */}
      {!isLoggedIn ?
        <BlurInfo>
          <>
            <svg width="653" height="374" viewBox="0 0 653 374" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="-6" y="-2" width="666" height="378" fill="#D9D9D9" />
            </svg>


            {/* <img src={`${financialSynopsis.image_1}`} className={`${style["fs-image"]}`} alt="Financial Synopsis" /> */}
            {/* <img src={`${financialSynopsis.image_2}`} className={`${style["fs-image"]}`} alt="Financial Synopsis" /> */}

            {/* <h5>
           <div className="rupee my-auto">₹</div> Funding
         </h5> */}
            <div className={style["fs-desc"]} >
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur asperiores aperiam, obcaecati tempore eligendi corporis nesciunt eum in dolorem repudiandae fuga doloremque, corrupti quam voluptate! Dicta aut fugiat nam. At tempora culpa doloribus accusantium porro.
            </div>
          </>
        </BlurInfo>
        : <>
          <FullImage image={financialSynopsis.image_1.replace("http://", "https://")} classNames={style["fs-image"]} height={"100%"} width={"100%"} />
          <FullImage image={financialSynopsis.image_2.replace("http://", "https://")} classNames={style["fs-image"]} height={"100%"} width={"100%"} />

          {/* <img src={`${financialSynopsis.image_1}`} className={`${style["fs-image"]}`} alt="Financial Synopsis" /> */}
          {/* <img src={`${financialSynopsis.image_2}`} className={`${style["fs-image"]}`} alt="Financial Synopsis" /> */}

          {/* <h5>
          <div className="rupee my-auto">₹</div> Funding
        </h5> */}
          <div className={style["fs-desc"]} dangerouslySetInnerHTML={{ __html: markupText(financialSynopsis?.description, "\r\n", "br") }}></div>
        </>}


    </div>
  );
};

export default FinancialSynopsis;
