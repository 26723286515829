import React, { useState } from 'react';
import Update from '../Updates/Update';
import styles from './InvestmentDocuments.module.css'; 
import { askQuery } from '../../services/startup';

const Queries = () => {
  const [query, setQuery] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    askQuery(query, (err, res) => {
      if (err) {
        setErrorMessage('There was an error submitting your query.');
        console.error(err);
        // Clear the error message after 10 seconds
        setTimeout(() => setErrorMessage(''), 10000);
      } else {
        setSuccessMessage('Your query has been submitted successfully.');
        setQuery(''); // Clear the query input
        // Clear the success message after 10 seconds
        setTimeout(() => setSuccessMessage(''), 10000);
      }
    });
  };

  return (
    <div className={styles.queriesContainer}>
      <h3>Have a Question in mind? Ask Us</h3>
      <textarea
        className={styles.queryInput}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Type your question here..."
      />
      <button className={styles.submitButton} onClick={handleSubmit}>
        Submit
      </button>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
    </div>
  );
};

const InvestmentDocuments = () => {
  const [CurrentDocumentOpen, setCurrentDocumentOpen] = useState(<Update />);
  const [OpenDocument, setOpenDocument] = useState('update');

  return (
    <div id={styles.InvestmentDocuments}>
      <ul className={styles['navigation-btn']} id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link text-center ${OpenDocument === 'update' ? styles['active'] : ''}`}
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home-tab-pane"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected={OpenDocument === 'update'}
            onClick={() => {
              setCurrentDocumentOpen(<Update />);
              setOpenDocument('update');
            }}
          >
            Updates
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link text-center ${OpenDocument === 'queries' ? styles['active'] : ''}`}
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact-tab-pane"
            type="button"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected={OpenDocument === 'queries'}
            onClick={() => {
              setCurrentDocumentOpen(<Queries />); // Set Queries component
              setOpenDocument('queries');
            }}
          >
            Queries
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className={`tab-pane fade ${OpenDocument === 'update' ? 'show active' : ''}`} id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
          {CurrentDocumentOpen}
        </div>
        <div className={`tab-pane fade ${OpenDocument === 'queries' ? 'show active' : ''}`} id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
          {CurrentDocumentOpen}
        </div>
      </div>
    </div>
  );
};

export default InvestmentDocuments;
