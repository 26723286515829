import React, { useEffect, useState, useRef } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import logo from './../../Assets/images/logo.png'
import styles from './Navbar.module.css'
import arrow from './../../Assets/icons/arrow.png'
import Avatar from './../../Assets/icons/avatar.png'
import useAuthCtx from '../../hooks/useAuthCtx'
import SearchIcon from '../../Assets/icons/search_icon.svg'
import other_service_arrow from '../../Assets/icons/other_service_arrow.svg'
import { getprofile, getprofile_pic } from '../../services/startup'
const BASE_URL = 'https://venturebuddy.s3.amazonaws.com';
const Navbar = ({ arrowVisibility, setArrowVisibility }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [p_image, setp_image] = useState('')
  const { isLoggedIn, user, dp } = useAuthCtx()
  const navigate = useNavigate()
  const location = useLocation()
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (isLoggedIn) {
      getprofile_pic((err, res) => {
        if (err) return
        const urlportion = res?.data?.profile_info[0]?.profile_pic?.split('https://testingfrontendbanao.io/media')
        const desiredPortion = urlportion[1]
        setp_image(`${BASE_URL}${desiredPortion}`)
      })
    }
  }, [dp, isLoggedIn])

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
        const dropdownIcon = document.getElementById('dropdown-icon')
        if (dropdownIcon) {
          dropdownIcon.style.transform = 'rotate(0deg)'
        }
      }
    }

    // Only add the event listener if the dropdown is open
    if (isOpen) {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    const dropdownIcon = document.getElementById('dropdown-icon')
    if (dropdownIcon) {
      dropdownIcon.style.transform = isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  }, [isOpen])

  const links = document.getElementsByClassName('nav-link')
  const registerEmailActiveness = document.getElementById('register-email-page')?.classList[1]

  useEffect(() => {
    for (let i = 0; i <= links.length; i++) {
      links[i]?.addEventListener('click', () => {
        i === 0 ? (document.getElementById('hello').style.display = 'block') : (document.getElementById('hello').style.display = 'none')
      })
    }
  }, [links])

  useEffect(() => {
    if (location.pathname === '/register' && registerEmailActiveness === 'active') {
      setArrowVisibility(false)
    } else {
      setArrowVisibility(true)
    }

    if (location.pathname !== '/register') {
      setArrowVisibility(false)
    }
    if (location.pathname === '/privacy/policy') {
      setArrowVisibility(true)
    }
  }, [registerEmailActiveness, location.pathname, arrowVisibility, setArrowVisibility])

  const handleNavigates = (nav) => {
    navigate(nav)
    setIsOpen(false) // Close the dropdown
    const dropdownIcon = document.getElementById('dropdown-icon')
    dropdownIcon.style.transform = 'rotate(0deg)'
  }

  const BtnRef = useRef(null)
  useEffect(() => {
    const Nav = document.getElementById('navbarText')
    window.addEventListener('click', () => {
      if (Nav.className === 'navbar-collapse collapse show') {
        BtnRef?.current.click()
      }
    })
  }, [])

  const locationArray = ['/', '/privacy-policy', '/add-fundraise', '/fundraising', '/community', '/newsletter', '/blog', '/post', '/contact', '/terms', '/aboutus', '/wealth-manager']

  
  return (
    <>
      <div className={`container-fluid bg-blue text-white text-center text-uppercase ${locationArray.includes(location.pathname) ? '' : 'd-none'}`} id={styles.hello}>
        <div className={styles.hellotxt}>
        Calculate the valuation of your business in just 15 minutes.{' '}
        <a className="text-white text-decoration-underline" href="https://valuation.venturebuddy.io/" target="_blank" rel="noreferrer">
          Click here
        </a> 
        </div>
        <div className={styles.x}> X</div>
      </div>
      <nav className={`sticky-top navbar navbar-expand-lg bg-white fw-bold mt-0`} id={styles.navbar}>
        <div className="col-4 col-lg-2 order-1 mx-auto fixed-sm-top mx-lg-0 order-lg-0 top-0 py-3 py-lg-0 my-auto" id={styles.logo}>
          <NavLink to="/">
            <img src={logo} className="img-fluid col-10 col-lg-8" alt="" />
          </NavLink>
        </div>
        <div className="container-fluid py-0">
          <button
            className="carousel-control-prev position-relative text-dark d-lg-none"
            type="button"
            data-bs-target="#carouselExampleControls2"
            data-bs-slide="prev"
            id="prev2"
            style={arrowVisibility ? { display: 'block' } : { display: 'none' }}
            onClick={() => {
              navigate('/')
            }}
          >
            <img src={arrow} alt="" className="img-fluid" id={styles.arrowIcon} />
          </button>
          {!arrowVisibility ? (
            <button ref={BtnRef} className="navbar-toggler border-0 col-2 p-0" style={{ boxShadow: 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" id="toggler">
              <span className="navbar-toggler-icon"></span>
            </button>
          ) : null}

          {/* this div is search bar for community  */}
          {/* <div className={`${location.pathname === '/community' ? 'd-block' : 'd-none'}`}>
            <div className={`search_bar_1 `} style={{ display: 'flex', alignItems: 'center', borderRadius: '10px', background: 'var(--Fill-Color-Light-Tertiary, rgba(118, 118, 128, 0.12))', padding: '7px 7px' }}>
              <img src={SearchIcon} className="search_bar_icon" style={{ width: '16px', height: '16px', marginRight: '4px', cursor: 'pointer' }} />
              <input type="text" placeholder="Search" className="search_bar_input" style={{ border: 'none', outline: 'none', width: '120px', marginRight: '10px', background: 'transparent' }} />
            </div>
          </div> */}

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto gap-4 mb-2 mb-lg-0 align-items-baseline" id="navbar-list">
              <li className="nav-item text-light py-0">
                <NavLink className="nav-link" to="/" end>
                  Home
                </NavLink>
              </li>
              <li className="nav-item text-light py-0">
                <NavLink className="nav-link" to="/explore">
                  Invest
                </NavLink>
              </li>
              <li className="nav-item text-light py-0">
                <NavLink className={`nav-link ${location.pathname === '/add-fundraise' ? 'active' : ''}`} to="/fundraising">
                  Raise
                </NavLink>
              </li>
              <li
                className="nav-item text-light py-0"
                style={{
                  marginTop: -5,
                  marginRight: "-5px",
                }}
              >
                <a className="nav-link" target={'_blank'} href="https://valuation.venturebuddy.io/" rel="noreferrer" >
                  Valuation calculator
                </a>
              </li>
              <li className="nav-item text-light py-0 border-3" ref={dropdownRef}>
              <button
                id="dropdown-btn"
                style={{ marginRight:"25px",backgroundColor: 'white', border: 'none', color: '#6D747A', fontWeight: '500', fontSize: '16px' }}
                onClick={(e) => {
                  e.stopPropagation(); // Stop the event from propagating
                  toggleDropdown();
                }}
              >
                Services <img id="dropdown-icon" src={other_service_arrow} style={{ width: '16px', height: '16px', transition: 'transform 0.3s ease' }} />
              </button>
              </li>
              {isOpen && (
                <div
                  className={styles['mobile_nav']}
                  style={{
                    width: '200px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: '60px',
                    right: '33%',
                    borderRadius: '8px',
                    boxShadow: '2px 4px 31px 0 rgba(0, 0, 0, 0.125)',
                    paddingLeft: '10px',
                    gap: '10px',
                    zIndex: '1000',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <p onClick={() => handleNavigates('/otherservice/VirtualCFO')} style={{ color: '#6D747A', fontWeight: '500', fontSize: '16px', cursor: 'pointer', margin: '-1px' }}>
                      Virtual CFO
                    </p>
                    <p onClick={() => handleNavigates('/otherservice/FinancialModelling')} style={{ color: '#6D747A', fontWeight: '500', fontSize: '16px', cursor: 'pointer', margin: '-1px' }}>
                      Financial modelling
                    </p>
                    <p onClick={() => handleNavigates('/otherservice/OverseasCompanyRegistration')} style={{ color: '#6D747A', fontWeight: '500', fontSize: '16px', cursor: 'pointer', margin: '-1px' }}>
                      Overseas company registrations
                    </p>
                    <p onClick={() => handleNavigates('/otherservice/BookkeepingAndAccounting')} style={{ color: '#6D747A', fontWeight: '500', fontSize: '16px', cursor: 'pointer', margin: '-1px' }}>
                    Book Keeping and Accounting
                    </p>
                    <p onClick={() => handleNavigates('/otherservice/FinancialDueDiligence')} style={{ color: '#6D747A', fontWeight: '500', fontSize: '16px', cursor: 'pointer', margin: '-1px' }}>
                    Financial Due Diligence
                    </p>
                    <p onClick={() => handleNavigates('/otherservice/PitchDeck')} style={{ color: '#6D747A', fontWeight: '500', fontSize: '16px', cursor: 'pointer', margin: '-1px' }}>
                      Pitchdeck
                    </p>
                  </div>
                </div>
              )}

              <li className="nav-item text-light py-0 d-lg-none border-3">
                <NavLink className="nav-link" to="/contact">
                  Contact us
                </NavLink>
              </li>
              <li className="nav-item text-light">
                <NavLink
                  className="py-0 nav-link border-end d-none d-lg-block border-3"
                  style={{
                    marginTop: 9,
                    paddingRight: '32px',
                    
                  }}
                  to="/contact"
                >
                  Contact us
                </NavLink>
              </li>
              <li className="py-0 my-auto">
                {isLoggedIn ? (
                  <>
                    <div className="fw-normal d-flex align-items-center" id={styles.avatar}>
                      <img onClick={() => navigate('/dashboard')} src={p_image || Avatar} width="30px" className="me-2" height="30px" alt="Avatar" id="avatar" />
                    </div>
                  </>
                ) : (
                  <NavLink className="nav-link" to="/login">
                    Login & Signup
                  </NavLink>
                )}
              </li>
              <li className="nav-item px-4 ps-4 py-0" id={styles.animatedButton}>
                <Link to="/explore" className={`${styles.liveDeals}`}>
                  Live Deals
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
