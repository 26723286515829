import React from 'react'
import style from './FullImage.module.css'
import close from '../../Assets/plus.png'
import { useState } from 'react'
import { useEffect } from 'react'

const FullImage = ({ classNames, image, width, height }) => {
  // Note This file is in use
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [show])

  return (
    <div className={style['main-fullimage-container']}>
      <img className={classNames} src={image} alt="" style={{ width: width, height: height }} />
      <div onClick={() => setShow(true)} className={style['fullscreen-btn']}>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 122.88 122.87">
          <g>
            <path fill="black" d="M122.88,77.63v41.12c0,2.28-1.85,4.12-4.12,4.12H77.33v-9.62h35.95c0-12.34,0-23.27,0-35.62H122.88L122.88,77.63z M77.39,9.53V0h41.37c2.28,0,4.12,1.85,4.12,4.12v41.18h-9.63V9.53H77.39L77.39,9.53z M9.63,45.24H0V4.12C0,1.85,1.85,0,4.12,0h41 v9.64H9.63V45.24L9.63,45.24z M45.07,113.27v9.6H4.12c-2.28,0-4.12-1.85-4.12-4.13V77.57h9.63v35.71H45.07L45.07,113.27z" />
          </g>
        </svg>
      </div>
      <div className={`${style['fullscreen-image']} ${show && style['show']}`} id="fullscreen">
        <img src={image} className={style['full-image']} alt="" />
        <div className={style['close-btn']} onClick={() => setShow(false)}>
          <img src={close} alt="" />
        </div>
      </div>
    </div>
  )
}

export default FullImage
