import React from 'react'
import styles from '../Update.module.css'

const Updata = ({ dp, date, year, opened, title, para, name, designation, last }) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <div className={styles.date}>
          <b>{date}</b> <br /> <span>{year}</span>
        </div>
        <div className={styles.data} id={last && styles['last-update']}>
          {opened ? (
            <div className={styles.title}>Opened for Funding</div>
          ) : (
            <>
              <div className={styles.title}>{title}</div>
              <div className={styles.para}>{para}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Updata
