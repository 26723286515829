import React, { useContext } from "react";
import { authContext } from "../Contexts/Authcontext";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthCtx from "../hooks/useAuthCtx";

const RequireAuth = () => {
  const { isLoggedIn } = useAuthCtx();
  const location = useLocation();
  const { slug } = useParams();

  if (!isLoggedIn && slug && location.pathname.includes("/dashboard/deal/")) return <Navigate to={`/startup/${slug}`} replace />;

  return isLoggedIn ? <Outlet /> : <Navigate to={"/login"} state={{ from: location.pathname }} replace />;
};

export default RequireAuth;
