import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { startupdetailContext } from "./Startupdetailcontext";

const investContext = createContext();

const Investcontext = ({ children }) => {
  const [res5, setRes5] = useState(null);
  const [myinv, setMyinv] = useState(null);

  // const myInvestments = () => {
  //   axios
  //     .get(`${url}myinvesments/`, config)
  //     .then((res) => {
  //       setMyinv(res);
  //     })
  //     .catch((err) => alert(err.message));
  // };

  return (
    <investContext.Provider
      value={{
        myinv,
        res5,
      }}
    >
      {children}
    </investContext.Provider>
  );
};

export { Investcontext, investContext };
