import React, { useEffect, useState } from 'react';
import Style from './DiscussionC.module.css';
import axios from 'axios';
import CommentCompo1 from './commentcompo/CommentCompo1';

const DiscussionC = ({ startupid ,containerRef}) => {
    const [postcomment, setPostComment] = useState('');
    const [comments, setComments] = useState([]);
    const [showAllComments, setShowAllComments] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [showCommentsCount, setShowCommentsCount] = useState(2);
    const [parentid,setParentid]=useState()
    const [repliesLoadin,setRepliesLoadin]=useState(false)
    const [allreplies,setReplies]=useState()
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem('access_token');
    const headers = {
        Authorization: `Bearer ${token}`
    };
    const handlePost1 = async () => {
        if (postcomment === '') {
            return alert('write something');
        }
        const body = {
            startup: startupid,
            comment: postcomment
        };
        try {
            const response = await axios.post(`${BASE_URL}postdiscussion/`, body, { headers });
            const res = response.data;
            setNewComment(res);
            setPostComment('');
        } catch (err) {
            console.error('Error posting discussion:', err);
        }
    };
    const handlePost2 = async () => {
        if (postcomment === '') {
            return alert('write something');
        }
        const body = {
            startup: startupid,
            comment: postcomment,
            parent:parentid
        }
       
        try {
            const response = await axios.post(`${BASE_URL}postdiscussion/`, body, { headers });
            const res = response.data;
            setNewComment(res);
            setPostComment('');
            // console.log("this is do reply comment response",res)
        } catch (err) {
            console.error('Error posting discussion:', err);
        }
    };

    const handleFetchComment = async () => {
        const body = {
            id: startupid
        };
        try {
            const response = await axios.post(`${BASE_URL}listdiscussioncomments/`, body);
            const res = response.data;
            setComments(res);
        } catch (err) {
            console.error('Error fetching comments:', err);
        }
    };

    const toggleShowAllComments = () => {
        setShowAllComments(!showAllComments);
    };

    useEffect(() => {
        handleFetchComment();
    }, [startupid, newComment]);



    return (
        <>
            <div className={Style['main']} ref={containerRef}>
                <div className={Style['title']}>
                    <div className={Style['title2']}>
                        <p>Discussion</p>
                    </div>
                </div>

                <div className={Style['join_us']}>
                    <p>Join the Conversation</p>
                    <div className={Style['triangle']}></div>
                    <div className={Style['join_wrap']}>
                        <input type="text" placeholder="Start a discussion..." onChange={(e) => setPostComment(e.target.value)} value={postcomment} />
                        <button onClick={handlePost1}>POST</button>
                    </div>
                </div>

                <div className={Style['comm_box']}>
                {comments && comments?.slice(0, showAllComments ? comments?.length : showCommentsCount).map((item, index) => (
                    <CommentCompo1 startupid={startupid}  setReplies={setReplies} setRepliesLoadin={setRepliesLoadin} setParentid={setParentid} item={item} key={index} custom={index} setnewComment={setNewComment} />
                ))}
                </div>

              
                {!showAllComments && comments?.length > 2 && (
                    <div className={Style['view_more']} onClick={toggleShowAllComments} style={{cursor:'pointer'}}>
                        <p>View More ({comments?.length-2})</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default DiscussionC;