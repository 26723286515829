import React, { useEffect, useState } from 'react';
import style from './MobileBlogs.module.css';
import styles from '../blogslider.module.css';
import Arrow from '../../pitchdeck/pich_assest/arrow.svg';
import { ListAllBlogs } from '../../../../services/blogsApi';
import { useNavigate } from 'react-router-dom';

const MobileBlogs = ({hidden}) => {
  const [numToShow, setNumToShow] = useState(3);
  const [allblogs1,setAllblogs]=useState()
  const navigate = useNavigate()
  const allblogs = async ()=>{
    try{
      const response = await ListAllBlogs()
      const res = await response
      // console.log("this is mobile blogs res",res)
      setAllblogs(res)      
    }catch(err){

    }
}

useEffect(()=>{
  allblogs()
},[])
  const numberofblogs=[1,2,3,4,5,6,7,8,9,10]

  const handleViewMore = () => {
    setNumToShow(prevNum => prevNum + 3);
  };

  const handleviewmore = (slug)=>{
    navigate(`/blog/${slug}`)
  }

  return (
    <>
      <div className={style['main_container']}>
        <div className={styles['blog_heading']} style={{display:`${hidden===true?'none':''}`}}>
          <h1>Blogs</h1>
          <p>Stay informed with our medical blog. From health tips to the latest advancements, we're your source for concise, insightful updates on wellness and medical breakthroughs.</p>
        </div>

        <div className={style['blogs_container']}>
        {allblogs1?.results?.slice(0, allblogs1?.results?.length<4?allblogs1?.results?.length:numToShow).map((item,index)=>(
            <>
            <div className={styles['blog_card']} key={index}>
     <div className={styles['blog_tags']}>
        <p>Tags</p>
        <p>New Technology</p>
     </div>
     <div className={styles['blogs_image']}>
        <img src={item?.blog_image===null?'https://www.ingenious.co.uk/wp-content/uploads/2023/08/What-is-Cloud-Computing.jpeg':item?.blog_image}/>
     </div>
     <div className={styles['blogs_detail']}>
        <h1>{item?.blog_title}</h1>
        <br/>
        <h2>10 min read</h2>
        <h3>12 February, 2024 </h3>
        
        <br/>
        <button style={{marginTop:'18px',marginLeft:"5px"}} onClick={()=>handleviewmore(item?.slug)}>Read more <img src={Arrow} style={{marginBottom:'3px'}}/></button>
     </div>
   </div>
            </>
        ))

        }
     </div>

        {numToShow < allblogs1?.length && (
          <div className={style['view_more_container']}>
            <button onClick={handleViewMore}>View More</button>
          </div>
        )}
      </div>
    </>
  );
};

export default MobileBlogs;
