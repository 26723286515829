import React from "react";
import useStartupCtx from "../../hooks/useStartupCtx";
import FullImage from "../FullImage/FullImage";
import style from "./StartupCustomSections.module.css";

const StartupCustomSections = ({ styles }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail } = startupFullDetails;
  return (
    <div className={style["custom-section-wrapper"]}>
      {mainDetail.custom_sections.map((section, index) => {
        return (
          <div data-scroll-id={index + section.name} className={`${style["custom-section"]}`}>
            <h1 className={styles["sectionHeader"]}>
              {section.name}
              <div className={styles["borderBottom"]}></div>
            </h1>
            <FullImage image={section.image.replace("http://", "https://")} classNames={style["image"]} height={"100%"} width={"100%"} />
          </div>
        );
      })}
    </div>
  );
};

export default StartupCustomSections;
