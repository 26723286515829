import React, { useState, useEffect } from 'react';
import style from './CommentCard.module.css';
import img from '../../Assets/images/Niromi.png';
import like from '../../Assets/icons/Vectorlike.png';
import reply from '../../Assets/icons/Vectorreply.png';
import { format } from 'timeago.js';
import { BASE_URL } from '../../utils/constant';
import { DisLikeComment, DisLikeComment2, LikeComment, ReplyLike, True2Comment, TrueComment, ViewReplyComment } from '../../services/Communitys';
import { useNavigate } from 'react-router-dom';

const CommentCard = ({ comment, likes, reply, time, profile,setDisliked, state, comId, liked,replies1,disLiked, Tfalse,customKey, com, profile_pic, time1, like1, id,indexd,setIndexo,setParent,setthoughtid,thoughtID }) => {
  const [timeAgo, setTimeAgo] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [replyLiked,setreplyLiked]=useState(false)
  const [replyCom,setReplyCom]=useState(true)
  const [allReply,setReply]=useState()
  const [dislike,setDislike]=useState()
  const [dislike3,setDislike3]=useState()
  const [dislike2,setDislike2]=useState()
  const [comLiked,setComLiked]=useState()
  const [dislked3,setDisliked3]=useState()
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const DotsArray = [1, 2, 3];

  const toggleExpansion = async (num) => {
    setExpanded((prevExpanded) => !prevExpanded);
    if (!expanded) {
     if(comId===undefined)return
     try {
      const response = await ViewReplyComment(comId);
      state(response,comId); 
      indexd(customKey)
    } catch (error) {
    }
    } else {
      state(null, comId); 
    }
  };

  const handleLike = async (id) => {
    if (!token) {
      navigate('/login');
    }

    try {
      const response = await LikeComment(id);
      liked(response);
      setComLiked(response)
    } catch (error) {;
    }
  };

  const handleRelyLike = async(id)=>{
    if (!token) {
      navigate('/login');
    }
    try{
       const response = await ReplyLike(id)
        setreplyLiked(true)
       
    }catch(error){
    }
  }

  const handleReply = (id)=>{
     setReplyCom((prevExpanded)=>!prevExpanded)
     setParent(comId)
     setthoughtid(thoughtID)    
     replyCom===true?setIndexo(id):setIndexo(null)
  }

  useEffect(() => {
    const date = new Date(time || time1);
    const formattedTime = format(date);
    setTimeAgo(formattedTime);
  }, [time]);


  const replies = async()=>{
    if(comId===undefined)return
    try{
      const response = await ViewReplyComment(comId)
      const res = await response
      setReply(res)
      if(replies1!==null){
       toggleExpansion()
      }
    }catch(error){}
  }
  useEffect(()=>{
 
   replies()

  },[replies1,dislike3,dislked3,comId])
  useEffect(() => {
    const truedLiked = async () => {
      try {
        if (id !== undefined) { 
          const response = await True2Comment(id);
          const res = await response;
          setDislike3(res);
          if (replies1 !== null) {
            toggleExpansion();
          }
        }
      } catch (error) {}
    };
    truedLiked();
  }, [replies1, replyLiked, dislked3, dislike3]);

  const handleDislike = async(id)=>{
    try{
     const response = await DisLikeComment(id)
     const res = await response
     setDislike2(res)
     setDisliked(res)
    }catch(error){
 
    }
   }

  useEffect(()=>{
  const TrueLiked = async()=>{
   try{
      const response = await TrueComment(comId)
      const res = await response
      setDislike(res)
   }catch(error){
   }
  }
  TrueLiked()
  },[dislike2,comLiked ,dislike3,comId])


  const handleReplyDislike = async(id)=>{
    try{
      const response = await DisLikeComment2(id)
      const res = await response
      setDisliked3(res)
      setreplyLiked(false)
      

    }catch(error){
    }
  }


 
  return (
    <>
    <div className={style['main-comment-card']}>
      <div className={style['profile-pic']}>
        <img
          src={`${BASE_URL}${profile || profile_pic}`}
          alt=""
          onError={(e) => {
            e.target.src =
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
          }}
        />
      </div>
      <div className={style['comment']}>
        <div className={style['three-dots']}>
          {DotsArray.map((idx) => (
            <span className={style['dots']} key={idx}></span>
          ))}
        </div>
        <div className={style['time']}>
          <p>{timeAgo}</p>
        </div>
        <div className={style['comment-text']}>
          <p>{comment || com}</p>
        </div>
        <div className={style['actions-btns']}>
          {Tfalse === true ? (
            <p onClick={dislike?.liked===true?()=>handleDislike(comId):() => handleLike(comId)} style={{ cursor: 'pointer' }}>
              {likes || like1} {dislike?.liked===true?'Liked':'Likes'} <img src={like} alt=""  className={`${dislike?.liked===true?style['rotate-100']:''}`}/>
            </p>
          ) : (
            <p onClick={dislike3?.liked===true?()=>handleReplyDislike(id):() => handleRelyLike(id)} style={{ cursor: 'pointer' }}>
              {likes || `${replyLiked===true  ?like1+1:like1}` }  {dislike3?.liked===true?'liked':'likes'} <img src={like} alt="" className={`${dislike3?.liked===true?style['rotate-100']:''}`}/>
            </p>
          )}

          <p style={{ cursor: 'pointer' }} onClick={()=>handleReply(customKey)}>
            {Tfalse===true?'Add Comment':''}
             {Tfalse===true?<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M14 7.66669C14.0023 8.5466 13.7967 9.41461 13.4 10.2C12.9296 11.1412 12.2065 11.9328 11.3116 12.4862C10.4168 13.0396 9.3855 13.3329 8.33333 13.3334C7.45342 13.3356 6.58541 13.1301 5.8 12.7334L2 14L3.26667 10.2C2.86995 9.41461 2.66437 8.5466 2.66667 7.66669C2.66707 6.61452 2.96041 5.58325 3.51381 4.68839C4.06722 3.79352 4.85884 3.0704 5.8 2.60002C6.58541 2.20331 7.45342 1.99772 8.33333 2.00002H8.66667C10.0562 2.07668 11.3687 2.66319 12.3528 3.64726C13.3368 4.63132 13.9233 5.94379 14 7.33335V7.66669Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>:''}
          </p>
        </div>
      </div>
    </div>
    {(allReply?.length===0 || Tfalse===false)?(
      ''
    ):   ( <p style={{marginLeft:'50px',cursor:'pointer',color:'#000000',fontWeight:'600'}} onClick={()=>toggleExpansion(1)} >view all {allReply?.length} replies </p>)

    }

    </>
  );
};

export default CommentCard;
