import React, { useEffect, useState } from 'react'
import Style from '../DiscussionC.module.css'
import axios from 'axios'
import { format } from 'timeago.js'
import { useNavigate } from 'react-router-dom'
import ReplyComponent from '../ReplyComponent'
import dropdownimage from '../../reply_btn_image.png'

const CommentCompo1 = ({ custom, item, setRepliesLoadin, startupid }) => {
  const [userdetail, setUserDetail] = useState(null)
  const [isreplyshow, setIsreplyshow] = useState(false)
  const [replytext, setReplytext] = useState('')
  const [parentid, setParentid] = useState()
  const [showing, setShowing] = useState(false)
  const [allreplies, setReplies] = useState([])
  const [displayedReplies, setDisplayedReplies] = useState(5) // Initial number of displayed replies
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')
  const headers = {
    Authorization: `Bearer ${token}`,
  }

  const BASE_URL = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    const handleUserData = async () => {
      const headers = {
        'Content-type': 'application/json',
      }
      try {
        const response = await axios.get(`${BASE_URL}getuser/${item?.user}/`, { headers })
        const res = response.data
        setUserDetail(res['Users'][0])
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    handleUserData()
  }, [item?.user])

  const handlefetchreplies = async (parentids) => {
    setRepliesLoadin(true)
    const formdata = {
      id: parentids,
    }
    try {
      const response = await axios.post(`${BASE_URL}listdiscussioncommentreplies/`, formdata)
      const res = await response.data
      setParentid(parentids)
      setReplies(res)
      setRepliesLoadin(false)
      setIsreplyshow(true)
    } catch (error) {
      // console.log(error)
      setRepliesLoadin(false)
      setIsreplyshow(false)
      setShowing(false)
    }
  }

  const handlePost2 = async () => {
    if (!token) {
      navigate('/login')
    }

    if (replytext === '') {
      return alert('write something')
    }
    const body = {
      startup: startupid,
      comment: replytext,
      parent: parentid,
    }

    try {
      const response = await axios.post(`${BASE_URL}postdiscussion/`, body, { headers })
      const res = response.data
      setReplytext('')
      setShowing(false)
      // console.log('this is do reply comment response', res)
    } catch (err) {
      console.error('Error posting discussion:', err)
    }
  }

  const handleViewMore = () => {
    setDisplayedReplies((prevCount) => prevCount + 5) // Increase the number of displayed replies by 5
  }

  return (
    <>
      <div className={`${Style['comment_model']} ${custom === 0 ? `${Style['for_radius']}` : ''}`} style={{ borderRadius: '12px' }} key={custom} onClick={() => handlefetchreplies(item?.id)}>
        <div className={Style['time_ago']}>{format(item?.timestamp)}</div>
        <div className={Style['comment_model_wrap']}>
          <div className="">
            <img src={userdetail?.profile_pic ? `${BASE_URL}media/${userdetail.profile_pic}` : 'https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg'} alt="Profile Picture" onError={(e) => (e.target.src = 'https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg')} style={{ borderRadius: '50%' }} />
          </div>
          <div className={Style['content']}>
            <h1>{`${userdetail?.first_name || ''} ${userdetail?.last_name || ''}`}</h1>
            <p>{item?.comment}</p>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '20px',
          }}
        >
          <img
            src={dropdownimage}
            style={{
              width: '15px',
              cursor: 'pointer',
              transform: showing ? 'rotate(180deg)' : 'none',
              marginRight: '10px',
            }}
            onClick={() => setShowing((prev) => !prev)}
          />
          <span
            style={{
              color: '#a0a8ae',
              fontSize: '12px',
              fontWeight: '400',
              cursor: 'pointer',
            }}
          >
            View comments
          </span>
        </div>

        {showing && (
          <>
            {isreplyshow && (
              <>
                {allreplies && allreplies.MessageReplies.length > 0 ? (
                  <>
                    {allreplies.MessageReplies.slice(0, displayedReplies).map((item, index) => (
                      <div style={{ marginTop: '20px' }}>
                        <ReplyComponent key={index} item={item} />
                      </div>
                    ))}
                    {allreplies.MessageReplies.length > displayedReplies && (
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <button onClick={handleViewMore} className={Style['view_more']} style={{ marginLeft: '20px', backgroundColor: 'white', border: 'none', color: '#283891', outline: 'none' }}>
                          View More
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <p style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>No Replies Found</p>
                )}
                <div className={Style['post_div']}>
                  <div className={Style['post_div_wrap']}>
                    <input style={{ width: '87%', border: 'none', outline: 'none', backgroundColor: 'none', paddingLeft: '10px', color: '#939CA3', fontSize: '12px', fontWeight: '400', marginTop: '-10px' }} type="text" placeholder="Write a comment" onChange={(e) => setReplytext(e.target.value)} value={replytext}></input>
                    <button onClick={handlePost2}>REPLY</button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default CommentCompo1
