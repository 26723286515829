import React from "react";
import styles from "./InvestHeader.module.css";

const InvestHeader = () => {
  // Note This file is in use
  return (
    <div id={styles.investHeader}>
      <h1>Invest Now</h1>
      <h6 className={styles.Desc}>
        Kickstart your jouney with verified and promising startups by
        Venturebuddy.
      </h6>
    </div>
  );
};

export default InvestHeader;
