import { React } from "react";
import Closed from "../../components/Closed/Closed";
import FAQ from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import Options from "../../components/Options/Options";
import InvestHeader from "../../components/InvestHeader/InvestHeader";
import { useNavigate } from "react-router-dom";
import useAuthCtx from "../../hooks/useAuthCtx";
import { useEffect } from "react";
import styles from "./index.module.css";
import LiveDeals from "../../components/Live/LiveDeals";
import { Helmet } from "react-helmet";
import FAQ2 from "../../components/FAQ/investment_faq/FAQ2";

const Explore = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Explore | Venture Buddy</title>
      </Helmet>
      <div className="overflow-hidden">
        <InvestHeader />
        <div className={styles.options}>
          <Options />
        </div>
        <LiveDeals />
        <Closed />
        <div style={{marginTop:'60px'}}>
        <FAQ2/>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Explore;
