import React from "react";
import style from "./IndustryOverview.module.css";
import { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import useStartupCtx from "../../hooks/useStartupCtx";
import { useEffect } from "react";
import { useState } from "react";
import { markupText } from "../../utils";
const IndustryOverview = ({ styles: parentStyles }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();
  const { industryOverview } = startupFullDetails;

  //   This state will hold our array of string but with bold text
  const [OverviewText, setOverviewText] = useState([]);
  useEffect(() => {
    setOverviewText(industryOverview?.lines);
  }, [industryOverview]);

  return (
    <div data-scroll-id="industry-overview" className={style["industry-overview-wrapper"]}>
      <h1 className={parentStyles.sectionHeader}>
        Industry Overview
        <div className={parentStyles.borderBottom}></div>
      </h1>
      <p className="" style={{ fontWeight: "400" }}>
        {industryOverview?.describtion}
      </p>

      <Swiper
        slidesPerView={"auto"}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        mousewheel={{
          // sensitivity: 0.5,
          releaseOnEdges: true,
          forceToAxis: true,
        }}
        modules={[Pagination, Mousewheel]}
        navigation={false}
        grabCursor={true}
        className={` ${style["swiper-container"]}`}
      >
        {OverviewText?.map((text, index) => {
          return (
            <SwiperSlide className={style["swiper-item"]} key={index}>
              <p className={`${style["overview-text"]}`} dangerouslySetInnerHTML={{ __html: markupText(text, "\r\n", "br") }} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default IndustryOverview;
