import React, { useState } from 'react'
import Style from './termcon.module.css'
import useAuthCtx from '../../../hooks/useAuthCtx';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { registerNewUser, updateGoogleUser } from "../../../services/account";

const TermAndCondition = ({registerInputData, setRegisterInputData}) => {
    const { setIsLoggedIn, showFinishSignupModal, setShowFinishSignupModal } = useAuthCtx();
    const navigate = useNavigate();
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const createUser = () => {
        const userData = new FormData();
        userData.append("first_name", registerInputData.first_name);
        userData.append("last_name", registerInputData.last_name);
        userData.append("email", registerInputData.email);
        userData.append("mobile", registerInputData.mobile);
        userData.append("password", registerInputData.password);
        userData.append("start_up_choice", registerInputData.start_up_type);
        userData.append("referal_code", registerInputData.referral_code);
        userData.append("username", registerInputData[registerInputData.method]);
        userData.append("deals_type", registerInputData.deals_type);
        userData.append("is_terms_conditions", true);
        setSubmitBtnLoading(true);
        // console.log("this is userdata",userData)
        registerNewUser(userData, (err, res) => {
          setSubmitBtnLoading(false);
          console.error(err)
          // console.log("this is response from backend register api",res)
          if (err) return toast.error("Something went wrong. Please try again!");
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem('user_id',res?.data?.id)
          setIsLoggedIn(true);
          setShowFinishSignupModal(false);
          toast.success("Welcome to Venture Buddy.");
          navigate("/dashboard", { replace: true });
        });
      };
  return (
   <>
    <div className={Style['main1']}>
     <div className={Style['wrapper_1']}>
       <div className={Style['content_div']}>
         <h1>Terms & Conditions</h1>
         <p>When it comes to what you should include in your terms and conditions or how you should be wording them, there are what could be considered standard clauses but, ultimately, you should always consider the particularities of your business and avoid using legalese.
This template could help you get a head start, simply adapt it to your business, taking into consideration the services or products that you offer and the risks and liabilities that come with those.</p>
<h2>Condition of use</h2>
<p>When it comes to what you should include in your terms and conditions or how you should be wording them, there are what could be considered standard clauses but, ultimately, you should always consider the particularities of your business and avoid using legalese. This template could help you get a head start, simply adapt it to your business, taking into consideration the services or products that you offer and the risks and liabilities that come with those.</p>
<h3>Privacy policy</h3>
<p>When it comes to what you should include in your terms and conditions or how you should be wording them, there are what could be considered standard clauses but, ultimately, you should always consider the particularities of your business and avoid using legalese. This template could help you get a head start, simply adapt it to your business, taking into consideration the services or products that you offer and the risks and liabilities that come with those.</p>
       </div>

     </div>

     <div className={Style['btn_cls']}>
        <div>
            <button onClick={createUser}>{submitBtnLoading ? <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ margin: "auto", background: "rgba(221, 221, 221, 0)", display: "block" }}
                width="42px"
                height="42px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle cx={50} cy={50} fill="none" stroke="#f2f2f2" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                </circle>
              </svg>:'Accept'}</button>
        </div>
     </div>

    </div>
   </>
  )
}

export default TermAndCondition