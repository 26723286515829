import React, { useEffect } from 'react';
import style from './Blog.module.css';
import portalImage from '../../Assets/images/portal-image.png';
import author from '../../Assets/images/Niromi.png';
import Footer from '../../components/Footer/Footer';
import CommentComponent from '../../components/CommentComponent/CommentComponent';
import { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { useLocation, useParams } from 'react-router-dom';
import { SingleBlogComment, SingleBlogSlug } from '../../services/blogsApi';
import axios from 'axios';
import DOMPurify from 'dompurify';  // Add this line to import DOMPurify

const Blog = () => {
  const [arrowVisibility, setArrowVisibility] = useState(false);
  const [singleblog1, setSingleblog] = useState();
  const [comment, setComment] = useState();
  const [like, setLiked] = useState();
  const [text, setText] = useState("");
  const [commit, setCommit] = useState();
  const [commitindex, setcommitindex] = useState(null);
  const [replyid, setReplyid] = useState();
  const [replies, setreplies] = useState("");
  const [replydata, setReplydata] = useState();
  const [replyIndex, setReplyIndex] = useState(null);
  const [commentid, setcommentid] = useState();
  const [allreplies, setallreplies] = useState();
  const [userProfile, setuserProfile] = useState();
  const user_id = localStorage.getItem('user_id');

  const token = localStorage.getItem("access_token");
  const location = useLocation();
  const { slug } = useParams();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  let baseUrl = process.env.REACT_APP_BASE_URL;
  if (baseUrl.endsWith("/")) {
    baseUrl = baseUrl.slice(0, -1);
  }
  const BASE_URL1 = baseUrl;

  const formatDate = (datestamp) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(datestamp).toLocaleDateString('en-US', options);
  };

  const singleblogs = async () => {
    try {
      const response = await SingleBlogSlug(slug);
      const res = await response;
      setSingleblog(res.results[0]);
    } catch (err) {
    }
  };

  const getSingleBlogComment = async () => {
    try {
      const response = await axios.get(`${BASE_URL}listblogcomments/?id=${singleblog1?.id}`);
      const blogComments = response.data;
      setComment(blogComments["Blog Comments"]);
    } catch (err) {
    }
  };

  const handlecommit = async () => {
    if (text === "") {
      return alert("please enter something");
    }

    if (!token) {
      return alert("please login");
    }
    try {
      const response = await fetch(`${BASE_URL}postcomment/`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          blog: singleblog1?.id,
          comment: text
        })
      });
      const res = await response.data;
      setCommit(res);
      setText("");
      getSingleBlogComment();
    } catch (err) {
    }
  };

  const handlecommit2 = async () => {
    const response = await fetch(`${BASE_URL}postcomment/`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        blog: singleblog1?.id,
        comment: replies,
        parent: replyid
      })
    });
    const res = await response.json();
    setReplydata(res);
    setcommitindex(null);
    setreplies("");
  };

  const replyAll = async () => {
    try {
      const response = await axios.get(`${BASE_URL}commentreplies/${commentid}/`);
      const res = await response.data;
      setallreplies(res);
    } catch (err) { }
  };

  useEffect(() => {
    replyAll();
  }, [commentid]);

  useEffect(() => {
    singleblogs();
  }, [like, commit]);

  useEffect(() => {
    if (singleblog1?.id) {
      getSingleBlogComment();
    }
  }, [singleblog1, commit, like, replydata]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}getuser/${user_id}/`);
        const res = await response.data;
        setuserProfile(res?.Users[0]);
      } catch (err) { }
    };
    fetchProfile();
  }, []);

  const sanitizedContent = singleblog1?.blog ? DOMPurify.sanitize(singleblog1.blog) : '';

  return (
    <>
      {location.pathname.includes('/blog/') && <Navbar arrowVisibility={arrowVisibility} setArrowVisibility={setArrowVisibility}></Navbar>}
      <div className={style['main-blog-container']}>
        <div className={style['heading']}>
          <h1>Blogs</h1>
        </div>
        <div className={style['blog-container']}>
          <div className={style['blog-image']}>
            <img src={singleblog1?.blog_image === null ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbBznjz44UMQEBvKX3FppyjMFajU47p-7Hs1A8Y91kpA&s" : singleblog1?.blog_image} alt="" onError={(e) => console.error(e)} style={{ width: '1216px', height: '484px' }} />
          </div>
          <div className={style['blog-content']}>
            <div className={style['blog-title-and-content']}>
              <div className={style['blog-author']}>
                <img src={singleblog1?.blog_author_image} className={style['author-image']} alt="" />
                <div className={style['author-name-wrapper']}>
                  <span className={style['author-name']}>{singleblog1?.blog_author_name}</span>
                  <span className={style['date-time']}>{formatDate(singleblog1?.datestamp)}</span>
                </div>
                <span className={style['time-required-to-read']}> 5 min read </span>
              </div>
              <h3 className={style['blog-title']}>{singleblog1?.blog_title}</h3>
              <div className={style['blog-des']} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </div>
          </div>
        </div>
        <div className={style['main-comment-container']}>
          <div className={style['add-comment-container']}>
            <img src={`${BASE_URL1}/media/${userProfile?.profile_pic}`} className={style['author-image']} alt=""
              onError={(e) => e.target.src = "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"}
            />
            <div className={style['add-comment-container_2']}>
              <input type="text" placeholder="Add a comment..." value={text} onChange={(e) => setText(e.target.value)} />
              <p onClick={handlecommit} style={{ cursor: 'pointer' }}>send</p>
            </div>
          </div>
          <div className={style['all-comment-container']}>
            {comment ?
              (comment?.map((item, index) => (
                <>
                  <div key={item.id}>
                    <CommentComponent key={index} setReplyid={setReplyid} setcommitindex={setcommitindex} customkey={index} commentId={item?.id} setLiked={setLiked} like={like} img={item?.user?.profile_pic === null ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : `${BASE_URL1}${item?.user?.profile_pic}`} title={`${item?.user?.first_name} ${item?.user?.last_name}`} des={item?.comment} likes={item?.likes} dislike={item?.dislikes} replydata={replydata} setReplyIndex={setReplyIndex} replyIndex={replyIndex} setcommentid={setcommentid} commentid={commentid} replyCompo={false} />
                  </div>
                  {index === commitindex ? <>
                    <div className={style['add-comment-container']} style={{ width: '100%', paddingLeft: '40px' }}>
                      <img src={`${BASE_URL1}/media/${userProfile?.profile_pic}`} className={style['author-image']} alt="" onError={(e) => e.target.src = "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"} />
                      <div className={style['add-comment-container_2']}>
                        <input type="text" placeholder="Add a comment..." value={replies} onChange={(e) => setreplies(e.target.value)} />
                        <p onClick={handlecommit2} style={{ cursor: 'pointer' }}>send</p>
                      </div>
                    </div>
                  </> : ''}
                  {index === replyIndex ? (
                    allreplies ? (
                      allreplies?.MessageReplies?.map((item, index) => (
                        <>
                          <div key={index} style={{ marginLeft: '50px' }}>
                            <CommentComponent key={index} setReplyid={setReplyid} setcommitindex={setcommitindex} customkey={index} commentId={item?.id} setLiked={setLiked} like={like} img={item?.user?.profile_pic === null ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : item?.user?.profile_pic} title={`${item?.user?.first_name} ${item?.user?.last_name}`} des={item?.comment} likes={item?.likes} dislike={item?.dislikes} replydata={replydata} setReplyIndex={setReplyIndex} replyIndex={replyIndex} replyCompo={true} />
                          </div>
                        </>
                      ))
                    ) : ''
                  ) : ''}
                </>
              ))) : 'Loading.....'
            }
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
