import React from 'react'
import style from "./AboutUsCard.module.css"
const AboutUsCard = ({ card_data }) => {
    // Note This File is in use
    return (
        <div className={style["main-about-card-container"]}>
            <div className={style["about-icon"]}>
                <div className={style["icon-holder"]}>
                    <img src={card_data?.logo} alt="" />
                </div>
            </div>
            <div className={style["card-heading"]}>
                <p>{card_data?.heading}</p>
            </div>
            <div className={style["card-about"]}>
                <p>{card_data?.para}</p>
            </div>
        </div>
    )
}

export default AboutUsCard