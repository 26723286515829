import React from 'react'
import style from './ManagerCard.module.css'
const ManagerCard = ({ index, heading, subheading, img, reverse, uniqueId }) => {
  return (
    <div className={style[reverse ? 'main-manager-card-reverse' : 'main-manager-card']}>
      <div className={style['text-container']}>
        <h1 className={style['index']}>{index}</h1>
        <h1 className={style['heading']}>{heading}</h1>
        <p className={style['sub-heading']}>{subheading}</p>
      </div>
      <div className={style['image-container']} id={style[uniqueId]}>
        <img src={img} alt="" />
      </div>
    </div>
  )
}

export default ManagerCard
