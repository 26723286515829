import React, { useEffect, useState } from 'react'
import style from "./CommentComponent.module.css"
import author from '../../Assets/images/Niromi.png'
import style1 from '../../Pages/Blog/Blog.module.css'
import axios from 'axios'
import ReplyImg from '../../Assets/icons/reply_comment.svg'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'


const user_id = Number(localStorage.getItem("user_id"))
// console.log("this is userid type",user_id)

const CommentComponent = ({ img, title, des ,likes,dislike ,commentId,setLiked,like,setcommitindex,customkey,setReplyid,replydata,setReplyIndex,replyIndex,setcommentid,replyCompo}) => {
    const [reply,setReply]=useState(true)
    const [replies,setreplies]=useState()
    const [likedornot,setLikedornot]=useState()
    const navigate = useNavigate()
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const userid = localStorage.getItem('user_id')
    const handleTrueLike = async(commentid)=>{
        const data={
            user_id:userid
        }
        const response = await axios.post(`${BASE_URL}checklike/${commentid}/`,data)
        const res = await response.data
        return res
    }
    const handleTrueDislike = async(commentid)=>{
        const data={
            user_id:userid
        }
        const response = await axios.post(`${BASE_URL}checkdislike/${commentid}/`,data)
        const res = await response.data
        return res
    }
    const handlelike = async () => {  
        if(!userid){
            navigate("/login")
          }      
        const data ={
            user_id:user_id,
    message_id:commentId,
    like:1,
    dislike:0
        }
    
    //   const trueorfalse = await  handleTrueLike(commentId)
    //    if(trueorfalse?.result===true){
    //     toast.error("Already Liked")
    //     return
    //    }
        try {
            // console.log("this isfora",data)
            const response = await axios.post(`${BASE_URL}likeordislikecomment/`, data);
             const res = response.data
             if(res.MessageReply==="you Have choosen Wrong Operation"){
                toast("already liked")
             }
             setLiked(res)
          
        } catch (err) {
        }
    };

    const handledilike = async () => {   
        if(!userid){
            navigate("/login")
          }     
        const data ={
            user_id:user_id,
    message_id:commentId,
    like:0,
    dislike:1
        }
        // const trueorfalse = await handleTrueDislike(commentId)
        // if(trueorfalse?.result===true){
        //     toast.error("Already Dislike")
        //     return
        // }
        try {
            const response = await axios.post(`${BASE_URL}likeordislikecomment/`, data);
             const res = response.data
             setLiked(res)
             if(res.MessageReply==="you Have choosen Wrong Operation"){
                toast("already disliked")
             }
        } catch (err) {
            console.log(err);
        }
    };

    const handleReply = ()=>{
        setReply((prev)=>!prev)
        setcommitindex(customkey)
        setReplyid(commentId)
        reply===true?setcommitindex(customkey):setcommitindex(null)
    }


    const replyCount = async()=>{
        try{
            const response = await axios.get(`${BASE_URL}commentreplies/${commentId}/`)
            const res = await response.data
            setreplies(res)
        
        }catch(err){}
    }
    useEffect(()=>{
        replyCount()
    },[replydata])
    const handlereplyindex1 = ()=>{
        if(customkey===replyIndex){
            setReplyIndex(null)
            setcommentid(null)
        }else{
        setReplyIndex(customkey)
        setcommentid(commentId)}
    }
   
    
    return (
        <>
        <div className={style["main-comment-component"]} >
            <img src={img} className={style["user-image"]} alt="" />
            <div className={style["main-comment"]}>
                <div className={style["title-des-container"]} style={{width:'100%'}}>
                    {replyCompo===true?
                    <>
                        <div style={{width:'50vw'}}>
                        <h6>{title}</h6>
                    <p>{des}</p>
                        </div>
                    </>:
                    <>
                    <h6>{title}</h6>
                    <p>{des}</p>
                    </>

                    }
                    <div className={style["buttons"]} style={{display:'flex',justifyContent:'space-between'}}>
                       {replyCompo===false? 
                       <>
                       <div>
                            <button className={style["like"]}
                            onClick={handlelike}
                            ><svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M16 20H5V7L12 0L13.25 1.25C13.3667 1.36667 13.4627 1.525 13.538 1.725C13.6127 1.925 13.65 2.11667 13.65 2.3V2.65L12.55 7H19C19.5333 7 20 7.2 20.4 7.6C20.8 8 21 8.46667 21 9V11C21 11.1167 20.9833 11.2417 20.95 11.375C20.9167 11.5083 20.8833 11.6333 20.85 11.75L17.85 18.8C17.7 19.1333 17.45 19.4167 17.1 19.65C16.75 19.8833 16.3833 20 16 20ZM7 18H16L19 11V9H10L11.35 3.5L7 7.85V18ZM5 7V9H2V18H5V20H0V7H5Z" fill="#1C1B1F" />
                            </svg> {likes}</button>
                            <button className={style["dislike"]}
                            onClick={handledilike}
                            > <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M16 20H5V7L12 0L13.25 1.25C13.3667 1.36667 13.4627 1.525 13.538 1.725C13.6127 1.925 13.65 2.11667 13.65 2.3V2.65L12.55 7H19C19.5333 7 20 7.2 20.4 7.6C20.8 8 21 8.46667 21 9V11C21 11.1167 20.9833 11.2417 20.95 11.375C20.9167 11.5083 20.8833 11.6333 20.85 11.75L17.85 18.8C17.7 19.1333 17.45 19.4167 17.1 19.65C16.75 19.8833 16.3833 20 16 20ZM7 18H16L19 11V9H10L11.35 3.5L7 7.85V18ZM5 7V9H2V18H5V20H0V7H5Z" fill="#1C1B1F" />
                            </svg> {dislike}</button>
                            <div style={{display:'flex',alignContent:'center',cursor:'pointer'}}>
                                <img src={ReplyImg} />
                               <div style={{marginTop:'15px',display:'flex',alignContent:'center',justifyContent:'space-around'}} onClick={handlereplyindex1}> <span style={{paddingLeft:'5px'}}>{replies?.MessageReplies?.length}</span>
                                <p style={{paddingLeft:'5px'}}> Replies</p></div>
                            </div>
                        </div>
                        <div style={{display:'flex',justifyItems:'end'}}>
                        <button className={style["reply"]} style={{textAlign:'right',paddingLeft:'5vw'}} onClick={handleReply}>Reply</button>
                        </div>
                       </>:''

                       }
                    </div>
                    
                </div>
                
            </div>
            
        </div>
        {/* {comment && 
           <>
           <div className={style1['add-comment-container']}>
          <img src={author} className={style1['author-image']} alt="" />
          <input type="text" placeholder="Add a comment..." />
          <p style={{cursor:'pointer'}} onClick={()=>alert("send button click but backend not integrate")}>send</p>
        </div>
           </>

        } */}
        </>
    )
}

export default CommentComponent
