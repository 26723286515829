import React, { useState } from 'react'
import styles from './RegisterPass.module.css'
import registerStyles from './../../Pages/Register/Register.module.css'
import { useRef } from 'react'
import ErrorIcon from '../../Assets/vector/Error'
import { validatePassword } from '../../utils'
import { validateReferralCode } from '../../services/startup'

const stars = []
const passwordChars = []

const RegisterPass = ({ currentRegisterProgress, setCurrentRegisterProgress, registerInputData, setRegisterInputData }) => {
  // Note This file is in use
  const [display, setDisplay] = useState('block')
  const passwordValidate = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{6,}).*$/g

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const passInputRef = useRef(null)

  const [passErrMsg, setPassErrMsg] = useState(null)
  const [refErrMsg, setrefErrMsg] = useState(null)

  if (document.getElementById('register-left')) {
    document.getElementById('register-left').style.display = display
  }

  const submitPassword = () => {
    if (registerInputData.password.length < 6) return setPassErrMsg('Password should be at least 6 character long')

    if (!validatePassword(registerInputData.password)) return setPassErrMsg('Password must contain 1 Capital letter, 1 Small letter 1 Number, 1 Special character')

    if (registerInputData.referral_code) {
      const formDataValidation = new FormData()
      formDataValidation.append('referral_code', registerInputData?.referral_code)
      validateReferralCode(formDataValidation, (err, res) => {
        // console.log("this is referral data",res)
        if (err) {
          setrefErrMsg('Referral Code is Valid')
          return
        } else if (res.data?.success) {
          setCurrentRegisterProgress(currentRegisterProgress + 1)
          setDisplay('none')
          document.getElementById(registerStyles.registerRight).classList.remove('col-md-7')
          document.getElementById(registerStyles.registerLeft).classList.remove('d-md-block')
          return
        } else {
          setrefErrMsg('Referral Code is Valid')
          return
        }
      })
    } else {
      setCurrentRegisterProgress(currentRegisterProgress + 1)
      setDisplay('none')
      document.getElementById(registerStyles.registerRight).classList.remove('col-md-7')
      document.getElementById(registerStyles.registerLeft).classList.remove('d-md-block')
    }
  }

  // useEffect(() => {
  //   const img = document.createElement("img");
  //   if (isVisible) {
  //     img.src = eyeImage;
  //     img.width = 20;
  //     document.getElementById(styles.eye).innerHTML = "";
  //     document.getElementById(styles.eye).appendChild(img);
  //   } else {
  //     document.getElementById(styles.eye).innerHTML = "";
  //     const i = document.createElement("i");
  //     i.classList.add("fas");
  //     i.classList.add("fa-eye-slash");
  //     document.getElementById(styles.eye).appendChild(i);
  //   }
  // }, [isVisible]);

  // const createPassword = (e) => {
  //   if (e.nativeEvent.data !== null) {
  //     stars.push("*");
  //     passwordChars.push(e.target.value.split("").pop());
  //     document.getElementById("passwordField2").value = stars.join("");
  //   } else {
  //     stars.pop();
  //     passwordChars.pop();
  //     document.getElementById("passwordField2").value = stars.join("");
  //   }
  // };

  // const createEncodedPassword = (e) => {
  //   if (e.nativeEvent.data !== null) {
  //     passwordChars.push(e.target.value.split("").pop());
  //     stars.push("*");
  //     e.target.value = stars.join("");
  //     document.getElementById("passwordField").value = passwordChars.join("");
  //   } else {
  //     passwordChars.pop();
  //     stars.pop();
  //     e.target.value = stars.join("");

  //     document.getElementById("passwordField").value = passwordChars.join("");
  //   }
  // };

  return (
    <div className="text-dark" id={styles.registerPass}>
      <h1>Signup</h1>
      <h6>Set Password</h6>

      <div id={`${styles.password}`}>
        <div className={`${passErrMsg && 'form-input-err'}`}>
          <input
            ref={passInputRef}
            type={isPasswordVisible ? 'text' : 'password'}
            className={`form-control p-3 `}
            id="passwordField"
            autoComplete="off"
            placeholder="your password"
            value={registerInputData.password}
            onChange={(e) => {
              setPassErrMsg(null)
              setRegisterInputData((prev) => {
                return { ...prev, password: e.target.value }
              })
            }}
            required
          />
          <label id={styles.eye} htmlFor={styles.check}>
            <i className={`fas ${isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}></i>
          </label>
          <input type="checkbox" id={styles.check} onChange={(e) => setIsPasswordVisible(!isPasswordVisible)} />
        </div>
      </div>
      {passErrMsg && (
        <p className={`input-err-msg`}>
          <ErrorIcon fill="red" width="15px" height="15px" />

          <span>{passErrMsg}</span>
        </p>
      )}

      <div className="mt pt-3">
        <h6 className="pt-2 pb-2">Referral Code (Optional)</h6>
        <input
          type="text"
          className="form-control p-3"
          placeholder="Your referral code"
          id="referral"
          onChange={(e) => {
            setRegisterInputData((prev) => {
              return { ...prev, referral_code: e.target.value }
            })
            setrefErrMsg(null)
          }}
        />
      </div>
      {refErrMsg ? (
        <p className={`input-err-msg`}>
          <ErrorIcon fill="red" width="15px" height="15px" />
          <span>{refErrMsg}</span>
        </p>
      ) : (
        ''
      )}
      <input type="submit" className="btn-blue w-100 mt-4" onClick={submitPassword} value="NEXT" />
      {/* <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" id="next"></button> */}
    </div>
  )
}

export default RegisterPass