import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL
const token = localStorage.getItem("access_token")


export const ListAllBlogs = async()=>{
    const headers={
        'Content-type':'application/json'
    }
    try{
        const response = await axios.get(`${BASE_URL}listblogs/`,{headers})
        const res = await response.data
        return res

    }catch(err){
        console.log(err)
    }
}

export const SingleBlogSlug = async(slug)=>{
    const headers={
        'Content-type':'application/json'
    }
    try{
        const response = await axios.get(`${BASE_URL}getblog/?slug=${slug}`,{headers})
        const res = await response.data
        return res

    }catch(err){
        console.log(err)
    }
}

export const SingleBlogComment = async (ids)=>{
    const headers={
        'Content-type':'application/json'
    }
    try{
        const response = await axios.get(`${BASE_URL}listblogcomments/?id=${ids}`,{headers})
        const res = await response.data
        // console.log("this  is response",res)
        return res

    }catch(err){
        console.log(err)
    }
}


export const postComment = async(blogid,text)=>{
    if(text===""){
        return alert("please enter something")
      }
  
      if(!token){
        return alert("please login")
      }
      try{
       const response = await fetch(`${BASE_URL}postcomment/?blog=${blogid}&parent=22`,{
        method:"POST",
        headers:{
          'Content-type':'application/json',
          'Authorization':`Bearer ${token}`
        },
        body:JSON.stringify({
          comment:text
        })
       })
       const res = await response.data
    return res
      }catch(err){
        console.log(err)
      }

}


export const likeOrDislikeComment = async(task,commentId)=>{
    const data ={
        task:task,
        comment_id:commentId
    }
    try {
        const response = await axios.post(`${BASE_URL}likeordislike/`, data);
         const res = response.data
          return res
    } catch (err) {
        console.log(err);
    }

}