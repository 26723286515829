import React, { useEffect, useState } from "react";
import styles from "./GetTouch.module.css";
import { toast } from "react-toastify";
import { isEmptyField, validateEmail, validatePhone } from "../../utils";
import { sendGetInTouchForm } from "../../services/contact";
import ErrorIcon from "../../Assets/vector/Error";

const GetTouchWithUs = () => {
  // Note This file is in use
  const [whoAreYou, setWhoAreYou] = useState(0);
  const [who, setwho] = useState("Who are you?");
  // const [arrow, setarrow] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessgae] = useState("");

  // const [whoAreYouErrMsg, setWhoAreYouErrMsg] = useState(null);
  const [whoErrMsg, setWhoErrMsg] = useState(null);
  const [fullNameErrMsg, setFullNameErrMsg] = useState(null);
  const [emailErrMsg, setEmailErrMsg] = useState(null);
  const [phoneErrMsg, setPhoneErrMsg] = useState(null);
  const [messageErrMsg,setmessageErrMsg]=useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false);
  const selection = document.getElementById("selections")

  const handleSelect = (e)=>{
    setWhoAreYou(e.target.value);
    if(whoAreYou===0){
     setWhoErrMsg("Please select who are you !")
    }else{
     setWhoErrMsg(null)

    }
  }

  selection?.addEventListener("click",()=>{
    return setWhoErrMsg(null)
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    const alphabetRegex = /^[A-Za-z ]+$/;
    const numberRegex = /^[0-9]+$/;
 
if (fullName === "") {
  return setFullNameErrMsg("This field is required!");
} else {
  setFullNameErrMsg("")
}

if (!alphabetRegex.test(fullName)) {
  return setFullNameErrMsg("Please enter only alphabetic characters!");
}else{
  setFullNameErrMsg("")
}

    if(email===""){
      return setEmailErrMsg("This field is required!")
    }
    if(phone==="" || !numberRegex.test(phone)){
      return setPhoneErrMsg("This field is required! or Enter Valid Number")
    }
    if(phone.length!==10){
      return setPhoneErrMsg("This field is required! or Enter Valid Number")
    }
    if(message===""){
      return setmessageErrMsg("please enter something")
    }
    if(whoAreYou===0){
      return setWhoErrMsg("Please select who are you !")
    }
    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      return setEmailErrMsg("Please enter a valid email address!");
  }
    // if (!alphabetRegex.test(fullName)) return setFullNameErrMsg('Please enter a valid full name!');
    // if (isEmptyField(fullName)) return setFullNameErrMsg("This field is required!");
    // if (isEmptyField(email)) return setEmailErrMsg("This field is required!");
    // if (!validateEmail(email)) return setEmailErrMsg("Please enter a valid email!");
    // if (isEmptyField(phone)) return setPhoneErrMsg("This field is required!");
    // if (!validatePhone(phone)) return setPhoneErrMsg("Please enter a valid phone number!");
    // if (!validateEmail(email)) return setEmailErrMsg("Please enter a valid email!");
    // if (message==="") return setmessageErrMsg("please enter something")
    // if(whoAreYou === 1) return setWhoErrMsg("Please select who are you !")

    const formData = new FormData();
    formData.append("full_name", fullName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);
    formData.append("who_are_you", whoAreYou);

    setIsSubmitting(true);
    sendGetInTouchForm(formData, (err, res) => {
      setIsSubmitting(false);
      if (err) return toast.error("Something went wrong!");
      toast.info("Thank you, our team will contact you soon!");
      setFullName("");
      setEmail("");
      setPhone("");
      setMessgae("");
    });
  };

  useEffect(() => {
    document.getElementById("email").setCustomValidity("");
  }, [email]);

  return (
    <div className={`${styles.getTouchContainer}`}>
      <h1 className="" id={styles.getTouchHeader}>
        Get In Touch With Us
      </h1>
      <p className={`${styles.phone_getIn_touch_msg}`}>We are here to help and answer any questions you might have or any services you are looking for!</p>

      <form className={`col-11 rounded-4 my-4 ${styles["get-in-touch-form"]}`} action="">
        <div className={`${styles.form_input_container}`}>
          <div className="col-12  row justify-content-between mt-4 mb-4">
             <label htmlFor="" className={`form-label ${styles.label}`}>
              Who are you?
            </label>
            <div class={`mb-3 ${whoErrMsg} && "form-input-err"}`}></div>
            <div className={`dropdown ${styles["is-investor-dropdown"]}`} id={`${styles.padding_left_none}`}>
              <select className={styles["select-dropdown"]} id="selections" onChange={handleSelect} >
                <option>Who are you?</option>
                <option value={1}>Investor</option>
                <option value={2}>Startup Founder</option>
                <option value={3}>Others</option>
              </select>
            </div>
            {whoErrMsg && (
                  <p className={`input-err-msg`}>
                    <ErrorIcon fill="red" width="15px" height="15px" />

                    <span>{whoErrMsg}</span>
                  </p>
                )}
          </div>
          <div className="col-12 w-100 row justify-content-between my-4 " id={`${styles.padding_left_none}`}>
            <div className="col-12 col-lg-6 pe-lg-4 w-100" id={`${styles.padding_left_none}`}>
              <label htmlFor="fullName" className={`form-label ${styles.label}`}>
                Full Name
              </label>
              <div class={`mb-3 ${fullNameErrMsg && "form-input-err"}`}>
                <input
                  type="text"
                  class={`form-control p-3 `}
                  id="fullName"
                  name="fullName"
                  value={fullName}
                  placeholder="Enter your full name"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setFullName(e.target.value);
                    setFullNameErrMsg(null);
                  }}
                />
                {fullNameErrMsg && (
                  <p className={`input-err-msg`}>
                    <ErrorIcon fill="red" width="15px" height="15px" />

                    <span>{fullNameErrMsg}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-12  row justify-content-between my-4" id={`${styles.padding_left_none}`}>
            <div className="col-12 col-lg-6" id={`${styles.padding_left_none}`}>
              <label htmlFor="email" className={`form-label ${styles.label}`}>
                Email ID
              </label>
              <div class={`mb-3 ${emailErrMsg && "form-input-err"}`}>
                <input
                  type="email"
                  class="form-control p-3"
                  id="email"
                  name="email"
                  value={email}
                  placeholder="Enter your email Id"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailErrMsg(null);
                  }}
                />
                {emailErrMsg && (
                  <p className={`input-err-msg`}>
                    <ErrorIcon fill="red" width="15px" height="15px" />

                    <span>{emailErrMsg}</span>
                  </p>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6" id={`${styles.padding_left_none}`}>
              <label htmlFor="phone" className={`form-label ${styles.label}`}>
                Phone Number
              </label>
              <div class={`mb-3 ${phoneErrMsg && "form-input-err"}`}>
                <input
                  type="text"
                  class={`form-control p-3 `}
                  id="phone"
                  name="phone"
                  value={phone}
                  maxLength={10}
                  placeholder="Enter your phone number"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setPhoneErrMsg(null);
                  }}
                />
                {phoneErrMsg && (
                  <p className={`input-err-msg`}>
                    <ErrorIcon fill="red" width="15px" height="15px" />

                    <span>{phoneErrMsg}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 row justify-content-between" id={`${styles.padding_left_none}`}>
            <div className="col-12 col-lg-12" id={`${styles.padding_left_none}`}>
              <label htmlFor="email" className={`form-label ${styles.label}`}>
                Message
              </label>
              <div class={`mb-3 `}>
                <textarea
                  name=""
                  id=""
                  class={`form-control p-3 ${messageErrMsg && "form-input-err"}`}
                 
                  cols="30"
                  rows="5"
                  placeholder="Enter your message here..."
                  onChange={(e) => {
                    setMessgae(e.target.value);
                    setmessageErrMsg(null);
                  }}
                  value={message}
                ></textarea>
                   {messageErrMsg && (
                  <p className={`input-err-msg`}>
                    <ErrorIcon fill="red" width="15px" height="15px" />

                    <span>{messageErrMsg}</span>
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="w-100" id={`${styles.padding_left_none}`}>
            <button className="btn btn-blue col-2" id={styles.submitBtn} disabled={isSubmitting} onClick={handleSubmit}>
              {isSubmitting ? "SENDING..." : "SEND"}
            </button>
          </div>
        </div>
      </form>

      {/* Form for mobile */}
      <form className={`${styles.form_mobile}`} action="">
        <div className={`col-12  row justify-content-between mt-4 mb-4 ${styles.drop_input}`}>
          <label htmlFor="" className={`form-label ${styles.label}`}>
            Who are you?
          </label>
          <div className={`dropdown w-100 ${styles["is-investor-dropdown"]}`}>
          <select className={styles["select-dropdown"]} onChange={(e)=>{
                setWhoAreYou(e.target.value);
              }} >
                <option>Who are you?</option>
                <option value={1}>Investor</option>
                <option value={2}>Startup Founder</option>
                <option value={3}>Others</option>
              </select>
          </div>
        </div>
        <div className="col-12 col-lg-6 pe-lg-4">
          <label htmlFor="fullName" className={`form-label ${styles.label}`}>
            Full Name
          </label>
          <div class={`mb-3 ${fullNameErrMsg && "form-input-err"}`}>
            <input
              type="text"
              class={`form-control p-3 `}
              id="fullName"
              name="fullName"
              value={fullName}
              placeholder="Enter your full name"
              aria-describedby="basic-addon3"
              onChange={(e) => {
                setFullName(e.target.value);
                setFullNameErrMsg(null);
              }}
            />
            {fullNameErrMsg && (
              <p className={`input-err-msg`}>
                <ErrorIcon fill="red" width="15px" height="15px" />

                <span>{fullNameErrMsg}</span>
              </p>
            )}
          </div>
        </div>
        <div className="col-12 col-lg-6 pe-lg-4">
          <label htmlFor="email" className={`form-label ${styles.label}`}>
            Email ID
          </label>
          <div class={`mb-3 ${emailErrMsg && "form-input-err"}`}>
            <input
              type="email"
              class="form-control p-3"
              id="email"
              name="email"
              value={email}
              placeholder="Enter your email Id"
              aria-describedby="basic-addon3"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErrMsg(null);
              }}
            />
            {emailErrMsg && (
              <p className={`input-err-msg`}>
                <ErrorIcon fill="red" width="15px" height="15px" />

                <span>{emailErrMsg}</span>
              </p>
            )}
          </div>
          <div className="col-12 col-lg-6" id={`${styles.padding_left_none}`}>
            <label htmlFor="phone" className={`form-label ${styles.label}`}>
              Phone Number
            </label>
            <div class={`mb-3 ${phoneErrMsg && "form-input-err"}`}>
              <input
                type="text"
                class={`form-control p-3 `}
                id="phone"
                name="phone"
                value={phone}
                placeholder="Enter your phone number"
                aria-describedby="basic-addon3"
                maxLength={10}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setPhoneErrMsg(null);
                }}
              />
              {phoneErrMsg && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />

                  <span>{phoneErrMsg}</span>
                </p>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-12" id={`${styles.padding_left_none}`}>
            <label htmlFor="email" className={`form-label ${styles.label}`}>
              Message
            </label>
            <div class={`mb-3 `}>
              <textarea
                name=""
                id=""
                class="form-control p-3"
                cols="30"
                rows="5"
                placeholder="Enter your message here..."
                onChange={(e) => {
                  setMessgae(e.target.value);
                  setEmailErrMsg(null);
                }}
                value={message}
              ></textarea>
            </div>
          </div>
        </div>
        <button className="btn btn-blue col-2" id={styles.submitBtn_mobile} disabled={isSubmitting} onClick={handleSubmit}>
          {isSubmitting ? "SENDING..." : "SEND"}
        </button>
      </form>
    </div>
  );
};

export default GetTouchWithUs;
