import React, { useState } from 'react'
import styles from './Support.module.css'
import SupportPopup from './SupportPopup'
import { getprofile } from '../../services/startup'
import { toast } from 'react-toastify'
import { sendCallbackReq } from '../../services/contact'

/* File is in use */

const Support = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null)

  const handleRequest = () => {
    setIsLoading(true)
    getprofile((err, res) => {
      if (err) {
        toast.error('Something Went Wrong!')
        setIsLoading(false)
      } else {
        let mobile = res.data[0].mobile
        if (mobile) {
          setPhoneNumber(mobile)
          setShowPopup(false)
          sendReq(mobile)
        } else {
          setShowPopup(true)
          setIsLoading(false)
        }
      }
    })
  }

  const sendReq = (phone) => {
    const formData = new FormData()
    formData.append('mobile_num', phone)
    sendCallbackReq(formData, (err) => {
      if (err) {
        toast.error('Something Went Wrong!')
      } else {
        setPhoneNumber(null)
        toast.success('Thank you, our team will contact you soon!')
      }
      setIsLoading(false)
    })
  }

  return (
    <>
      <div className={styles.support_bg}></div>
      <div className={styles.support_container} style={{paddingLeft:'50px'}}>
        <div className={styles.support_title}>
          <h1>Contact Us</h1>
          <p>Our support team is available for your queries round the week from 10 am to 7 pm.</p>
        </div>
        <div className={styles.support_info}>
          <a href="https://wa.me/919776655444" className={styles.info_container} target="_blank" rel="noopener noreferrer">
            <div>
              <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.7756 10.3397C14.1415 10.3495 14.5472 10.3693 14.9331 11.2261C15.1891 11.7964 15.6202 12.8464 15.9715 13.7017C16.2448 14.3673 16.4697 14.915 16.5259 15.0271C16.6545 15.2831 16.7337 15.5785 16.5655 15.9231C16.5457 15.9638 16.527 16.0026 16.5089 16.0401C16.374 16.3201 16.278 16.5193 16.0511 16.7799C15.9578 16.887 15.8607 17.0044 15.7638 17.1214C15.5935 17.3272 15.4244 17.5316 15.2794 17.676C15.0222 17.9319 14.7549 18.2078 15.0517 18.7198C15.3487 19.2318 16.3875 20.9157 17.921 22.2747C19.5697 23.7425 21.0033 24.361 21.728 24.6737C21.8687 24.7344 21.9827 24.7836 22.0663 24.8252C22.5809 25.0812 22.8875 25.0418 23.1843 24.6972C23.4812 24.3524 24.4705 23.2003 24.8169 22.6883C25.1532 22.1763 25.4994 22.2551 25.9744 22.4323C26.4492 22.6095 28.982 23.8502 29.4964 24.1062C29.5981 24.1569 29.6932 24.2025 29.7814 24.2448C30.1393 24.4164 30.3826 24.5331 30.4858 24.707C30.6144 24.9236 30.6144 25.9478 30.189 27.1491C29.7538 28.3505 27.6563 29.5026 26.7064 29.5912C26.6165 29.5997 26.527 29.6105 26.4349 29.6217C25.5645 29.727 24.4596 29.8606 20.5229 28.3111C15.6722 26.4036 12.4701 21.6708 11.8227 20.7139C11.7716 20.6384 11.7364 20.5864 11.7176 20.5612L11.7067 20.5465C11.4121 20.1524 9.61023 17.7413 9.61023 15.2537C9.61023 12.8736 10.7827 11.6345 11.3174 11.0693C11.3513 11.0335 11.3827 11.0004 11.411 10.9699C11.8858 10.4579 12.44 10.3299 12.7862 10.3299C13.1326 10.3299 13.4788 10.3299 13.7756 10.3397Z"
                  fill="#4DBC5C"
                />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.372023 38.6629C0.208389 39.2568 0.749366 39.8051 1.34546 39.6494L10.5601 37.2425C13.4689 38.8181 16.7437 39.6551 20.0779 39.6551H20.0878C31.0598 39.6551 40.0037 30.763 40.0037 19.8325C40.0037 14.5347 37.9357 9.55188 34.1762 5.80982C30.4168 2.06793 25.4204 0 20.0876 0C9.11581 0 0.171876 8.8921 0.171876 19.8227C0.170629 23.3 1.08847 26.7164 2.83324 29.729L0.372023 38.6629ZM5.72549 30.5258C5.93913 29.7503 5.83241 28.9215 5.42928 28.2255C3.94897 25.6695 3.17082 22.771 3.17188 19.8227C3.17188 10.5632 10.7584 3 20.0876 3C24.6274 3 28.8628 4.75397 32.0599 7.93611C35.2533 11.1147 37.0037 15.3322 37.0037 19.8325C37.0037 29.0919 29.4172 36.6551 20.0878 36.6551H20.0779C17.2449 36.6551 14.4609 35.9436 11.989 34.6046C11.3199 34.2422 10.5382 34.1476 9.80199 34.3399L4.27718 35.783L5.72549 30.5258Z" fill="#4DBC5C" />
              </svg>
            </div>
            <div className={styles.info_text}>
              <p className={styles.text}>
                Whatsapp to <span>+91 9594228662</span>
              </p>
            </div>
          </a>

          <a href="mailto:support@venturebuddy.com" className={styles.info_container}>
            <div>
              <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.71588 7.67826C8.25103 7.42795 7.68881 7.44046 7.23555 7.71119C6.78229 7.98192 6.50473 8.471 6.50473 8.99896V27.999C6.50473 28.8274 7.1763 29.499 8.00473 29.499C8.83316 29.499 9.50473 28.8274 9.50473 27.999V11.5103L20.2936 17.3197C20.7375 17.5587 21.2719 17.5587 21.7159 17.3197L32.5047 11.5103V27.999C32.5047 28.8274 33.1763 29.499 34.0047 29.499C34.8332 29.499 35.5047 28.8274 35.5047 27.999V8.99896C35.5047 8.471 35.2272 7.98192 34.7739 7.71119C34.3206 7.44046 33.7584 7.42795 33.2936 7.67826L21.0047 14.2953L8.71588 7.67826Z" fill="#EE5454" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.6259 1.44263C24.5592 0.810789 17.4503 0.810789 10.3836 1.44263L7.3489 1.71397C4.09367 2.00503 1.46353 4.49187 0.990742 7.7257C-0.00525564 14.5383 -0.00525552 21.4596 0.990742 28.2722C1.46353 31.5061 4.09367 33.9929 7.3489 34.284L10.3836 34.5553C17.4503 35.1871 24.5592 35.1871 31.6259 34.5553L34.6606 34.284C37.9158 33.9929 40.546 31.5061 41.0188 28.2722C42.0148 21.4596 42.0148 14.5383 41.0188 7.7257C40.546 4.49187 37.9158 2.00503 34.6606 1.71397L31.6259 1.44263ZM10.6508 4.43071C17.5397 3.81476 24.4698 3.81476 31.3587 4.43071L34.3934 4.70205C36.2657 4.86945 37.7784 6.29975 38.0503 8.15969C39.0042 14.6845 39.0042 21.3134 38.0503 27.8382C37.7784 29.6982 36.2657 31.1285 34.3934 31.2959L31.3587 31.5672C24.4698 32.1832 17.5397 32.1832 10.6508 31.5672L7.61607 31.2959C5.74383 31.1285 4.23111 29.6982 3.95919 27.8382C3.00526 21.3134 3.00526 14.6845 3.95919 8.15969C4.23111 6.29975 5.74383 4.86945 7.61607 4.70205L10.6508 4.43071Z"
                  fill="#EE5454"
                />
              </svg>
            </div>
            <div className={styles.info_text}>
              <p className={styles.text}>
                Email to <span>preetesh@venturebuddy.io</span>
              </p>
            </div>
          </a>

          <div className={styles.info_container} onClick={handleRequest}>
            <div>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.95446 14.9615C5.83855 23.421 12.7493 30.1471 21.3413 33.7942L22.8568 34.4694C26.0989 35.9139 29.9096 34.888 31.9883 32.0111L34.5371 28.4836C35.6268 26.9753 35.346 24.8781 33.8977 23.7097L29.4483 20.1201C27.8999 18.871 25.6231 19.162 24.4386 20.7603L23.4463 22.0993C19.0575 19.8375 15.4728 16.2527 13.2109 11.8639L14.55 10.8716C16.1483 9.68714 16.4392 7.41035 15.1901 5.86201L11.6 1.41192C10.432 -0.0358692 8.33556 -0.317079 6.82732 0.771734L3.28074 3.33205C0.384773 5.42268 -0.635084 9.2655 0.843116 12.517L1.95212 14.9564L1.95446 14.9615ZM22.5251 31.0376C14.6176 27.6845 8.25664 21.4961 4.68201 13.7123L4.68082 13.7097L3.57414 11.2754C2.68722 9.32452 3.29913 7.01883 5.03671 5.76445L8.5833 3.20413C8.79876 3.04859 9.09825 3.08876 9.26511 3.29559L12.8552 7.74568C13.0336 7.96687 12.9921 8.29212 12.7637 8.46134L10.427 10.1931C9.85948 10.6136 9.66229 11.3731 9.95352 12.0166C12.6294 17.9295 17.3808 22.6809 23.2936 25.3567C23.9371 25.648 24.6966 25.4508 25.1172 24.8833L26.8489 22.5465C27.0181 22.3182 27.3434 22.2766 27.5646 22.455L32.0141 26.0446C32.221 26.2115 32.2611 26.5112 32.1054 26.7266L29.5567 30.2541C28.3094 31.9803 26.023 32.5958 24.0777 31.7291L22.5501 31.0482L22.5251 31.0376Z"
                  fill="#3E4C9C"
                />
                <path d="M20.5015 11.9996C20.5015 12.3974 20.6595 12.7789 20.9408 13.0602C21.2221 13.3415 21.6036 13.4996 22.0015 13.4996H29.6583C30.4867 13.4996 31.1583 12.828 31.1583 11.9996C31.1583 11.1711 30.4867 10.4996 29.6583 10.4996H25.6228L31.9408 4.18155C32.5266 3.59577 32.5266 2.64602 31.9408 2.06023C31.355 1.47445 30.4053 1.47445 29.8195 2.06023L23.5015 8.37825V4.34272C23.5015 3.51429 22.8299 2.84272 22.0015 2.84272C21.173 2.84272 20.5015 3.51429 20.5015 4.34272V11.9996Z" fill="#3E4C9C" />
              </svg>
            </div>
            <div className={styles.info_text}>
              <p className={styles.text}>{isLoading && phoneNumber ? 'Sending...' : isLoading && !phoneNumber ? 'Loading...' : 'Request a callback'}</p>
            </div>
          </div>
        </div>
      </div>
      {showPopup && <SupportPopup setShowPopup={setShowPopup} />}
    </>
  )
}

export default Support
