import React from "react";
import styles from "./wallet.module.css";
const Tanslist = (prop) => {
  return (
    <>
      <div className={styles.t_list}>
        <div className={styles.t_wrap_list}>
          {prop.type === "Deposited" ? (
            <p className={styles.t_deposit} style={{ background: "#4dbc5c" }}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5897_40117)">
                  <path
                    d="M9 13.5914L17.607 4.98438L19.021 6.39838L10.414 15.0054H18V17.0054H7V6.00538H9V13.5904V13.5914Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5897_40117">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </p>
          ) : (
            <p className={styles.t_deposit} style={{ background: "#EE5454" }}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5897_40120)">
                  <path
                    d="M15 10.4086L6.393 19.0156L4.979 17.6016L13.586 8.99462L6 8.99462L6 6.99462L17 6.99463L17 17.9946L15 17.9946L15 10.4096L15 10.4086Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5897_40120">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(24 24) rotate(-180)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </p>
          )}
          <div className={styles.tittle_date}>
            <h1 className={styles.remarks}>{prop.added}</h1>
            <p className={styles.t_date}>{prop.date}</p>
          </div>
          <h2>₹ {prop.amount}</h2>
          {prop.type === "Deposited" ? (
            <p style={{ color: "#4dbc5c" }} className={styles.t_type}>
              {prop.type}
            </p>
          ) : (
            <p style={{ color: "#EE5454" }} className={styles.t_type}>
              {prop.type}
            </p>
          )}
          <p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                stroke="#0D1218"
                stroke-width="1.5"
              />
              <path
                d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                stroke="#0D1218"
                stroke-width="1.5"
              />
              <path
                d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                stroke="#0D1218"
                stroke-width="1.5"
              />
            </svg>
          </p>
        </div>
      </div>
    </>
  );
};

export default Tanslist;
