import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import arrow from '../../Assets/arrow.png';
import AboutStartUp from '../AboutStartUp/AboutStartUp';
import CompanyDocuments from '../CompanyDocuments/CompanyDocuments';
import Competitors from '../Competitors/Competitors';
import DealTerms from '../DealTerms/DealTerms';
import FinancialSynopsis from '../FinancialSynopsis/FinancialSynopsis';
import FundUsage from '../FundUsage/FundUsage';
import IndustryOverview from '../IndustryOverview/IndustryOverview';
import OutTeam from '../OutTeam/OutTeam.js';
import PitchDeck from '../PitchDeck/PitchDeck';
import Swot from '../Swot/Swot';
import VideoPitch from '../VideoPitch/VideoPitch';
import StartupCustomSections from '../StartupCustomSections/StartupCustomSections';

import useStartupCtx from '../../hooks/useStartupCtx';
import styles from './StartupDetailBody.module.css';
import useAuthCtx from '../../hooks/useAuthCtx';
import BlurInfo from '../BlurInfo/BlurInfo';
import DiscussionC from './discussion_comment/DiscussionC.js';

const StartupDetailBody = () => {
  const location = useLocation();
  const containerRef = useRef(null);

  const { isLoggedIn } = useAuthCtx();
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail, founders, industryOverview, financialSynopsis, fundsUsage, swotAnalysis } = startupFullDetails;

  const [scrollActiveSection, setScrollActiveSection] = useState(null);

  const handleScroll = () => {
    const sections = document.querySelectorAll('[data-scroll-id]');
    const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

    if (!sections?.length || sections?.length === 0) return;
    
    if (isBottom) {
      setScrollActiveSection('discussion_forum');
      return;
    }

    sections.forEach((section) => {
      let rect = section.getBoundingClientRect();
      if (rect.top <= (isLoggedIn ? 10 : 100) && rect.bottom > (isLoggedIn ? 10 : 100)) {
        setScrollActiveSection(section.getAttribute('data-scroll-id'));
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollChange = (id) => {
    const sections = document.querySelectorAll('[data-scroll-id]');
    if (!sections?.length || sections?.length === 0) return;
    sections.forEach((section) => {
      if (section.getAttribute('data-scroll-id') === id) {
        section.scrollIntoView({ behavior: isLoggedIn ? 'smooth' : 'auto' });
        if (!isLoggedIn) {
          setTimeout(() => {
            window.scrollBy(0, -90);
          }, 5);
        }
      }
    });
  };

  const handleScrolldiscuss = () => {
    containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    setScrollActiveSection('discussion_forum');
  };

  const ScrollRight = () => {
    const scroll = document.getElementById('scroll');
    scroll.scrollLeft += 25;
  };

  const ScrollLeft = () => {
    const scroll = document.getElementById('scroll');
    scroll.scrollLeft -= 25;
  };

  return (
    <div className={styles['body-wrapper']}>
      <aside
        style={{
          top: location.pathname.includes('dashboard') ? '0px' : '120px',
          '--top': !isLoggedIn ? '81px' : '15px',
        }}
        id="scroll"
      >
        <div className={styles['right-arrow-conatiner']} onClick={ScrollRight}>
          <img src={arrow} className={styles['right-arrow']} alt="" />
        </div>
        <div className={styles['left-arrow-container']} onClick={ScrollLeft}>
          <img src={arrow} className={styles['left-arrow']} alt="" />
        </div>
        <ul>
          {(mainDetail.about_startup_long || mainDetail.about_startup_points) && (
            <li onClick={() => handleScrollChange('about')} className={scrollActiveSection === 'about' ? styles.active : ''}>
              About
            </li>
          )}
          {(mainDetail?.problem_pic || mainDetail?.problem_statement || mainDetail?.solution || mainDetail?.solution_pic) && (
            <li onClick={() => handleScrollChange('what-solving')} className={scrollActiveSection === 'what-solving' && styles.active}>
              What are We Solving
            </li>
          )}
          {founders?.length !== 0 && (
            <li onClick={() => handleScrollChange('our-team')} className={scrollActiveSection === 'our-team' && styles.active}>
              Our Team
            </li>
          )}
          {industryOverview && (
            <li onClick={() => handleScrollChange('industry-overview')} className={scrollActiveSection === 'industry-overview' && styles.active}>
              Industry Overview
            </li>
          )}
          {mainDetail?.competitors?.length !== 0 && (
            <li onClick={() => handleScrollChange('competitors')} className={scrollActiveSection === 'competitors' && styles.active}>
              Competitors
            </li>
          )}
          {(financialSynopsis?.image_1 || financialSynopsis?.image_2 || financialSynopsis?.description) && (
            <li onClick={() => handleScrollChange('financial-synopsis')} className={scrollActiveSection === 'financial-synopsis' && styles.active}>
              Financial Synopsis
            </li>
          )}
          {fundsUsage && (
            <li onClick={() => handleScrollChange('fund-usage')} className={scrollActiveSection === 'fund-usage' && styles.active}>
              Funds Usage
            </li>
          )}
          {swotAnalysis && (
            <li onClick={() => handleScrollChange('swot')} className={scrollActiveSection === 'swot' && styles.active}>
              SWOT Analysis
            </li>
          )}
          {mainDetail && mainDetail?.video_pitch && (
            <li onClick={() => handleScrollChange('video-pitch')} className={scrollActiveSection === 'video-pitch' && styles.active}>
              Video Pitch Deck
            </li>
          )}
          {mainDetail?.custom_sections?.map((section, index) => (
            <li key={index} onClick={() => handleScrollChange(`${index + section.name}`)} className={scrollActiveSection === `${index + section.name}` ? styles.active : ''}>
              {section.name}
            </li>
          ))}
          <li className={scrollActiveSection === "discussion_forum" ? styles.active : ''} onClick={handleScrolldiscuss}>
            <a>Discussion forum</a>
          </li>
        </ul>
      </aside>

      <main>
        <section className={styles['side-content']}>
          {mainDetail && <DealTerms />}
          {mainDetail?.company_documents && <CompanyDocuments />}
        </section>
        <section className={styles['main-content']}>
          {(mainDetail.about_startup_long || mainDetail.about_startup_points) && <AboutStartUp sectionHeader={styles.sectionHeader} borderBottom={styles.borderBottom} />}
          {!isLoggedIn ? (
            <BlurInfo showText={true}>
              <div className={styles['blur-component-children']}></div>
            </BlurInfo>
          ) : (
            <>
              {(mainDetail?.problem_pic || mainDetail?.problem_statement || mainDetail?.solution || mainDetail?.solution_pic) && <PitchDeck sectionHeader={styles.sectionHeader} borderBottom={styles.borderBottom} />}
              {founders?.length !== 0 && <OutTeam sectionHeader={styles.sectionHeader} borderBottom={styles.borderBottom} />}
              {industryOverview && <IndustryOverview styles={styles} />}
              {mainDetail?.competitors?.length !== 0 && <Competitors styles={styles} />}
              {(financialSynopsis?.image_1 || financialSynopsis?.image_2 || financialSynopsis?.description) && <FinancialSynopsis styles={styles} />}
              {fundsUsage && <FundUsage styles={styles} />}
              {swotAnalysis && <Swot styles={styles} />}
              {mainDetail && mainDetail?.video_pitch && <VideoPitch styles={styles} />}
              {mainDetail && mainDetail?.custom_sections && <StartupCustomSections styles={styles} />}
              <DiscussionC startupid={mainDetail?.id} containerRef={containerRef} data-scroll-id="discussion_forum" />
            </>
          )}
        </section>
      </main>
    </div>
  );
};

export default StartupDetailBody;