import React from "react";
import style from "./Swot.module.css";
import useStartupCtx from "../../hooks/useStartupCtx";
import useAuthCtx from "../../hooks/useAuthCtx";
import BlurInfo from "../BlurInfo/BlurInfo";

const Swot = ({ styles }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();
  const { swotAnalysis } = startupFullDetails;
  const { isLoggedIn } = useAuthCtx();
  return (
    <div data-scroll-id="swot" className={style["swot-wrapper"]}>
      <h1 className={styles.sectionHeader}>
        SWOT Analysis
        <div className={styles.borderBottom}></div>
      </h1>
      {!isLoggedIn ? <BlurInfo>
        <>
          <div className={style["swot-cards-container"]}>
            <section data-letter="S" className={style["card"]}>
              <h4>Strengths</h4>
              <ul>
                {swotAnalysis?.strength?.map((str) => (
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, eum.</li>
                ))}
              </ul>
            </section>
            <section data-letter="W" className={style["card"]}>
              <h4>Weaknesses</h4>
              <ul>
                {swotAnalysis?.weekness?.map((str) => (
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, eum.</li>
                ))}
              </ul>
            </section>
            <section data-letter="O" className={style["card"]}>
              <h4>Opportunities</h4>
              <ul>
                {swotAnalysis?.opportunities?.map((str) => (
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, eum.</li>
                ))}
              </ul>
            </section>
            <section data-letter="T" className={style["card"]}>
              <h4>Threats</h4>
              <ul>
                {swotAnalysis?.threat?.map((str) => (
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, eum.</li>
                ))}
              </ul>
            </section>
          </div>

          {swotAnalysis?.describtion && (
            <p className={style["description"]} style={{ fontWeight: "400" }}>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis obcaecati exercitationem recusandae quos omnis! Totam, odit possimus? A maxime sint aliquid magni, dolorum corrupti eos!
            </p>
          )}
        </>
      </BlurInfo> : <>
        <div className={style["swot-cards-container"]}>
          <section data-letter="S" className={style["card"]}>
            <h4>Strengths</h4>
            <ul>
              {swotAnalysis?.strength?.map((str) => (
                <li>{str}</li>
              ))}
            </ul>
          </section>
          <section data-letter="W" className={style["card"]}>
            <h4>Weaknesses</h4>
            <ul>
              {swotAnalysis?.weekness?.map((str) => (
                <li>{str}</li>
              ))}
            </ul>
          </section>
          <section data-letter="O" className={style["card"]}>
            <h4>Opportunities</h4>
            <ul>
              {swotAnalysis?.opportunities?.map((str) => (
                <li>{str}</li>
              ))}
            </ul>
          </section>
          <section data-letter="T" className={style["card"]}>
            <h4>Threats</h4>
            <ul>
              {swotAnalysis?.threat?.map((str) => (
                <li>{str}</li>
              ))}
            </ul>
          </section>
        </div>

        {swotAnalysis?.describtion && (
          <p className={style["description"]} style={{ fontWeight: "400" }}>
            {swotAnalysis?.describtion}
          </p>
        )}
      </>}


    </div>
  );
};

export default Swot;
