import React from 'react'
import dhl from './../../Assets/images/organizations/client-1-removebg-preview.png'
import next from './../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from './../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from './../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from './../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from './../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from './../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from './../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from './../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from './../../Assets/images/organizations/client-13-removebg-preview.png'
import styles from './Organization.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'
import { Link } from 'react-router-dom'

const Organizations = () => {
  // Note This file is in use
  return (
    <>
      <div id={styles.organizations} className="container-fluid">
        <>
          <h4 className="text-blue text-center" id={styles.orgHead}>
            Our Top Clients
          </h4>
          {/* Desktop Slider Start Here */}
          <div className={`${styles.slider_desktop}`}>
            <Swiper
              slidesPerView={5}
              spaceBetween={0}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              // pagination={{
              //   clickable: true,
              // }}
              navigation={false}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center}`}>
                  <img src={dhl} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center} `}>
                  <img src={next} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg}`}>
                  <img src={aviva} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center} `}>
                  <img src={santander} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center}`}>
                  <img src={serco} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center} `}>
                  <img src={healthShield} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center} `}>
                  <img src={client_10} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center} `}>
                  <img src={client_11} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center} `}>
                  <img src={client_12} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center} `}>
                  <img src={client_13} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg} ${styles.center}`}>
                  <img src={next} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={`${styles['swiper-slide-width']}`}>
                <div className={` ${styles.Orgimg}`}>
                  <img src={aviva} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          {/* Desktop Slider End Here */}

          {/* Phone Slider Start Here */}
          <div className=" row row-cols-3 row-cols-md-6 justify-content-center align-items-start mx-auto" id={`${styles.organisation_conatiner_mobile}`}>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto`}>
              <img src={dhl} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={next} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={aviva} alt="" />
            </div>
            <div className={` ${styles.Orgimg} ${styles.rowtwo1} rounded-circle col text-center my-auto `}>
              <img src={santander} alt="" />
            </div>
            <div className={` ${styles.Orgimg} ${styles.rowtwo2} rounded-circle col text-center my-auto `}>
              <img src={serco} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={healthShield} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={next} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={client_10} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={client_11} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={client_12} alt="" />
            </div>
            <div className={` ${styles.Orgimg} rounded-circle col text-center my-auto `}>
              <img src={client_13} alt="" />
            </div>
          </div>
          {/* Phone Slider End Here */}
        </>
      </div>

      <div className="container-fluid px-0 px-lg-5">
        <div className="bg-secondary-blue rounded-4 mx-5 text-center" id={styles.joinGrowingBusinesses}>
          <h1 className="text-blue mb-0 " id={styles.growHead}>
            Join 200+ growing businesses that use Venturebuddy.
          </h1>
          <p className="text-blue" id={styles.growPara}>
            To provide the best of our consulting services and help business in all stages of their growth is the mission of Venturebuddy
          </p>

          <Link to="/register" className="btn-blue" id={styles.growBtn}>
            Get Started
          </Link>
        </div>
      </div>
    </>
  )
}

export default Organizations
