import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './JoinAsInvestor.module.css'

const JoinAsInvestor = () => {
  const navigate = useNavigate()
  // Note This file is in use
  return (
    <div className="container-fluid px-lg-5 py-5" id={`${styles.mobile_style_container}`}>
      <div className="bg-secondary-blue text-blue text-center  mx-lg-5" id={`${styles.mobile_style}`}  style={{borderRadius: '24px', important: 'true'}}>
        <div className="col-12 col-lg-6 mx-auto px-1 " id={styles.container}>
          <h1 className={styles.joinHeader}>Join us as an Investor</h1>
          <p className={` mt-[0px] ${styles.joinPara} col-md-9 mx-auto`}>Multiply your investments by investing in high growth verified startups and contributing to India's startup journey.</p>
          <button
            className={styles['get-started-btn']}
            onClick={() => {
              navigate('/register')
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  )
}

export default JoinAsInvestor
