import axios from 'axios'
import { operation } from '../utils'

import { BASE_URL } from '../utils/constant'

const getHeaders = (type) => {
  const token = localStorage.getItem('access_token')
  let headers = {}
  if (type === 'form') headers['Content-Type'] = 'multipart/form-data'
  else headers['Content-Type'] = 'application/json'

  if (token) headers['Authorization'] = `Bearer ${token}`
  return headers
}

export const getUserData = (cb) => {
  const headers = getHeaders()
  axios
    .get(`${BASE_URL}/userData/`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const checkUserName = (username, cb) => {
  operation.attempt(() => {
    const headers = getHeaders()
    axios
      .get(`${BASE_URL}/checkData/?username=${username}`, { headers })
      .then((res) => {
        if (operation.retry(res?.status === 500)) return
        cb(null, res)
      })
      .catch((err) => {
        // if (operation.attempt(err))
        cb(err, null)
      })
  })
}

export const generateOtpRegister = (username, cb) => {
  operation.attempt(() => {
    const headers = getHeaders()
    delete headers['Authorization']
    axios
      .get(`${BASE_URL}/otpGenerate/${username}`, { headers })
      .then((res) => {
        if (operation.retry(res?.status === 500)) return
        cb(null, res)
      })
      .catch((err) => {
        if (operation.retry(err)) return
        cb(err, null)
      })
  })
}
export const verifyOtpRegister = (username, formdata, cb) => {
  const headers = getHeaders()
  delete headers['Authorization']
  axios
    .post(`${BASE_URL}/otpGenerate/${username}`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const generateOtp = (username, cb) => {
  operation.attempt(() => {
    const headers = getHeaders()
    delete headers['Authorization']
    axios
      .get(`${BASE_URL}/newregisterotp/${username}`, { headers })
      .then((res) => {
        if (operation.retry(res?.status === 500)) return
        cb(null, res)
      })
      .catch((err) => {
        if (operation.retry(err)) return
        cb(err, null)
      })
  })
}
export const verifyOtp = (username, formdata, cb) => {
  const headers = getHeaders()
  delete headers['Authorization']
  axios
    .post(`${BASE_URL}/newregisterotp/${username}`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const registerNewUser = (formdata, cb) => {
  const headers = getHeaders()
  delete headers['Authorization']
  axios
    .post(`${BASE_URL}/registerUser/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const updateGoogleUser = (formdata, cb) => {
  const headers = getHeaders()
  axios
    .post(`${BASE_URL}/googledata/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const getLoginToken = async (formdata, cb) => {
  operation.attempt(async () => {
    const headers = getHeaders()
    await axios
      .post(`${BASE_URL}/getToken/`, formdata, { headers })
      .then((res) => {
        if (operation.retry(res?.status === 500)) return
        cb(null, res)
      })
      .catch((err) => {
        if (operation.retry(err)) return
        cb(err, null)
      })
  })
}

export const getKycDetail = async (cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/getuserKyc/`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const postKycDetail = async (formdata, cb) => {
  const headers = getHeaders('form')
  await axios
    .post(`${BASE_URL}/postuserKyc/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const validatePanDetail = async (formdata, cb) => {
  const headers = getHeaders('form')
  await axios
    .post(`${BASE_URL}/panverification/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const sendAadharOtp = async (formdata, cb) => {
  const headers = getHeaders('form')
  await axios
    .post(`${BASE_URL}/aadharverification/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const verifyAadharOtp = async (formdata, cb) => {
  const headers = getHeaders('form')
  await axios
    .post(`${BASE_URL}/aadharotpverification/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const verifyBankDetails = async (formdata, cb) => {
  const headers = getHeaders('form')
  await axios
    .post(`${BASE_URL}/bankverification/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const postBankDetail = async (formdata, cb) => {
  const headers = getHeaders('form')
  await axios
    .post(`${BASE_URL}/bankAccount/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const googleLogin = async (formdata, cb) => {
  const headers = getHeaders('form')
  delete headers['Authorization']
  await axios
    .post(`${BASE_URL}/dj-rest-auth/google/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const getConvenienceFee = async (id, cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/getconveniencefee/?startup_id=${id}`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const getSavedDeals = async (cb) => {
  const headers = getHeaders()
  await axios
    .get(`${BASE_URL}/getsaveddeals/`, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const updateDealBookmark = async (data, cb) => {
  const headers = getHeaders()
  await axios
    .patch(`${BASE_URL}/livedealbookmark/`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}

export const postprofil = async (formdata, cb) => {
  const headers = getHeaders('form')
  await axios
    .post(`${BASE_URL}/profileSetup/`, formdata, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
