import React, { useState } from 'react'
import styles from './Support.module.css'
import ErrorIcon from '../../Assets/vector/Error'
import { validatePhone } from '../../utils'
import { toast } from 'react-toastify'
import { sendCallbackReq } from '../../services/contact'

/* File is in use */

const SupportPopup = ({ setShowPopup }) => {
  const [number, setNumber] = useState(null)
  const [phoneErrMsg, setPhoneErrMsg] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validatePhone(number)) return setPhoneErrMsg('Please enter a valid phone number!')
    sendReq(number)
  }

  const sendReq = (phone) => {
    const formData = new FormData()
    formData.append('mobile_num', phone)

    setIsSubmitting(true)
    sendCallbackReq(formData, (err) => {
      setIsSubmitting(false)
      if (err) {
        toast.error('Something Went Wrong!')
      } else {
        setShowPopup(false)
        setNumber(null)
        toast.success('Thank you, our team will contact you soon!')
      }
    })
  }

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.popup_container}>
        <div className={styles.popup_header}>
          <p>Enter your phone number</p>
          <button onClick={() => setShowPopup(false)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.46458 15.5359L15.5356 8.46484" stroke="#6D747A" stroke-width="1.5" stroke-linecap="round" />
              <path d="M8.46458 8.46409L15.5356 15.5352" stroke="#6D747A" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </button>
        </div>
        <form className={styles.request_form}>
          <div className={phoneErrMsg && styles['form-input-err']}>
            <input
              type="text"
              placeholder="+91 - "
              value={number}
              onChange={(e) => {
                setNumber(e.target.value)
                setPhoneErrMsg(null)
              }}
            />
            {phoneErrMsg && (
              <p className={styles['input-err-msg']}>
                <ErrorIcon fill="red" width="15px" height="15px" />
                <span>{phoneErrMsg}</span>
              </p>
            )}
          </div>
          <button className={styles.form_btn} onClick={handleSubmit}>
            {isSubmitting ? 'Sending...' : 'Request a call back'}
          </button>
        </form>
      </div>
    </>
  )
}

export default SupportPopup
