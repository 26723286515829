import React, { useEffect } from "react";
import styles from "./LiveDeals.module.css";
import DealCard from "../DealCard/DealCard";
import useStartupCtx from "../../hooks/useStartupCtx";
import { useState } from "react";

const LiveDeals = () => {
  // There are two live deals files this one is also in use
  const { allstartups } = useStartupCtx();

  const [liveStartups, setLiveStartups] = useState([]);

  useEffect(() => {
    if (allstartups?.length === 0) return setLiveStartups([]);
    const filterby_deadline = allstartups.filter((startup) => {
      const MS_TIME_DIFFERENCE = new Date(startup.deadline).getTime() - new Date().getTime();
      const HOURS_TIME_DIFFERENCE = Math.floor(MS_TIME_DIFFERENCE / 1000 / 60 / 60);
      const DAYS_TIME_DIFFERENCE = Math.floor(HOURS_TIME_DIFFERENCE / 24);
      if (HOURS_TIME_DIFFERENCE <= 0 || !startup?.is_live) {
      } else {
        return startup;
      }
    });
    setLiveStartups(filterby_deadline);
  }, [allstartups]);

  return (
    <div className={styles.livedeals}>
      <div className={styles.title}>Live Deals</div>
      <div className={styles.sub}>{liveStartups.length} Opportunities</div>

      {liveStartups.length > 0 && (
        <div className={styles["deals-contaniner"]}>
          {liveStartups.map((ele, idx) => (
            <DealCard key={ele.id} deal={ele} isClosed={false} />
          ))}
        </div>
      )}
      {liveStartups.length > 10 ? (
        <div className="d-flex justify-content-center">
          <div className="btn btn-view">View more</div>
        </div>
      ) : null}
    </div>
  );
};

export default LiveDeals;
