import React from "react";
import style from "./BlogComponent.module.css";

// Utility function to strip HTML tags
const stripHtmlTags = (str) => {
  return str ? str.replace(/<\/?[^>]+(>|$)/g, "") : "";
};

const BlogComponent = ({ lastBlog }) => {
  const formatDate = (datestamp) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(datestamp).toLocaleDateString("en-US", options);
  };

  return (
    <div className={style["main-blog-container"]}>
      <div className={style["blog-image"]}>
        <img
          src={
            lastBlog?.blog_image
              ? lastBlog.blog_image
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbBznjz44UMQEBvKX3FppyjMFajU47p-7Hs1A8Y91kpA&s"
          }
          alt=""
        />
      </div>
      <div className={style["blog-heading"]}>
        <h1>{lastBlog?.blog_title}</h1>
        <p className={style["blog-short-des"]}>
          {stripHtmlTags(lastBlog?.blog).substring(0, 150)}...
        </p>
      </div>
      <div className={style["author-info"]}>
        <img
          src={lastBlog?.blog_author_image}
          className={style["author-pic"]}
          alt=""
        />
        <div className={style["other-info"]}>
          <p className={style["name"]}>{lastBlog?.blog_author_name}</p>
          <p className={style["date-time"]}>{formatDate(lastBlog?.datestamp)} • 5min read </p>
        </div>
      </div>
    </div>
  );
};

export default BlogComponent;
