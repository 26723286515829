import React from 'react'
import style from './MobileViewClient.module.css'

const MobileViewClients = ({clientlogos}) => {
  return (
   <>
    <div className={style['mobile_view_wrapper']}>
      <div className={style['mobile_view_main']}>
      <h1>Our Top Clients</h1>
       <div className={style['clients_logos']}>
       {clientlogos.map((imgs,index)=>(<img src={imgs} key={index} alt='clients company logo' />))}
       </div>
      </div>

    </div>
   </>
  )
}

export default MobileViewClients