import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import arrow from './assests/Arrow_2.svg'
import styles from './InvestNowProcess.module.css'
import InvestNowContent from '../../components/InvestNowContent/InvestNowContent'
import useStartupCtx from '../../hooks/useStartupCtx'
import useAuthCtx from '../../hooks/useAuthCtx'
import { useEffect } from 'react'
import { getConvenienceFee, getKycDetail } from '../../services/account'
import { toast } from 'react-toastify'

const InvestNowProcess = () => {
  const navigate = useNavigate()

  const { slug } = useParams()
  const { user, setUserKycDetail, convenienceFee, setConvenienceFee } = useAuthCtx()
  const { startupFullDetails } = useStartupCtx()
  const { mainDetail, founders, industryOverview, competitors, financialSynopsis, fundsUsage, swotAnalysis } = startupFullDetails

  useEffect(() => {
    if (!user.user_kyc_completed) return navigate(`/dashboard/deal/${slug}`, { replace: true })
    getKycDetail((err, res) => {
      if (err) return toast.error('There is some issue in KYC, Report the issue'), navigate(`/dashboard/deal/${slug}`, { replace: true })
      setUserKycDetail(res.data)
    })
    //! TODO: Convenience fee using slug
    getConvenienceFee(mainDetail.id, (err, res) => {
      if (err) return setConvenienceFee(0)
      else setConvenienceFee(res.data)
    })
  }, [user])

  useEffect(() => {
    if (Object.keys(mainDetail).length <= 0) return navigate(`/dashboard/deal/${slug}`, { replace: true })
  }, [])

  if (Object.keys(mainDetail).length <= 0) return <div></div>
  return (
    <div id={styles.container}>
      <Link to={-1}>
        <img src={arrow} id={styles.arrow} alt="" style={{marginBottom:'-50px',objectFit:"contain",}}/>
        <div className={styles.mobile_back}>
          <svg width="32" height="24" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.775014 3.649C0.581163 3.84285 0.581163 4.15715 0.775014 4.351L3.93399 7.50998C4.12784 7.70383 4.44214 7.70383 4.63599 7.50998C4.82984 7.31613 4.82984 7.00183 4.63599 6.80798L1.82801 4L4.63599 1.19202C4.82984 0.998169 4.82984 0.683875 4.63599 0.490024C4.44214 0.296174 4.12784 0.296174 3.93399 0.490024L0.775014 3.649ZM16.0176 3.50361L1.12601 3.50361V4.49639L16.0176 4.49639V3.50361Z" fill="#6D747A" />
          </svg>
        </div>
      </Link>

      <div id={styles.investHeadingContainer} className="d-flex justify-content-between" style={{paddingBottom:'27px'}}>
        <div id={styles.investHeading} className="d-flex align-items-center" style={{ gap: '8px' }}>
          <img src={`${mainDetail?.logo.replace('http://', 'https://')}`} alt="logo" className={styles['startup-logo']} />
          <h1>Invest In {mainDetail?.start_up_name}</h1>
        </div>

        <div id={styles.investorType}>
          <h6 style={{color:'#000000',fontSize:'12px',fontWeight:'700'}}>Investing As</h6>
          <select class="form-select" aria-label="Default select example" style={{color:'#6D747A',fontWeight:'400',border:'1px solid #939CA3'}}>
            <option value="0">Individual</option>
          </select>
        </div>
      </div>

      <InvestNowContent />
    </div>
  )
}

export default InvestNowProcess
