import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import hdfc from "../../Assets/images/hdfc.png";
import icici from "../../Assets/images/icici.png";

import styles from "./withdraw.module.css";
const Withdraw = () => {
  const [amount, setamount] = useState(5000);
  const handleLotQtyUpdate = (value) => {
    setamount(value);
  };
  const [scard, setscard] = useState(0);

  return (
    <>
      <div className={styles.w_container}>
        <div className={styles.w_wrap}>
          <h1>Withdraw Money</h1>
          <div className={styles.w_head}>
            <p>
              Withdrawable funds: <span> ₹ 74,800</span>
            </p>
          </div>
          <div className={styles.am_amount}>
            <div className={styles.am_amount_input}>
              <label>₹</label>
              <input type="number" value={amount} onChange={(e) => handleLotQtyUpdate(e.target.value)} />
            </div>
            <div className={styles.pre_amount}>
              <li
                onClick={() => {
                  setamount(5000);
                }}
                className={amount === 5000 ? styles.pre_active : ""}
              >
                25%
              </li>
              <li
                onClick={() => {
                  setamount(10000);
                }}
                className={amount === 10000 ? styles.pre_active : ""}
              >
                50%
              </li>
              <li
                onClick={() => {
                  setamount(20000);
                }}
                className={amount === 20000 ? styles.pre_active : ""}
              >
                100%
              </li>
            </div>
          </div>
          <div className={styles.w_bank}>
            <h2>Credit To Existing Accounts</h2>
            <div className={styles.select_card}>
              <div className={styles.am_cards}>
                <div className={styles.bank_details}>
                  {/* <input
                    type="radio"
                    checked={scard === 0}
                    onChange={() => setscard(0)}
                  /> */}
                  <div className={styles.radio_check} onClick={() => setscard(0)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle
                        cx="12"
                        cy="12"
                        r="4.5"
                        stroke="#939CA3"
                        // stroke={scard === 0 ? "" : "#939CA3;"}
                        fill={scard === 0 ? "#283891" : ""}
                      />
                      <circle cx="12" cy="12" r="11.5" stroke="#939CA3" />
                    </svg>
                  </div>
                  <p>**** - 0837 - **** - 3846</p>
                </div>
                <div className={styles.bank_logo}>
                  <img src={hdfc} />
                </div>
              </div>
              <div className={styles.am_cards}>
                <div className={styles.bank_details}>
                  <div className={styles.radio_check} onClick={() => setscard(1)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle
                        cx="12"
                        cy="12"
                        r="4.5"
                        stroke="#939CA3"
                        // stroke={scard === 0 ? "" : "#939CA3;"}
                        fill={scard === 1 ? "#283891" : ""}
                      />
                      <circle cx="12" cy="12" r="11.5" stroke="#939CA3" />
                    </svg>

                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="5" fill="#283891"/>
<circle cx="12" cy="12" r="11.5" stroke="#283891"/>
</svg> */}
                  </div>
                  <p>**** - 0837 - **** - 3846</p>
                </div>
                <div className={styles.bank_logo}>
                  <img src={icici} />
                </div>
              </div>
            </div>
          </div>
          <Link to="confirm">
            <button>Continue</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
