import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Authcontext } from "./Contexts/Authcontext";
import { Startupdetailcontext } from "./Contexts/Startupdetailcontext";
import { Investcontext } from "./Contexts/Investcontext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ScrollToTop from "./utils/ScrollToTop";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Authcontext>
      <BrowserRouter>
        <Startupdetailcontext>
          <Investcontext>
            <GoogleOAuthProvider clientId="296196156652-l8d3cb3n5vsgvtd0sfoanmgtdd0pv4hb.apps.googleusercontent.com">
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </GoogleOAuthProvider>
          </Investcontext>
        </Startupdetailcontext>
      </BrowserRouter>
    </Authcontext>
  </React.StrictMode>
);
