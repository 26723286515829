import React, { useState } from 'react'
import styles from './contact.module.css'
import { toast } from 'react-toastify'
import { isEmptyField, validateEmail, validatePhone } from '../../utils'
import { sendGetInTouchForm } from '../../services/contact'
import ErrorIcon from '../../Assets/vector/Error'
import Footer from '../../components/Footer/Footer'
import ContactInfo from '../../components/ContactInfo/ContactInfo'

const Contact = () => {
  const [whoAreYou, setWhoAreYou] = useState(1)
  const [toggleBtn, setToggleBtn] = useState('Investor')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const [fullNameErrMsg, setFullNameErrMsg] = useState(null)
  const [emailErrMsg, setEmailErrMsg] = useState(null)
  const [phoneErrMsg, setPhoneErrMsg] = useState(null)
  const [messageErrMsg, setMessageErrMsg] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    const alphabetRegex = /^[A-Za-z]+$/;
    const numberRegex = /^[0-9]+$/;
    if(fullName===""){
      return setFullNameErrMsg("This field is required!")
    }
    if(email===""){
      return setEmailErrMsg("This field is required!")
    }
    if(phone==="" || !numberRegex.test(phone)){
      return setPhoneErrMsg("This field is required! or Enter Valid Number")
    }
    if(message===""){
      return setMessageErrMsg("please enter something")
    }
    if(whoAreYou===0){
      return toast.error("please select who are you");
    }
    // if (!alphabetRegex.test(fullName)) return setFullNameErrMsg('Please enter a valid full name!');
    // if (isEmptyField(fullName)) return setFullNameErrMsg("This field is required!");
    // if (isEmptyField(email)) return setEmailErrMsg("This field is required!");
    // if (!validateEmail(email)) return setEmailErrMsg("Please enter a valid email!");
    // if (isEmptyField(phone)) return setPhoneErrMsg("This field is required!");
    // if (!validatePhone(phone)) return setPhoneErrMsg("Please enter a valid phone number!");
    // if (!validateEmail(email)) return setEmailErrMsg("Please enter a valid email!");
    // if (message==="") return setmessageErrMsg("please enter something")
    // if(whoAreYou === 1) return setWhoErrMsg("Please select who are you !")

    const formData = new FormData();
    formData.append("full_name", fullName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);
    formData.append("who_are_you", whoAreYou);

    setIsSubmitting(true);
    sendGetInTouchForm(formData, (err, res) => {
      setIsSubmitting(false);
      if (err) return toast.error("Something went wrong!");
      toast.info("Thank you, our team will contact you soon!");
      setFullName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setWhoAreYou(null);
    });
  };


  /* For Dropdown Menu */
  const [showMenu, setShowMenu] = useState(false)

  return (
    <>
      <div className={styles.contact} >
        <ContactInfo />

        <div className={styles.contact_container}>
          <h1 className={styles.contact_header}>Send us a message</h1>
          <form className={styles.form}>
            <div className={styles['who-are-you']}>
              <label className={styles['who-label']}>Who are you?</label>
              <div className={styles['who-are-you-dropdown']} id={`${styles.padding_left_none}`}>
                <button type="button" onClick={() => setShowMenu(!showMenu)}>
                  {toggleBtn}
                  <div>
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55824 5.76036L5 5.18182L4.44176 5.76036C4.75007 6.07988 5.24993 6.07988 5.55824 5.76036ZM5 4.02473L1.34772 0.23964C1.03941 -0.0798802 0.53954 -0.0798802 0.231231 0.23964C-0.0770772 0.559159 -0.0770772 1.0772 0.231231 1.39672L4.44176 5.76036L5 5.18182L5.55824 5.76036L9.76877 1.39672C10.0771 1.0772 10.0771 0.559159 9.76877 0.23964C9.46046 -0.0798802 8.96059 -0.0798802 8.65228 0.23964L5 4.02473Z" fill="#6D747A" />
                    </svg>
                  </div>
                </button>

                <ul className={`${styles.dropdown_menu} ${showMenu ? styles.show : ''}`}>
                  <li
                    onClick={(e) => {
                      setWhoAreYou(1)
                      setToggleBtn('Investor')
                      setShowMenu(!showMenu)
                    }}
                  >
                    Investor
                  </li>
                  <li
                    onClick={(e) => {
                      setWhoAreYou(2)
                      setToggleBtn('Startup Founder')
                      setShowMenu(!showMenu)
                    }}
                  >
                    Startup Founder
                  </li>
                  <li
                    onClick={(e) => {
                      setWhoAreYou(3)
                      setToggleBtn('Others')
                      setShowMenu(!showMenu)
                    }}
                  >
                    Others
                  </li>
                </ul>
              </div>
            </div>

            <div id={`${styles.padding_left_none}`}>
              <label htmlFor="fullName">Full Name</label>
              <div className={fullNameErrMsg && styles['form-input-err']}>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={fullName}
                  placeholder="Enter your full name"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setFullName(e.target.value)
                    setFullNameErrMsg(null)
                  }}
                />
                {fullNameErrMsg && (
                  <p className={styles['input-err-msg']}>
                    <ErrorIcon fill="red" width="15px" height="15px" />
                    <span>{fullNameErrMsg}</span>
                  </p>
                )}
              </div>
            </div>

            <div className={styles.input_container}>
              <div id={`${styles.padding_left_none}`}>
                <label htmlFor="email">Email</label>
                <div className={emailErrMsg && styles['form-input-err']}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Enter your email Id"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setEmailErrMsg(null)
                    }}
                  />
                  {emailErrMsg && (
                    <p className={styles['input-err-msg']}>
                      <ErrorIcon fill="red" width="15px" height="15px" />
                      <span>{emailErrMsg}</span>
                    </p>
                  )}
                </div>
              </div>

              <div id={`${styles.padding_left_none}`}>
                <label htmlFor="phone">Phone Number</label>
                <div className={phoneErrMsg && styles['form-input-err']}>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={phone}
                    maxLength={10}
                    placeholder="Enter your phone number"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setPhone(e.target.value)
                      setPhoneErrMsg(null)
                    }}
                  />
                  {phoneErrMsg && (
                    <p className={styles['input-err-msg']}>
                      <ErrorIcon fill="red" width="15px" height="15px" />
                      <span>{phoneErrMsg}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div id={`${styles.padding_left_none}`}>
              <label htmlFor="message">Message</label>
              <div className={messageErrMsg && styles['form-input-err']}>
                <textarea
                  id="message"
                  cols="30"
                  rows="5"
                  placeholder="Enter your message here..."
                  onChange={(e) => {
                    setMessage(e.target.value)
                    setMessageErrMsg(null)
                  }}
                  value={message}
                ></textarea>
                {messageErrMsg && (
                  <p className={styles['input-err-msg']}>
                    <ErrorIcon fill="red" width="15px" height="15px" />
                    <span>{messageErrMsg}</span>
                  </p>
                )}
              </div>
            </div>

            <div id={`${styles.padding_left_none}`}>
              <button className={styles.submitBtn} disabled={isSubmitting} onClick={handleSubmit}>
                {isSubmitting ? 'Sending...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Contact
