import React, { useEffect, useState } from 'react'
import styles from './MyInvestments.module.css'
import searchIcon from './../../Assets/icons/search.png'
import StartExplore from '../StartExplore/StartExplore'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { investContext } from '../../Contexts/Investcontext'
import { Link } from 'react-router-dom'
import { getinvest } from '../../services/startup'
import { formatINR } from '../../utils'
const MyInvestments = () => {
  const { myinv } = useContext(investContext)

  const [options, setOptions] = useState('Investment')
  const [viewmore, setviewmore] = useState(true)
  // const navigate = useNavigate()
  // const [investmentType, setInvestmentType] = useState('Investment type')

  /************************************************************ */
  const [invest, setinvest] = useState([])
  const [loadingLoader, setLoadingLoader] = useState(true)

  // const getHeaders = () => {
  //   const token = localStorage.getItem("access_token");
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   if (token) headers["Authorization"] = `Bearer ${token}`;
  //   return headers;
  // };

  // const headers = getHeaders();
  // // console.log("from " + headers);

  // const getinvest = async (cb) => {
  //   const headers = getHeaders();
  //   await axios
  //     .get(`${domain}/myinvesments/`, { headers })
  //     .then((res) => cb(null, res))
  //     .catch((err) => cb(err, null));
  // };

  useEffect(() => {
    getinvest((err, res) => {
      if (err) return
      setinvest(res.data)
      setLoadingLoader(false)
      if (res.data.length < 7) {
        setviewmore(false)
      }
    })
  }, [])
  // console.log('from investment page: ' + JSON.stringify(invest))

  /*********************************************************** */
  const setArrow = () => {
    // document.getElementsByClassName("dropdown-toggle")[1].classList.contains("show") ? window.getComputedStyle(document
    //         .getElementsByClassName("dropdown-toggle")[1], '::after').getPropertyValue('transform') = 'rotate(0)': window.getComputedStyle(document
    //             .getElementsByClassName("dropdown-toggle")[1], '::after').getPropertyValue('transform') = 'rotate(90deg)'

    if (document.getElementsByClassName('dropdown-toggle')[1].classList.contains('show')) {
      // // window.getComputedStyle(document.getElementsByClassName("dropdown-toggle")[1], '::after').getPropertyValue('transform') = 'rotate(0)'
      // // window
      // //     .getComputedStyle(
      // //         document.getElementsByClassName("dropdown-toggle")[1],
      // //         "::after"
      // //     )
      // //     .getPropertyValue("transform", "rotate(0)");
      // document
      //     .getElementsByClassName("dropdown-toggle")[1].after(st)
    }
  }

  //     const investments = [
  //     {
  //         image: uberEats,
  //         name: "Uber Eats",
  //         category: "Fintech, Health",
  //         date: "13/10/2022",
  //         sharesNumber: "1200",
  //         status: "550%",
  //         invested: "1,40,000",
  //     },
  //     {
  //         image: polygon,
  //         name: "Polygon",
  //         category: "Blockchain, web2",
  //         date: "13/10/2022",
  //         sharesNumber: "675",
  //         status: "550%",
  //         invested: "1,10,000",
  //     },
  //     {
  //         image: cred,
  //         name: "CRED",
  //         category: "Fintech",
  //         date: "13/10/2022",
  //         sharesNumber: "960",
  //         status: "85%",
  //         invested: "2,45,000",
  //     },
  // ];

  // const selectInvestment = () => {
  //     navigate("/dashboard/my-investment-portal");
  // };
  var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  const setdate = (val) => {
    const _date = new Date(val)
    const D = `${_date.getDate()} ${mS[_date.getMonth()]} ${_date.getFullYear()}`
    return D
  }
  if (invest.length === 0) {
    return (
      <>
        {loadingLoader ? (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', background: 'rgba(221, 221, 221, 0)', display: 'block' }} width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx={50} cy={50} fill="none" stroke="#000" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
            </circle>
          </svg>
        ) : (
          <StartExplore heading="Currently, there are no Investment done." para="Invest now, what are you waiting for?" />
        )}
      </>
    )
  }

  return (
    <>
      <div id={styles.investment} >
        {/* <Alert /> */}
        <div id={styles.investmentHeader} style={{paddingLeft:'35px'}}>
          <h1>My Investments</h1>
          <h6 className="col-4">venture buddy offers a variety of investment opportunities.</h6>
        </div>
        <div id={styles.search}>
          <div className="d-flex justify-content-between">
            <div id={styles.searchInput}>
              <img src={searchIcon} alt="searchIcon" id={styles.searchIcon} />

              <input className="rounded-pill" type="text" placeholder="Search for invested startups" />
            </div>
            <div id={styles.sort}>
              <h5 id={styles.sortHead}>sort:</h5>
              <div className="dropdown">
                <div className="bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id={styles.sortList}>
                  {options}
                </div>
                <ul className="dropdown-menu">
                  <li onClick={() => setOptions('Investment 1')}>Investment 1</li>
                  <li onClick={() => setOptions('Investment 2')}>Investment 2</li>
                  <li onClick={() => setOptions('Investment 3')}>Investment 3</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* invest now new */}
        <div className={styles.investTabel}>
          <table>
            <tr>
              <th style={{ width: '158px' }}>Date of Investment</th>
              <th style={{ width: '158px', textAlign: 'start' }}>Company Name</th>
              <th style={{ width: '226px' }}>Type of Instrument</th>
              <th style={{ width: '118px' }}>No. of lots</th>
              <th style={{ width: '134px' }}>Amount Invested</th>
              <th style={{ width: '145px' }}>Company Valuation</th>
            </tr>
            {invest.map((val, ind) => (
              <tr>
                <td style={{ maxWidth: '158px' }} className={styles.date}>
                  {setdate(val?.date)}
                </td>
                <td style={{ maxWidth: '194px' }}>
                  <Link to={`/dashboard/my-investment/${val?.id}`}>
                    <div className={` ${styles.tabelcompanyName}`}>
                      <img src={val?.logo} />
                      <h5>{val?.start_up_name}</h5>
                    </div>
                  </Link>
                </td>
                <td style={{ maxWidth: '226px' }} className={styles.insturment}>
                  {val?.type_of_instrument}
                </td>
                <td style={{ maxWidth: '118px' }} className={styles.lots}>
                  {val?.lots}
                </td>
                <td style={{ maxWidth: '134px' }} className={styles.investment}>
                  {formatINR(val?.invested_amount)}
                </td>
                <td style={{ maxWidth: '145px' }} className={styles.valuations}>
                  ₹ {formatINR(val?.company_valuation)}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className={styles.investTabelPhone}>
          {invest.map((val, ind) =>
            viewmore ? (
              ind < 5 ? (
                <div className={styles.investTabelPhoneWraper} key={ind}>
                  <Link to={`/dashboard/my-investment/${val?.id}`} className={styles.mobCompany}>
                    <img src={val?.logo} />
                    <h5>{val?.start_up_name}</h5>
                  </Link>
                  <div className={styles.phoneInvestAmount}>
                    <p>₹ {formatINR(val?.company_valuation)}</p>
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              <div className={styles.investTabelPhoneWraper} key={ind}>
                <div>
                  <Link to={`/dashboard/my-investment/${val?.id}`} className={styles.mobCompany}>
                    <img src={val?.logo} />
                    <h5>{val?.start_up_name}</h5>
                  </Link>
                </div>
                <div className={styles.phoneInvestAmount}>
                  <p>₹ {formatINR(val?.company_valuation)}</p>
                </div>
              </div>
            )
          )}
          <div className={styles.btnWraper}>
            {viewmore ? (
              <button
                onClick={() => {
                  setviewmore(false)
                }}
                className={styles.viewmore}
              >
                viewmore
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* <div id={styles.arrowContainer}>
        <div id={styles.leftArrow} className={styles.navigationArrow}>
          <img src={arrow} alt="" />
        </div>
        <h6>1/1</h6>
        <div id={styles.rightArrow} className={styles.navigationArrow}>
          <img src={arrow} alt="" />
        </div>
      </div> */}
      </div>
    </>
  )
}

export default MyInvestments
