import axios from "axios";
import React, { useEffect, useState } from "react";
import ErrorIcon from "../../Assets/vector/Error";
import { generateOtp, verifyOtp } from "../../services/account";
import styles from "./RegisterVerification.module.css";

const RegisterVerification = ({ currentRegisterProgress, setCurrentRegisterProgress, registerInputData }) => {
  // Note This file is in use
  const [digitOne, setDigitOne] = useState("");
  const [digitTwo, setDigitTwo] = useState("");
  const [digitThree, setDigitThree] = useState("");
  const [digitFour, setDigitFour] = useState("");

  const [otpErrorMsg, setOtpErrorMsg] = useState(null);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [resendBtnLoading, setResendBtnLoading] = useState(false);

  const [timer, setTimer] = useState(30);

  const resendOtp = () => {
    setResendBtnLoading(true);
    generateOtp(registerInputData[registerInputData.method], (err, res) => {
      setResendBtnLoading(false);
      if (err) return alert.error("Something went wrong!");
      setTimer(60);
    });
  };

  const checkOTP = (e) => {
    e.preventDefault();
    const otp = String(digitOne) + String(digitTwo) + String(digitThree) + String(digitFour);
    if (otp.length < 4) return setOtpErrorMsg("Please enter a valid PIN!");

    setSubmitBtnLoading(true);
    const formData = new FormData();
    formData.append("otp", otp);
    verifyOtp(registerInputData[registerInputData.method], formData, (err, res) => {
      setSubmitBtnLoading(false);
      if (err) return setOtpErrorMsg("Please enter a valid PIN!");
      if (res.status === 200) {
        setCurrentRegisterProgress(currentRegisterProgress + 1);
      }
    });
  };

  useEffect(() => {
    if (currentRegisterProgress !== 1) return;
    document.getElementById("digit1").focus();
  }, [currentRegisterProgress]);

  //   useEffect(() => {
  //     if (error) {
  //       for (const items of document.getElementsByClassName("otp")) {
  //         items.style.color = "#FF2A2A";
  //         items.style.border = "1px solid #FF2A2A";
  //       }
  //     } else {
  //       for (const items of document.getElementsByClassName("otp")) {
  //         items.style.border = "1px solid #d9d9d9";
  //         items.style.color = "#d9d9d9";
  //       }
  //     }
  //   }, [error]);

  const handleDigitOne = (e) => {
    if (e !== null) {
      document.getElementById("digit1").style.border = "1px solid #283891";
      document.getElementById("digit1").style.color = "#283891";
      document.getElementById("digit2").focus();
      document.getElementById("smallDigit1").style.border = "1px solid #283891";
      document.getElementById("smallDigit1").style.color = "#283891";
      document.getElementById("smallDigit2").focus();
    } else {
      document.getElementById("digit1").style.border = "1px solid #d9d9d9";
      document.getElementById("smallDigit1").style.border = "1px solid #d9d9d9";
    }
  };
  const handleDigitTwo = (e) => {
    if (e !== null) {
      document.getElementById("digit2").style.border = "1px solid #283891";
      document.getElementById("digit2").style.color = "#283891";
      document.getElementById("digit3").focus();
      document.getElementById("smallDigit2").style.border = "1px solid #283891";
      document.getElementById("smallDigit2").style.color = "#283891";
      document.getElementById("smallDigit3").focus();
    } else {
      document.getElementById("digit1").focus();
      document.getElementById("digit2").style.border = "1px solid #d9d9d9";
      document.getElementById("smallDigit1").focus();
      document.getElementById("smallDigit2").style.border = "1px solid #d9d9d9";
    }
  };
  const handleDigitThree = (e) => {
    if (e !== null) {
      document.getElementById("digit3").style.border = "1px solid #283891";
      document.getElementById("digit3").style.color = "#283891";
      document.getElementById("digit4").focus();
      document.getElementById("smallDigit3").style.border = "1px solid #283891";
      document.getElementById("smallDigit3").style.color = "#283891";
      document.getElementById("smallDigit4").focus();
    } else {
      document.getElementById("digit2").focus();
      document.getElementById("digit3").style.border = "1px solid #d9d9d9";
      document.getElementById("smallDigit2").focus();
      document.getElementById("smallDigit3").style.border = "1px solid #d9d9d9";
    }
  };
  const handleDigitFour = (e) => {
    if (e === null) {
      document.getElementById("digit3").focus();
      document.getElementById("digit4").style.border = "1px solid #d9d9d9";
      document.getElementById("smallDigit3").focus();
      document.getElementById("smallDigit4").style.border = "1px solid #d9d9d9";
    } else {
      document.getElementById("digit4").style.border = "1px solid #283891";
      document.getElementById("digit4").style.color = "#283891";
      document.getElementById("smallDigit4").style.border = "1px solid #283891";
      document.getElementById("smallDigit4").style.color = "#283891";
    }
  };

  useEffect(() => {
    if (currentRegisterProgress !== 1) return;
    if (timer <= 0) return setTimer(0);
    setTimeout(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  }, [timer, currentRegisterProgress]);

  return (
    <>
      {/* For Desktop  */}
      <div className="text-dark d-none d-lg-block" id={styles.verifyEmail}>
        <h1 className="my-0">Signup</h1>
        <h6 className="pb-2">Enter Verification Code</h6>
        <p className="col-10 pb-2 mt-0">
          We have sent a verification code to{" "}
          <span className="text-dark">
            {registerInputData[registerInputData.method]?.slice(0, 5)}&times;&times;&times;
            {registerInputData[registerInputData.method]?.slice(registerInputData[registerInputData.method]?.length - 3, registerInputData[registerInputData.method]?.length)}
          </span>
        </p>

        <form onSubmit={checkOTP}>
          <div className={`row row-cols-4 justify-content-between px-3 ${otpErrorMsg && "form-input-err"}`}>
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitOne(e.target.value);
                handleDigitOne(e.nativeEvent.data);
              }}
              id="digit1"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitTwo(e.target.value);
                handleDigitTwo(e.nativeEvent.data);
              }}
              id="digit2"
              className={` ${styles.otp}`}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitThree(e.target.value);
                handleDigitThree(e.nativeEvent.data);
              }}
              id="digit3"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitFour(e.target.value);
                handleDigitFour(e.nativeEvent.data);
              }}
              id="digit4"
              className={styles.otp}
            />
          </div>
          {otpErrorMsg && (
            <p className={`input-err-msg`}>
              <ErrorIcon fill="red" width="15px" height="15px" />
              <span>{otpErrorMsg}</span>
            </p>
          )}

          <input type="submit" disabled={submitBtnLoading} className={`btn-blue w-100 `} onClick={checkOTP} value={submitBtnLoading ? "VERIFYING..." : "NEXT"} />

          {/* <button className="carousel-control-next d-none" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next" id="next"></button> */}
        </form>

        <p className="text-end w-100" id={styles.timer}>
          {timer === 0 ? (
            <button
              disabled={resendBtnLoading}
              className="text-primary py-1 px-2 mt-0 cursor-pointer"
              style={{ border: "none", background: "none", fontSize: 18, opacity: resendBtnLoading ? 0.4 : 1 }}
              onClick={resendOtp}
            >
              {resendBtnLoading ? "Sending.." : "Resend code"}
            </button>
          ) : (
            <span id={styles.resend}>Resend code in {timer} sec</span>
          )}
        </p>
      </div>

      {/* For mobile */}
      <div className="text-dark d-lg-none bg-white px-2" id={styles.verifyEmail}>
        <h1 className="my-0">Signup</h1>
        <h6>Enter Verification Code</h6>
        <p className="col-10">
          We have sent a verification code to{" "}
          <span className="text-dark">
            {registerInputData[registerInputData.method]?.slice(0, 3)}XXX
            {registerInputData[registerInputData.method]?.slice(registerInputData[registerInputData.method]?.length - 3, registerInputData[registerInputData.method]?.length)}
          </span>
        </p>

        <form onSubmit={checkOTP}>
          <div className={`row row-cols-4 justify-content-between px-3 ${otpErrorMsg && "form-input-err"}`}>
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitOne(e.target.value);
                handleDigitOne(e.nativeEvent.data);
              }}
              id="smallDigit1"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitTwo(e.target.value);
                handleDigitTwo(e.nativeEvent.data);
              }}
              id="smallDigit2"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitThree(e.target.value);
                handleDigitThree(e.nativeEvent.data);
              }}
              id="smallDigit3"
              className={styles.otp}
            />
            <input
              type="text"
              maxLength={1}
              onChange={(e) => {
                setOtpErrorMsg(null);
                setDigitFour(e.target.value);
                handleDigitFour(e.nativeEvent.data);
              }}
              id="smallDigit4"
              className={styles.otp}
            />
          </div>

          {otpErrorMsg && (
            <p className={`input-err-msg`}>
              <ErrorIcon fill="red" width="15px" height="15px" />
              <span>{otpErrorMsg}</span>
            </p>
          )}
          <p className="text-end w-100 " id={styles.timer}>
            {timer === 0 ? (
              <button className="text-primary py-1 px-2 mt-3 cursor-pointer" style={{ border: "none", background: "none", fontSize: 18, opacity: resendBtnLoading ? 0.4 : 1 }} onClick={resendOtp}>
                {resendBtnLoading ? "Sending.." : "Resend code"}
              </button>
            ) : (
              <span id={styles.resend}>Resend code in {timer} sec</span>
            )}
          </p>

          <input type="submit" disabled={submitBtnLoading} className={`btn-blue mt-0 w-100 }`} onClick={checkOTP} value={submitBtnLoading ? "VERIFYING..." : "NEXT"} />

          {/* <button className="carousel-control-next mt-3" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next" id="next"></button> */}
        </form>
      </div>
    </>
  );
};

export default RegisterVerification;