import React from 'react'
import style from './blogslider.module.css'
import Arrow from '../pitchdeck/pich_assest/arrow.svg'
import { useNavigate } from 'react-router-dom'
import BlogComponent from '../../../components/BlogComponent/BlogComponent';
const BlogsCards = ({item}) => {
   const navigate = useNavigate()
   const handleviewmore = (slug)=>{
     navigate(`/blog/${slug}`)
   }
  return (
   <>
   <div className={style['blog_card']}>
     <div className={style['blog_tags']}>
        <p>Tags</p>
        <p>New Technology</p>
     </div>
     <div className={style['blogs_image']}>
        <img src={item?.blog_image===null?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbBznjz44UMQEBvKX3FppyjMFajU47p-7Hs1A8Y91kpA&s":item?.blog_image}/>
     </div>
     <div className={style['blogs_detail']}>
        <h1>{item.blog_title}</h1>
        <br/>
        <h2>10 min read</h2>
        <h3>12 February, 2024 </h3>
        
        <button  onClick={()=>handleviewmore(item?.slug)} style={{paddingLeft:'25px'}}>Read more <img src={Arrow} style={{marginBottom:'3px'}}/></button>
     </div>
   </div>

   </>
  )
}

export default BlogsCards