import { useState } from 'react'
import OTP from '../../components/OTP/OTP'
import loginImage from './../../Assets/images/login-image.png'
import LoginPass from '../../components/LoginPass/LoginPass'
import LoginEmail from '../../components/LoginEmail/LoginEmail'
import styles from './Login.module.css'
import { getLoginToken } from '../../services/account'
import useAuthCtx from '../../hooks/useAuthCtx'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
const Login = () => {
  const navigate = useNavigate()
  const { isLoggedIn, setIsLoggedIn } = useAuthCtx()
  const location = useLocation()
  // I am only importing retry options instead of whole retry function because it was creating some issue during register

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard', { replace: true })
    }
  }, [isLoggedIn])

  const [loginData, setLoginData] = useState({
    username: '',
    type: 'password', // VALUE -> password/otp
    password: '',
    otp: '',
  })

  const handleLogin = async () => {
    const formData = new FormData()
    formData.append('username', loginData.username)

    if (loginData.type === 'password') {
      formData.append('grant_type', 'password')
      formData.append('password', loginData.password)
    } else if (loginData.type === 'otp') {
      formData.append('grant_type', 'otp')
    }
    await getLoginToken(formData, (err, res) => {
      if (err) {
        if (err.response.data.errors === 'check your password') {
          return toast.error('Incorrect Username or Password!')
        }
      }
      else{
        localStorage.setItem('access_token', res?.data?.access_token)
        localStorage.setItem('user_id',res?.data?.id)
        setIsLoggedIn(true)
        navigate(location?.state?.to || '/dashboard', {
          replace: true,
        })
      }
    })
  }
  const [currentLoginProgress, setCurrentLoginProgress] = useState(0)

  return (
    <div className="container" id={`${styles.main_login_container}`} style={{marginTop:'50px'}}>
      <div className="row login-form mx-auto my-2 col-12 col-lg-8">
        <div className="text-center col-5 d-none d-md-block" id={styles.loginLeft}>
          <p className="text-white mx-auto col-9">“Don't shoot your shot, without testing your aim”</p>
          <img src={loginImage} id={styles.loginImage} className="mx-auto col-6" alt="" />
        </div>

        <div className="col-12 col-md-7" id={styles.loginRight}>
          <div id="carouselExampleControls" className="carousel slide" data-loop="false" data-interval="false">
            <div className="carousel-inner">
              <div className={`carousel-item ${currentLoginProgress === 0 && 'active'}`} id={styles.carouselItem}>
                <LoginEmail loginData={loginData} setLoginData={setLoginData} currentLoginProgress={currentLoginProgress} setCurrentLoginProgress={setCurrentLoginProgress}></LoginEmail>
              </div>
          
              <div className={`carousel-item ${currentLoginProgress === 1 && loginData.type === 'password' && 'active'}`} id={styles.carouselItem}>
                <LoginPass loginData={loginData} setLoginData={setLoginData} currentLoginProgress={currentLoginProgress} setCurrentLoginProgress={setCurrentLoginProgress} handleLogin={handleLogin}></LoginPass> 
              </div>
              
              <div className={`carousel-item ${currentLoginProgress === 1 && loginData.type === 'otp' && 'active'}`} id={styles.carouselItem}>
                <OTP loginData={loginData} setLoginData={setLoginData} currentLoginProgress={currentLoginProgress} setCurrentLoginProgress={setCurrentLoginProgress} handleLogin={handleLogin}></OTP>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
