import React, { useState, useEffect } from 'react'
import styles from './InvestNowContent.module.css'
import { Modal, Form } from 'react-bootstrap'
import useStartupCtx from '../../hooks/useStartupCtx'
import useAuthCtx from '../../hooks/useAuthCtx'
import { createInvestment, getPaymentUrl } from '../../services/startup'
import { toast } from 'react-toastify'
import { formatINR, markupText } from '../../utils'
import ErrorIcon from '../../Assets/vector/Error'
import ToolTip from '../ToolTip/ToolTip'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import backbtn from './assests_invest/Cross.svg'
import { postPromoCode } from '../../services/startup'
const InvestNowContent = () => {
  // Note This file is in use
  const { userKycDetail, convenienceFee } = useAuthCtx()

  const { startupFullDetails } = useStartupCtx()
  const numberFormateInr = new Intl.NumberFormat('en-IN', { currency: 'INR' })
  const { mainDetail } = startupFullDetails
  const [lotQty, setLotQty] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)

  const [totalInvestInputErrMsg, setTotalInvestInputErrMsg] = useState(null)

  const [ShowModal1, setShowModal1] = useState(false)
  const [ShowModal2, setShowModal2] = useState(false)
  const [ShowmPromoCodeModal, setShowPromoCodeModal] = useState(false)
  const [ShowModal3, setShowModal3] = useState(false)

  const [emailErrMsg, setEmailErrMsg] = useState(null)
  const [phoneErrMsg, setPhoneErrMsg] = useState(null)
  const [dateErrMsg, setDateErrMsg] = useState(null)

  const [submitBtnLoading, setSubmitBtnLoading] = useState(false)

  const handleSubmit1 = () => {
    setShowModal1(true)
  }
  const handleSubmit2 = () => {
    setShowModal1(false)
    setShowModal2(true)
  }
  const handleSubmit3 = () => {
    setShowModal1(false)
    setShowModal2(false)
    setShowModal3(true)
  }
  const CloseAllModalAtOnce = () => {
    setShowModal1(false)
    setShowModal2(false)
    setShowModal3(false)
    setShowPromoCodeModal(false)
  }
  const handleTotalInvestmentUpdate = (value) => {
    if (!value.match(/^\d*$/)) return
    setTotalInvestInputErrMsg(null)
    const newTotalAmount = parseInt(value, 10)
    setTotalAmount(isNaN(newTotalAmount) ? 0 : newTotalAmount)
    const calculatedLotQty = Math.floor(newTotalAmount / mainDetail.lot_price)
    setLotQty(calculatedLotQty)
  }

  const handleLotQtyUpdate = (value) => {
    if (!value.match(/^\d*$/)) return
    setTotalInvestInputErrMsg(null)
    const newLotQty = parseInt(value, 10)
    setLotQty(newLotQty)
    const newTotalAmount = newLotQty * mainDetail.lot_price
    setTotalAmount(isNaN(newTotalAmount) ? 0 : newTotalAmount)
  }
  useEffect(() => {
    if (totalAmount < mainDetail.min_investment) return setTotalInvestInputErrMsg(`Minimum investment amount is ${numberFormateInr.format(mainDetail.min_investment)}`)
    if (totalAmount > mainDetail.max_investment) return setTotalInvestInputErrMsg(`Maximum investment amount is ${numberFormateInr.format(mainDetail.max_investment)}`)
  }, [totalAmount])

  /* promocode*/
  const [promocode, setpromocode] = useState()
  const [promo_code, setpromo_code] = useState('')
  const [promocodeerr, setpromocodeerr] = useState(false)
  const [errmsg, seterrmsg] = useState('')
  const handleSubmitPromocode = () => {
    const formData = new FormData()
    formData.append('promo_code', promocode)
    formData.append('startup_id', mainDetail?.id)
    postPromoCode(formData, (err, res) => {
      if (err) {
        setpromocode('')
        setpromocodeerr(true)
        return
      } else if (!res?.data?.success) {
        setpromocodeerr(true)
        seterrmsg(res?.data?.message)
      } else if (res) {
        setpromo_code(promocode)
        setShowPromoCodeModal(false)
        setShowModal2(true)
        return
      }
    })
  }

  const submitAndProceed = async () => {
    setSubmitBtnLoading(true)
    const investData = {
      invest_on: mainDetail.id,
      lot_quantity: lotQty,
      lot_price: mainDetail.lot_price,
      legal_name: `${userKycDetail.first_name}${userKycDetail.middle_name && ' ' + userKycDetail.middle_name} ${userKycDetail.last_name}`,
      email: userKycDetail.email,
      pan: userKycDetail.pan_card_number,
      dob: userKycDetail.dob,
      phone: userKycDetail.phone,
      promo_code: promo_code,
    }
    await createInvestment(investData, (err, res) => {
      // console.log('this is response investment', res)
      if (err) {
        toast.error('Something went wrong!')
        setSubmitBtnLoading(false)
        return
      }
    })
    await getPaymentUrl((err, res) => {
      if (err || res.data.status !== 200) {
        toast.error('Something went wrong!')
        setSubmitBtnLoading(false)
        return
      }
      window.open(res.data.payment_link, '_self')
    })
  }
  const { width } = useWindowDimensions()

  /* New Date fomate */
  let options = { year: 'numeric', month: 'long', day: 'numeric' }
  const dateFormat = new Intl.DateTimeFormat('en-GB', options)
  return (
    <div className="row row-cols-3" id={styles.investNowContent}>
      <>
        <section id={styles['aboutPortal']} style={{ width: '430px' }}>
          <h1>About {mainDetail.start_up_name}</h1>
          <img src={`${mainDetail.start_up_pic.replace('http://', 'https://')}`} alt="" className="img-fluid" />
          <p style={{ color: '#6D747A', fontWeight: '400' }} dangerouslySetInnerHTML={{ __html: markupText(mainDetail?.about_startup_long, '\r\n', 'br') }}></p>
        </section>
        <section id={styles['lotContainer']}>
          <div id={styles['lots']} style={{ borderRadius: '20px', width: 'fit', height: '472px', }}>
            <label htmlFor="lotQuantity">Quantity of Lots</label>
            <input id="lotQuantity" type="number" min={1} autoComplete="off" className="form-control" value={lotQty} onChange={(e) => handleLotQtyUpdate(e.target.value)} />
            <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '3px' }}>
              <div id={styles['recommended']}>
                <p style={{ cursor: 'default' }}>Recommended: </p>
                <p style={{ cursor: 'pointer' }} onClick={() => handleLotQtyUpdate('5')}>
                  5
                </p>
                <p style={{ cursor: 'pointer' }} onClick={() => handleLotQtyUpdate('10')}>
                  10
                </p>
                <p style={{ cursor: 'pointer' }} onClick={() => handleLotQtyUpdate('20')}>
                  20
                </p>
              </div>
            </div>
            <label style={{ marginTop: '20px' }} htmlFor="total-investment">
              Total Investment Amount
            </label>
            <div className={`${totalInvestInputErrMsg && 'form-input-err'}`}>
              <input id="total-investment" type="text" className={`form-control ${totalInvestInputErrMsg ? 'form-input-err' : ''}`} value={totalAmount} onChange={(e) => handleTotalInvestmentUpdate(e.target.value)} />
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <p style={{ color: '#676879', fontWeight: '400', fontSize: '14px', display: 'flex', gap: '15px', zIndex: '9', }}>
                  <span>Quick Selection (₹): </span>{' '}
                  <span style={{ cursor: 'pointer' }} onClick={() => handleTotalInvestmentUpdate('50000')}>
                    50,000
                  </span>{' '}
                  <span style={{ cursor: 'pointer' }} onClick={() => handleTotalInvestmentUpdate('100000')}>
                    100,000
                  </span>{' '}
                  <span style={{ cursor: 'pointer' }} onClick={() => handleTotalInvestmentUpdate('200000')}>
                    200,000
                  </span>
                </p>
              </div>
              {totalInvestInputErrMsg && (
                <p className={`input-err-msg`}>
                  <ErrorIcon fill="red" width="15px" height="15px" />
                  <span>{totalInvestInputErrMsg}</span>
                </p>
              )}
            </div>
            <label style={{ marginTop: '20px' }} htmlFor="lotValue">
              Lot Value
            </label>
            <input id="lotPrice" type="text" className="form-control" disabled value={mainDetail.lot_price} />

            <button id={styles['investButton']} className="btn-blue " disabled={totalAmount < mainDetail.min_investment || totalAmount > mainDetail.max_investment || lotQty === 0 ? true : false} onClick={handleSubmit1}>
              INVEST NOW
            </button>
          </div>
        </section>
        <section id={styles['dealContainer']}>
          <div id={styles['dealTerms']} style={{ paddingLeft: '18px', paddingRight: '18px', width: '100%' }}>
            <h2 id={styles['termsHeader']}>Deal Terms</h2>

            <div className={styles['termsText']}>
              <div className={`d-flex justify-content-between ${styles['termTextContainer']}`}>
                <h6>Minimum Investment</h6>
                <div className={styles['hidden_mob']}>
                  <ToolTip data={'Minimum investment required from each individual or single investor'} bottom left />
                </div>
              </div>
              <h2>
                <span className="rupee">₹</span>
                {numberFormateInr.format(mainDetail.min_investment)}
              </h2>
            </div>

            <div className={styles['termsText']}>
              <div className={`d-flex justify-content-between ${styles['termTextContainer']}`}>
                <h6>Maximum Investment</h6>
                <div className={styles['hidden_mob']}>
                  <ToolTip data={'Maximum investment required from each individual or single investor'} bottom left />
                </div>
              </div>
              <h2>
                <span className="rupee">₹</span>
                {numberFormateInr.format(mainDetail.max_investment)}
              </h2>
            </div>

            <div className={styles['termsText']}>
              <div className={`d-flex justify-content-between ${styles['termTextContainer']}`}>
                <h6>Funding Goal</h6>
                <div className={styles['hidden_mob']}>
                  <ToolTip data={'Maximum amount that startups may raise.'} bottom left />
                </div>
              </div>
              <h2>
                <span className="rupee">₹</span>
                {numberFormateInr.format(mainDetail.funding_goal)}
              </h2>
            </div>

            <div className={styles['termsText']}>
              <div className={`d-flex justify-content-between ${styles['termTextContainer']}`}>
                <h6>Deadline</h6>
                <div className={styles['hidden_mob']}>
                  <ToolTip data={'The deadline for investing in a startup.'} bottom left />
                </div>
              </div>
              <h2>{dateFormat.format(new Date(mainDetail?.deadline))}</h2>
            </div>
            <div className={styles['termsText']}>
              <div className={`d-flex justify-content-between ${styles['termTextContainer']}`}>
                <h6>Valuation Amount</h6>
                <div className={styles['hidden_mob']}>
                  <ToolTip data={'Total Valuation Amount.'} bottom left />
                </div>
              </div>
              <h2>
                <span className="rupee">₹</span>
                {formatINR(mainDetail?.valuation_amount)}
              </h2>
            </div>
            <div className={styles['termsText']}>
              <div className={`d-flex justify-content-between ${styles['termTextContainer']}`}>
                <h6>Type of Instrument</h6>
                <div className={styles['hidden_mob']}>
                  <ToolTip data={'Type of Instrument of a Startup.'} bottom left />
                </div>
              </div>
              <h2>{mainDetail?.type_of_instrument}</h2>
            </div>
          </div>
        </section>
      </>

      <Modal show={ShowModal1} centered onHide={() => setShowModal1(false)} style={{ padding: '0px' }} size="xl" className={styles['modal-body']}>
        <Modal.Body style={{ padding: '0px' }}>
          <div class="modal-content">
            <div className="row row-cols-2" id={styles['mobile_modal']} style={{ width: '858px', height: '468px' }}>
              <div id={styles['modalLeft']} style={{ paddingTop: '29px' }}>
                <div
                  className={styles['back-btn']}
                  onClick={() => {
                    setShowModal1(false)
                  }}
                  style={{ marginTop: '-20px' }}
                >
                  <img src={backbtn} alt="" />
                </div>
                <Form>
                  <div className={styles['personal_details']}>
                    <h1>Personal Details</h1>
                  </div>
                  <Form.Group controlId="name">
                    <Form.Label style={{ marginBottom: '9.5px' }}>Legal Name</Form.Label>
                    <Form.Control value={`${userKycDetail.first_name}${userKycDetail.middle_name && ' ' + userKycDetail.middle_name} ${userKycDetail.last_name}`} disabled className="p-2" type="text" style={{ borderRadius: '8px', height: '48px', width: '336px' }} />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label style={{ marginTop: '5px', paddingTop: '6px', paddingBottom: '10px' }}>Email</Form.Label>
                    <div className={`${emailErrMsg && 'form-input-err'}`}>
                      {/*      
                      <Form.Control value={userKycDetail.email} className="p-2" type="email" disabled style={width <= 1000 ? { minWidth: '15rem' } : {}}     style={{borderRadius:'8px',height:'48px',width:'336px'}}/>
                      
                      */}
                      <Form.Control value={userKycDetail.email} className="p-2" type="email" disabled style={{ borderRadius: '8px', height: '48px', width: 'fit-content' }} />
                      {emailErrMsg && (
                        <p className={`input-err-msg`}>
                          <ErrorIcon fill="red" width="15px" height="15px" />
                          <span>{emailErrMsg}</span>
                        </p>
                      )}
                    </div>
                  </Form.Group>

                  <div className="" id={`${styles.flex_column}`}>
                    <Form.Group className="mb-2 w-100" controlId="pan">
                      <Form.Label style={{ marginTop: '5px', paddingTop: '6px', paddingBottom: '10px' }}>PAN</Form.Label>
                      <Form.Control style={{ borderRadius: '6px', height: '48px', width: '140px' }} value={userKycDetail.pan_card_number} className="p-2" type="text" disabled />
                    </Form.Group>
                    <Form.Group className=" w-100" id={styles['date_container']} controlId="date">
                      <Form.Label style={{ marginTop: '5px', paddingTop: '6px', paddingBottom: '10px' }}>Date Of birth</Form.Label>
                      <div className={`${dateErrMsg && 'form-input-err'}`} id={styles['align_right']}>
                        <Form.Control value={userKycDetail.dob} style={{ borderRadius: '8px', height: '48px', width: '140px' }} className="p-2" type="date" max={new Date().toISOString().split('T')[0]} disabled id={styles['user-dob']} />
                        {dateErrMsg && (
                          <p className={`input-err-msg`}>
                            <ErrorIcon fill="red" width="15px" height="15px" />
                            <span>{dateErrMsg}</span>
                          </p>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Group controlId="phone" style={{ marginTop: '-5px' }}>
                    <Form.Label style={{ paddingTop: '-50px', paddingBottom: '12px' }}>Phone</Form.Label>
                    <div className={`${phoneErrMsg && 'form-input-err'}`}>
                      <Form.Control style={{ borderRadius: '8px', height: '48px', width: 'fit-content' }} value={userKycDetail.phone} disabled className="p-2" type="phone" maxLength={10} />
                      {phoneErrMsg && (
                        <p className={`input-err-msg`}>
                          <ErrorIcon fill="red" width="15px" height="15px" />
                          <span>{phoneErrMsg}</span>
                        </p>
                      )}
                    </div>
                  </Form.Group>
                </Form>
              </div>
              <div id={styles['modalRight']}>
                <div className="text-end" id={styles['hide_text']}>
                  <button className="bg-transparent border-0 " style={{ position: 'absolute', right: '1.5%', top: '2%' }} onClick={() => setShowModal1(false)}>
                    <img src={backbtn} />
                  </button>
                </div>
                <p className={styles['Order-details']} style={{ marginTop: '0px' }}>
                  Order Details
                </p>

                <div className={styles['startupDetails']} style={{ marginTop: '0px' }}>
                  <h4>Startup Name</h4>
                  <h5>{mainDetail.start_up_name}</h5>
                </div>

                <div className={styles['startupDetails']}>
                  <h4>Lot Qty.</h4>
                  <h5>{lotQty}</h5>
                </div>

                <div className={styles['startupDetails']}>
                  <h4>Lot Price</h4>
                  <h5>
                    <span className="rupee">₹</span>
                    {mainDetail.lot_price}
                  </h5>
                </div>

                <div className={styles['startupDetails']}>
                  <h4>Convenience Fees</h4>
                  <h5>
                    <span className="rupee">₹</span>
                    {(totalAmount * convenienceFee) / 100}
                  </h5>
                </div>

                <div className={styles['startupDetails']}>
                  <h4>Total</h4>
                  <h3 className={styles['total_amount']}>
                    <span className="rupee">₹</span>
                    {totalAmount + (totalAmount * convenienceFee) / 100}
                  </h3>
                </div>

                <p id={styles.tds}>18% GST included in convenience fee.</p>
                <button type="submit" className={styles['submitButton']} disabled={false} data-bs-target="#exampleModalToggle2" style={{ height: '50px', width: '100%',marginLeft:"1%" }} data-bs-toggle="modal" onClick={handleSubmit3}>
                  PROCEED TO PAY
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Temporary Commented */}
      {/* <Modal show={ShowModal2} centered size="lg">
        <Modal.Body style={{ padding: '0px' }}>
          <div class="modal-header justify-content-between" id={styles.checkout}>
            <button
              class="btn"
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
              onClick={() => {
                setShowModal1(true)
                setShowModal2(false)
                setShowModal3(false)
              }}
            >
              {'<'}
            </button>
            <h1 class="modal-title text-center" id="exampleModalToggleLabel2">
              Check Out
            </h1>
            <button type="button" id="close" class="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal2(false)}>
              &times;
            </button>
          </div>
          <div className="d-flex col-6 mx-auto" id={styles.walletBalance}>
            <input id="check" onChange={(e) => setDisplay(e.target.checked)} type="checkbox" className="d-none" />
            <label className="d-flex" htmlFor="check" style={{ gap: '16px' }}>
              <div id={styles.checkboxIconContainer}>
                <img className={display ? 'd-block' : 'd-none'} src={checkicon} alt="" />
              </div>
              <div className={styles.balance}>
                <h6>Wallet balance</h6>
                <h5>
                  <span className="rupee">₹</span>
                  500.00
                </h5>
              </div>
            </label>
          </div> */}
      {/* <p
            className="col-6 mx-auto"
            id={styles.promo}
            onClick={() => {
              setShowPromoCodeModal(true)
              setShowModal2(false)
            }}
          >
            I have a promo code
          </p> */}

      {/* <div className="col-6 mx-auto" id={styles.walletbody}>
            <button className={styles.submitButton} onClick={handleSubmit3}>
              PROCEED TO PAY
            </button>
          </div>
          {/*</div>*/}
      {/* </Modal.Body> */}
      {/* </Modal> */}
      <Modal show={ShowmPromoCodeModal} centered size="lg">
        <Modal.Body style={{ padding: '0px' }}>
          <div class="modal-header justify-content-between" id={styles['checkout']}>
            <button
              class="btn"
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
              onClick={() => {
                setShowModal1(false)
                setShowModal2(true)
                setShowModal3(false)
                setShowPromoCodeModal(false)
              }}
            >
              {'<'}
            </button>
            <h1 class="modal-title text-center" id="exampleModalToggleLabel2">
              Promo Code
            </h1>
            <button type="button" id="close" class="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPromoCodeModal(false)}>
              &times;
            </button>
          </div>
          <div className="d-flex col-6 mx-auto" id={styles['promo_input']}>
            <input
              type="text"
              placeholder="Enter Your Promo Code"
              value={promocode}
              onChange={(e) => {
                setpromocode(e.target.value)
                setpromocodeerr(false)
              }}
            />
            {promocodeerr ? (
              <p className={`input-err-msg`}>
                <span>{errmsg}</span>
              </p>
            ) : (
              ''
            )}
          </div>

          <div className="col-6 mx-auto" id={styles['walletbody']}>
            <button className={styles['submitButton']} onClick={handleSubmitPromocode}>
              Apply
            </button>
          </div>
          {/*</div>*/}
        </Modal.Body>
      </Modal>

      <Modal show={ShowModal3} className={styles['term_padding']} centered size="xl">
        <Modal.Body style={{ padding: '0px' }}>
          <div className="p-4 mx-auto bg-light" id={styles['TermsAndConditionPage']}>
            <div id={styles.termsAndCondition} className="text-dark bg-white pt-4">
              <h3>Terms & Conditions</h3>
              <ul>
                <li>I am aware that startup investments carry risk and uncertainty, but there is also potential for larger returns and limited loss to my investments. </li>
                <li>I have read and understood the "Investor FAQ" in its entirety.</li>
                <li>I am solely responsible for my investments, and I agree that Venturebuddy is not providing any kind of investment advice.</li>
              </ul>
            </div>
            <div className="text-center pb-3">
              <button
                disabled={submitBtnLoading}
                style={{
                  pointerEvents: submitBtnLoading ? 'none' : 'all',
                  opacity: submitBtnLoading ? 0.5 : 1,
                  width: '104px',
                  height: '48px',
                  padding: '0px',
                }}
                className="btn-outline-blue px-4"
                id={styles['decline']}
                onClick={CloseAllModalAtOnce}
              >
                DECLINE
              </button>
              <button disabled={submitBtnLoading} className={`btn-blue px-4 ${submitBtnLoading && 'py-0'} ${styles['accept_button']}`} style={{ width: '104px', height: '48px', padding: '0px' }} onClick={submitAndProceed}>
                {submitBtnLoading ? (
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx={50} cy={50} fill="none" stroke="#fff" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                    </circle>
                  </svg>
                ) : (
                  'ACCEPT'
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default InvestNowContent
