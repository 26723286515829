import React from 'react';
import dp from '../../Assets/dp.png';
import Updata from './Updata/Updata';
import styles from './Update.module.css';
import { useParams } from 'react-router-dom';
import { getmyInvest_details } from '../../services/startup';
import { getStartupUpdates } from '../../services/startup';
import { useState, useEffect } from 'react';

const Update = () => {
  const [data, setdata] = useState([]);
  const [detail, setdetail] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getmyInvest_details(id, (err, res) => {
      if (err) return;
      setdetail(res.data);
    });
  }, [id]);

  const val = detail[0]?.startup_id; 
  useEffect(() => {
    if (val) {
      getStartupUpdates(val, (err, res) => { 
        if (err) return;
        setdata(res.data.results);
      });
    }
  }, [val]);

  const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const setdate = (val) => {
    const _date = new Date(val);
    const D = `${mS[_date.getMonth()]} ${_date.getDate()}`;
    return D;
  };

  const setdate_year = (val) => {
    const _date = new Date(val);
    const D = `${_date.getFullYear()}`;
    return D;
  };

  return (
    <div className="ps-4 ms-4" id={styles.update}>
      {data.map((val, ind) => (
        <Updata
          key={ind}
          dp={dp}
          date={setdate(val.date)}
          year={setdate_year(val.date)}
          opened={false}
          title={val.heading}
          para={val.update}
          designation="Team Member"
          last={ind + 1 === data.length ? 'last-update' : ''}
        />
      ))}
    </div>
  );
};

export default Update;
