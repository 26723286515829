import React from "react";
import { Autoplay, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import insightsImg from "./../../Assets/images/insights.png";
import barchartImg from "./../../Assets/images/bar-charts.png";
import CarouselSlide from "../Carousel/CarouselSlide";
import { useState, useEffect } from "react";
import { startupdetailContext } from "../../Contexts/Startupdetailcontext";
import { useContext } from "react";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/autoplay";
import styles from "./HeaderCarousel.module.css";
const HeaderCarousel = () => {
  // Note This file is in use
  const { borderImg, insights, barchart, carContainer } = styles;
  /* Api Data*/
  const { allstartups, filteredstartups } = useContext(startupdetailContext);
  // console.log(
  //   "🚀 ~ file: LiveDeals.js:14 ~ LiveDeals ~ allstartups from-> slider",
  //   allstartups
  // );
  const [liveStartups, setLiveStartups] = useState([]);

  // useEffect(() => {
  //   // let data = filteredstartups.length===0 ? allstartups : filteredstartups
  //   if (allstartups?.length === 0) return setLiveStartups([]);
  //   const filterby_deadline = allstartups.filter((startup) => {
  //     const MS_TIME_DIFFERENCE =
  //       new Date(startup.deadline).getTime() - new Date().getTime();
  //     const HOURS_TIME_DIFFERENCE = Math.floor(
  //       MS_TIME_DIFFERENCE / 1000 / 60 / 60
  //     );
  //     const DAYS_TIME_DIFFERENCE = Math.floor(HOURS_TIME_DIFFERENCE / 24);
  //     if (
  //       DAYS_TIME_DIFFERENCE < 0 ||
  //       startup?.amount_raised >= startup?.funding_goal ||
  //       !startup?.is_live
  //     ) {
  //     } else {
  //       return startup;
  //     }
  //   });
  //   setLiveStartups(filterby_deadline);
  // }, [allstartups, filteredstartups]);
  return (
    <div className={`mx-lg-4 ${carContainer}`}>
      <div className="border border-1  me-lg-5" id={styles.sliderContainer}>
        <Swiper
          autoplay={{ delay: 3000 }}
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative, Autoplay]}
          width={540}
        >
          {allstartups?.map((val) => (
            <SwiperSlide key={val.id}>
              <CarouselSlide
                image={val?.start_up_pic}
                header={val?.start_up_name}
                paragraph={val?.about_startup_short}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <img className={borderImg} id={insights} src={insightsImg} alt="" style={{width:'100px',height:'100px'}}/>
        <img className={borderImg} id={barchart} src={barchartImg} alt="" style={{width:'72px',height:'72px'}}/>
      </div>
    </div>
  );
};

export default HeaderCarousel;
