import React from "react";
import style from "./FundUsage.module.css";
import { PieChart, Pie, Cell } from "recharts";
import useStartupCtx from "../../hooks/useStartupCtx";
import useAuthCtx from "../../hooks/useAuthCtx";
import BlurInfo from "../BlurInfo/BlurInfo";

const FundUsage = ({ styles }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();
  const { fundsUsage } = startupFullDetails;
  const { isLoggedIn } = useAuthCtx();

  const COLORS = ["#3E4C9C", "#4DBC5C", "#55bbFC", "#BB55FC", "#DEA700", "#44FCbb", "#66CCFF"];
  // Using this fakedata so that if any user uses the devtools to see the data without logging in then he would see this fake data instead of the real data
  const FakeData = [{ name: "Name", value: 15 }, { name: "Name", value: 15 }, { name: "Name", value: 15 }, { name: "Name", value: 15 }]
  return (
    <div data-scroll-id="fund-usage" id={style.FundUsage}>
      <h1 className={styles.sectionHeader}>
        Funds Usage
        <div className={styles.borderBottom}></div>
      </h1>
      {!isLoggedIn ? <BlurInfo>
        <>
          <div className={style.chartContainer}>
            <div className="position-relative">
              <PieChart width={259} height={259}>
                <Pie data={FakeData} cx={120} cy={200} innerRadius={75} outerRadius={120} paddingAngle={0} dataKey={FakeData.valu}>
                  {COLORS.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>

            <div id={style.indicators}>
              {FakeData.map((usage, index) => {
                return (
                  <div className={style.indicator}>
                    <div
                      style={{
                        backgroundColor: COLORS[index % COLORS.length],
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <h6>
                      FundUsage - 0%
                    </h6>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      </BlurInfo> : <>
        <div className={style.chartContainer}>
          <div className="position-relative">
            <PieChart width={259} height={259}>
              <Pie data={fundsUsage.fields} cx={120} cy={200} innerRadius={75} outerRadius={120} paddingAngle={0} dataKey={fundsUsage.fields.value}>
                {fundsUsage.fields.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </div>

          <div id={style.indicators}>
            {fundsUsage.fields.map((usage, index) => {
              return (
                <div className={style.indicator}>
                  <div
                    style={{
                      backgroundColor: COLORS[index % COLORS.length],
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <h6>
                    {usage.name} - {usage.value}%
                  </h6>
                </div>
              );
            })}
          </div>
        </div>
      </>}

    </div>
  );
};

export default FundUsage;
