import React, { useEffect, useState } from 'react'
import style from './pitchdeck.module.css'
import style3 from '../../../components/Testimonial/Testimonial.module.css'
import hero from './pich_assest/pitchdeck_hero.svg'
import back from './pich_assest/path_bg.svg'
import pitch1 from './pich_assest/pitchdeck_01.svg'
import pitch2 from './pich_assest/pitchdeck_02.svg'
import pitch3 from './pich_assest/pitchdeck_03.svg'
import styles from '../../../components/HowItWorks/HowItWorks.module.css'
import HowItWorks1 from '../../../Assets/images/how-it-works-1.png'
import HowItWorks2 from '../../../Assets/images/how-it-works-2.png'
import HowItWorks3 from '../../../Assets/images/how-it-works-3.png'
import HowItWorks4 from '../../../Assets/images/how-it-works-4.png'
import icon1 from '../../../Assets/icons/invesment 1.svg'
import icon2 from '../../../Assets/icons/growth 1.svg'
import HowItWorksItem from '../../../components/HowItWorks/HowItWorksItem'
import client_image from './pich_assest/client_image.svg'
import Star from './pich_assest/star.svg'
import Style2 from '../../../components/Organizations/Organization.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'
import dhl from '../../../Assets/images/organizations/client-1-removebg-preview.png'
import next from '../../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from '../../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from '../../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from '../../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from '../../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from '../../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from '../../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from '../../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from '../../../Assets/images/organizations/client-13-removebg-preview.png'
import FAQ2 from '../../../components/FAQ/investment_faq/FAQ2'
import Faq3 from '../faqs/Faq3'
import GetTouchItAll from '../gettouchit/GetTouchItAll'
import Footer from '../../../components/Footer/Footer'
import BlogsSlider from '../blogs/BlogsSlider'
import Client1 from './pich_assest/business_1.svg'
import Client2 from './pich_assest/business_2.svg'
import Client3 from './pich_assest/business_3.svg'
import Client4 from './pich_assest/business_4.svg'
import Client5 from './pich_assest/business_5.svg'
import Client6 from './pich_assest/business_6.svg'
import TestimonialCard from '../../../components/Testimonial_Card/TestimonialCard'
import Testimonial from '../../../components/Testimonial/Testimonial'
import MobileBlogs from '../blogs/mobileblogs/MobileBlogs'
import MobileViewClients from '../MobileViewClients/MobileViewClients'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const PitchDeck = () => {
  const [indexed, setIndexed] = useState(0)
  const [activeId, setactiveID] = useState(1)
  const [data1, setdata] = useState([])
  const [about, setabout] = useState({})
  const [showMain2, setShowMain2] = useState(false)
  const navigate = useNavigate()
  const FetchTestimonial = async () => {
    const data = await Testimonial()
    // console.log(data)
    setdata(data?.results?.slice(0, 10))
    setabout({
      title: data?.results[0]?.client_opinion_heading,
      des: data?.results[0]?.client_opinion,
      stars: data?.results[0]?.client_rating,
      id: data?.results[0]?.id,
    })
  }
  useEffect(() => {
    FetchTestimonial()
  }, [])
  const pitchdata = [
    {
      title: 'Clear and Compelling Storytelling',
      detail: 'Craft a narrative that resonates with your audience, focusing on the problem your product or service solves, your unique solution, and the benefits it offers.',
      Image: pitch1,
    },
    {
      title: 'Concise and Impactful Content',
      detail: 'Each slide is precisely crafted to convey essential information efficiently, leveraging powerful visuals and concise messaging to make a lasting impression on investors',
      Image: pitch2,
    },
    {
      title: 'Visual Appeal and Professional Design',
      detail: 'Use high-quality visuals, such as images, charts, and graphs, to enhance understanding and retention of information.',
      Image: pitch3,
    },
  ]
  const items = [
    {
      id: 1,
      heading: 'Identify the Problem',
      paragraph: 'Start by clearly articulating the problem your product or service aims to solve. Describe the pain points or challenges faced by your target audience, making it relatable and compelling.',
      image: HowItWorks1,
      reverse: false,
    },
    {
      id: 2,
      heading: 'Present Your Solution',
      paragraph: 'Introduce your solution to the identified problem. Highlight how your product or service addresses the needs of your target market in a unique and effective way. Emphasize the benefits and advantages of choosing your solution over others..',
      image: HowItWorks2,
      reverse: true,
    },
    {
      id: 3,
      heading: 'Showcase Market Opportunity and Potential',
      paragraph: 'Demonstrate the market size and potential for your solution. Provide insights into the market demand, growth trends, and opportunities for expansion. Show investors the potential return on investment and the scalability of your business model.',
      image: HowItWorks3,
      reverse: false,
    },
  ]

  const clientReview = [
    {
      id: 1,
      name: 'Rohit Ransore',
      status: 'INVESTER',
      profession: 'UX DESIGNER,ATG',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 2,
      name: 'Jeniffer White',
      status: 'INVESTER',
      profession: 'Vice President, Google',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 3,
      name: 'Niromi Taylor',
      status: 'BUSNINESS',
      profession: 'Senior Manager, IKEA',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
  ]

  const clientlogos = [Client1, Client2, Client3, Client4, Client5, Client6]

  const scrollintoview = (number) => {
    const ele = document.querySelector(`.class${number}`)
    ele.scrollIntoView({ behavior: 'smooth' })
  }

  function startprinting() {
    let start = clientReview[indexed].review[0].review_star
    let starts = []

    for (let i = 1; i <= start; i++) {
      starts.push(<img src={Star} />)
    }

    return starts
  }

  const data = [
    {
      question: 'How does your process work for creating custom pitch decks?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What sets your pitch deck services apart from others?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'Is there a sample report or a free trial available?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What is the authenticity of the tool?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'How does Venturebuddy protect my data?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What information do you need from clients to start working on their pitch deck?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
  ]
  const handlenavigate = () => {
    navigate('/contact')
  }
  const PrintRating = (number) => {
    const tempArr = []
    for (let i = 0; i < number; i++) {
      tempArr.push(i)
    }
    return tempArr
  }
  useEffect(() => {
    if (data1) {
      const Ele = document.querySelectorAll('[data-number]')
      const obs = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const Id = entry.target.getAttribute('data-number')
              setactiveID(parseInt(Id))
            }
          })
        },
        {
          threshold: 0.5,
        }
      )
      Ele.forEach((e) => {
        obs.observe(e)
      })
    }
  }, [data1])
  useEffect(() => {
    // Delay the visibility of the main2 section
    const timer = setTimeout(() => {
      setShowMain2(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  const handleDownloadPdf = async () => {
    // try {
    //   const response = await axios.get("https://venturebuddy.s3.amazonaws.com/images/None/Venturebuddy_Profile.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5LUNIOC64KPVLUUJ%20%2F20240809%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240809T150528Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7baf8a74af851b74c5e8036e38ce80faaa033335eb803545e56a43d3b9dd36", {
    //     responseType: 'blob',
    //   });
    //   const blob = new Blob([response.data], { type: 'application/pdf' });
    //   const url = window.URL.createObjectURL(blob);
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'Venturebuddy_Profile.pdf');
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   window.URL.revokeObjectURL(url);
    // } catch (error) {
    //   console.error('Error downloading PDF:', error);
    // }

    window.open('https://venturebuddy.s3.amazonaws.com/images/None/Venturebuddy_Profile.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5LUNIOC64KPVLUUJ%20%2F20240809%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240809T150528Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7baf8a74af851b74c5e8036e38ce80faaa033335eb803545e56a43d3b9dd36', '_blank')
  }

  return (
    <>
      <section className={style['main1']}>
        <div className={style['pitch_hero']}>
          <img src={back} />
          <div className={style['detail_hero']}>
            <div>
              <div className={style['detail']}>
                <h1 className={style['hero_text']}>
                  Pitch Perfect: Crafting Decks That
                  <br /> Wow Investors!
                </h1>
                <h1 className={style['hero_text2']}>Pitch Perfect: Crafting Decks That Wow Investors!for your startup</h1>
                <p>Unleash Impact with Compelling Stories, Stunning Design, and Creative Content.</p>
              </div>
              <div className={style['pitch_image_1']}>
                <img src={hero} className={style['pitch_image']} />
              </div>
              <div className={style['download_btn']}>
                <button onClick={handleDownloadPdf}>Download Sample For Free</button>
              </div>
            </div>
          </div>
          <div className={style['white_bg']} />
        </div>
      </section>
      <br />
      {showMain2 && (
        <section className={style['main2']}>
          <div className={style['wrapper_pitch']}>
            <div className={style['pitch_deck_detail1']}>
              <div className={style['deatil_2']}>
                <h1>What makes best Pitch deck</h1>
                <div className={style['small_detail']}>
                  <p>Transform your pitch deck into a fundraising powerhouse, captivating investors within 10 minutes. Let our expertise accelerate your startup's journey to success.</p>
                </div>
              </div>
              <br />
              <div className={style['detail_list']}>
                {pitchdata.map((item, index) => (
                  <>
                    <div className={style['pitch_deatil_card']} key={index}>
                      <img src={item.Image} />
                      <h2>{item.title}</h2>
                      <div className={style['detail_03']}>
                        <h6>{item.detail}</h6>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main3']}>
          <div className={style['pitch_deck_everyone_wrapper']}>
            <div className={style['pitch_deck_everyone']}>
              <h1>Craft your pitch deck with maximum impact!</h1>
              <div className={style['everyone_detail']}>
                <p>Elevate your pitch deck with our expert guidance, transforming presentations into powerful fundraising tools that captivate investors and drive success for your venture.</p>
              </div>
              <div>
                <button onClick={() => handlenavigate()}>Contact Us</button>
              </div>
            </div>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main4']}>
          <div id={styles.howItWorks}>
            <div id={styles.HowItWorksHead}>
              <h1 className="text-center" id={styles.HowItWorks} style={{ color: '#283891' }}>
                Create a Compelling Pitch Deck For Your Next Presentation!
              </h1>
              <p id={styles.getStart} style={{ color: '#6D747A', textAlign: 'center' }}>
                Get started in just 3 easy steps!
              </p>
            </div>
            <div className={`container-fluid col-lg-7 col-11 mt-5 ${styles.howItWorks_wrap}`}>
              <div className="text-center">
                <div className="text-start">
                  {items.map((item) => (
                    <HowItWorksItem key={item.id} serial={item.id} heading={item.heading} paragraph={item.paragraph} image={item.image} reverse={item.reverse} imagewidth={300} imagehieght={230} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main5']}>
          <BlogsSlider />
        </section>
      )}
      {showMain2 && (
        <section className={style['main05']}>
         
          <MobileBlogs />
        </section>
      )}
      {showMain2 && (
        <section className={style['main6']}>
          
          <Testimonial />
        </section>
      )}
      {showMain2 && (
        <section className={style['main7']} style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
          <div id="Style2.organizations" className="container-fluid" style={{ height: '705px', background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
            <>
              <div style={{ paddingTop: '250px', width: '100%' }}>
                <h4 className="text-blue text-center" id={Style2.orgHead}>
                  Our Top Clients
                </h4>
                {/* Desktop Slider Start Here */}
                <div className={style['company_wrapper']}>
                  <div className={style['company_main']}>
                    <img src={Client1} />
                    <img src={Client2} />
                    <img src={Client3} />
                    <img src={Client4} />
                    <img src={Client5} />
                    <img src={Client6} />
                  </div>
                </div>
              </div>
              {/* Desktop Slider End Here */}
            </>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main07']}>
          <MobileViewClients clientlogos={clientlogos} />
        </section>
      )}
      {showMain2 && (
        <section className={style['main8']} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={style['mobile_div']} style={{ width: '750px' }}>
            <Faq3 data={data} page="Pitchdeck" />
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main9']}>
          <GetTouchItAll />
        </section>
      )}
      {showMain2 && <Footer />}
    </>
  )
}

export default PitchDeck
