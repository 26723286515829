import React, { useContext } from "react";
import Alert from "../../components/Alert/Alert";
import Options from "../../components/Options/Options";
import styles from "./SavedDeals.module.css";
//import logo from "./../../Assets/images/uber-eats.png";
import { startupdetailContext } from "../../Contexts/Startupdetailcontext";
import mentor from "../../Assets/images/startups/mentor.png";
import DealCard from "../../components/DealCard/DealCard";
import useStartupCtx from "../../hooks/useStartupCtx";
import useAuthCtx from "../../hooks/useAuthCtx";
import StartExplore from "../StartExplore/StartExplore";
import { useEffect } from "react";
import { getSavedDeals } from "../../services/account";
import { useState } from "react";
import { getAllStartups } from "../../services/startup";

const SavedDeals = () => {
  const { user, savedDeals, setSavedDeals } = useAuthCtx();
  const [isLoading, setIsLoading] = useState(true);

  const getSavedDeals = async () => {
    setSavedDeals([]);
    const newDeals = [];
    for await (const deal of user.saved_deals) {
      if (deal.live_deals_bookmark__id === null) continue;
      setIsLoading(true);
      await getAllStartups(`id=${deal.live_deals_bookmark__id}`, (err, res) => {
        if (err || res.data.length === 0) return;
        newDeals.push(res.data[0]);
      });
    }
    setSavedDeals(newDeals);
    setIsLoading(false);
  };

  useEffect(() => {
    getSavedDeals();
  }, [user]);

  if (isLoading)
    return (
      <div className={styles["loading-wrapper"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{ margin: "auto", background: "rgba(221, 221, 221, 0)", display: "block" }}
          width="80px"
          height="80px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx={50} cy={50} fill="none" stroke="#333" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
          </circle>
        </svg>
      </div>
    );
  if (savedDeals.length === 0) return <StartExplore heading="Currently there are no saved deals." para="Save deals will be shown here." />;
  return (
    <div id={styles.DealsContainer}>
      <div id={styles.investmentHeader}>
        <h1>Saved Deals</h1>
        <h6 className="col-4">Your hand picked deals are closing soon..</h6>
      </div>

      <div id={styles.dealsHeading}>
        <p>{savedDeals?.length} saved deals</p>
      </div>

      <div className={styles["deals-container"]} id={styles.cardContainer}>
        {savedDeals.map((ele) => (
          <DealCard key={ele.id} deal={ele} isClosed={false} />
        ))}
      </div>
    </div>
  );
};

export default SavedDeals;
