import React from "react";
import styles from "./PitchDeck.module.css";
import useStartupCtx from "../../hooks/useStartupCtx";
import { markupText } from "../../utils";
import FullImage from "../FullImage/FullImage";

const PitchDeck = ({ sectionHeader, borderBottom }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail } = startupFullDetails;


  return (
    <div data-scroll-id="what-solving" className={styles["pitch-deck-wrapper"]}>
      <h1 className={sectionHeader}>
        What are We Solving?
        <div className={borderBottom}></div>
      </h1>

      {(mainDetail.problem_pic || mainDetail?.problem_statement) && <h3>PROBLEM</h3>}
      {mainDetail.problem_pic && <FullImage image={mainDetail.problem_pic} />}
      {mainDetail.problem_statement && <p style={{ fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: markupText(mainDetail?.problem_statement, "\r\n", "br") }}></p>}

      {(mainDetail.solution_pic || mainDetail?.solution) && <h3>SOLUTION</h3>}
      {mainDetail.solution_pic && <FullImage image={mainDetail.solution_pic} />}
      {mainDetail?.solution && <p style={{ fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: markupText(mainDetail?.solution, "\r\n", "br") }}></p>}
    </div>
  );
};

export default PitchDeck;
