import React, { useEffect, useState } from "react";
import styles from "./BecomeInvestor.module.css";
import KYC from "../../components/KYC/KYC.js";
import BankDetails from "../../components/BankDetails/BankDetails";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthCtx from "../../hooks/useAuthCtx";
import { getUserData, postKycDetail } from "../../services/account";
import { useRef } from "react";
import Nav_bi from "./Nav_bi";
import { toast } from "react-toastify";
import KycAadharDetail from "../../components/KycAadharDetail/KycAadharDetail";
import TermConditions from "./termandConditions/TermCondition2.js";
import TermCondition2 from "./termandConditions/TermCondition2.js";

const BecomeInvestor = ({ name }) => {
  const location = useLocation();
  const { user, setUser } = useAuthCtx();
  const navigate = useNavigate();
  const submitBtnRef = useRef(null);
  useEffect(() => {
    if (Object.keys(user).length === 0) {
      return getUserData((err, res) => {
        if (err) {
          return;
        }
        setUser(res.data?.[0]);
      });
    }
    if (user.user_kyc_completed) {
      navigate("/dashboard", { replace: true });
    }
  }, [user]);
  const [userKycDetails, setUserKycDetails] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    middle_name: "",
    father_name: "",
    email: user?.email,
    phone: user?.mobile,
    dob: "",
    pan_card_number: "",
    isPanVarified: false,
    aadhar_card_number: "",
    isAadharVarified: false,

    bank_account_number: "",
    ifsc_code: "",
    isBankVarified: false,

    // identity_proof: "0",
    // identity_proof_number: "",
    // identity_proof_front: null,
    // identity_proof_back: null,
    // address_proof: "0",
    // address_proof_number: "",
    // address_proof_front: null,
    // address_proof_back: null,
  });


  // console.log("this is kyc data ",userKycDetails)

  // console.log(userKycDetails);
  const submitKycDetails = async () => {
    submitBtnRef.current.disabled = true;
    submitBtnRef.current.innerText = "Processing...";
    const kycFormData = new FormData();
    for (const key in userKycDetails) {
      // console.log("this is key",key,"this is userdata",userKycDetails[key])
      kycFormData.append(key, userKycDetails[key]);
    }
    await postKycDetail(kycFormData, (err, res) => {
      // console.log("this is postKycDetail",res)
      if (err || res.status !== 201) {
        submitBtnRef.current.disabled = false;
        submitBtnRef.current.innerText = "SUBMIT";
        return toast.error("Something went wrong!");
      }
      getUserData((err, res) => {
        submitBtnRef.current.disabled = false;
        submitBtnRef.current.innerText = "SUBMIT";
        if (err) return;
        setUser(res.data?.[0]);
        toast.success("KYC Completed!");
        navigate(location.state.to || location.state.from || "/dashboard");
      });
    });
  };

  return (
    <>
      <Nav_bi />
      <div id={styles.becomeInvestorContainer} className="becomeInvestorContainer">
        <div className="col-5 mx-auto" id={styles.progress}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column justify-content-center">
              <div id="stepOne" className={`${styles.progressNumber} step-done`}>
                1
              </div>
              <p>Account Verified</p>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div id="stepTwo" className={`${styles.progressNumber} step-active`}>
                2
              </div>
              <p className="text-center">KYC</p>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div id="stepThree" className={`${styles.progressNumber}`}>
                3
              </div>
              <p>Bank Verification</p>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div id="stepThree" className={`${styles.progressNumber}`}>
                4
              </div>
              <p>Agreement</p>
            </div>
          </div>
        </div>

        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" dataInterval="false" style={{marginTop:'-20px'}}>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <KYC userKycDetails={userKycDetails} setUserKycDetails={setUserKycDetails} />
            </div>
            <div class="carousel-item ">
              <KycAadharDetail userKycDetails={userKycDetails} setUserKycDetails={setUserKycDetails} />
            </div>
            <div class="carousel-item">
              <BankDetails userKycDetails={userKycDetails} setUserKycDetails={setUserKycDetails} submitBtnRef={submitBtnRef} submitKycDetails={submitKycDetails} />
            </div>
            <div class="carousel-item">
              <TermCondition2 userKycDetails={userKycDetails} setUserKycDetails={setUserKycDetails} submitBtnRef={submitBtnRef} submitKycDetails={submitKycDetails} />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev" id="prev" style={{ visibility: "hidden" }}></button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next" id="next" style={{ visibility: "hidden" }}></button>
        </div>
      </div>
    </>
  );
};

export default BecomeInvestor;
