import React from "react";
import styles from "./CompanyDocuments.module.css";
import document from "./../../Assets/icons/document.png";
import useStartupCtx from "../../hooks/useStartupCtx";
import useAuthCtx from "../../hooks/useAuthCtx";
import BlurInfo from "../BlurInfo/BlurInfo";
const CompanyDocuments = () => {
  // Note This File is in use
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail } = startupFullDetails;
  const { isLoggedIn } = useAuthCtx()
 

  return (
    <>
      {!isLoggedIn ? <BlurInfo>
        <div className={styles["company-document-wrapper"]}>
          <h2>Company Documents</h2>

          {mainDetail.company_documents.map((doc, index) => {
            return (
              <>
                <a className={styles["document"]} href="#" rel="noreferrer">
                  <img src={document} alt="" />
                  <h3>Lorem ipsum dolor sit Lorem ipsum dolor.</h3>
                </a>
                {index !== mainDetail.company_documents.length - 1 && <div className={styles["border"]}></div>}
              </>
            );
          })}
        </div>
      </BlurInfo> : <div className={styles["company-document-wrapper"]} style={{borderRadius:'20px'}}>
       <div className={styles["second_wrap"]}>
       <h2>Company Documents</h2>
       </div>

        {mainDetail.company_documents.map((doc, index) => {
          return (
            <>
              <a className={styles["document"]} href={doc.file_url.replace("http://", "https://")} download={doc.name} target={"_blank"} rel="noreferrer">
                <img src={document} alt="" />
                <h3>{doc.name || `Document ${index + 1}`}</h3>
              </a>
              {index !== mainDetail.company_documents.length - 1 && <div className={styles["border"]}></div>}
            </>
          );
        })}
      </div>}

    </>
  );
};

export default CompanyDocuments;
