import React from 'react'
import location from '../../Assets/location_on.svg'
import phone from '../../Assets/phone.svg'
import mail from '../../Assets/email.svg'
import styles from './contactInfo.module.css'

const ContactInfo = () => {
  // Note This File is in use
  const contactInfo = [
    {
      id: 1,
      type: 'location',
      src: location,
    },
    {
      id: 2,
      type: 'phone',
      src: phone,
      name: '+91 95942 28662',
    },
    {
      id: 3,
      type: 'mail',
      src: mail,
      name: 'preetesh@venturebuddy.io ',
    },
  ]

  return (
    <div className={styles.contact_info}>
      <h1>Please reach us out at:</h1>

      <div>
        {contactInfo.map((info) => (
          <div key={info.id} className={styles.info_container}>
            <div className={styles.img_holder}>
              <img src={info.src} alt={info.type} />
            </div>
            <p>
              {info.type === 'mail' ? (
                <a href={`mailto:${info.name}`}>{info.name}</a>
              ) : info.type === 'phone' ? (
                <a href={`tel:${info.name}`}>{info.name}</a>
              ) : info.type === 'location' ? (
                <a href={`https://goo.gl/maps/snNe2wckfRAkwg7W8`} target="_blank" rel="noopener noreferrer">
                  Dravyam Ventures Private Limited <br/> 503, Corporate Park, Sec-18, Vashi, Navi Mumbai 400703
                  <br/>
                  CIN: U74999MH2016PTC282743
                </a>
              ) : (
                info.name
              )}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContactInfo
