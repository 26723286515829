import React from "react";
import { useState } from "react";
import useAuthCtx from "../../hooks/useAuthCtx";

import styles from "./FinishSignupModal.module.css";

import TypeOfDeals from "../TypeOfDeals/TypeOfDeals";
import TypeOfStartUps from "../TypeOfStartUps/TypeOfStartUps";

const FinishSignupModal = () => {
  // Note This file is in use
  const { registerInputData, setRegisterInputData } = useAuthCtx();

  const [currentProgress, setCurrentProgress] = useState(1);

  return (
    <div className={styles["update-mode-modal-bg"]}>
      <div className={styles["update-mode-modal"]}>
        {currentProgress === 1 && (
          <TypeOfDeals currentProgress={currentProgress} setCurrentProgress={setCurrentProgress} registerInputData={registerInputData} setRegisterInputData={setRegisterInputData} />
        )}
        {currentProgress === 2 && <TypeOfStartUps registerInputData={registerInputData} setRegisterInputData={setRegisterInputData} />}
      </div>
    </div>
  );
};

export default FinishSignupModal;
