import React, { useState } from 'react'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import Alert from '../../components/Alert/Alert'
import Sidebar from '../../components/Sidebar/Sidebar'
import styles from './Dashbord.module.css'
import Nav_dash from './Nav_dash'
// import Navbar from "../../components/Navbar/Navbar";
const Dashboard = () => {
  // const [arrowVisibility, setArrowVisibility] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname === '/dashboard') navigate('live-deals', { replace: true })
  }, [location])

  return (
    <>
      <div className={styles.nav_display}>
        {/* <Navbar arrowVisibility={arrowVisibility} setArrowVisibility={setArrowVisibility}></Navbar> */}
        <Nav_dash />
      </div>
      <div className={styles.side_box}>
        <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        {location.pathname !== '/dashboard/live-deals' ? (
          <div className={isSidebarOpen ? `w-100 ${styles['dashboard-conatiner-style-mobile']}` : `col-10 ms-auto ${styles['dashboard-conatiner-style-mobile']}`} style={{ padding: isSidebarOpen ? '0px 40px 0px 0px' : '0 20px' }}>
            <Alert />
            <Outlet />
          </div>
        ) : (
          <div className={isSidebarOpen ? `w-100 ${styles['dashboard-conatiner-style-mobile']}` : `col-10 ms-auto ${styles['dashboard-conatiner-style-mobile']}`} style={{ padding: isSidebarOpen ? '0px 0px 0px 0px' : '0 20px' }}>
            <Alert />
            <Outlet />
          </div>
        )}
      </div>
    </>
  )
}

export default Dashboard
