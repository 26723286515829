import React from 'react'
import styles from './DealTerms.module.css'
import useStartupCtx from '../../hooks/useStartupCtx'
import ToolTip from '../ToolTip/ToolTip'
import { formatINR } from '../../utils'
import useAuthCtx from '../../hooks/useAuthCtx'
import BlurInfo from '../BlurInfo/BlurInfo'

// WORKING: File is in use.
const DealTerms = () => {
  const { startupFullDetails } = useStartupCtx()
  const { mainDetail, founders, industryOverview, competitors, financialSynopsis, fundsUsage, swotAnalysis } = startupFullDetails
  const { isLoggedIn } = useAuthCtx()
  let options = { year: 'numeric', month: 'long', day: 'numeric' }
  const dateFormat = new Intl.DateTimeFormat('en-GB', options)
  return (
    <>
      {!isLoggedIn ? (
        <BlurInfo>
          <div className={styles['deal-term-wrapper']}>
            <h1>Deal Terms</h1>

            <section className={styles['deal-term-statement-container']}>
              <div className={styles['deal-term-statement']}>
                <h4>Minimum Investment</h4>
                <p>
                  <span className="rupee">₹</span>0
                </p>
              </div>
              <div className={styles['tooltip-container']}>
                <ToolTip data={'Minimum investment required from each individual or single investor'} bottom left />
              </div>
            </section>

            <section className={styles['deal-term-statement-container']}>
              <div className={styles['deal-term-statement']}>
                <h4>Maximum Investment</h4>
                <p>
                  <span className="rupee">₹</span>0
                </p>
              </div>
              <div className={styles['tooltip-container']}>
                <ToolTip data={'Maximum investment required from each individual or single investor'} bottom left />
              </div>
            </section>

            <section className={styles['deal-term-statement-container']}>
              <div className={styles['deal-term-statement']}>
                <h4>Funding Goal</h4>
                <p>
                  <span className="rupee">₹</span>0
                </p>
              </div>
              <div className={styles['tooltip-container']}>
                <ToolTip data={'Maximum amount that startups may raise.'} bottom left />
              </div>
            </section>

            <section className={styles['deal-term-statement-container']}>
              <div className={styles['deal-term-statement']}>
                <h4>Deadline</h4>
                <p>0</p>
              </div>
              <div className={styles['tooltip-container']}>
                <ToolTip data={'The deadline for investing in a startup.'} bottom left />
              </div>
            </section>

            <section className={styles['deal-term-statement-container']}>
              <div className={styles['deal-term-statement']}>
                <h4>Valuation Amount</h4>
                <p>
                  <span className="rupee">₹</span>0
                </p>
              </div>
              <div className={styles['tooltip-container']}>
                <ToolTip data={'Total Valuation Amount'} bottom left />
              </div>
            </section>

            <section className={styles['deal-term-statement-container']}>
              <div className={styles['deal-term-statement']}>
                <h4>Type of Instrument</h4>
                <p className={styles['type-of-instrument']}> Lorem ipsum dolor sit amet consectetur . </p>
              </div>
              <div className={styles['tooltip-container']}>
                <ToolTip data={'Type of Instrument of a Startup.'} bottom left />
              </div>
            </section>
          </div>
        </BlurInfo>
      ) : (
        <div className={styles['deal-term-wrapper']}>
          <div className={styles['deal_text']}>
          <h1>Deal Terms</h1>
          </div>

          <section className={styles['deal-term-statement-container']}>
            <div className={styles['deal-term-statement']}>
              <h4>Minimum Investment</h4>
              <p>
                <span className="rupee">₹</span>
                {formatINR(mainDetail?.min_investment)}
              </p>
            </div>
            <div className={styles['tooltip-container']}>
              <ToolTip data={'Minimum investment required from each individual or single investor'} bottom left />
            </div>
          </section>

          <section className={styles['deal-term-statement-container']}>
            <div className={styles['deal-term-statement']}>
              <h4>Maximum Investment</h4>
              <p>
                <span className="rupee">₹</span>
                {formatINR(mainDetail?.max_investment)}
              </p>
            </div>
            <div className={styles['tooltip-container']}>
              <ToolTip data={'Maximum investment required from each individual or single investor'} bottom left />
            </div>
          </section>

          <section className={styles['deal-term-statement-container']}>
            <div className={styles['deal-term-statement']}>
              <h4>Funding Goal</h4>
              <p>
                <span className="rupee">₹</span>
                {formatINR(mainDetail?.funding_goal)}
              </p>
            </div>
            <div className={styles['tooltip-container']}>
              <ToolTip data={'Maximum amount that startups may raise.'} bottom left />
            </div>
          </section>

          <section className={styles['deal-term-statement-container']}>
            <div className={styles['deal-term-statement']}>
              <h4>Deadline</h4>
              <p>{dateFormat.format(new Date(mainDetail?.deadline))}</p>
            </div>
            <div className={styles['tooltip-container']}>
              <ToolTip data={'The deadline for investing in a startup.'} bottom left />
            </div>
          </section>

          <section className={styles['deal-term-statement-container']}>
            <div className={styles['deal-term-statement']}>
              <h4>Valuation Amount</h4>
              <p>
                <span className="rupee">₹</span>
                {formatINR(mainDetail?.valuation_amount)}
              </p>
            </div>
            <div className={styles['tooltip-container']}>
              <ToolTip data={'Total Valuation Amount'} bottom left />
            </div>
          </section>

          <section className={styles['deal-term-statement-container']}>
            <div className={styles['deal-term-statement']}>
              <h4>Type of Instrument</h4>
              <p className={styles['type-of-instrument']}>{mainDetail?.type_of_instrument}</p>
            </div>
            <div className={styles['tooltip-container']}>
              <ToolTip data={'Type of Instrument of a Startup.'} bottom left />
            </div>
          </section>
          {/* <div className={styles.promo_code}>
            <h3>{mainDetail?.promo_code}</h3>
          </div> */}
        </div>
      )}
    </>
  )
}

export default DealTerms
