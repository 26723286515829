import React, { useState, useRef, useEffect } from 'react'
import style from './ChatBot.module.css'
import ChatBotIcon from '../../Assets/icons/Vectorchat.png'
import arrow from '../../Assets/icons/arrow.png'
import { getQuestions } from '../../services/startup'
import chatbot_icon from './chatbot_icon.svg'
import axios from 'axios'

const ChatBot = () => {
  const inputRef = useRef()
  const btnRef = useRef()
  const [OpenChat, setOpenChat] = useState('chat-container-closed')
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [allPrevious, setAllPrevious] = useState([])
  const [isLoading, seIsLoading] = useState(false)
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [qanda, setQanda] = useState([
    {
      id: 1,
      question: 'Who are you: Investor/Fundraiser?',
      answer: '',
    },
    {
      id: 2,
      question: 'Which sector example: Fintech, Edtech, Healthcare etc?',
      answer: '',
    },
    {
      id: 3,
      question: 'Are you retail/angel?',
      answer: '',
    },
    {
      id: 4,
      question: 'Your name?',
      answer: '',
    },
    {
      id: 5,
      question: 'Your contact number?',
      answer: '',
    },
  ])
  const chating = useRef()

  const openChatBox = () => {
    setOpenChat((prevState) => (prevState === 'chat-container-closed' ? 'chat-container-open' : 'chat-container-closed'))
    document.body.style.overflow = OpenChat === 'chat-container-closed' ? 'hidden' : 'unset'
  }

  const handlenextquestion = () => {
    setCurrentQuestion((prev) => prev + 1)
  }

  const submitanswers = async () => {
    seIsLoading(true)
    const newFormdata = {
      investor_type: allPrevious[0].answer,
      sector: allPrevious[1].answer,
      investment_type: allPrevious[2].answer,
      name: allPrevious[3].answer,
      contact: allPrevious[4].answer,
    }
    const response = await axios.post(`${BASE_URL}postchatbotinfo/`, newFormdata)
    const res = await response.data
    // console.log('this is data', res)
    seIsLoading(false)
    return res
  }

  const updateingquestion = async () => {
    let scrollAmount = 500
    if (currentQuestion + 1 > 5) {
      const response = await submitanswers()
      setAllPrevious([...allPrevious, { question: 'Thank you! We will contact you shortly.', answer: '' }])
      localStorage.setItem('chatbot', JSON.stringify(allPrevious))
    } else {
      setAllPrevious([...allPrevious, qanda[currentQuestion]])
    }
    setTimeout(() => {
      if (chating.current) {
        // chating.current.scroll({ top: chating.current.scrollHeight, behavior: 'smooth' });
        chating.current.scroll({ top: 1000, behavior: 'smooth' })
      }
    }, 100)
  }

  useEffect(() => {
    const storedChatbotData = localStorage.getItem('chatbot')
    if (storedChatbotData) {
      try {
        const parsedData = JSON.parse(storedChatbotData)
        setAllPrevious(parsedData)
      } catch (error) {
        console.error('Error parsing chatbot data from localStorage:', error)
      }
    } else {
      updateingquestion()
    }
  }, [currentQuestion, qanda])

  return (
    <>
      <div className={style[OpenChat === 'chat-container-closed' ? 'chatbot-btn' : 'chatbot-btn-active']} onClick={openChatBox}>
        {OpenChat === 'chat-container-closed' ? (
          <img src={ChatBotIcon} alt="" />
        ) : (
          <svg className={style['close-svg']} fill="#fff" version="1.1" id="Capa_1" width="800px" height="800px" viewBox="0 0 92.132 92.132">
            <g>
              <g>
                <path
                  d="M2.141,89.13c1.425,1.429,3.299,2.142,5.167,2.142c1.869,0,3.742-0.713,5.167-2.142l33.591-33.592L79.657,89.13
        c1.426,1.429,3.299,2.142,5.167,2.142c1.867,0,3.74-0.713,5.167-2.142c2.854-2.854,2.854-7.48,0-10.334L56.398,45.205
        l31.869-31.869c2.855-2.853,2.855-7.481,0-10.334c-2.853-2.855-7.479-2.855-10.334,0L46.065,34.87L14.198,3.001
        c-2.854-2.855-7.481-2.855-10.333,0c-2.855,2.853-2.855,7.481,0,10.334l31.868,31.869L2.143,78.795
        C-0.714,81.648-0.714,86.274,2.141,89.13z"
                />
              </g>
            </g>
          </svg>
        )}
      </div>
      <div className={style[OpenChat]}>
        <div className={style['heading']}>
          <p className={style['name']}>Venturebuddy ChatBot</p>
          <div className={style['online_sts']}>
            {' '}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="6" fill="#88D392" />
            </svg>
            <p>Online</p>{' '}
          </div>
        </div>
        <div className={style['conversation-container']} ref={chating}>
          <p>Hi, I am Venturebuddy Chatbot.</p>
          {allPrevious.map((item, index) => (
            <>
              <div style={{ marginBottom: '10px' }} key={index}>
                <img src={chatbot_icon} style={{ marginBottom: '15px' }} />
                <div style={{ backgroundColor: '#E9EBF4', color: '#08090A', borderRadius: '24px', height: 'auto', maxWidth: '84%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '15px', textAlign: 'center' }}>
                  <p key={index}>{item.question}</p>
                </div>
                {item.answer === '' ? (
                  ''
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                    <div style={{ backgroundColor: '#4DBC5C', borderRadius: '24px', color: '#FFFFFF', height: 'auto', maxWidth: '84%', textAlign: 'center', paddingTop: '15px', paddingRight: '10px', paddingLeft: '10px' }}>
                      <p key={index}>{item?.answer}</p>
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
          {isLoading ? 'Loading...' : ''}
        </div>
        <div className={style['message-input']}>
          <input
            type="text"
            placeholder="Type your query"
            ref={inputRef}
            // value={allPrevious[0]?.question || ""}
            onChange={(e) => {
              allPrevious[currentQuestion].answer = e.target.value
            }}
          />
          <button
            className={style['send-btn']}
            ref={btnRef}
            onClick={() => {
              handlenextquestion()
              inputRef.current.value = ''
            }}
          >
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.94596 7.3126C0.42396 7.1386 0.41896 6.8576 0.95596 6.6786L20.043 0.316599C20.572 0.140599 20.875 0.436599 20.727 0.954599L15.273 20.0406C15.123 20.5696 14.818 20.5876 14.594 20.0856L11 11.9976L17 3.9976L8.99996 9.9976L0.94596 7.3126Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}

export default ChatBot
