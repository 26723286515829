import React from "react";
import styles from "./AboutStartUp.module.css";
import checkCircle from "./../../Assets/icons/check-circle.png";
import useStartupCtx from "../../hooks/useStartupCtx";
import { markupText } from "../../utils";

const AboutStartUp = ({ sectionHeader, borderBottom }) => {
  // Note This File is in use
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail } = startupFullDetails;

  return (
    <div data-scroll-id={"about"} className={styles["about-startup-wrapper"]}>
      <h1 className={sectionHeader}>
        About 
        <div className={borderBottom}></div>
      </h1>
      <p dangerouslySetInnerHTML={{ __html: markupText(mainDetail?.about_startup_long, "\r\n", "br") }}></p>

      {mainDetail.about_startup_points?.map((str) => {
        return (
          <div className={styles["list-item"]}>
            <img src={checkCircle} className={styles["checked-circle"]} alt="" />
            <p className="mt-0" style={{ fontWeight: "400" }}>{str}</p>
          </div>
        );
      })}
    </div>
  );
};

export default AboutStartUp;
