import React from "react";
import styles from "./Carousel.module.css";

const  CarouselSlide = ({ image, header, paragraph }) => {
  // Note This File is in use
  return (
    <div className="position-relative rounded-4" id={`${styles.mobile_carousel}`}>
    <div id={`${styles.img_div}`}>
      <img src={image.replace("http://", "https://")} id={styles.img} className="img-fluid" alt="" />
    </div>
    
      <div
        className={`position-absolute bottom-0  text-white  p-lg-4 pe-lg-5 ${styles.carText}`}
      >
        <h3>{header}</h3>
        <p className={`text-white ${styles["carousel-img-text"]}`}>{paragraph}</p>
      </div>
    </div>
  );
};

export default CarouselSlide;
