import React from "react";
import styles from "./OutTeam.module.css";
import useStartupCtx from "../../hooks/useStartupCtx";

const OutTeam = ({ sectionHeader, borderBottom }) => {
  // Note This file is in use
  const { startupFullDetails } = useStartupCtx();

  const { founders } = startupFullDetails;

  return (
    <div data-scroll-id="our-team" className={styles["our-team-wrapper"]}>
      <h1 className={sectionHeader}>
        Our Team
        <div className={borderBottom}></div>
      </h1>

      {founders?.results?.map((founder) => {
        return (
          <div className={styles["member-container"]} id={styles.emilyStern}>
            <div>
              <img className={styles["member-profile"]} src={founder?.profile?.replace("http://", "https://")} alt="" />
            </div>
            <div className={styles["member-detail"]}>
              <div className={styles["member-detail-header"]}>
                <h3>{founder?.founder_name}</h3>
                <p>{founder?.position}</p>
              </div>
              <p className={styles["member-detail-desc"]} style={{ fontWeight: "400" }}>
                {founder?.describtion}
              </p>
            </div>
          </div>
        );
      })}

      {/* <div className="col-10 ms-auto" id={styles.more}>
        <div className={styles.memberDesc}>
          {mainDetail?.employee_strength && (
            <div className="d-flex align-items-center" style={{ marginTop: "17.5px" }}>
              <h1>{mainDetail.employee_strength}+ others at office and other remote locations</h1>
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default OutTeam;
