import retry from 'retry'
export const validateEmail = (email) => {
  let a = String(email)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  return a !== null ? true : false
}

export const validatePhone = (phone) => {
  let a = String(phone).match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
  return a !== null ? true : false
}
export const validatePassword = (password) => {
  let a = String(password).match(/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{6,}).*$/g)
  return a !== null ? true : false
}
export const validatePanNumber = (pan) => {
  var panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  return panRegex.test(pan.toUpperCase());
}

export const isEmptyField = (value) => {
  let a = String(value).match(/((\r\n|\n|\r)$)|(^(\r\n|\n|\r))|^\s*$/gm)
  return a !== null ? true : false
}

export const formatINR = (number) => {
  const intlInr = new Intl.NumberFormat('en-IN', { currency: 'INR' })
  return intlInr.format(number)
}

export const markupText = (text, identifier, htmltag) => {
  var array = text.split(identifier)
  var previous = ''
  var previous_i
  for (let i = 0; i < array.length; i++) {
    if (i % 2) {
      //odd number
    } else if (i != 0) {
      previous_i = eval(i - 1)
      array[previous_i] = '<' + htmltag + '>' + previous + '</' + htmltag + '>'
    }
    previous = array[i]
  }
  var newtext = ''
  for (let i = 0; i < array.length; i++) {
    newtext += array[i]
  }
  return newtext
}
export const operation = retry.operation({
  retries: 3,
  factor: 2,
  minTimeout: 1000,
  maxTimeout: 10000,
  randomize: true,
})
