import React from "react";
// import Alert from "../../components/Alert";
import Alert from "../../components/Alert/Alert";
import CopyReferCode from "../../components/CopyReferCode/CopyReferCode";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "./ReferAndEarn.module.css";

const ReferAndEarn = () => {
  return (
    <div id={styles.referBG}>
    
      {/* 

      <div className="content"> */}
      <div id={styles.referContent}>
        {/* <Alert /> */}
        <CopyReferCode />
      </div>
    </div>
  );
};

export default ReferAndEarn;
