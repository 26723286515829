import axios from 'axios';
import React, { useEffect, useState } from 'react'
import style from '../discussion_comment/DiscussionC.module.css'
import { format } from 'timeago.js';

const ReplyComponent = ({item}) => {
    const [userdetail, setUserDetail] = useState(null);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // console.log(BASE_URL)
    useEffect(() => {
        const handleUserData = async () => {
            const headers = {
                'Content-type': 'application/json'
            };
            try {
                const response = await axios.get(`${BASE_URL}getuser/${item?.user}/`);
                const res = response.data; 
                setUserDetail(res['Users'][0]);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        handleUserData();
    }, [item?.user]);

    // console.log("this is reply comment response",userdetail)
  return (
    <div>
        <div className={style['reply_component']}>
         <div className={style['reply_profile']}>
            <div className={style['reply_profile_image']}>
            <img 
    src={userdetail?.profile_pic ? `${BASE_URL}media/${userdetail.profile_pic}` : 'https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg'} 
    alt="Profile Picture" 
/>

            </div>
           <div className={style['content2']}>
           <h1>{`${userdetail?.first_name || ''} ${userdetail?.last_name || ''}`}</h1>
           <p>{item?.comment}</p>
           </div>
            
         </div>
         <div className={style['time_ago']}>{format(item?.timestamp)}</div>

        </div>
    </div>
  )
}

export default ReplyComponent