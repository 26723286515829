import React, { useState, useEffect } from 'react'
import style from './Fianantial.module.css'
import styles from '../pitchdeck/pitchdeck.module.css'
import finacial from './fianantial_assests/finacial_1.svg'
import finacial2 from './fianantial_assests/finacial_2.svg'
import finacia3 from './fianantial_assests/finacial_3.svg'
import Star from '../pitchdeck/pich_assest/star.svg'
import client_image from '../pitchdeck/pich_assest/client_image.svg'
import Style2 from '../../../components/Organizations/Organization.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'
import dhl from '../../../Assets/images/organizations/client-1-removebg-preview.png'
import next from '../../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from '../../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from '../../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from '../../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from '../../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from '../../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from '../../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from '../../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from '../../../Assets/images/organizations/client-13-removebg-preview.png'
import BlogsSlider from '../blogs/BlogsSlider'
import Faq3 from '../faqs/Faq3'
import Faq4 from '../faqs/Faq4'
import GetTouchItAll from '../gettouchit/GetTouchItAll'
import Footer from '../../../components/Footer/Footer'
import styles1 from '../pitchdeck/pitchdeck.module.css'
import back from '../pitchdeck/pich_assest/path_bg.svg'
import hero from './fianantial_assests/finacial_hero.svg'
import help_right from './fianantial_assests/help_left_image.svg'
import bg_green from './fianantial_assests/Illustration.svg'
import client1 from './fianantial_assests/business_1.svg'
import client2 from './fianantial_assests/business_2.svg'
import client3 from './fianantial_assests/business_3.svg'
import client4 from './fianantial_assests/business_4.svg'
import client5 from './fianantial_assests/business_5.svg'
import client6 from './fianantial_assests/business_6.svg'
import MobileBlogs from '../blogs/mobileblogs/MobileBlogs'
import MobileClientView from '../MobileClient/MobileClientView'
import MobileViewClients from '../MobileViewClients/MobileViewClients'
import Testimonial from '../../../components/Testimonial/Testimonial'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Fianantial1 = () => {
  const [indexed, setIndexed] = useState(0)
  const [showMain2, setShowMain2] = useState(false)
  const navigate = useNavigate()
  const pitchdata = [
    {
      title: '1. High in Accuracy',
      detail: "Benefit from precise and reliable financial models that lay the groundwork for your venture's success.",
      Image: finacial,
    },
    {
      title: '2. Maximum Automation',
      detail: 'Harness the power of automation to simplify complex financial calculations and modelling tasks.',
      Image: finacial2,
    },
    {
      title: '3. Save Time',
      detail: 'Streamline your financial planning process and save valuable time for strategic decision-making.',
      Image: finacia3,
    },
  ]
  const clientReview = [
    {
      id: 1,
      name: 'Rohit Ransore',
      status: 'INVESTER',
      profession: 'UX DESIGNER,ATG',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 2,
      name: 'Jeniffer White',
      status: 'INVESTER',
      profession: 'Vice President, Google',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 3,
      name: 'Niromi Taylor',
      status: 'BUSNINESS',
      profession: 'Senior Manager, IKEA',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
  ]

  function startprinting() {
    let start = clientReview[indexed].review[0].review_star
    let starts = []

    for (let i = 1; i <= start; i++) {
      starts.push(<img src={Star} />)
    }

    return starts
  }
  const data = [
    {
      question: 'How does your process work for creating custom pitch decks?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What sets your pitch deck services apart from others?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'Is there a sample report or a free trial available?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What is the authenticity of the tool?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'How does Venturebuddy protect my data?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What information do you need from clients to start working on their pitch deck?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
  ]

  const clientlogos = [client1, client2, client3, client4, client5, client6]

  const handlenavigate = () => {
    navigate('/contact')
  }

  useEffect(() => {
    // Delay the visibility of the main2 section
    const timer = setTimeout(() => {
      setShowMain2(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div >
      <section className={style['main1']} >
        <div className={styles1['pitch_hero']} style={{ width: '100%' }}>
          <img src={back} className={style['back_image']} />
          <div className={styles1['detail_hero']}>
            <div>
              <div className={styles1['detail']}>
                <h1 className={styles1['hero_text']} style={{ lineHeight: '70px' }}>
                  Unlock Your Business Potential with
                  <br /> Expert Financial Modeling
                </h1>
                <h1 className={style['hero_text2']} style={{ textAlign: 'center', paddingLeft: '5px', paddingRight: '5px' }}>
                  Unlock Your Business Potential with Expert Financial Modeling
                </h1>
                <p style={{ fontWeight: '400', color: '#939CA3' }}>VentureBuddy's Financial Modeling Service is Your Key to Success</p>
              </div>
              <div className={styles1['pitch_image_1']} >
                <img src={hero} className={styles1['pitch_image']} />
              </div>
              <div className={styles1['download_btn']} style={{ marginTop: '60px' }}>
                <button style={{ width: '185px' }} onClick={() => handlenavigate()}>
                  Get Started now!
                </button>
              </div>
            </div>
          </div>
          <div className={styles1['white_bg']} />
        </div>
      </section>
      {showMain2 && (
        <section className={style['main2']}>
          <div className={styles['wrapper_pitch']}>
            <div className={styles['pitch_deck_detail1']}>
              <div className={styles['deatil_2']} style={{ width: '100%' }}>
                <h1>Turn your idea into success with Venturebuddy</h1>
                <div className={styles['small_detail']} style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{ width: '50%', fontWeight: '500', color: '#6D747A' }}>Struggling to turn your vision into a viable venture? VentureBuddy is your trusted partner in financial modeling. Our expert service empowers you to bring your ideas to life with precision and confidence.</p>
                </div>
              </div>
              <br />
              <div className={styles['detail_list']}>
                {pitchdata.map((item, index) => (
                  <>
                    <div className={styles['pitch_deatil_card']} key={index}>
                      <img src={item.Image} />
                      <h2>{item.title}</h2>
                      <div className={styles['detail_03']}>
                        <h6>{item.detail}</h6>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main3']}>
          <div className={style['helphing_wrapper']}>
            <div className={style['helping_left_side']}>
              <div className={style['hepling_right_side2']}>
                <img src={help_right} />
              </div>
              <h1>
                How VentureBuddy Helps
                <br /> Businesses
              </h1>
              <p>VentureBuddy empowers businesses by providing comprehensive financial modeling solutions that streamline operations, enhance decision-making, and drive sustainable growth. Whether you're a startup founder or a seasoned entrepreneur, our services are tailored to meet your unique needs and accelerate your path to success.</p>
              <h2>Founder and Small Companies</h2>
              <p>Discover how VentureBuddy can streamline your financial modeling process and turn your business idea into a reality. Say goodbye to tedious data entry and embrace effortless financial planning with our expert services!</p>
              <h2>Mid-Sized Businesses</h2>
              <p>Unlock the strategic insights you need to drive growth and success with VentureBuddy. Our tailored financial modeling solutions empower mid-sized businesses to make informed decisions and navigate the path to expansion with confidence.</p>
            </div>
            <div className={style['hepling_right_side']}>
              <img src={help_right} />
            </div>
          </div>
          <div className={styles1['download_btn']} style={{ marginTop: '20px' }}>
            <button style={{ width: '138px' }} onClick={() => handlenavigate()}>
              Contact Us
            </button>
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main4']}>
        
          <Testimonial />
        </section>
      )}
      {/* <div className={styles['client_about_detail']}>
        <h1>What Clients say About us</h1>
      </div> */}
      {showMain2 && (
        <div className={style['mobile_view_client']} style={{marginTop:'10%'}}>
        
          <MobileClientView />
        </div>
      )}
      <br />
      {showMain2 && (
        <section className={style['main5']} style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
          <div id="Style2.organizations" className="container-fluid" style={{ height: '705px', background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
            <div style={{ paddingTop: '250px', width: '100%' }}>
              <h4 className="text-blue text-center" id={Style2.orgHead}>
                Our Top Clients
              </h4>
              {/* Desktop Slider Start Here */}
              <div className={style['company_wrapper']}>
                <div className={style['company_main']}>
                  <img src={client1} />
                  <img src={client2} />
                  <img src={client3} />
                  <img src={client4} />
                  <img src={client5} />
                  <img src={client6} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main05']}>
          <MobileViewClients clientlogos={clientlogos} />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main6']}>
          <BlogsSlider />
        </section>
      )}
      {showMain2 && (
        <section className={style['main06']}>
          <MobileBlogs />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main7']} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '750px' }}>
            <Faq3 data={data} page="Fianantial modelling"/>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main8']}>
          <GetTouchItAll />
        </section>
      )}
      {showMain2 &&( <div className={style.footerstyle}>
        <Footer />
      </div> 
      )}
    </div>
  )
}

export default Fianantial1
