import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Error from "../../Assets/vector/Error";
import { sendAadharOtp, verifyAadharOtp } from "../../services/account";
import { isEmptyField } from "../../utils";

import styles from "./KycAadharDetail.module.css";

const KycAadharDetail = ({ userKycDetails, setUserKycDetails }) => {
  // Note This file is in use
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);

  const [sendOtpBtnDisabled, setSendOtpBtnDisabled] = useState(false);
  const [sendOtpBtnLoading, setSendOtpBtnLoading] = useState(false);

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isInitialOtpSent, setIsInitialOtpSent] = useState(false);
  const [resendOtpTimer, setResendOtpTimer] = useState(60);
  const [resendOtpBtnActive, setResendOtpBtnActive] = useState(false);

  const [otp, setOtp] = useState("");
  const [refId, setRefId] = useState("");
  const [inputErrMsgs, setInputErrMsgs] = useState({ aadhar_card_number: null, otp: null });
  let timerInterval;
  const startResendOtpTimer = () => {
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      setResendOtpTimer((prev) => {
        if (prev === 0) {
          clearInterval(timerInterval);
          setResendOtpBtnActive(true);
          setResendOtpTimer(60);
          setSendOtpBtnDisabled(false);
          setIsOtpSent(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleKycAadharDetailSubmit = (e) => {
    e.preventDefault();
    document.getElementById("next").click();
    document.getElementById("stepThree").classList.remove("step-active");
    document.getElementById("stepThree").classList.add("step-done");
    document.getElementById("stepFour").classList.add("step-active");
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!userKycDetails.aadhar_card_number.match(/^\d{12}$/g))
      return setInputErrMsgs((prev) => {
        return { aadhar_card_number: "Please enter a valid Aadhar number!" };
      });

    const formData = new FormData();
    formData.append("aadhar_number", userKycDetails.aadhar_card_number);

    setSendOtpBtnDisabled(true);
    setSendOtpBtnLoading(true);

    sendAadharOtp(formData, (err, res) => {
      setSendOtpBtnLoading(false);
      if (err || res.status !== 200) {
        setSendOtpBtnDisabled(false);
        toast.error("Something went wrong! Please try again.");
      }
      // console.log("this is aadhar ",res)
      if (res.data.status === 400 && res.data.message === "user with this AADHAR number Already Exist") {
        setSendOtpBtnDisabled(false);
        toast.error("This Aadhar Number is already registered!");
        return setInputErrMsgs((prev) => {
          return { aadhar_card_number: "This Aadhar Number is already registered!" };
        });
      }
      if (res.data.status === 400 && res.data?.message?.code === "Verification_under_process" && res.data?.message?.message === "Otp generated for this aadhaar, please try after 45 sec") {
        toast.error("OTP generated for this aadhaar.. please try after 45 seconds.");
        setSendOtpBtnDisabled(false);
        return setInputErrMsgs((prev) => {
          return { aadhar_card_number: "OTP generated for this Aadhaar. please try after 45 seconds." };
        });
      }
      if (res.data.status === 400 && res.data?.message?.code === "verification_failed" && res.data?.message?.message === "Verification attempt failed") {
        toast.error("Aadhar Verification Failed, please try again..");
        setSendOtpBtnDisabled(false);
      }

      if (res.data.status !== 200) {
        toast.error("Aadhar number is incorrect!");
        setSendOtpBtnDisabled(false);
        return setInputErrMsgs((prev) => {
          return { aadhar_card_number: "Please enter a valid Aadhar number!" };
        });
      }

      if (res.data.status === 200 && res.data.response.status === "SUCCESS") {
        setRefId(res.data.response.ref_id);
        setIsOtpSent(true);
        setIsInitialOtpSent(true);
        startResendOtpTimer();
        toast.success("OTP sent to registered number!");
        return setInputErrMsgs((prev) => {
          return { aadhar_card_number: null };
        });
      }
    });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (isEmptyField(otp))
      return setInputErrMsgs((prev) => {
        return { ...prev, otp: "Please enter valid OTP!" };
      });
    const formData = new FormData();
    formData.append("ref_id", refId);
    formData.append("otp", otp);
    setVerifyBtnLoading(true);
    verifyAadharOtp(formData, (err, res) => {
      setVerifyBtnLoading(false);
      // console.log("this is aadhar data",res)
      if (err || res?.status !== 200) return toast.error("Something went wrong!");
      if (res?.data?.status !== 200) {
        if (res?.data?.message === "Processing request, please try after 35 sec") {
          toast.error("Please try again!");
          setInputErrMsgs((prev) => {
            return {
              ...prev,
              otp: "Processing request, please try after 45 sec!",
            };
          });
        } else {
          setInputErrMsgs((prev) => {
            return { ...prev, otp: "OTP is invalid!" };
          });
          toast.error("OTP is invalid 1!");
        }
      }
      if (res.data.status === 200) {
        setUserKycDetails((prev) => {
          return { ...prev, isAadharVarified: true };
        });
        toast.success("OTP verified successfully!");
        document.getElementById("next").click();
        document.getElementById("stepTwo").classList.remove("step-active");
        document.getElementById("stepTwo").classList.add("step-done");
        document.getElementById("stepThree").classList.add("step-active");
      }
    });
  };

  return (
    <div id={styles.KycAadharDetailContainer}>
      {/* <img src={leftArrow} onClick={() => document.getElementById("prev").click()} id={styles.arrow} alt="arrow" /> */}
      <form onSubmit={handleKycAadharDetailSubmit}>
        <h1>Verify your Aadhar details</h1>
        <h3 style={{fontWeight:'400'}}>Become an investor in few simple steps</h3>

        <div className="" id={styles.KycAadharDetail}>
          <div id={styles.accountNumber} className={` ${styles.a_no}`}>
            <h2>Aadhar card</h2>
            <div className={`${inputErrMsgs.aadhar_card_number && "form-input-err"} d-flex justify-content-between align-items-start gap-4 ${styles.card_no}`}>
              <div className="" style={{ width: "100%" }}>
                <input
                  type="text"
                  className="form-control col-5"
                  name="aadhar_card_number"
                  placeholder="Aadhar card number"
                  maxLength={12}
                  minLength={12}
                  value={userKycDetails.aadhar_card_number}
                  disabled={isInitialOtpSent}
                  style={{width:'336px'}}
                  onChange={(e) => {
                    setInputErrMsgs((prev) => {
                      return { ...prev, aadhar_card_number: null };
                    });
                    setUserKycDetails((prev) => {
                      return {
                        ...prev,
                        aadhar_card_number: e.target.value,
                      };
                    });
                  }}
                />
                {inputErrMsgs.aadhar_card_number && (
                  <p className={`input-err-msg`}>
                    <Error fill="red" width="15px" height="15px" />
                    <span>{inputErrMsgs.aadhar_card_number}</span>
                  </p>
                )}
              </div>

              <button
                type="submit"
                onClick={sendOtp}
                disabled={sendOtpBtnDisabled || !userKycDetails.aadhar_card_number}
                className={`btn-blue px-0 ${styles["send-otp-btn"]}`}
                style={{ height: "48px", width: "140px", padding: "0 auto" }}
              >
                {sendOtpBtnLoading ? (
                  <svg
                    style={{
                      margin: "auto",
                      background: "rgba(221, 221, 221, 0)",
                      display: "block",
                    }}
                    width="140px"
                    height="40px"
                    viewBox="0 0 100 100"
                  >
                    <circle cx={50} cy={50} fill="none" stroke="#fff" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                    </circle>
                  </svg>
                ) : isOtpSent ? (
                  `Resend OTP in ${resendOtpTimer} sec`
                ) : resendOtpBtnActive ? (
                  "Resend OTP"
                ) : (
                  <p style={{width:'140px',paddingTop:'2px'}}>Send OTP</p>
                )}
              </button>
            </div>
          </div>
          <div className={`${styles.verify}`}>
            <div id={styles.ifscCode} className={` ${styles.otp_no}`}>
              <h2>OTP</h2>
              <div className={`${inputErrMsgs.otp && "form-input-err"}`}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="One-time password"
                  name="ifsc"
                  disabled={!isInitialOtpSent}
                  value={otp}
                  style={{width:'336px',fontWeight:'600'}}
                  onChange={(e) => {
                    setInputErrMsgs((prev) => {
                      return { ...prev, otp: null };
                    });
                    setOtp(e.target.value);
                  }}
                />

                {inputErrMsgs.otp && (
                  <p className={`input-err-msg`}>
                    <Error fill="red" width="15px" height="15px" />
                    <span>{inputErrMsgs.otp}</span>
                  </p>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                onClick={verifyOtp}
                disabled={!userKycDetails.aadhar_card_number || !otp || verifyBtnLoading}
                className={`btn-blue px-5 ${styles["verify-btn"]}`}
                style={{ height: "48px", width: "140px", padding: "0 auto" ,fontWeight:'600'}}
              >
                {verifyBtnLoading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{
                      margin: "auto",
                      background: "rgba(221, 221, 221, 0)",
                      display: "block",
                    }}
                    width="40px"
                    height="40px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <circle cx={50} cy={50} fill="none" stroke="#fff" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                    </circle>
                  </svg>
                ) : (
                  "Verify"
                )}
              </button>
              {verifyBtnLoading && <p>Please wait.. We are processing your details.</p>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default KycAadharDetail;
