import { useEffect, useState, useContext } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './App.css'
import Alert from './components/Alert/Alert'
import Navbar from './components/Navbar/Navbar'
import Sidebar from './components/Sidebar/Sidebar'
import BecomeInvestor from './Pages/BecomeInvestor/BecomeInvestor'
import Dashboard from './Pages/Dashboard/Dashboard'
import Home from './Pages/Home/Home'
import LiveDeals from './Pages/LiveDeals/LiveDeals'
import Login from './Pages/Login/Login'
import ReferAndEarn from './Pages/ReferAndEarn/ReferAndEarn'
import Register from './Pages/Register/Register'
import InvestNowProcess from './Pages/InvestNowProcess/InvestNowProcess'
import TermsAndConditionPage from './Pages/TermsAndConditionPage/TermsAndConditionPage'
import PaymentSuccessFul from './Pages/PaymentSuccessFul/PaymentSuccessFul'
import MyInvestments from './Pages/MyInvestments/MyInvestments'
import InvestmentDetail from './Pages/InvestmentDetail/InvestmentDetail'
import RequireAuth from './Pages/RequireAuth'
import FAQ from './components/FAQ/FAQ'
import About from './components/StartupDetailBody/StartupDetailBody'
import useAuthCtx from './hooks/useAuthCtx'
import { getUserData } from './services/account'
import Explore from './Pages/Explore'
import StartExplore from './Pages/StartExplore/StartExplore'
import SavedDeals from './Pages/SavedDeals/SavedDeals'
import ListingPage from './components/ListingPage'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
import { toast, ToastContainer } from 'react-toastify'
import FinishSignupModal from './components/FinishSignupModal/FinishSignupModal'
import { isEmptyField } from './utils'
import AboutUs from './Pages/AboutUs/AboutUs'
import FundRaising from './Pages/FundRaising/FundRaising'
import Profilesetup from './Pages/profileSetup/Profilesetup'
import AddFundRaise from './Pages/AddFundRaise/AddFundRaise'
import logo from './Assets/logo-full.png'
import Wallet from './Pages/wallet/Wallet'
import AddAmount from './Pages/addamount/AddAmount'
import Withdraw from './Pages/withdraw/Withdraw'
import Confirm_withdraw from './Pages/confirmWithdraw/Confirm_withdraw'
import Community from './Pages/Community/Community'
import Comments from './Pages/Comments/Comments'
import NewsLetter from './Pages/NewsLetter/NewsLetter'
import Blog from './Pages/Blog/Blog'
import Contact from './Pages/ContactUs/Contact'
import Wealthmanager from './Pages/WealthManager/Wealthmanager'
import Support from './components/Support/Support'
import Profilepreview from './Pages/profileSetup/Profilepreview'
import NewPost from './Pages/Comments/Post/NewPost'
import ReactGa from 'react-ga'
import PitchDeck from './Pages/OtherServiceUi/pitchdeck/PitchDeck'
import VirtualCfo1 from './Pages/OtherServiceUi/VirtualCfo/VirtualCfo1'
import BookingKeeping1 from './Pages/OtherServiceUi/BookKeeping/BookingKeeping1'
import Fianantial1 from './Pages/OtherServiceUi/Finacial/Fianantial1'
import Overseas1 from './Pages/OtherServiceUi/Oversease/Overseas1'
import BusinessPlan from './Pages/OtherServiceUi/BusinessPlan/BusinessPlan'


function App() {
  const location = useLocation()
  const navigate = useNavigate()

  const [arrowVisibility, setArrowVisibility] = useState(false)
  const { isLoggedIn, setIsLoggedIn, setUser, showFinishSignupModal, setShowFinishSignupModal } = useAuthCtx()
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      setIsLoading(false)
      setIsLoggedIn(false)
      return
    }
    getUserData((err, res) => {
      if (err) {
        setIsLoading(false)
        setIsLoggedIn(false)
        // localStorage.removeItem("access_token");
        return
      }

      setIsLoading(false)
      setIsLoggedIn(true)
      setUser(res.data?.[0])

      if (isEmptyField(res.data?.[0]?.deals_type) && res.data?.[0]?.start_up_type?.length === 0) {
        setShowFinishSignupModal(true)
        toast.info('Complete the Signup to continue!', {
          toastId: 'complete-signup',
        })
        return
      }
    })
  }, [])

  
  useEffect(() => {
    if(isLoggedIn === true)
    getUserData((err, res) => {
      if (err) return
      setUser(res.data?.[0])
    })
  }, [isLoggedIn])
  // Add All new location Here
  const LocationArray = ['/', '/explore', '/terms', '/login', '/register', '/startup', '/privacy-policy', '/aboutus', '/fundraising', '/add-fundraise', '/community', '/comments', '/newsletter', '/blog/', '/contact', '/wealth-manager','/post','/otherservice/PitchDeck','/otherservice/VirtualCFO','/otherservice/BookkeepingAndAccounting','/otherservice/FinancialModelling','/otherservice/OverseasCompanyRegistration','/otherservice/FinancialDueDiligence']
  if (isLoading)
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          background: '#dedcdccf',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={logo} width="200" alt="" />
      </div>
    )
  return (
    <>
      {LocationArray.includes(location.pathname) && <Navbar arrowVisibility={arrowVisibility} setArrowVisibility={setArrowVisibility}></Navbar>}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/startup/:slug" element={<ListingPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index path="live-deals" element={<LiveDeals />} />
            <Route path="deal/:slug" element={<ListingPage />} />
            <Route path="deal/:slug/invest" element={<InvestNowProcess />} />
            {/* FIXME: Temprary changes for my-investment route */}
            {/* <Route path="my-investment" element={<StartExplore heading="Currently, there are no Investment done." para="Invest now, what are you waiting for?" />} /> */}
            <Route path="my-investment" element={<MyInvestments />} />
            <Route path="my-investment/:id" element={<InvestmentDetail />} />
            <Route path="saved-deals" element={<SavedDeals />} />
            {/* <Route
              path="wallet"
              element={<StartExplore heading="" para="" />}
            /> */}
            <Route path="profile/profile-edit" element={<Profilesetup />} />
            <Route path="profile" element={<Profilepreview />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="wallet/add-amount" element={<AddAmount />} />
            <Route path="wallet/withdraw" element={<Withdraw />} />
            <Route path="wallet/withdraw/confirm" element={<Confirm_withdraw />} />
            <Route path="tools" element={<StartExplore heading="" para="" />} />
            <Route path="refer-and-earn" element={<ReferAndEarn />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="support" element={<Support />} />
          </Route>
          <Route path="/become-investor" element={<BecomeInvestor />} />
          <Route path="/payment-check" element={<PaymentSuccessFul />} />
        </Route>
        <Route path="/refer" element={<ReferAndEarn />} />
        <Route path="/terms" element={<TermsAndConditionPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/fundraising" element={<FundRaising />} />
        <Route path="/add-fundraise" element={<AddFundRaise />} />
        <Route path="/community" element={<Community />} />
        <Route path="/comments" element={<Comments />} />
        <Route path="/newsletter" element={<NewsLetter />} />
        <Route path="/blog/:slug" element={<Blog />} />
        <Route path="/wealth-manager" element={<Wealthmanager />} />
        <Route path="/post" element={<NewPost />} />
        <Route path='/otherservice/PitchDeck' element={<PitchDeck/>} />
        <Route path='/otherservice/VirtualCFO' element={<VirtualCfo1/>} />
        <Route path='/otherservice/BookkeepingAndAccounting' element={<BookingKeeping1/>} />
        <Route path='/otherservice/FinancialModelling' element={<Fianantial1/>} />
        <Route path='/otherservice/OverseasCompanyRegistration' element={<Overseas1/>} />
        <Route path='/otherservice/FinancialDueDiligence' element={<BusinessPlan/>} />





      </Routes>

      {showFinishSignupModal && <FinishSignupModal />}
      <ToastContainer position="bottom-left" autoClose={4000} newestOnTop={true} closeOnClick={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="dark" />
    </>
  )
}

export default App
