import React, { useState } from 'react'
import style from './gettouchit.module.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const GetTouchItAll = () => {
    const [userdata,setUserdata]=useState(
        {
            name:"",
            email:""
        }
    )
    const [isLoading,setIsLoading]=useState(false)
    const BASE_URL  = process.env.REACT_APP_BASE_URL

    const handlesubmit = async () => {
        try {
            const { name, email } = userdata;
            const newFormdata = {
                full_name: name,
                email
            };
            setIsLoading(true); 
            if (name === "" || email === "") {
                toast.error("Please fill all required fields.");
                setIsLoading(false);
                return
            }
            if (!validateName(name)) {
                toast.error("Please enter a valid name (letters and spaces only).");
                setIsLoading(false);
                return;
            }
            if (!validateEmail(email)) {
                toast.error("Please enter a valid email address.");
                setIsLoading(false);
                return;
            }
            const response = await axios.post(`${BASE_URL}getintouch/`, newFormdata);
            setIsLoading(false);
            setUserdata({
                name:"",
                email:""
            })
            toast.success('Form submitted successfully!');
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            toast.error('Failed to submit form. Please try again later.');
        }
    };
    const validateName = (name) => {
        const regex = /^[a-zA-Z\s]*$/;
        return regex.test(name);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
  return (
   <>
    <div className={style['gettouch']}>
        <div className={style['gettouch_heading']}>
            <h1>Get In Touch With Us</h1>
            <h3>We are here to help and answer any questions you might have or any<br/> services you are looking for!</h3>
        </div>
        <div className={style['gettouch_wrapp']}>
            <div className={style['gettouch_wrapper']}>
                <div className={style['whole_gettouch_wrapper']}>
                <div className={style['inputs_gettouch']}>
                       <div className={style['input_01']}>
                        <label>Name</label>
                        <input value={userdata.name} type='text' placeholder='Enter your name'  onChange={(e) => setUserdata({ ...userdata, name: e.target.value })}/>
                       </div>
                       <div className={style['input_02']}>
                        <label>Email ID</label>
                        <input value={userdata.email} type='text' placeholder='Enter your email id'      onChange={(e) => setUserdata({ ...userdata, email: e.target.value })}/>
                       </div>
                </div>
                <button    
                disabled={isLoading}
                onClick={handlesubmit}
                style={{ opacity: isLoading ? 0.5 : 1 }} 
                className={style['gettouch_send']}>SEND</button>
                </div>
            </div>
        </div>
    </div>
    {/* <ToastContainer/> */}
   </>
  )
}

export default GetTouchItAll