import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./LoginPass.module.css";
import { useRef } from "react";
import ErrorIcon from "../../Assets/vector/Error";
import { isEmptyField } from "../../utils";
import { generateOtpRegister } from "../../services/account";
import { toast } from "react-toastify";

const LoginPass = ({ handleLogin, loginData, setLoginData }) => {
  // Note This file is in use
  const passInputRef = useRef(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passErrMsg, setPassErrMsg] = useState(null);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [requestOtpBtnLoading, setRequestOtpBtnLoading] = useState(false);

  const submitPassword = async () => {
    if (isEmptyField(loginData.password)) return setPassErrMsg("This field can not be empty!");
    setSubmitBtnLoading(true);
    await handleLogin();
    setSubmitBtnLoading(false);
  };

  const handleRequestOtp = () => {
    setRequestOtpBtnLoading(true);
    generateOtpRegister(loginData.username, (err, res) => {
      setRequestOtpBtnLoading(false);
      if (err) return toast.error("Something went wrong!");
      setLoginData((prev) => {
        return { ...prev, type: "otp" };
      });
    });
  };

  return (
    <div id={styles.LoginRight} className="text-dark">
      <h1>Login</h1>
      <h6 style={{ fontWeight: 500, marginTop: 18, marginBottom: 12 }}>Password</h6>
      <div id={styles.password}>
        <div className={`${passErrMsg && "form-input-err"}`}>
          <input
            ref={passInputRef}
            type={isPasswordVisible ? "text" : "password"}
            className="form-control p-3 "
            id="passwordField"
            autoComplete="off"
            placeholder="your password"
            value={loginData.password}
            onChange={(e) => {
              setPassErrMsg(null);
              setLoginData((prev) => {
                return { ...prev, password: e.target.value };
              });
              
            }}
            required
            
            style={isPasswordVisible ? { "-webkit-text-security": "star" } : {}}
          />

          <label id={styles.eye} htmlFor={styles.check}>
            <i className={`fas ${isPasswordVisible ? "fa-eye" : "fa-eye-slash"}`}></i>
          </label>

          <input type="checkbox" id={styles.check} onChange={() => setIsPasswordVisible(!isPasswordVisible)} />
        </div>
      </div>
      {passErrMsg && (
        <p className={`input-err-msg`}>
          <ErrorIcon fill="red" width="15px" height="15px" />
          <span>{passErrMsg}</span>
        </p>
      )}

      <input disabled={submitBtnLoading} type="submit" value={submitBtnLoading ? "PROCESSING..." : "LOGIN"} className="w-100 btn-blue fw-bold" id={styles.input} onClick={submitPassword} />
      <p className={`text-center text-dark fw-bold ${styles.or}`}>OR</p>

      <button className="carousel-control-next btn-outline-blue w-100 p-3" type="button" id={styles.requestOTP} onClick={handleRequestOtp} disabled={requestOtpBtnLoading}>
        Request OTP
      </button>

      <p className="text-center text-dark fw-bold">
        Don't have an account?{" "}
        <Link to="/register" className="text-green" style={{fontWeight:'600'}}>
              Sign Up
            </Link>
      </p>
    </div>
  );
};

export default LoginPass;
