import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import styles from "./TermsAndConditionPage.module.css";
import Footer from "../../components/Footer/Footer";
import { termCon } from "../../services/termConditions";

const TermsAndConditionPage = () => {
  const [data,setData]=useState()
  useLayoutEffect(()=>{
     const fetchData = async ()=>{
      try{
        const response = await termCon()
        const res = await response.data
        setData(res)

      }catch(error){
        console.log(error)
      }
     }

     fetchData()
  },[])
  return (
    <>
      <div id={styles.TermsAndConditionPage}>
        <div className={styles.TermsAndConditionPage_wrap}>
          <h1>Terms and Conditions</h1>
          <div className={styles.terms_start}>
            <h2>Agreement</h2>
            <div className={styles.terms_body}>
              {data?
              (data?.map((item,index)=>(
                <>
                <div className={`${styles.intro}`} key={index}>
                <h3>{item?.id} {item?.title}</h3>
                <ul>
                  <li>
                    {item?.desc}
                  </li>
                  
                </ul>
              </div>
                </>
              ))):'Loading.....'

              }
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditionPage;
