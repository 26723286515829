import React from 'react'
import { useNavigate } from 'react-router-dom'
import style from "./BlurInfo.module.css"
const BlurInfo = ({ children, showText }) => {
    // Note This File is in use
    const navigate = useNavigate()
    return (
        <div className={style["main-blur-info-container"]}>
            <div className={style["msg-btn-container"]}>
                <div className={style["lock-icon"]}>
                    <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 9H16.25V6.5C16.25 3.05 13.45 0.25 10 0.25C6.55 0.25 3.75 3.05 3.75 6.5V9H2.5C1.125 9 0 10.125 0 11.5V24C0 25.375 1.125 26.5 2.5 26.5H17.5C18.875 26.5 20 25.375 20 24V11.5C20 10.125 18.875 9 17.5 9ZM6.25 6.5C6.25 4.425 7.925 2.75 10 2.75C12.075 2.75 13.75 4.425 13.75 6.5V9H6.25V6.5ZM17.5 24H2.5V11.5H17.5V24ZM10 20.25C11.375 20.25 12.5 19.125 12.5 17.75C12.5 16.375 11.375 15.25 10 15.25C8.625 15.25 7.5 16.375 7.5 17.75C7.5 19.125 8.625 20.25 10 20.25Z" fill="#0F1F78" />
                    </svg>
                </div>
                {showText ? <>
                    <p>Login to view full information</p>
                    <button className={style["login-btn"]} onClick={() => {
                        navigate("/login")
                    }} >Login</button>
                </> : null}
            </div>
            {children}
        </div>
    )
}

export default BlurInfo