import React from 'react'
import styles from "./FundFaq.module.css"
import dropdownicon from "../../Assets/dropdown.png"
import { useState } from 'react'
const FundFaq = () => {
    // Note This file is in use
    const [dropdown, setdropdown] = useState({
        one: "faq-dropdown",
        two: "faq-dropdown",
        three: "faq-dropdown",
        four: "faq-dropdown",
        five: "faq-dropdown",
        six: "faq-dropdown",
    })
    return (
        <div className={styles["main-faq-container"]}>
            <div className={styles[dropdown.one]}>
                <p className={styles["dropdown-btn"]} onClick={() => {
                    if (dropdown.one === "faq-dropdown") {
                        setdropdown({ ...dropdown, one: "faq-dropdown_open" })
                    } else {
                        setdropdown({ ...dropdown, one: "faq-dropdown" });
                    }
                }}>What is VentureBuddy? <img src={dropdownicon} className={dropdown.one === "faq-dropdown" ? "" : styles["img-rotate"]} alt="" />
                </p>
                <p className={styles["faq-des"]}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum voluptatum fugiat, iste similique quam omnis maxime minus, ratione fuga nam dignissimos tempora rem, odit non?</p>
            </div>
            <div className={styles[dropdown.two]}>
                <p className={styles["dropdown-btn"]} onClick={() => {
                    if (dropdown.two === "faq-dropdown") {
                        setdropdown({ ...dropdown, two: "faq-dropdown_open" })
                    } else {
                        setdropdown({ ...dropdown, two: "faq-dropdown" });
                    }
                }}>What kind of businesses can fundraise? <img src={dropdownicon} className={dropdown.two === "faq-dropdown" ? "" : styles["img-rotate"]} alt="" />
                </p>
                <p className={styles["faq-des"]}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum voluptatum fugiat, iste similique quam omnis maxime minus, ratione fuga nam dignissimos tempora rem, odit non?</p>
            </div>
            <div className={styles[dropdown.three]}>
                <p className={styles["dropdown-btn"]} onClick={() => {
                    if (dropdown.three === "faq-dropdown") {
                        setdropdown({ ...dropdown, three: "faq-dropdown_open" })
                    } else {
                        setdropdown({ ...dropdown, three: "faq-dropdown" });
                    }
                }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit? <img src={dropdownicon} className={dropdown.three === "faq-dropdown" ? "" : styles["img-rotate"]} alt="" />
                </p>
                <p className={styles["faq-des"]}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum voluptatum fugiat, iste similique quam omnis maxime minus, ratione fuga nam dignissimos tempora rem, odit non?</p>
            </div>
            <div className={styles[dropdown.four]}>
                <p className={styles["dropdown-btn"]} onClick={() => {
                    if (dropdown.four === "faq-dropdown") {
                        setdropdown({ ...dropdown, four: "faq-dropdown_open" })
                    } else {
                        setdropdown({ ...dropdown, four: "faq-dropdown" });
                    }
                }}>What are the investment terms? <img src={dropdownicon} className={dropdown.four === "faq-dropdown" ? "" : styles["img-rotate"]} alt="" />
                </p>
                <p className={styles["faq-des"]}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum voluptatum fugiat, iste similique quam omnis maxime minus, ratione fuga nam dignissimos tempora rem, odit non?</p>
            </div>
            <div className={styles[dropdown.five]}>
                <p className={styles["dropdown-btn"]} onClick={() => {
                    if (dropdown.five === "faq-dropdown") {
                        setdropdown({ ...dropdown, five: "faq-dropdown_open" })
                    } else {
                        setdropdown({ ...dropdown, five: "faq-dropdown" });
                    }
                }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit? <img src={dropdownicon} className={dropdown.five === "faq-dropdown" ? "" : styles["img-rotate"]} alt="" />
                </p>
                <p className={styles["faq-des"]}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum voluptatum fugiat, iste similique quam omnis maxime minus, ratione fuga nam dignissimos tempora rem, odit non?</p>
            </div>
            <div className={styles[dropdown.six]}>
                <p className={styles["dropdown-btn"]} onClick={() => {
                    if (dropdown.six === "faq-dropdown") {
                        setdropdown({ ...dropdown, six: "faq-dropdown_open" })
                    } else {
                        setdropdown({ ...dropdown, six: "faq-dropdown" });
                    }
                }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit? <img src={dropdownicon} className={dropdown.six === "faq-dropdown" ? "" : styles["img-rotate"]} alt="" />
                </p>
                <p className={styles["faq-des"]}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum voluptatum fugiat, iste similique quam omnis maxime minus, ratione fuga nam dignissimos tempora rem, odit non?</p>
            </div>
        </div>
    )
}

export default FundFaq