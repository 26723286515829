import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import st from "./Policy.module.css";
import { Privacy } from "../../services/PrivacyPolicys";

const PrivacyPolicy = () => {
  const [data,setData]=useState()
  useLayoutEffect(()=>{
     const fetchData = async ()=>{
      try{
        const response = await Privacy()
        const res = await response.data
        setData(res)

      }catch(error){
        console.log(error)
      }
     }

     fetchData()
  },[])
  return (
    <div className={st["main-policy-container"]}>
      <div className={st["policy-text-container"]}>
        <div className={st["policy-text-holder"]}>
          <div className={st["page-info-container"]}>
            <h1 className={st["page-heading"]}>Privacy Policy</h1>
            <p className={st["page-text"]}>
              Dravyam Ventures Private Limited value the belief and faith as
              entrusted upon them. The following Privacy Policy should be read
              along with the Terms and Conditions as set forth by Dravyam
              Ventures Private Limited for the full understanding of practices
              as well as users responsibility while interacting and accessing
              the website (hereinafter referred to as “Website”).
            </p>
            <p className={st["page-text"]}>
              Dravyam Ventures Private Limited nowhere intends to breach the Law
              of the Land wherein, this Privacy Policy is published in
              accordance with the provisions of the Indian Information
              Technology Act, 2000 and the rules made thereunder. More
              specifically, The Information Technology (Reasonable Security
              Practices and Procedures and Sensitive Personal data or
              information) Rules, 2011 and the Information Technology
              (Intermediary Guidelines) Rules, 2011.
            </p>
          </div>
          <div>
          {data?
            ( data?.map((item,index)=>(
              <>
              <div key={index}>
              <div className={st["policy-heading"]}>
              <h1>{item?.id} {item?.title}</h1>
            </div>
            <div className={st["policy-text"]}>
              <p>
                {item?.desc}
              </p>
           
            </div>
            </div>
              </>
             ))):'Loading.........'

          }                
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
