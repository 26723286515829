import React, { useState } from "react";
import technology from "./../../Assets/images/startups/tech.png";
import ecommerce from "./../../Assets/images/startups/ecommerce.png";
import wearables from "./../../Assets/images/startups/wearables.png";
import agency from "./../../Assets/images/startups//agancy.png";
import marketing from "./../../Assets/images/startups/marketing.png";
import services from "./../../Assets/images/startups/services.png";
import saas from "./../../Assets/images/startups/saas.png";
import consumer from "./../../Assets/images/startups/consumer.png";
import others from "./../../Assets/images/startups/others.png";
import styles from "./TypeOfStartUps.module.css";
import { registerNewUser, updateGoogleUser } from "../../services/account";
import useAuthCtx from "../../hooks/useAuthCtx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TypeOfStartUps = ({ currentProgress, setCurrentProgress, registerInputData, setRegisterInputData }) => {
  // Note This file is in use
  const { setIsLoggedIn, showFinishSignupModal, setShowFinishSignupModal } = useAuthCtx();
  const [buttonActive, setButtonActive] = useState(false);
  const navigate = useNavigate();
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  // console.log("this is dat a",registerInputData)







  // console.log("this is setshowsignup",showFinishSignupModal)
  const updateStartupSelection = (number) => {
    let arr = [...registerInputData.start_up_type];
    if (arr.includes(number)) arr = arr.filter((num) => num !== number);
    else arr.push(number);

    if(arr.length>=1){
      setButtonActive(true)
    }else{
      setButtonActive(false)
    }

    setRegisterInputData((prev) => {
      return { ...prev, start_up_type: arr };
    });
  };

  // console.log("this is current process",currentProgress)


  const updateUser = () => {
    console.log("hello")
    const userData = new FormData();
    userData.append("start_up_type", registerInputData.start_up_type);
    userData.append("deals_type", registerInputData.deals_type);
    userData.append("referal_code", registerInputData.referral_code);

    setSubmitBtnLoading(true);
    updateGoogleUser(userData, (err, res) => {
      setSubmitBtnLoading(false);
      if (err) return toast.error("Something went wrong. Please try again!");
      setIsLoggedIn(true);
      toast.success("Welcome to Venture Buddy.");
      setShowFinishSignupModal(false)
      navigate("/dashboard", { replace: true });
    });
  };

  return (
    <div className="text-dark pt-4 ms-lg-4" id={styles.typeOfStartUps} style={{marginTop:'-45px'}}>
      <h5 className="text-center" id={styles["heading"]} style={{paddingTop:'50px'}}>What type of startups are you looking for?</h5>

      <div className={`row text-blue text-dark pt-5 pb-0 mb-0 ${styles.typesbox}`}>
        <div id={styles.startUpContainer}>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(1) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="technology"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(1);
              }}
            >
              <img src={technology} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>Technology</h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(2) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="ecommerce"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(2);
              }}
            >
              <img src={ecommerce} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>ECommerce</h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(3) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="wearables"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(3);
              }}
            >
              <img src={wearables} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>Wearables</h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(4) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="agency"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(4);
              }}
            >
              <img src={agency} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>Agency</h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(5) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="marketing"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(5);
              }}
            >
              <img src={marketing} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>Marketing</h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(6) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="services"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-centere`}
              onClick={() => {
                updateStartupSelection(6);
              }}
            >
              <img src={services} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>Services</h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(7) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="saas"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(7);
              }}
            >
              <img src={saas} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>SAAS</h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(8) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="consumer"
          >
            <div
              className={`${styles.startUpImg} rounded-circle mt-sm-0 p-4 d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(8);
              }}
            >
              <img src={consumer} alt="" className="mx-auto img-fluid" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>
              Consumer <br /> Products
            </h6>
          </div>
          <div
            className={`${styles.startUp} ${registerInputData.start_up_type.includes(9) && styles.active} text-center py-2 p-0 d-flex align-items-center justify-content-center flex-column`}
            id="others"
          >
            <div
              className={`${styles.startUpImg} rounded-circle d-flex justify-content-center align-items-center`}
              onClick={() => {
                updateStartupSelection(9);
              }}
            >
              <img src={others} alt="" className="mx-auto" />
            </div>
            <h6 className={`pt-1 ${styles.startUpText}`}>Other</h6>
          </div>
        </div>
        <div className="text-center pb-3">
          <button
            className={`btn btn-blue px-4 mt-0 ${buttonActive ? "" : "disabled"}`}
            onClick={() => showFinishSignupModal ? updateUser() :setCurrentProgress(currentProgress + 1)}
          >
            {!submitBtnLoading ? (
              "Next"
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ margin: "auto", background: "rgba(221, 221, 221, 0)", display: "block" }}
                width="42px"
                height="42px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle cx={50} cy={50} fill="none" stroke="#f2f2f2" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                </circle>
              </svg>
            )}
          </button>
        </div>

        <button className="carousel-control-next mt-3" type="button"></button>
      </div>
    </div>
  );
};

export default TypeOfStartUps;