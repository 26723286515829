import React, { useEffect, useState } from 'react'
import styles from './Profilepreview.module.css'
import { Link } from 'react-router-dom'
import { getprofile } from '../../services/startup'
import { getprofile_pic } from '../../services/startup'
import useStartupCtx from '../../hooks/useStartupCtx'
const Profilepreview = () => {
  const [p_data, setdata] = useState([])
  const [p_image, setp_image] = useState([])
  const [loadingLoader, setLoadingLoader] = useState(true)
 const BASE_URL = 'https://venturebuddy.s3.amazonaws.com';
  useEffect(() => {
    setLoadingLoader(true)
    getprofile((err, res) => {
      if (err) return
      // console.log(res.data)
      setdata(res.data[0])
      setLoadingLoader(false)
    })
    getprofile_pic((err, res) => {
      if (err) return
      else {
        const urlportion = res?.data?.profile_info[0]?.profile_pic?.split("https://testingfrontendbanao.io/media");
        const desiredPortion = urlportion[1];
        setp_image(`${BASE_URL}${desiredPortion}`)
        setLoadingLoader(false)
      }
    })
  }, [])

  // console.log("this is profile view data",p_image)

  return (
    <>
      {loadingLoader ? (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', background: 'rgba(221, 221, 221, 0)', display: 'block' }} width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx={50} cy={50} fill="none" stroke="#000" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
          </circle>
        </svg>
      ) : (
        <div className={styles.preview_container} >
          <div className={styles.preview_wrap}>
            <h1>Profile </h1>
            <div className={styles.first_preview}>
              <div className={styles.logo_preview}>
              {p_data ? (
  <>
    {p_image?.length === 0 || !p_image ? (
      <svg className={styles.profileimg} width={46} height={48} viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M33.9405 12.9341C33.9405 7.03342 29.1571 2.25 23.2564 2.25C17.3558 2.25 12.5723 7.03342 12.5723 12.9341C12.5723 18.8347 17.3558 23.6181 23.2564 23.6181C29.1571 23.6181 33.9405 18.8347 33.9405 12.9341ZM36.1905 12.9341C36.1905 5.79078 30.3997 0 23.2564 0C16.1131 0 10.3223 5.79078 10.3223 12.9341C10.3223 18.9748 14.4635 24.0483 20.0617 25.4706C15.0108 26.0578 10.2748 28.3307 6.64334 31.9621C2.38968 36.2158 0 41.985 0 48.0006H2.25C2.25 42.5817 4.40263 37.3848 8.23433 33.5531C12.066 29.7214 17.2629 27.5688 22.6818 27.5688C28.1006 27.5688 33.2975 29.7214 37.1292 33.5531C40.9609 37.3848 43.1135 42.5817 43.1135 48.0006H45.3635C45.3635 41.985 42.9739 36.2158 38.7202 31.9622C35.2657 28.5076 30.8116 26.2825 26.0377 25.5684C31.8443 24.2959 36.1905 19.1226 36.1905 12.9341Z" fill="#595757" />
      </svg>
    ) : (
      <img src={p_image} alt="profile1" onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"} />
    )}
  </>
) : (
  <svg className={styles.profileimg} width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M33.9405 12.9341C33.9405 7.03342 29.1571 2.25 23.2564 2.25C17.3558 2.25 12.5723 7.03342 12.5723 12.9341C12.5723 18.8347 17.3558 23.6181 23.2564 23.6181C29.1571 23.6181 33.9405 18.8347 33.9405 12.9341ZM36.1905 12.9341C36.1905 5.79078 30.3997 0 23.2564 0C16.1131 0 10.3223 5.79078 10.3223 12.9341C10.3223 18.9748 14.4635 24.0483 20.0617 25.4706C15.0108 26.0578 10.2748 28.3307 6.64334 31.9621C2.38968 36.2158 0 41.985 0 48.0006H2.25C2.25 42.5817 4.40263 37.3848 8.23433 33.5531C12.066 29.7214 17.2629 27.5688 22.6818 27.5688C28.1006 27.5688 33.2975 29.7214 37.1292 33.5531C40.9609 37.3848 43.1135 42.5817 43.1135 48.0006H45.3635C45.3635 41.985 42.9739 36.2158 38.7202 31.9622C35.2657 28.5076 30.8116 26.2825 26.0377 25.5684C31.8443 24.2959 36.1905 19.1226 36.1905 12.9341Z" fill="#595757" />
  </svg>
)}

                <div>
                  <h2>
                    {p_data?.first_name} {p_data?.last_name}
                  </h2>
                  <p className={styles.userType}>Investor</p>
                </div>
              </div>
              <div className={styles.edit_btn_wrap}>
                <Link to="profile-edit">
                  <button className={styles.edit_btn}>Edit</button>
                </Link>
              </div>
            </div>
            {p_data?.address || p_data?.address === 'null' || p_data?.address === null ? (
              <div className={`${styles.second_preview} ${styles.preview_div}`}>
                <h2>Address</h2>
                <p> {p_data?.address} </p>
              </div>
            ) : (
              ' '
            )}
            <div className={`${styles.third_preview}`}>
              {p_data?.mobile || p_data?.mobile === 'null' || p_data?.mobile === 'null' ? (
                <div className={styles.preview_div}>
                  <h2>Mobile Number</h2>
                  <p> {p_data?.mobile} </p>
                </div>
              ) : (
                ''
              )}
              {p_data?.email || p_data?.email === 'null' || p_data?.email === null ? (
                <div className={styles.preview_div}>
                  <h2>Email</h2>
                  <p> {p_data?.email}</p>
                </div>
              ) : (
                ' '
              )}
            </div>

            <div className={`${styles.forth_preview} ${styles.preview_div}`}>
              <h2>User ID</h2>
              <p>{p_data?.user_id}</p>
            </div>
            {p_data?.website_link || p_data?.website_link == 'null' || p_data?.website_link == null ? (
              <div className={`${styles.fifth_preview} ${styles.preview_div}`}>
                <h2>Link your own website</h2>
                <p> {p_data?.website_link} </p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Profilepreview
