import React from "react";
import style from "./Competitors.module.css";
import aave from "./../../Assets/images/competitors/aave.png";
import useStartupCtx from "../../hooks/useStartupCtx";
import useAuthCtx from "../../hooks/useAuthCtx";
import BlurInfo from "../BlurInfo/BlurInfo";
const Competitors = ({ styles: parentStyles }) => {
  // Note This File is In use
  const { startupFullDetails } = useStartupCtx();
  const { mainDetail } = startupFullDetails;
  const { isLoggedIn } = useAuthCtx();
  const Capital = (text) => {
    const CapitalFirstLetter = text.slice(0, 1).toUpperCase();
    const otherText = text.slice(1);
    return `${CapitalFirstLetter}${otherText}`;
  };
  const ArrayForLooping = [1, 2, 3]
  return (
    <div data-scroll-id="competitors" className={style["competitors-wrapper"]}>
      <h1 className={parentStyles["sectionHeader"]}>
        Competitors
        
        <div className={parentStyles["borderBottom"]}></div>
        
      </h1>
     <div className=" pb-3" style={{borderBottom:'1px solid #CED4DA'}}>
     <p style={{marginTop:'8px',color:'#000000',fontWeight:'400'}}>Companies like {mainDetail?.competitors?.map((comp)=>(<><span style={{color:'#000000',fontWeight:'500'}}>{comp?.start_up_name}</span></>))}</p>
     </div>
      {!isLoggedIn ? <BlurInfo>
        <>
          {ArrayForLooping.map((each, idx) => {
            return <div className={`${style["competitor"]} blur-info `} key={idx}>
              <div className={style["competitor-header"]}>
                <img src={aave} alt="" />
                <p>Competitors</p>
              </div>

              <p className={style["competitor-detail"]}>
                Country State City | 0 employees
              </p>

              <div className={style["competitor-tags"]}>
                <span>Tag</span>
                <span>Tag1</span>
                <span>Tag2</span>
              </div>
            </div>
          })}

        </>
      </BlurInfo> : <>
        {mainDetail?.competitors?.map((comp) => (
          <>
            <div className={style["competitor"]} style={{borderBottom:'1px solid #CED4DA'}}>
              <div className={style["competitor-header"]}>
                <img src={comp.logo.replace("http://", "https://")} alt="" />
                <p>{comp?.start_up_name}</p>
              </div>

              <p className={style["competitor-detail"]}>
                {Capital(comp?.city)}, {Capital(comp?.state)}, {Capital(comp?.country)} | {comp?.employee_strength} employees
              </p>

              <div className={style["competitor-tags"]}>
                {comp.sector.map((item) => {
                  return <span>{item}</span>;
                })}
              </div>
            </div>
            <hr />
          </>
        ))}
      </>}


    </div>
  );
};

export default Competitors;
