import React, { useEffect, useState } from 'react';
import styles from './InvestmentDetail.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { getmyInvest_updates, getmyInvest_details, getinvest, getConvenienceFee } from '../../services/startup';
import InvestmentDocuments from '../../components/InvestmentDocuments/InvestmentDocuments';
import { downloadReceipt } from '../../utils/receiptDownload';
import { formatINR } from '../../utils';
import ToolTip from '../../components/ToolTip/ToolTip';
import Loader from '../../components/Loader/Loader.js';
import { format } from 'date-fns';

const InvestmentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [detail, setdetail] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [fundingPercentage, setFundingPercentage] = useState(0);
  const [invest, setinvest] = useState([]);
  const [viewmore, setviewmore] = useState(true);
  const [convenienceFee, setConvenienceFee] = useState(null); // State for convenience fee

  useEffect(() => {
    getmyInvest_details(id, (err, res) => {
      setLoading(false);
      if (err) return;
      setdetail(res.data);
    });
  }, [id]);

  const val = detail[0]?.startup_slug;

  useEffect(() => {
    getmyInvest_updates(val, (err, res) => {
      if (err) return;
      setdata(res.data);
      setFundingPercentage(parseInt(detail[0]?.status_percentage));
      setTimeout(() => {
        setLoading(false);
      }, 200);
    });
  }, [val]);

  useEffect(() => {
    getinvest((err, res) => {
      if (err) return;
      // console.log('Invest data:', res.data); // Log the invest data
      setinvest(res.data);
      setLoading(false);
      if (res.data.length < 7) {
        setviewmore(false);
      }
    });
  }, []);

  useEffect(() => {
    if (detail.length > 0) {
      const startupId = detail[0]?.startup_id; // Assuming the startup_id is available in the detail array
      // console.log('Startup ID:', startupId); // Log the startup ID
      getConvenienceFee(startupId, (err, res) => {
        if (err) {
          console.error('Error fetching convenience fee:', err);
          return;
        }
        // console.log('Convenience Fee Response:', res.data);
        setConvenienceFee(res.data); // Set convenience fee in state
      });
    }
  }, [detail]);

  // console.log('fund % val -> ' + fundingPercentage);

  const handleDownloadReceipt = () => {
    const investData = invest[0];
    const formattedDate = investData?.date ? format(new Date(investData.date), 'yyyy-MM-dd') : '';
    const receiptTotal = investData?.investment_amount || 0; // Initial receipt total
    const convenienceFeeAmount = convenienceFee || 0; // Convenience fee amount
    const finalTotalAmount = receiptTotal + convenienceFeeAmount; // Final total amount

    const receiptData = {
      issueDate: formattedDate,
      company: investData?.start_up_name || '',
      user: '',
      transactionId: investData?.order_id || '',
      orderId: investData?.order_id || '',
      lotPrice: formatINR(investData?.investment_amount) || '',
      qty: String(investData?.lots) || '',
      totalAmount: formatINR(receiptTotal),
      receiptTotal: formatINR(receiptTotal),
      convenienceFee: formatINR(convenienceFeeAmount),
      finalTotalAmount: formatINR(finalTotalAmount),
    };
    downloadReceipt(receiptData);
  };

  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div id={styles.investmentPortal} style={{paddingLeft:'60px'}}>
          <div className={styles.heading}>
            <h1>My Investments</h1>
            <h6 className={styles['navigation-heading']}>
              <span onClick={() => navigate('/dashboard/my-investment')}>My Investments</span>
              <span>{'>'}</span>
              <span onClick={() => navigate(`/dashboard/deal/${data[0]?.slug}`)}>{data[0]?.start_up_name}</span>
            </h6>
          </div>

          <div id={styles.portalHeader}>
            <div className={styles['startup-left-container']}>
              <div className={styles['startup-name-img']} style={{ gap: '8px' }}>
                <img src={data[0]?.logo} width="40px" height="40px" alt="" />
                <h1 className={styles['startup-name']}>{data[0]?.start_up_name}</h1>
              </div>
              <p className={styles['startup-text']}>{data[0]?.about_startup_short}</p>
              <div className={styles.tags}>
                {data[0]?.sector?.map((sec) => (
                  <button key={sec}>{sec}</button>
                ))}
                {data[0]?.tech?.map((sec) => (
                  <button key={sec}>{sec}</button>
                ))}
              </div>
            </div>
            <div className={styles['startup-right-container']}>
              <div id={styles.committed}>
                <div id={styles.committedAmount}>
                  <h1>
                    <span className="rupee">₹</span>
                    {formatINR(data[0]?.amount_raised)}
                  </h1>
                </div>
                <div id={styles.subhead}>
                  <h3 className={styles['tooltip-text']}>Commited</h3>
                  <ToolTip data={'Amount Invested by the Investors'} top="15px" />
                </div>
              </div>

              <div id={styles.chartCont}>
                <div id={styles.blue} style={{ width: `${fundingPercentage > 50 ? 50 : fundingPercentage}%` }}></div>
                <div id={styles.green} style={{ width: `${fundingPercentage > 100 ? 100 : fundingPercentage}%` }}></div>
              </div>
              <div className={styles['raised-percentage']} style={{ marginTop: '20px' }}>
                <h3>
                  <span id={styles.percentage}>{data[0]?.total_investor}</span> INVESTORS
                </h3>
              </div>
              <hr />
              <div id={styles.myInvestment}>
                <h3>
                  My Investment<span className={styles.colon}>:</span>
                </h3>
                <h1>
                  <span className="rupee">₹</span>
                  {formatINR(Number(detail[0]?.invested_amount))}
                </h1>
              </div>
              <div className={styles['download-btn']}>
                <button className={styles['invoice-download-btn']} onClick={handleDownloadReceipt}>
                  Download Receipt
                </button>
              </div>
              <hr className={styles['last-horizontal-line']} />
            </div>
          </div>

          <InvestmentDocuments />
        </div>
      )}
    </>
  );
};

export default InvestmentDetail;
