import { BASE_URL } from '../utils/constant'
import axios from 'axios'


export const Privacy = async () => {
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.get(`${BASE_URL}/posrorgprivacy/`, { headers });
      return response.data; 
    } catch (error) {
      console.log(error);
    }
  }