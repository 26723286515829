import React, { useEffect, useState } from "react";
import Translist from "./Translist";
import { Link } from "react-router-dom";
import styles from "./wallet.module.css";
import axios from "axios";
const Wallet = () => {
  const [transaction,setTransaction]=useState()
  const [total,setTotal]=useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(5);
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const token = localStorage.getItem("access_token")
  const handleGetAllTransaction = async()=>{
    const headers={
      "Authorization":`Bearer ${token}`
    }
    try{
      const response = await axios.get(`${BASE_URL}getwallettransations/`,{headers:headers})
      const res = await response.data
      setTransaction(res)
      // console.log("this is res transaction",res)
    }catch(error){}
  }
  const handleUserTotal = async()=>{
    const headers={
      "Authorization":`Bearer ${token}`
    }
    try{
      const response = await axios.get(`${BASE_URL}getwallettotal/`,{headers:headers})
      const res = await response.data
      setTotal(res[0]?.totalamount)
    }catch(error){}
  }

  useEffect(()=>{
    handleGetAllTransaction()
    handleUserTotal()
  },[])
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transaction?.slice(indexOfFirstTransaction, indexOfLastTransaction);
  const paginate = (pageNumber) =>{ 
    if(transaction?.length<6){
      setCurrentPage(1)
    }else{
      setCurrentPage(pageNumber);
    }
  }
  return (
    <>
      <div className={styles.w_container}>
        <div className={styles.w_wrapper} style={{paddingLeft:'50px'}}>
          <h1>My Wallet</h1>
          <div className={styles.head_Wrap}>
            <div className={styles.w_left}>
              <h2>₹ {total}</h2>
              {/* <div className={styles.w_buttons}>
                <Link to="add-amount">
                  <button className={styles.w_b1}>Add Money</button>
                </Link>
                <Link to="withdraw">
                  <button className={styles.w_b2}>Withdraw Money</button>
                </Link>
              </div> */}
            </div>
            <div className={styles.w_right}>
              <div className={styles.tran_sort}>
                <h1>Transaction</h1>
                <div className={styles.w_filter}>
                  <div className={styles.w_dropdown}>
                    <p>
                      Filter{" "}
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.62 5.21875L7.81667 9.02208C7.36751 9.47125 6.63251 9.47125 6.18334 9.02208L2.38 5.21875"
                          stroke="#9797A3"
                          stroke-width="1.2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </div>
                  <div className={styles.w_dropdown}>
                    <p>
                      Yearly{" "}
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.62 5.21875L7.81667 9.02208C7.36751 9.47125 6.63251 9.47125 6.18334 9.02208L2.38 5.21875"
                          stroke="#9797A3"
                          stroke-width="1.2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </div>
                  <div className={styles.w_dropdown}>
                    <p>
                      Monthly{" "}
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.62 5.21875L7.81667 9.02208C7.36751 9.47125 6.63251 9.47125 6.18334 9.02208L2.38 5.21875"
                          stroke="#9797A3"
                          stroke-width="1.2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
              </div>
              {currentTransactions?.length > 0 ? (
                currentTransactions?.map((item, index) => (
                  <Translist 
  key={index} 
  added={item.transaction_type === "add" ? `${item?.remark} used your referral code` : item?.remark}   
  date={new Date(item?.transactiondate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })} 
  amount={item?.walletmoney} 
  type={item?.transaction_type === "add" ? 'Deposited' : ''} 
/>
               
                ))
              ) : (
                <div className={styles['no_transaction']}>
                  <p>No transactions recorded</p>
                </div>
              )}
              <div className={styles.pagechange}>
                <svg  onClick={() => paginate(currentPage - 1)} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.38">
                    <path
                      d="M25.0303 22.5303C25.3232 22.2374 25.3232 21.7626 25.0303 21.4697C24.7374 21.1768 24.2626 21.1768 23.9697 21.4697L19.9697 25.4697C19.8232 25.6161 19.75 25.8081 19.75 26C19.75 26.1017 19.7702 26.1987 19.8069 26.2871C19.8435 26.3755 19.8978 26.4584 19.9697 26.5303L23.9697 30.5303C24.2626 30.8232 24.7374 30.8232 25.0303 30.5303C25.3232 30.2374 25.3232 29.7626 25.0303 29.4697L22.3107 26.75H32C32.4142 26.75 32.75 26.4142 32.75 26C32.75 25.5858 32.4142 25.25 32 25.25H22.3107L25.0303 22.5303Z"
                      fill="black"
                    />
                    <rect x="0.5" y="0.5" width="51" height="51" rx="25.5" stroke="#283891" />
                  </g>
                </svg>
                <p>{currentPage}/{Math.ceil(transaction?.length / transactionsPerPage)}</p>
                <svg onClick={() => paginate(currentPage + 1)} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.38">
                    <path
                      d="M27.4697 22.5303C27.1768 22.2374 27.1768 21.7626 27.4697 21.4697C27.7626 21.1768 28.2374 21.1768 28.5303 21.4697L32.5303 25.4697C32.8232 25.7626 32.8232 26.2374 32.5303 26.5303L28.5303 30.5303C28.2374 30.8232 27.7626 30.8232 27.4697 30.5303C27.1768 30.2374 27.1768 29.7626 27.4697 29.4697L30.1893 26.75H20.5C20.0858 26.75 19.75 26.4142 19.75 26C19.75 25.5858 20.0858 25.25 20.5 25.25H30.1893L27.4697 22.5303Z"
                      fill="black"
                    />
                    <rect x="0.5" y="0.5" width="51" height="51" rx="25.5" stroke="#283891" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
