import React, { useEffect, useState } from "react";
import styles from "./Closed.module.css";
import DealCard from "../DealCard/DealCard";
import useStartupCtx from "../../hooks/useStartupCtx";

const Closed = () => {
  // Note This File is in use
  const { allstartups } = useStartupCtx();

  const [closedStartups, setClosedStartups] = useState([]);

  useEffect(() => {
    if (allstartups.length === 0) return setClosedStartups([]);
    const filterby_deadline = allstartups.filter((startup) => {
      const MS_TIME_DIFFERENCE =
        new Date(startup.deadline).getTime() - new Date().getTime();
      const HOURS_TIME_DIFFERENCE = Math.floor(
        MS_TIME_DIFFERENCE / 1000 / 60 / 60
      );
      const DAYS_TIME_DIFFERENCE = Math.floor(HOURS_TIME_DIFFERENCE / 24);
      if (HOURS_TIME_DIFFERENCE <= 0 || !startup?.is_live) return startup;
    });
    setClosedStartups(filterby_deadline);
  }, [allstartups]);


  // console.log("this is closedStartups",closedStartups)

  return (
    closedStartups.length !== 0 && (
      <div className={styles.livedeals}>
        <div className={styles.title}>Closed Deals</div>
        <div className={styles.sub}>{closedStartups.length} Opportunities</div>
        {closedStartups.length > 0 && (
          <div className={styles["deals-contaniner"]}>
            {closedStartups.map((ele, idx) => (
              <DealCard key={idx} deal={ele} isClosed={true} />
            ))}
          </div>
        )}
        {closedStartups.length > 10 && (
          <div className="d-flex justify-content-center">
            <div className="btn btn-view">View more</div>
          </div>
        )}
      </div>
    )
  );
};

export default Closed;
