import React, { useEffect, useState } from 'react';
import style from './Community.module.css';
import style1 from '../Comments/Comments.module.css'
import Footer from '../../components/Footer/Footer';
import img1 from '../../Assets/images/bruno-ver.png';
import img2 from '../../Assets/images/emily-stern.png';
import img3 from '../../Assets/images/Niromi.png';
import img4 from '../../Assets/images/rohit.png';
import { AllThoughts, AllThoughtsUsers } from '../../services/Communitys';
import { useNavigate } from 'react-router-dom';
import Thought from './Thought';

const Community = () => {
  const [thought, setThought] = useState([]);
  const [profiles,setProfiles]=useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AllThoughts();
        setThought(response);

        
       
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

 
  const formatViewCount = (count) => {
    if (count >= 1000) {
      return (count / 1000).toFixed(1) + 'k';
    } else {
      return count.toString();
    }
  };

  const DemoTestimonial = [
    {
      des: 'The investment process is very seamless, I can invest in growing startup with as little as 10000 rs only.',
      name: 'Anushka Jain',
    },
    {
      des: 'Venturebuddy is a very good platform to invest in private market.',
      name: 'Anushka Jain',
    },
    {
      des: 'The investment process is very seamless, I can invest in growing startup with as little as 10000 rs only.',
      name: 'Anushka Jain',
    },
    {
      des: 'Venturebuddy is a very good platform to invest in private market.',
      name: 'Anushka Jain',
    },
    {
      des: 'Venturebuddy is a very good platform to invest in private market.',
      name: 'Anushka Jain',
    },
  ]

  // console.log('this is thought',thought)
  return (
    <>
      <div className={style['main-community-container']}>
        <div className={style['top-container']}>
          <div className={style['top-container-heading']}>
             <div className='wrapper' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
              <div>
              <h1>Vibrant Community</h1>
            <p className={style['sub-heading']}>A List of questions and thoughts by the users about the event.</p>
              </div>
              <button className={style1['post-btn']} style={{margin:"0px"}} onClick={()=> navigate("/post")}>Post</button>
             </div>

          </div>
          <div className={style['community-table']}>
            <table>
              <thead>
                <tr>
                  <th>Thoughts</th>
                  <th>Users</th>
                  <th>Views</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
              {thought?.length === 0 ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center' }}>
                    No data available
                  </td>
                </tr>
              ) : (
                thought?.map((each, index) => {
                  {/* const users =  fetchUsers(each.id) */}
                  {/* console.log(users) */}
                  
                  return (
                    
                    <div>
                    <Thought each={each} key={index}/>
                 </div> 
                  
                );
                {/* return null */}
                
                
              })
            )}
            <div style={{height:"50px"}}/>
              </tbody>
            </table>
          </div>
        </div>
        <div className={style['bottom-container']}>
          <div className={style['bottom-container-heading']}>
            <h1>Testimonials</h1>
            <p className={style['sub-heading']}>A List of questions and thoughts by the users about the event.</p>
          </div>
          <div className={style['testimonials-container']}>
            {DemoTestimonial.map((each, idx) => {
              return (
                <div className={style['testimonial-card']} key={idx}>
                  <p className={style['des']}> {each.des}</p>
                  <p className={style['user-name']}>{each.name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Community
