import React, { useState, useEffect } from 'react'
import style from './BusinessPlan.module.css'
import styles from '../pitchdeck/pitchdeck.module.css'
import Star from '../pitchdeck/pich_assest/star.svg'
import client_image from '../pitchdeck/pich_assest/client_image.svg'
import Style2 from '../../../components/Organizations/Organization.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'
import dhl from '../../../Assets/images/organizations/client-1-removebg-preview.png'
import next from '../../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from '../../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from '../../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from '../../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from '../../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from '../../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from '../../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from '../../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from '../../../Assets/images/organizations/client-13-removebg-preview.png'
import BlogsSlider from '../blogs/BlogsSlider'
import Faq3 from '../faqs/Faq3'
import Faq4 from '../faqs/Faq4'
import GetTouchItAll from '../gettouchit/GetTouchItAll'
import Footer from '../../../components/Footer/Footer'
import back from '../pitchdeck/pich_assest/path_bg.svg'
import hero from './business_plan_asset/business_plan_hero.svg'
import plan1 from './business_plan_asset/plan_1.svg'
import plan2 from './business_plan_asset/plan_2.svg'
import plan3 from './business_plan_asset/plan_3.svg'
import client1 from './business_plan_asset/business_1.svg'
import client2 from './business_plan_asset/business_2.svg'
import client3 from './business_plan_asset/business_3.svg'
import client4 from './business_plan_asset/business_4.svg'
import client5 from './business_plan_asset/business_5.svg'
import client6 from './business_plan_asset/business_6.svg'
import MobileBlogs from '../blogs/mobileblogs/MobileBlogs'
import MobileClientView from '../MobileClient/MobileClientView'
import MobileViewClients from '../MobileViewClients/MobileViewClients'
import Testimonial from '../../../components/Testimonial/Testimonial'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const BusinessPlan = () => {
  const [indexed, setIndexed] = useState(0)
  const navigate = useNavigate()
  const [showMain2, setShowMain2] = useState(false)
  const clientReview = [
    {
      id: 1,
      name: 'Rohit Ransore',
      status: 'INVESTER',
      profession: 'UX DESIGNER,ATG',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 2,
      name: 'Jeniffer White',
      status: 'INVESTER',
      profession: 'Vice President, Google',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 3,
      name: 'Niromi Taylor',
      status: 'BUSNINESS',
      profession: 'Senior Manager, IKEA',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
  ]

  function startprinting() {
    let start = clientReview[indexed].review[0].review_star
    let starts = []

    for (let i = 1; i <= start; i++) {
      starts.push(<img src={Star} />)
    }

    return starts
  }
  const data = [
    {
      question: 'How does your process work for creating custom pitch decks?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What sets your pitch deck services apart from others?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'Is there a sample report or a free trial available?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What is the authenticity of the tool?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'How does Venturebuddy protect my data?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What information do you need from clients to start working on their pitch deck?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
  ]
  const plans = [
    {
      title: 'Expert Insight:',
      detail: 'Tap into our seasoned financial expertise for comprehensive due diligence analysis tailored to your needs.',
      image: plan1,
    },
    {
      title: 'Customized Approach',
      detail: 'Benefit from personalized solutions that address your unique business concerns and goals.',
      image: plan2,
    },
    {
      title: 'Risk Management: ',
      detail: 'Navigate transactions confidently with our thorough risk assessment, ensuring informed decision-making and safeguarding your investments.',
      image: plan3,
    },
  ]
  const clientlogos = [client1, client2, client3, client4, client5, client6]
  const handlenavigate = () => {
    navigate('/contact')
  }
  useEffect(() => {
    // Delay the visibility of the main2 section
    const timer = setTimeout(() => {
      setShowMain2(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])
  return (
    <>
      <section className={style['main1']}>
        <div className={styles['pitch_hero']}>
          <img src={back} className={style['back_image']} />
          <div className={styles['detail_hero']}>
            <div>
              <div className={styles['detail']}>
                <h1 className={styles['hero_text']}>
                  Elevate Decision-Making with Strategic <br /> Financial Due Diligence
                </h1>
                <h1 className={style['hero_text2']} style={{ textAlign: 'center',lineHeight:"40px" }}>
                  Unlock Your Business Potential with Our Professional Business Plan Template
                </h1>
                <p>
                  Count on us for investment decisions that are crystal clear, precise, and <br />
                  backed by rock-solid financial due diligence
                </p>
              </div>
              <div className={styles['pitch_image_1']}>
                <img src={hero} className={styles['pitch_image']} style={{marginTop:'-20px'}} />
              </div>
              <div className={styles['download_btn']} style={{ display: 'flex' ,flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <button onClick={() => handlenavigate()}>Contact us</button>
                
                <p style={{color:'#6D747A',fontWeight:'400',fontSize:'16px',paddingTop:'16px'}}>Free and User-Friendly!</p>
              </div>
            </div>
          </div>
          <div className={styles['white_bg']} />
        </div>
      </section>
      <br />
      <br />
      <br />
      {showMain2 && (
        <section className={style['main2']}>
          <div className={styles['pitch_deck_everyone_wrapper']}>
            <div className={styles['pitch_deck_everyone']} style={{ height: '386px' }}>
              <h1>
                Venturebuddy’s Expert Transaction Advisory:
                <br /> Navigate Deals with Confidence
              </h1>
              <div className={styles['everyone_detail']}>
                <p>Financial Due Diligence | Post Deal Compliances | M&A Taxation | Business Valuation</p>
              </div>
              <div>
                <button style={{ marginTop: '35px', width:'150px' }} onClick={() => handlenavigate()}>
                  Lets get started
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main3']}>
          <div className={style['plan_heading']}>
            <h1>Financial Due Diligence Services</h1>
            <div className={style['plan_heading_two']}>
              <h2 style={{ color: '#6D747A' }}>
                We offer potential investors a comprehensive assessment encompassing a company's business model, earnings stability, cash flow viability, asset integrity, and financial data accuracy.
                
              </h2>
            </div>
          </div>

          <br />
          <div className={style['plan_wrapper']}>
            {plans.map((item, index) => (
              <>
                <div className={style['plan_card01']} key={index}>
                  <img src={item.image} />
                  <h3>{item.title}</h3>
                  <div className={style['plans_detail']}>
                    <h4>{item.detail}</h4>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className={styles['download_btn']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '35px' }}>
            {/* <button onClick={() => handlenavigate()}>Create Your Business Plan</button> */}
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main4']}>
          <Testimonial />
        </section>
      )}
      {/* {showMain2 && (
        <div className={style['mobile_view_client']}>
          <MobileClientView />
        </div>
      )} */}
      <br />
      {showMain2 && (
        <section className={style['main5']} style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
          <div id="Style2.organizations" className="container-fluid" style={{ height: '705px', background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
            <div style={{ paddingTop: '250px', width: '100%' }}>
              <>
                <h4 className="text-blue text-center" id={Style2.orgHead}>
                  Our Top Clients
                </h4>
                {/* Desktop Slider Start Here */}
                <div className={style['company_wrapper']}>
                  <div className={style['company_main']}>
                    <img src={client1} />
                    <img src={client2} />
                    <img src={client3} />
                    <img src={client4} />
                    <img src={client5} />
                    <img src={client6} />
                  </div>
                </div>
                {/* Desktop Slider End Here */}
              </>
            </div>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main05']}>
          <MobileViewClients clientlogos={clientlogos} />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main6']}>
          <BlogsSlider />
        </section>
      )}
      {showMain2 && (
        <section className={style['main06']}>
           <MobileBlogs />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main7']} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '750px' }}>
            <Faq3 data={data} />
            {/* <Faq4 data={data} /> */}
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main8']}>
          <GetTouchItAll />
        </section>
      )}
      <br />
      {showMain2 && <Footer />}
    </>
  )
}

export default BusinessPlan
