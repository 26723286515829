import axios from 'axios'

import { BASE_URL } from '../utils/constant'

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
  }
  return headers
}

export const sendGetInTouchForm = (data, cb) => {
  const headers = getHeaders()
  axios
    .post(`${BASE_URL}/getintouch/`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}
export const Testimonial = async () => {
  try {
    const url = `${BASE_URL}/getwhatclientsayaboutus/`
    const data = await fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    const res = await data.json()
    return res
  } catch (error) {
    if (error) {
      return
    }
  }
}
export async function getWealthManagerData() {
  const url = `${BASE_URL}/wealth_managers/`
  const data = await fetch(url, {
    method: 'GET',
    headers: getHeaders(),
  })
  const res = await data.json()
  return res
}
export async function postPartner(info) {
  try {
    const url = `${BASE_URL}/partners/`
    const data = await fetch(url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ ...info }),
    })
    const res = await data.json()
    return res
  } catch (error) {
    if (error) {
      return 'Internal Server Error'
    }
  }
}
export const sendCallbackReq = (data, cb) => {
  const headers = getHeaders()
  axios
    .post(`${BASE_URL}/request_call/`, data, { headers })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null))
}