import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../../Assets/images/logo.png";
import styles from "./Nav_bi.module.css";
const Nav_bi = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.header}>
        <div className={styles.wrap}>
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              navigate("/dashboard/live-deals");
            }}
          >
            <path
              d="M5.78033 1.53033C6.07322 1.23744 6.07322 0.762563 5.78033 0.46967C5.48744 0.176777 5.01256 0.176777 4.71967 0.46967L0.71967 4.46967C0.573223 4.61612 0.5 4.80806 0.5 5C0.5 5.10169 0.520239 5.19866 0.556909 5.28709C0.593509 5.37555 0.647763 5.45842 0.71967 5.53033L4.71967 9.53033C5.01256 9.82322 5.48744 9.82322 5.78033 9.53033C6.07322 9.23744 6.07322 8.76256 5.78033 8.46967L3.06066 5.75H12.75C13.1642 5.75 13.5 5.41421 13.5 5C13.5 4.58579 13.1642 4.25 12.75 4.25H3.06066L5.78033 1.53033Z"
              fill="black"
            />
          </svg>
          <Link to={"/dashboard/live-deals"}>
            <img src={logo} className="img-fluid col-10 col-lg-8" alt="" />
          </Link>
        </div>
      </div>
    </>
  );
};
export default Nav_bi;
