import React, { useState, useEffect } from 'react'
import style from './BookingKeeping.module.css'
import styles from '../pitchdeck/pitchdeck.module.css'
import booking_01 from './bookingkeeping_assests/booking_01.svg'
import booking_02 from './bookingkeeping_assests/booking_02.svg'
import booking_03 from './bookingkeeping_assests/booking_03.svg'
import booking_04 from './bookingkeeping_assests/booking_04.svg'
import booking_05 from './bookingkeeping_assests/booking_05.svg'
import booking_06 from './bookingkeeping_assests/booking_06.svg'
import client_image from '../pitchdeck/pich_assest/client_image.svg'
import Star from '../pitchdeck/pich_assest/star.svg'
import Faq3 from '../faqs/Faq3'
import GetTouchItAll from '../gettouchit/GetTouchItAll'
import Footer from '../../../components/Footer/Footer'
import Style2 from '../../../components/Organizations/Organization.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper'

import dhl from '../../../Assets/images/organizations/client-1-removebg-preview.png'
import next from '../../../Assets/images/organizations/client-2-removebg-preview.png'
import aviva from '../../../Assets/images/organizations/client-4-removebg-preview.png'
import santander from '../../../Assets/images/organizations/client-6-removebg-preview.png'
import healthShield from '../../../Assets/images/organizations/client-7-removebg-preview.png'
import serco from '../../../Assets/images/organizations/client-8-removebg-preview.png'
import client_10 from '../../../Assets/images/organizations/client-10-removebg-preview.png'
import client_11 from '../../../Assets/images/organizations/client-11-removebg-preview.png'
import client_12 from '../../../Assets/images/organizations/client-12-removebg-preview.png'
import client_13 from '../../../Assets/images/organizations/client-13-removebg-preview.png'
import BlogsSlider from '../blogs/BlogsSlider'
import back from '../pitchdeck/pich_assest/path_bg.svg'
import hero from './bookingkeeping_assests/booking_hero.svg'
import green_bg from '../pitchdeck/pich_assest/bg_green_all.svg'
import package1 from './bookingkeeping_assests/package_1.svg'
import package2 from './bookingkeeping_assests/package_2.svg'
import package3 from './bookingkeeping_assests/package_3.svg'
import package4 from './bookingkeeping_assests/package_4.svg'
import Packages from './packages/Packages'
import software1 from './bookingkeeping_assests/software_1.svg'
import software2 from './bookingkeeping_assests/software_2.svg'
import software3 from './bookingkeeping_assests/software_3.svg'
import software4 from './bookingkeeping_assests/software_4.svg'
import software5 from './bookingkeeping_assests/software_5.svg'
import software6 from './bookingkeeping_assests/software_6.svg'
import Client1 from './bookingkeeping_assests/business_1.svg'
import Client2 from './bookingkeeping_assests/business_2.svg'
import Client3 from './bookingkeeping_assests/business_3.svg'
import Client4 from './bookingkeeping_assests/business_4.svg'
import Client5 from './bookingkeeping_assests/business_5.svg'
import Client6 from './bookingkeeping_assests/business_6.svg'
import MobileBlogs from '../blogs/mobileblogs/MobileBlogs'
import MobileClientView from '../MobileClient/MobileClientView'
import MobileViewClients from '../MobileViewClients/MobileViewClients'
import Testimonial from '../../../components/Testimonial/Testimonial'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const BookingKeeping1 = () => {
  const [indexed, setIndexed] = useState(0)
  const [showMain2, setShowMain2] = useState(false)
  const navigate = useNavigate()
  const pitchdata = [
    {
      title: '1. Best Affordable Service',
      detail: "Quality accounting solutions that won't break the bank.",
      Image: booking_01,
    },
    {
      title: '2. No Hidden Costs',
      detail: "Transparent pricing ensures you know exactly what you're paying for.",
      Image: booking_02,
    },
    {
      title: '3. Expert Review',
      detail: 'Expert oversight guarantees accuracy and compliance.',
      Image: booking_03,
    },
    {
      title: '4. Save Time',
      detail: 'Streamlined processes free up your valuable time for other tasks.',
      Image: booking_04,
    },
    {
      title: '5. Accounting Standards Compliance',
      detail: 'Ensure adherence to IND AS/IFRS for regulatory compliance.',
      Image: booking_05,
    },
    {
      title: '6. Accuracy',
      detail: 'Precise and reliable financial reporting sets the foundation for success.',
      Image: booking_06,
    },
  ]
  const clientReview = [
    {
      id: 1,
      name: 'Rohit Ransore',
      status: 'INVESTER',
      profession: 'UX DESIGNER,ATG',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 2,
      name: 'Jeniffer White',
      status: 'INVESTER',
      profession: 'Vice President, Google',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
    {
      id: 3,
      name: 'Niromi Taylor',
      status: 'BUSNINESS',
      profession: 'Senior Manager, IKEA',
      client_photo: client_image,
      review: [
        {
          review_title: 'It was a great experience!',
          review_star: 5,
          review_main: 'It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience It was a great experience ',
        },
      ],
    },
  ]
  const data = [
    {
      question: 'How does your process work for creating custom pitch decks?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What sets your pitch deck services apart from others?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'Is there a sample report or a free trial available?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What is the authenticity of the tool?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'How does Venturebuddy protect my data?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
    {
      question: 'What information do you need from clients to start working on their pitch deck?',
      answer: 'Based on the data provided by you, we use globally accepted scientific methods and various metrics to carry out the valuation.',
    },
  ]

  function startprinting() {
    let start = clientReview[indexed].review[0].review_star
    let starts = []

    for (let i = 1; i <= start; i++) {
      starts.push(<img src={Star} />)
    }

    return starts
  }
  const accountDetail = ['Day to Day accounting', 'Basic MIS report Generation', 'Financial Statement Preparation', 'Bank Reconciliations', 'Multicurrency Accounting', 'Finalization of Accounts', 'Third Party Confirmations', 'Cashflow Statement']

  const accountDetail2 = ['Setting up accounts in software which client required', 'Basic Setting up online downloads from financial institutions ', 'Support Services', 'Mapping the accounts for tax preparation purposes', 'Creating templates for invoices', '24/7 access to financial data on the cloud', 'Setting up online downloads from financial institutions']

  const packageAccout = [
    {
      id: 1,
      title1: 'Start up Phase Accounting',
      title2: 'Turnover up to Rs 5 Crore',
      price: '$ 50',
      image: package1,
      color: '#2FB6FA',
    },
    {
      id: 2,
      title1: 'Growth Phase Accounting',
      title2: 'Turnover from Rs 5 Crore to 25 Crores',
      price: '$ 150',
      image: package2,
      color: '#F68C09',
    },
    {
      id: 3,
      title1: 'Expansion Phase Accounting',
      title2: 'Turnover from Rs 25 Crore to 100 Crores',
      price: '$ 300',
      image: package3,
      color: '#08DA4E',
    },
    {
      id: 4,
      title1: 'Unicorn Phase Accounting',
      title2: 'Turnover above 100 Crores',
      price: 'On call',
      image: package4,
      color: '#F760A6',
    },
  ]

  const clientlogos = [Client1, Client2, Client3, Client4, Client5, Client6]
  useEffect(() => {
    // Delay the visibility of the main2 section
    const timer = setTimeout(() => {
      setShowMain2(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  const handlenavigate = ()=>{
    navigate("/contact")
   }
   const wallet = ()=>{
    navigate("/dashboard/wallet")
   }


  return (
    <>
      <section className={style['main']}>
        <div className={styles['pitch_hero']}>
          <img src={back} className={style['back_image']} />
          <div className={styles['detail_hero']}>
            <div>
              <div className={styles['detail']} style={{ paddingTop: '45px' }}>
                <h1 className={styles['hero_text']}>Streamline Your Finances</h1>
                <h1 className={style['hero_text2']} style={{ textAlign: 'center' }}>
                  Streamline Your<br/> Finances
                </h1>
                <p>Say Goodbye to Wasting Money and Time on Accounting.</p>
              </div>
              <div className={styles['pitch_image_1']} style={{marginTop:"0px"}}>
                <img src={hero} className={styles['pitch_image']} />
              </div>
              <div className={styles['download_btn']}>
                <button style={{ width: '180px',marginTop:"-20px" }} onClick={() => handlenavigate()}>
                  Get Started now!
                </button>
              </div>
            </div>
          </div>
          <div className={styles['white_bg']} />
        </div>
      </section>
      <br />
      {showMain2 && (
        <section className={style['main2']} >
          <div className={style['accoutning_heading']}>
            <h1>Accounts Outsourcing</h1>
            <h2>We offer complete Bookkeeping & Accounting service at reasonable price .</h2>
          </div>
          <br />
          <div className={style['acoount_main']}>
            <div className={style['accoint_list1']}>
              {accountDetail.slice(0, 4).map((item, index) => (
                <div className={style['card_list_1']} key={index}>
                  <p>{item}</p>
                </div>
              ))}
            </div>
            <div className={style['accoint_list2']}>
              {accountDetail.slice(4).map((item, index) => (
                <div className={style['card_list_1']} key={index}>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      <br />
      {/* {showMain2 && (
        <section className={style['main3']}>
          <div className={style['Package_headeing']}>
            <h1>Packages for Accounting <br/>and Bookkeeping Services</h1>
            <h2>Take advantage of our bundle services and save time and money</h2>
            <br />
          </div>
          <br />
          <div className={style['main_package_bg']} style={{marginLeft:"0px"}}>
            <img src={green_bg} className={style['mian_package_image_green']} />
            <div className={style['package_wrapper_list']}>
              <div className={style['package_wrapper_list_2']} style={{ marginTop: '5px',width:'100%' }}>
                {packageAccout.map((item, index) => (
                  <>
                    <div className={style['package_card_02']} style={{ width: '291px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '15px', backgroundColor: 'white', borderRadius: '8px', borderBottom: `4px solid ${item.color}`, height: '484px',marginLeft:'0px' }} key={index}>
                      <div className="package_image" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img src={item.image} style={{ marginTop: index === 0 || index === 3 ? '-13px' : '-16px' }} />
                      </div>
                      <div className="package_detail">
                        <h2 style={{ fontSize: '28px', color: '#08090A', fontWeight: '600', textAlign: 'center', paddingTop: '25px' }}>{item.title1}</h2>
                        <h3 style={{ fontSize: '16px', color: '#000000', textAlign: 'center', fontWeight: '500', paddingTop: '10px' }}>{item.title2}</h3>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: index === 0 || index === 3 ? '20px' : '0' }}>
                          <h4 style={{ color: '#0F1F78', fontSize: '32px', fontWeight: '700', textAlign: 'center', display: 'flex' }}>
                            {item.price} <span style={{ color: '#0F1F78', fontSize: '16px', fontWeight: '500', display: `${item.price === 'On call' ? 'none' : 'block'}`, paddingTop: '14px', paddingLeft: '6px' }}>per Month</span>
                          </h4>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '22px' }}>
                          <button style={{ width: '110px', height: '48px', backgroundColor: 'white', border: '1px solid #0F1F78', borderRadius: '8px', color: '#0F1F78', fontWeight: '600',marginBottom:"-100px" }} onClick={() => wallet()}>
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            {showMain2 && (
              <section className={style['main4']}>
                <div className={style['accoutning_heading']}>
                  <h1>Accounts Outsourcing</h1>
                  <h2>We offer complete Bookkeeping & Accounting service at reasonable price .</h2>
                </div>
                <br />
                <div className={style['acoount_main']}>
                  <div className={style['accoint_list1']}>
                    {accountDetail2.slice(0, 2).map((item, index) => (
                      <div className={style['card_list_1']} key={index}>
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                  <div className={style['accoint_list2']}>
                    {accountDetail2.slice(2, 5).map((item, index) => (
                      <div className={style['card_list_1']} key={index}>
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                  <div className={style['accoint_list2']}>
                    {accountDetail2.slice(5).map((item, index) => (
                      <div className={style['card_list_1']} key={index}>
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}
          </div>
        </section>
      )} */}
      <br />

      <br />
      {showMain2 && (
        <section className={style['main5']}>
        
          <Packages />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main6']}>
          <div className={style['software_heaing']}>
            <h1>Top Accounting Software we are working with</h1>
          </div>
          <br />
          <div className={style['company_icons']}>
            <img src={software1} />
            <img src={software2} />
            <img src={software3} />
            <img src={software4} />
            <img src={software5} />
            <img src={software6} />
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main7']}>
          <div className={styles['wrapper_pitch']} style={{ paddingLeft: '25px', paddingRight: '25px' }}>
            <div className={styles['pitch_deck_detail1']} style={{ width: '100%' }}>
              <div className={styles['deatil_2']} style={{ width: '100%' }}>
                <h1>Efficient Accounting Solutions Tailored to Your Needs</h1>
                <div className={styles['small_detail']}>
                  <p style={{ fontWeight: '500' }}>
                    Experience Transparent, Affordable Services with Expert Oversight for
                    <br /> Maximum Accuracy
                  </p>
                </div>
              </div>
              <br />
              <div className={styles['detail_list']} style={{ flexWrap: 'wrap' }}>
                {pitchdata.map((item, index) => (
                  <>
                    <div className={styles['pitch_deatil_card']} key={index}>
                      <img src={item.Image} />
                      <div style={{ width: `${item.title === '5. Accounting Standards Compliance' ? '70%' : ''}`, textAlign: `${item.title === '5. Accounting Standards Compliance' ? 'center' : ''}` }}>
                        <h2>{item.title}</h2>
                      </div>
                      <div className={styles['detail_03']}>
                        <h6>{item.detail}</h6>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main8']}>
          <Testimonial />
        </section>
      )}
      {showMain2 && (
        <div className={styles['client_about_detail']}>
          <h1>What Clients say About us</h1>
        </div>
      )}
      {showMain2 && (
        <div className={style['mobile_view_client']}>
          <MobileClientView />
        </div>
      )}
      {showMain2 && (
        <section className={style['main7']} style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
          <div id="Style2.organizations" className="container-fluid" style={{ height: '705px', background: 'linear-gradient(180deg, #FFFFFF 0%, #EBF8ED 27.4%, #EBF8ED 74.79%, #FFFFFF 100%)' }}>
            <>
              <div style={{ paddingTop: '250px', width: '100%' }}>
                <h4 className="text-blue text-center" id={Style2.orgHead}>
                  Our Top Clients
                </h4>
                {/* Desktop Slider Start Here */}
                <div className={style['company_container']}>
                  <div className={style['company_main']}>
                    <img src={Client1} />
                    <img src={Client2} />
                    <img src={Client3} />
                    <img src={Client4} />
                    <img src={Client5} />
                    <img src={Client6} />
                  </div>
                </div>
                {/* Desktop Slider End Here */}
              </div>
            </>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main07']}>
          <MobileViewClients clientlogos={clientlogos} />
        </section>
      )}
      <br />
      {showMain2 && (
        <section className={style['main8']}>
          <BlogsSlider />
        </section>
      )}
      {showMain2 && (
        <section className={style['main08']}>
          <MobileBlogs />
        </section>
      )}
      {showMain2 && (
        <section className={style['main8']} style={{ display: 'flex', justifyContent: 'center', marginTop: '120px' }}>
          <div style={{ width: '750px' }}>
            <Faq3 data={data} page="Book keeping"/>
          </div>
        </section>
      )}
      {showMain2 && (
        <section className={style['main10']}>
          <GetTouchItAll />
        </section>
      )}
      {showMain2 && <Footer />}
    </>
  )
}

export default BookingKeeping1
