import axios from 'axios';
import { BASE_URL } from '../utils/constant';

const token = localStorage.getItem("access_token")

 
export const AllThoughts = async () => {
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.get(`${BASE_URL}/getthought/`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const AllThoughtsUsers = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  try {
    const response = await axios.get(`${BASE_URL}/getprofiles/${id}/`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const CommentsView = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.get(`${BASE_URL}/getthought/${id}/`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }

}

export const ThoughtComment = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.get(`${BASE_URL}/getthoughtcomment/${id}/`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }

}

export const ViewReplyComment = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
  };
  // console.log("this is idd" ,id)
  
  if(id===undefined)return
  try{
    const response = await axios.get(`${BASE_URL}/getreplycomment/${id}/`,{headers})
    return response.data

  }catch(error){
    throw error
  }
}

export const LikeComment = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };
  try{
    const response = await axios.get(`${BASE_URL}/likecomment/${id}/`,{headers})
    return response.data
  }catch(error){
    throw error
  }
}

export const DoComment = async(commentData)=>{
  
  const headers = {
    'Content-Type': 'application/json',
    'Authorization' :`Bearer ${token}`
  };

  try {
    const response = await axios.post(`${BASE_URL}/postthoughtcomment/`, commentData, {headers});
    return response.data;
  } catch (error) {;
    throw error;
  }
};

export const ReplyLike = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
    'Authorization' :`Bearer ${token}`
  };

  try {
    const response = await axios.get(`${BASE_URL}/likereplycomment/${id}/`,  {headers});
    return response.data;
  } catch (error) {;
    throw error;
  }


}

export const NewPost1 = async (data) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  try {
    const response = await axios.post(`${BASE_URL}/postthought/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CommentReply = async (data) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  try {
    const response = await axios.post(`${BASE_URL}/postreplycomment/`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const TrueComment = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
    'Authorization' :`Bearer ${token}`
  };

  if(id===undefined)return
  try {
    const response = await axios.get(`${BASE_URL}/getlikecomment/${id}/`,  {headers});
    return response.data;
  } catch (error) {;
    throw error;
  }


}

export const DisLikeComment = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
    'Authorization' :`Bearer ${token}`
  };

  try {
    const response = await axios.get(`${BASE_URL}/dislikecomment/${id}/`,  {headers});
    return response.data;
  } catch (error) {;
    throw error;
  }


}

export const True2Comment = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
    'Authorization' :`Bearer ${token}`
  };

  try {
    const response = await axios.get(`${BASE_URL}/getlikereplycomment/${id}/`,  {headers});
    return response.data;
  } catch (error) {;
    throw error;
  }


}

export const DisLikeComment2 = async(id)=>{
  const headers = {
    'Content-Type': 'application/json',
    'Authorization' :`Bearer ${token}`
  };
  try {
    const response = await axios.get(`${BASE_URL}/dislikereplycomment/${id}/`,  {headers});
    return response.data;
  } catch (error) {;
    throw error;
  }


}