// import { getAuth } from "firebase/auth";
import React, { useState, useContext } from "react";
// import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import app from "../../firebase.init";
import gIcon from "./../../Assets/icons/google-icon.png";
import styles from "./LoginEmail.module.css";
import { useGoogleLogin } from "@react-oauth/google";
import { authContext } from "../../Contexts/Authcontext";
import { isEmptyField, validateEmail, validatePhone } from "../../utils";
import { checkUserName, googleLogin } from "../../services/account";
import ErrorIcon from "../../Assets/vector/Error";
import GoogleLoginButton from "../GoogleLoginButton/GoogleLoginButton";
// import GoogleLogin from 'react-google-login';
// import { OAuth2Client } from "google-auth-library";

// WORKING: File is in use.
const LoginEmail = ({ loginData, setLoginData, currentLoginProgress, setCurrentLoginProgress }) => {
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [inputErrMsg, setInputErrMsg] = useState(null);

  const handleValidate = async (e) => {
    e.preventDefault();

    if (isEmptyField(loginData.username)) return setInputErrMsg("This field is required!");

    if (!validateEmail(loginData.username) && !validatePhone(loginData.username)) return setInputErrMsg("Enter a valid username!");
    setSubmitBtnLoading(true);
    checkUserName(loginData.username, (err, res) => {
      setSubmitBtnLoading(false);
      if (err || !res.data.message) return setInputErrMsg("This username is not registered!");
      setCurrentLoginProgress(currentLoginProgress + 1);
    });
  };

  return (
    <div id={styles.LoginEmail}>
      <div id="login-right" className="text-dark">
        <form className="my-auto d-flex flex-column justify-content-between">
          <h1 className="my-0">Login</h1>
          <h6
            style={{
              fontWeight: 500,
              marginTop: 18,
              marginBottom: 12,
            }}
          >
            Email ID/Mobile Number
          </h6>
          <div className={`${inputErrMsg && "form-input-err"}`}>
            <input
              type="text"
              className="form-control p-3"
              value={loginData.username}
              id="emailOrPhoneInput"
              onChange={(e) => {
                setLoginData((prev) => {
                  return { ...prev, username: e.target.value };
                });
                setInputErrMsg(null);
              }}
              placeholder="Enter Email ID or Mobile Number"
              required
            />
            {inputErrMsg && (
              <p className={`input-err-msg`}>
                <ErrorIcon fill="red" width="15px" height="15px" />
                <span>{inputErrMsg}</span>
              </p>
            )}
          </div>

          <input disabled={submitBtnLoading} type="submit" value="CONTINUE" onClick={handleValidate} className="w-100 btn-blue " id="continue" /> 

          <p className="text-center fw-bold or" id={styles.or} >OR</p>

          <GoogleLoginButton text="Login with Google" />

          <p className="text-center" id="signUp" style={{ marginTop: "5px" }}>
            Don't have an account?{" "}
            <Link to="/register" className="text-green" style={{fontWeight:'600'}}>
              Sign Up
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginEmail;
