import React from 'react'
import style from './AddFundRaise.module.css'
import addIcon from '../../Assets/icons/+white-plus.png'
import { useRef } from 'react'
import { useState } from 'react'
import heart from '../../Assets/icons/Iconheart.png'
import FundFaq from '../../components/FundRaiseFaq/FundFaq'
import { toast } from 'react-toastify'
import { addfundraise } from '../../services/startup'
import Footer from '../../components/Footer/Footer'
import { useEffect } from 'react'
import { validateEmail } from '../../utils'
import Plus from '../../Assets/icons/plus_icon.svg'
const AddFundRaise = () => {
  const Fileref = useRef()
  const [File, setFile] = useState(null)
  const [loading, setloading] = useState(false)
  const [PhoneNumber, setPhoneNumber] = useState(null)
  const [EmailError, setEmailError] = useState('')
  const [PhoneError, setPhoneError] = useState('')
  const [LocationError,setLocationError]=useState('')
  const [Data, setData] = useState({
    name: '',
    location: '',
    about: '',
    number: '',
    email: '',
  })
  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  const ValidatePhone = () => {
    let error = false
    if (PhoneNumber.slice(5).replace(' ', '').length < 10 || PhoneNumber.slice(5).replace(' ', '').length > 10 || PhoneNumber==="") {
      error = true
    }
    alphabet.forEach((each) => {
      if (Data.number.includes(each) || Data.number.includes(each.toLowerCase())) {
        error = true
      }
    })
    return error
  }
  const Add = async () => {
    setEmailError('')
    const alphabetRegex = /^[A-Za-z ]+$/;
    const locationRegex = /^[A-Za-z ,]+$/;

    const numberRegex = /^[0-9 -]+$/;

    if (/[a-zA-Z]/.test(PhoneNumber) || PhoneNumber.length!==10) {
      return setPhoneError('Please enter a valid contact number containing only numbers.');
    }
    else{
      setPhoneError('')
    }
    if(!locationRegex.test(Data.location)){
     return setLocationError('invalid location')
    }else{
      setLocationError('')
    }
    // Validation Email
    const isValidEmail = validateEmail(Data.email)

    if (!isValidEmail) {
      setEmailError('Invalid Email')
      return
    }
    setloading(true)
    const data = await addfundraise({
      company_name: Data.name,
      about_ur_company: Data.about,
      location: Data.location,
      phone: PhoneNumber.slice(5),
      email: Data.email,
      upload_files: File,
    })
    setloading(false)
    if (data.status === 200) {
      setPhoneError('')
      setLocationError('')
      toast.info(data.message)
      setFile(null)
      setData({
        name: '',
        location: '',
        about: '',
        number: '',
        email: '',
      })
    } else if (data.status === 400) {
      setFile(null)
      toast.error('Something Went Wrong')
    } else if (data.catchError) {
      setFile(null)
      toast.error(data.catchError)
    }
  }
  useEffect(() => {
    if (!Data.number) {
      setData({ ...Data, number: '' })
    }
  }, [Data.number])
  return (
    <div className={style['main-container']}>
      <div className={style['input-container']}>
        <div className={style['input-heading']}>
          <p className={style['main-heading']}>Raise on Venturebuddy</p>
          <p className={style['complement-heading']}>Back people and capital will follow. Venturebuddy allows every high growth potential founder to raise and create value</p>
        </div>
        <div className={style['input-holder']}>
          <label htmlFor="">
            Company Name
            <input
              type="text"
              value={Data.name}
              onChange={(e) => {
                setData({ ...Data, name: e.target.value })
              }}
              placeholder="Company Name"
            />
          </label>
          <label htmlFor="">
            Location
            <input
              type="text"
              value={Data.location}
              onChange={(e) => {
                setData({ ...Data, location: e.target.value })
              }}
              placeholder="Company Location"
            />
          </label>
          {LocationError && <span className={style['error']}>{LocationError}</span>}
          <label htmlFor="">
            About your company
            <textarea
              placeholder="Tell us little bit about your company"
              value={Data.about}
              onChange={(e) => {
                setData({ ...Data, about: e.target.value })
              }}
            ></textarea>
          </label>
          <label htmlFor="">
            Upload your Pitchdeck
            <div className={style['file-upload-container']}>
              {File ? <p className={style['file-placeholder']}>1 File Selected</p> : <p className={style['file-placeholder']}>Choose your file</p>}

              <img
                src={Plus}
                className={style['addIcon']}
                alt=""
                onClick={() => {
                  Fileref.current.click()
                }}
              />
          <input
  type="file"
  ref={Fileref}
  accept="video/*"
  onChange={(e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.type.startsWith('image/')) {
      alert('Please select a video file.');
      e.target.value = null;
    } else {
      setFile(selectedFile);
    }
  }}
  style={{ display: 'none' }}
/>
            </div>
            <div className={style['file-name']}>
              <p className={style['name']}>{File?.name}</p>
            </div>
          </label>
          <label htmlFor="">
            Contact number
            <input
              type="text"
              id={style['overflow-hidden']}
              pattern="[+0-9]"
              placeholder="+91 - "
              value={Data.number}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
                setData({ ...Data, number: e.target.value })
              }}
            />
            {PhoneError && <span className={style['error']}>{PhoneError}</span>}
          </label>
          <label htmlFor="">
            Email
            <input
              type="email"
              placeholder="Email"
              value={Data.email}
              onChange={(e) => {
                setData({ ...Data, email: e.target.value })
              }}
            />
            {EmailError && <span className={style['error']}>Invalid Email</span>}
          </label>
        </div>
        <div className={style['submit-btn']}>
          {loading ? (
            <button className={style['loader']} disabled={true}>
              {' '}
              <span></span>{' '}
            </button>
          ) : (
            <button disabled={!Data.name || !Data.email || !Data.location || !Data.number || !Data.about || !File || loading ? true : false} onClick={Add}>
              Submit
            </button>
          )}
        </div>
      </div>
      {/* ------- */}
      <div className={style['benifit-container']}>
        <div className={style['benifit-heading']}>
          <p>Benefits of raising capital on Venturebuddy</p>
        </div>
        <div className={style['benifit-options']}>
          {/* Card 1 */}
          <div className={style['benifit-card']}>
            <div className={style['image-holder']}>
              <img src={heart} alt="" />
            </div>
            <p className={style['option-card-heading']}>Large investors portfolio</p>
            <p className={style['option-card-des']}>With X business raised X from X investors, venturebuddy allows to raise for every high value startup</p>
          </div>
          {/* Card 2 */}
          <div className={style['benifit-card']}>
            <div className={style['image-holder']}>
              <img src={heart} alt="" />
            </div>
            <p className={style['option-card-heading']}>Fast processing</p>
            <p className={style['option-card-des']}>Our hassle free process allows to utilize funds as soon as the round is complete with no hidden costs involved.</p>
          </div>
          {/* Card 3 */}
          <div className={style['benifit-card']}>
            <div className={style['image-holder']}>
              <img src={heart} alt="" />
            </div>
            <p className={style['option-card-heading']}>Small investors are your superfans</p>
            <p className={style['option-card-des']}>Distribution is KING. Small investors fuel capital and build high gowth community</p>
          </div>
        </div>
      </div>
      {/* Faq */}
      <div className={style['faq-container']}>
        <div className={style['faq-heading']}>
          <p>Frequently Asked Questions</p>
        </div>
        <div className={style['faq-holder']}>
          <FundFaq />
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default AddFundRaise
