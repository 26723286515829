import React from 'react'
import ManagerCard from '../../components/WealthManagerCard/ManagerCard'
import style from './WealthManager.module.css'
import Image1 from '../../Assets/images/Pie chart-rafiki 1.png'
import Image3 from '../../Assets/images/freepik--Characters--inject-30.png'
import Image2 from '../../Assets/images/Wallet-rafiki 1.png'
import illustration from '../../Assets/images/Investing-rafiki 1.png'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Pagination, Navigation, Autoplay } from 'swiper'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Footer from '../../components/Footer/Footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import PartnerCard from '../../components/PartnerCard/PartnerCard'
import linkedin from '../../Assets/icons/linkedin-dark.png'
import { useEffect } from 'react'
import { useState } from 'react'
import cross from '../../Assets/plus.png'
import { getWealthManagerData, postPartner } from '../../services/contact'
import { useNavigate } from 'react-router-dom'
import { validateEmail } from '../../utils'
const Wealthmanager = () => {
  const { width } = useWindowDimensions()
  const [ShowModal, setShowModal] = useState('main-modal-container_closed')
  const [Name, setName] = useState('')
  const [Email, setEmail] = useState('')
  const [Number, setNumber] = useState('')
  const [NumberErr, setNumberErr] = useState('')
  const [EmailErr, setEmialErr] = useState('')
  const [PartnerData, setPartnerData] = useState([])
  const [Loading, setLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (ShowModal === 'main-modal-container') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [ShowModal])
  const SubmitForm = async () => {
    setEmialErr('')
    setNumberErr('')
    if (Number.length > 10 || Number.length < 10) {
      setNumberErr('Invalid Number')
      return
    }
    const isValidEmail = validateEmail(Email)
    if (!isValidEmail) {
      setEmialErr('Invalid Email')
      return
    }
    setLoading(true)
    const data = await postPartner({ name: Name, email: Email, contact_number: Number })
    setLoading(false)
    if (data?.message) {
      setShowModal('main-modal-container')
    }
  }
  const Fetch = async () => {
    const data = await getWealthManagerData()
    if (data?.data?.length < 0) return
    setPartnerData(data?.data)
  }
  useEffect(() => {
    const naviagtor = document.querySelector('.swiper-pagination-bullets')
    naviagtor.id = 'wealtmanager-page-swiper-navigator'
    const first_next_btn = document.querySelector('.swiper-button-next')
    const first_prev_btn = document.querySelector('.swiper-button-prev')
    first_next_btn.id = 'wealthmanager-navigation-btn-right'
    first_prev_btn.id = 'wealthmanager-navigation-btn-left'
    Fetch()
  }, [])
  return (
    <div className={style['main-wealth-manager-page-container']}>
      <div className={style[ShowModal]}>
        <div className={style['modal-message-container']}>
          <p>Thank You for Registering</p>
          <button
            onClick={() => {
              setShowModal('main-modal-container_closed')
            }}
          >
            Done
          </button>
          <img
            src={cross}
            className={style['close-btn']}
            onClick={() => {
              setShowModal('main-modal-container_closed')
            }}
            alt=""
          />
        </div>
      </div>
      <section className={style['hero-section']}>
        <div className={style['hero-text-container']}>
          <h1 className={style['hero-heading']}>
            {' '}
            <span className={style['text-green']}>Be a Wealth Manager with</span> <br /> <span className={style['text-blue']}>Venturebuddy-</span>{' '}
            <br/>
            <span>
              <i>
                {' '}
                your <br /> alternative investments techbuddy!
              </i>
            </span>
          </h1>
          <p className={style['sub-heading']}>
            Ab dusro ke saath, <br />
            Create karo apni bhi Wealth
          </p>
        </div>
        <div className={style['hero-form-container']}>
          <div className={style['form-heading']}>
            <h1>Join our Partner Program</h1>
          </div>
          <div className={style['main-form']}>
            <label htmlFor="Name">
              Name
              <input
                type="text"
                placeholder="Enter your name"
                value={Name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </label>
            <label htmlFor="Email">
              Email
              <input
                type="email"
                placeholder="Enter your email Id"
                value={Email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              <span className={style['error']}>{EmailErr}</span>
            </label>
            <label htmlFor="Phone">
              Contact Number
              <input
                type="number"
                placeholder="Enter your mobile number"
                value={Number}
                id={style['contact-number']}
                onChange={(e) => {
                  setNumber(e.target.value)
                }}
              />
              <span className={style['error']}>{NumberErr}</span>
            </label>
            <button className={style['form-btn']} onClick={SubmitForm} disabled={!Email || !Name || !Number ? true : false}>
              {Loading ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </div>
      </section>
      <section className={style['benifits-container']}>
        <div className={style['benifit-options']}>
          <div className={style['benifit-heading']}>
            <h1>Benefits of Joining Partner Program</h1>
          </div>
          <div className={style['manager-card-wrapper']}>
            <ManagerCard index={1} heading={'Diversification'} subheading={'Diversify your client’s portfolio in Startups'} img={Image1} uniqueId="hide-background-color" />
            <ManagerCard index={2} heading={'Monetary Benefits'} subheading={'Earn commission on the invested amount by your investor for lifetime'} img={Image2} reverse={true} />
            <br />
            <ManagerCard index={3} heading={'Vetted Startups'} subheading={'Start ups on our platform are vetted and promising'} img={Image3} />
          </div>
        </div>
        <div className={style['dialog-box']}>
          <p>Investing in startup companies can be a game changer for your client's growth story with potential returns!</p>
          <button
            className={style['dialog-btn']}
            onClick={() => {
              navigate('/explore')
            }}
          >
            Explore Startup
          </button>
          <div className={style['illustration']}>
            <img src={illustration} alt="" />
          </div>
        </div>
      </section>
      <section className={style['swiper-container']}>
        <div className={style['team-heading']}>
          <p id={style['team']}>Here are our Partners in Growth</p>
        </div>
        <div className={style['team-swiper']}>
          <Swiper
            slidesPerView={width < 1000 ? 1 : 3}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {PartnerData?.map((each, idx) => {
              return (
                <SwiperSlide>
                  <PartnerCard team_data={{ pic: each?.photo, name: each?.name, icon: linkedin, proffesion: each?.description, url: each?.linkedin_link, data: '' }} key={idx} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Wealthmanager
