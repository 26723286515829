import { BASE_URL } from '../utils/constant'

const getHeaders = (type) => {
    const token = localStorage.getItem('access_token')
    let headers = {}
    if (type === 'form') headers['Content-Type'] = 'multipart/form-data'
    else headers['Content-Type'] = 'application/json'
  
    if (token) headers['Authorization'] = `Bearer ${token}`
    return headers
  }

export const getFounders = async () => {
    try {
      const url = `${BASE_URL}/getfounders/`
      const data = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
      })
      const res = await data.json()
      return res
    } catch (error) {
      if (error) {
        return
      }
    }
  }

export const getTeam = async () => {
    try {
      const url = `${BASE_URL}/getteams/`
      const data = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
      })
      const res = await data.json()
      return res
    } catch (error) {
      if (error) {
        return
      }
    }
  }

export const getAdvisors = async () => {
    try {
      const url = `${BASE_URL}/getadvisors/`
      const data = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
      })
      const res = await data.json()
      return res
    } catch (error) {
      if (error) {
        return
      }
    }
  }