import React from "react";
import styles from "./comfirm_withdraw.module.css";
import hdfc from "../../Assets/images/hdfc.png";

const Confirm_withdraw = () => {
  return (
    <div className={styles.cw_container}>
      <div className={styles.cw_wap}>
        <h1>Confirm Withdrawal</h1>
        <div className={`${styles.cw_box} ${styles.cw_amount}`}>
          <p>Withdrawal amount</p>
          <h2>₹ 18,700</h2>
        </div>
        <div className={`${styles.cw_box} ${styles.cw_from}`}>
          <p>From</p>
          <h3>Wallet</h3>
        </div>
        <div className={`${styles.cw_box} ${styles.cw_to}`}>
          <h2>To</h2>
          <div className={styles.bank_details}>
            <h1>**** - 0837 - **** - 3846</h1>
            <img src={hdfc} />
          </div>
        </div>
        <div className={`${styles.cw_box} ${styles.cw_pay}`}>
          <p>Pay with</p>
          <h3>Net banking</h3>
        </div>
        <div className={`${styles.cw_box} ${styles.cw_fee}`}>
          <p>Transaction fee</p>
          <h3>Free</h3>
        </div>
        <button>Confirm Withdrawal</button>
      </div>
    </div>
  );
};

export default Confirm_withdraw;
