import React, { useEffect, useState } from 'react'
import RegisterEmail from '../../components/RegisterEmail/RegisterEmail'
import RegisterVerification from '../../components/RegisterVerification/RegisterVerification'
import RegisterPass from '../../components/RegisterPass/RegisterPass'
import loginImage from './../../Assets/images/login-image.png'
import TypeOfDeals from '../../components/TypeOfDeals/TypeOfDeals'
import TypeOfStartUps from '../../components/TypeOfStartUps/TypeOfStartUps'
import styles from './Register.module.css'
import { useNavigate } from 'react-router-dom'
import useAuthCtx from '../../hooks/useAuthCtx'
import TermAndCondition from './TermAndCondtion/TermAndCondition'

const Register = () => {
  const navigate = useNavigate()
  const { isLoggedIn, registerInputData, setRegisterInputData } = useAuthCtx()

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard', { replace: true })
    }
  }, [isLoggedIn])

  const [currentRegisterProgress, setCurrentRegisterProgress] = useState(0)
  // ### For understaning purpose only -
  // Total Progress Steps = {
  //   0: "name-username",
  //   1: "otp-verification",
  //   2: "set-password",
  //   3: "select-deals",
  //   4: "select-startup",
  // };

  // useEffect(() => {
  //   if (showFinishSignupModal) setCurrentRegisterProgress(3);
  //   else setCurrentRegisterProgress(0);
  // }, [showFinishSignupModal]);

  // console.log("this is main currentprocess",currentRegisterProgress)

  return (
    <div className="px-0 pt-2 pt-lg-2" id="register" style={{marginTop:'70px',maxWidth:"80%",alignSelf:"center",margin:"auto", }}>
      <div className="row mx-auto col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 justify-content-center" style={{height:'570px'}}>
        <div className={`text-center col-5 d-none  ${currentRegisterProgress === 0 || currentRegisterProgress === 1 || currentRegisterProgress === 2 ? 'd-lg-block' : 'd-none'} `} id={styles.registerLeft}>
          <p className="text-white" id={styles.registerPara}>
            “Don't shoot your shot, without testing your aim”
          </p>
          <img src={loginImage} className="mx-auto" alt="" />
        </div>

        <div className={`col-12 col-md-12 ${currentRegisterProgress === 0 || currentRegisterProgress === 1 || currentRegisterProgress === 2 ? 'col-lg-7' : ''}`} id={styles.registerRight}>
          <div>
            {currentRegisterProgress === 0 && (
              <RegisterEmail currentRegisterProgress={currentRegisterProgress} setCurrentRegisterProgress={setCurrentRegisterProgress} registerInputData={registerInputData} setRegisterInputData={setRegisterInputData} />
              // <div className={`carousel-item active `} id="register-email-page">
              // </div>
            )}
            {currentRegisterProgress === 1 && (
              <RegisterVerification currentRegisterProgress={currentRegisterProgress} setCurrentRegisterProgress={setCurrentRegisterProgress} registerInputData={registerInputData} setRegisterInputData={setRegisterInputData} />
              // <div className={`carousel-item active `}>
              // </div>
            )}
            {currentRegisterProgress === 2 && (
              <RegisterPass currentRegisterProgress={currentRegisterProgress} setCurrentRegisterProgress={setCurrentRegisterProgress} registerInputData={registerInputData} setRegisterInputData={setRegisterInputData} />
              // <div className={`carousel-item active `}>
              // </div>
            )}
            {currentRegisterProgress === 3 && <TypeOfDeals currentProgress={currentRegisterProgress} setCurrentProgress={setCurrentRegisterProgress} registerInputData={registerInputData} setRegisterInputData={setRegisterInputData} />}
            {currentRegisterProgress === 4 && <TypeOfStartUps currentProgress={currentRegisterProgress} setCurrentProgress={setCurrentRegisterProgress} registerInputData={registerInputData} setRegisterInputData={setRegisterInputData} />}
            {currentRegisterProgress === 5 && <TermAndCondition registerInputData={registerInputData} setRegisterInputData={setRegisterInputData}/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register